import { FormikCustomSelector } from 'components/Controls';
import { useState, useEffect } from 'react';
import { searchCustomersWithExternal } from 'shared/AsyncSearchUtils/searchCustomersWithExternal';

export const CustomerSelectorCell = ({
  cell,
  row,
  fuzzyErrors,
  organization,
  customerIdSourceAndType,
  formRef,
  CustomSingleOption,
}) => {
  const [selectOptions, setSelectOptions] = useState([]);

  const value = cell?.value;
  const fuzzyError = fuzzyErrors?.[row.original.rowIndex]?.customer_id;

  useEffect(() => {
    const searchCustomers = async () => {
      const response = await searchCustomersWithExternal({
        customerIdSourceAndType,
        searchQuery: value,
        orgId: organization?.id,
        paginated: false,
      });

      setSelectOptions(response);
    };

    if (value) {
      searchCustomers();
    } else {
      setSelectOptions([]);
    }
  }, [value, customerIdSourceAndType, organization]);

  return (
    <>
      <FormikCustomSelector
        height={32}
        useDefaultSelect={true}
        components={{ Option: CustomSingleOption }}
        placeholder="Select id..."
        minWidth={180}
        menuWidth={220}
        errorWithoutTooltip
        value={value ? { value: value, label: value } : null}
        isClearable={true}
        options={selectOptions}
        name={`[${row.original.rowIndex}].customer_id`}
        hasWarning={!!fuzzyError}
        tooltipInputDisplay={fuzzyError ? `Search for ${fuzzyError?.searchedName}` : null}
        handleChange={(option) => {
          formRef?.current?.setFieldValue(`[${row.original.rowIndex}].customer_id`, option?.value ?? null);
        }}
      />
    </>
  );
};
