import { TooltipContainer } from 'components/Tooltip';
import styled from 'styled-components';
import { capitalize, SEARCH_CONFIDENCE } from 'utils/stringUtils';

const FuzzyName = styled.span`
  color: ${({ textColor }) => textColor ?? 'black'};
`;

export const displayInterpretedValue = ({ entityName, interpretedValue, title, confidence, onClick }) => {
  let textColor;
  let toolTipContent;
  switch (confidence) {
    case SEARCH_CONFIDENCE.EXACT:
      textColor = 'var(--primaryGreen)';
      toolTipContent = `Found a ${entityName} with the exact name "${interpretedValue}" (AI interpreted from contract)`;
      break;
    case SEARCH_CONFIDENCE.FUZZY:
      textColor = 'var(--primaryYellow)';
      toolTipContent = `Found a ${entityName} with a similar name to "${interpretedValue}" (AI interpreted from contract)`;
      break;
    case SEARCH_CONFIDENCE.NO_MATCH:
      textColor = 'var(--primaryRed)';
      toolTipContent = `Could not find a ${entityName} with a similar name to "${interpretedValue}" (AI interpreted from contract). ${capitalize(
        entityName,
      )} is required`;
      break;
    default:
      textColor = 'black';
      toolTipContent = undefined;
  }
  return (
    <TooltipContainer toolTipContent={toolTipContent} width={300} isVisible={!!toolTipContent}>
      <FuzzyName textColor={textColor} data-cy={`${entityName}-table__row__customer-cell`} onClick={onClick}>
        {title}
      </FuzzyName>
    </TooltipContainer>
  );
};
