export const METADATA_TYPES = {
  TRANSACTIONS: 'transactions',
  CUSTOMERS: 'customers',
  PRODUCTS: 'products',
};

export const METADATA_DATA_TYPES = {
  STRING: 'string',
  BOOLEAN: 'boolean',
  DATETIME: 'datetime',
  NUMBER: 'number',
};
