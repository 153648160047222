import { useContext, useEffect, useState } from 'react';

import { AppContext } from 'AppContext';
import { getMetadataOptions } from 'api/metadata';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';
import { BillingDynamicFieldsTooltip, DYNAMIC_FIELD_TOOLTIP_SECTIONS } from 'views/Billing/BillingDynamicFieldsTooltip';
import { FormikCustomCheckbox, FormikCustomInput } from 'components/Controls';
import { CentererVertical } from 'components/Core';
import { templateToHTMLString } from 'utils/htmlUtils';
import { METADATA_FILTER_TYPES } from 'shared/Filters/MetadataFilter';
import { DEFAULT_AVAILABLE_TAGS, DESCRIPTION_TAGS } from 'consts/billing';

import { FormRow, PaddingWrapper } from '../styles';

export const AutoChargeInvoiceSendingSettings = ({ values }) => {
  const { orgId } = useContext(AppContext);

  const [transactionMetadataTags, setTransactionMetadataTags] = useState([]);
  const [customerMetadataTags, setCustomerMetadataTags] = useState([]);

  const availableTags = [
    ...DEFAULT_AVAILABLE_TAGS,
    ...transactionMetadataTags,
    ...customerMetadataTags,
    ...DESCRIPTION_TAGS,
  ];

  useEffect(() => {
    const getAvailableTags = async () => {
      // TODO: Rewrite to use useMetadataOptionsAPI hook
      const metadataOptions =
        (await getMetadataOptions({
          orgId,
          types: [METADATA_FILTER_TYPES.TRANSACTIONS, METADATA_FILTER_TYPES.CUSTOMERS],
        })) || {};

      setTransactionMetadataTags(
        metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS]
          ? Object.keys(metadataOptions[METADATA_FILTER_TYPES.TRANSACTIONS]).map(
              (tag) => `{transaction_metadata_${tag}}`,
            )
          : [],
      );

      setCustomerMetadataTags(
        metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS]
          ? Object.keys(metadataOptions[METADATA_FILTER_TYPES.CUSTOMERS]).map((tag) => `{customer_metadata_${tag}}`)
          : [],
      );
    };

    if (orgId) {
      getAvailableTags();
    }
  }, [orgId]);

  return (
    <FieldBody style={{ padding: 0, overflow: 'hidden' }}>
      <PaddingWrapper>
        <FieldTitle>Send invoices prior to auto-charging (Beta)</FieldTitle>
        <FormikCustomCheckbox
          name="autoChargeInvoiceSendingDefaults.auto_send"
          label="Auto send invoices to customers on auto charge before charging"
          data-cy="auto-charge-invoice-sending-setting__auto-send--checkbox"
        />

        {values.autoChargeInvoiceSendingDefaults?.auto_send && (
          <>
            <FormRow marginTop="16px" childrenWidth="100%">
              <FormikCustomInput
                name="autoChargeInvoiceSendingDefaults.days_before"
                label={`Send invoice to be auto charged how many business days before invoice date?`}
                placeholder="e.g. 2"
                value={values?.autoChargeInvoiceSendingDefaults?.days_before ?? 2}
                data-cy="auto-charge-invoice-sending-settings__days-before"
              />
            </FormRow>

            <FormRow marginTop="16px" childrenWidth="100%">
              <FormikCustomInput
                name="autoChargeInvoiceSendingDefaults.email_subject"
                label="Invoice Email Subject"
                HTMLString={templateToHTMLString({
                  text: values?.autoChargeInvoiceSendingDefaults?.email_subject,
                  availableTags,
                })}
                availableTags={availableTags}
                TopRightHeader={
                  <CentererVertical gap="8px">
                    <BillingDynamicFieldsTooltip
                      tags={{
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.DESCRIPTION_TAGS]: DESCRIPTION_TAGS,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                      }}
                      XOffset={100}
                    />
                  </CentererVertical>
                }
                type="HTMLEdit"
                placeholder="Enter Invoice Email Subject..."
                data-cy="auto-charge-invoice-sending-settings__email-subject--input"
              />
            </FormRow>

            <FormRow marginTop="16px" childrenWidth="100%">
              <FormikCustomInput
                name="autoChargeInvoiceSendingDefaults.email_body"
                minHeight="186px"
                label="Invoice Email Body"
                HTMLString={templateToHTMLString({
                  text: values?.autoChargeInvoiceSendingDefaults?.email_body,
                  availableTags,
                })}
                availableTags={availableTags}
                TopRightHeader={
                  <CentererVertical gap="8px">
                    <BillingDynamicFieldsTooltip
                      tags={{
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.DESCRIPTION_TAGS]: DESCRIPTION_TAGS,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                        [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                      }}
                      XOffset={100}
                    />
                  </CentererVertical>
                }
                type="HTMLEdit"
                placeholder="Enter Invoice Email Body..."
                data-cy="auto-charge-invoice-sending-settings__email-body--input"
              />
            </FormRow>
          </>
        )}
      </PaddingWrapper>
    </FieldBody>
  );
};
