import React, { useState, useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { truncate } from 'lodash';

import { AppContext } from 'AppContext';
import { ReactComponent as UploadIcon } from 'images/upload-csv-icon.svg';
import { ReactComponent as UploadedIcon } from 'images/uploaded-csv-icon.svg';
import { FlexerColumn, FlexerRow, TextBox, Spacer, Centerer } from 'components/Core';
import { Button } from 'components/Buttons';
import { fileToBase64 } from 'components/Controls/FileUploader/useFileUploader';
import { pluralize } from 'utils/stringUtils';
import { CircleLoader } from 'components/Loaders';
import { useContractsAPI } from 'api/contracts';

const Uploader = styled.div`
  min-height: 88px;
  padding: 20px;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({ isDragActive, fileLoaded }) =>
    isDragActive ? (fileLoaded ? 'var(--primaryBlue10)' : 'var(--primaryBlue5)') : 'var(--primaryBlue3)'};
  border: 1px dashed var(--primaryBlue);
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background-color: var(--primaryBlue5);
    border: 1px dashed var(--primaryBlue);
  }
`;

const IconWrapper = styled(Centerer)`
  width: 58px;
  height: 58px;
`;

const ClickableCancel = styled(TextBox)`
  &:hover {
    font-weight: 900;
  }
`;

const ContractsUploader = () => {
  const { orgId } = useContext(AppContext);

  const {
    operations: { bulkAddContracts, refetch },
  } = useContractsAPI({ orgId, autoFetch: false });

  const [files, setFiles] = useState([]);

  const handleSubmit = async ({ files }) => {
    const mappedFiles = [];
    for (const file of files) {
      const base64File = await fileToBase64(file);

      mappedFiles.push({
        attachments: [
          {
            name: file.name,
            base64_file: base64File,
            type: file.type,
            size: file.size,
            service: 'subscript',
            uploaded_at: new Date().toISOString(),
          },
        ],
      });
    }

    await bulkAddContracts.mutateAsync({
      data: {
        contracts: mappedFiles,
        opts: {
          interpret: true,
        },
      },
    });

    setFiles([]);
    refetch();
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Filter to include only PDF files
    const pdfFiles = acceptedFiles.filter((file) => file.type === 'application/pdf');

    // Store files in state
    setFiles((prevFiles) => [...prevFiles, ...pdfFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    multiple: true,
  });

  const isUploaded = !!files?.length;
  const uploadedFilesTextPreTruncate = files
    .map((file) => `${file.name} (${Math.round(file.size / 1024)}kb)`)
    .join(', ');
  const totalFilesSize = files.reduce((acc, file) => acc + file.size, 0);
  const uploadedFilesText = `${truncate(uploadedFilesTextPreTruncate, { length: 500 })} (total size: ${Math.round(
    totalFilesSize / 1024,
  )}kb)`;

  return (
    <Uploader {...getRootProps()} isDragActive={isDragActive} fileLoaded={isUploaded}>
      <input {...getInputProps()} />
      <FlexerRow alignItems="center" justifyContent="space-between">
        <FlexerRow alignItems="center">
          <IconWrapper>{isUploaded ? <UploadedIcon /> : <UploadIcon />}</IconWrapper>
          <Spacer width="10px" />
          <FlexerColumn>
            <div>
              {isUploaded ? (
                bulkAddContracts.isLoading ? (
                  <>
                    <TextBox>{`You're uploading ${pluralize(files.length, 'file')}. Please wait...`}</TextBox>
                    <CircleLoader width="20px" />
                  </>
                ) : (
                  <>
                    <TextBox>{`You've uploaded ${pluralize(files.length, 'file')}. `}</TextBox>
                    <ClickableCancel
                      color="var(--primaryRed)"
                      onClick={(evt) => {
                        setFiles([]);
                        evt.stopPropagation();
                      }}
                    >
                      Cancel
                    </ClickableCancel>
                  </>
                )
              ) : isDragActive ? (
                <TextBox>Drop the files here...</TextBox>
              ) : (
                <>
                  <TextBox>Upload one or multiple </TextBox>{' '}
                  <TextBox color="var(--primaryBlue)"> PDF files with contracts</TextBox>
                </>
              )}
            </div>
            <Spacer height="5px" />
            <TextBox opacity="0.5">
              {isUploaded ? uploadedFilesText : '(pdf only, less than 50mb, only first 10 pages processed)'}
            </TextBox>
          </FlexerColumn>
        </FlexerRow>
        <Button
          color="green"
          disabled={!isUploaded || bulkAddContracts.isLoading}
          filled
          active
          onClick={(evt) => {
            handleSubmit({ files });
            evt.stopPropagation();
          }}
        >
          <TextBox color="white" fontWeight="700">
            Confirm
          </TextBox>
        </Button>
      </FlexerRow>
    </Uploader>
  );
};

export default ContractsUploader;
