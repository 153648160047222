import axios from 'axios';
import { API_URL } from 'api/consts';

import { hydrateTransactionBTF, hydrateTransactionsBTF } from './hydration';

const buildUrl = ({ orgId, transactionId, suffix }) => {
  let url = `${API_URL}/organizations/${orgId}/transactions`;

  if (transactionId) url = `${url}/${transactionId}`;
  if (suffix) url = `${url}/${suffix}`;

  return url;
};

export const getTransactions = async ({ orgId, filters = {}, metadataFilter = {} }) => {
  const { page = 1, limit = 10, params, body, scopes = [] } = filters;

  const response = await axios.post(
    buildUrl({ orgId, suffix: 'filter' }),
    { ...body, scopes, metadataFilter },
    { params: { page, limit, ...params } },
  );

  response.data.data = hydrateTransactionsBTF(response.data.data);

  return response.data;
};

export const getTransaction = async ({ orgId, transactionId, params }) => {
  const response = await axios.get(buildUrl({ orgId, transactionId }), { params });
  const hydratedTransaction = hydrateTransactionBTF(response.data);
  return hydratedTransaction;
};

export const createTransaction = async ({ orgId, body }) => {
  const response = await axios.post(buildUrl({ orgId }), body);
  return response.data;
};

export const updateTransaction = async ({ orgId, transactionId, body, params }) => {
  const response = await axios.patch(buildUrl({ orgId, transactionId }), body, { params });
  return response.data;
};

export const deleteTransaction = async ({ orgId, transactionId, params }) => {
  const response = await axios.delete(buildUrl({ orgId, transactionId }), { params });
  return response.data;
};

export const restoreTransaction = async ({ orgId, transactionId }) => {
  const response = await axios.post(buildUrl({ orgId, transactionId, suffix: 'unarchive' }));
  return response.data;
};

export const duplicateTransaction = async ({ orgId, transactionId }) => {
  const response = await axios.post(buildUrl({ orgId, transactionId, suffix: 'duplicate' }));
  return response.data;
};

export const splitTransaction = async ({ orgId, transactionId, body }) => {
  const response = await axios.post(buildUrl({ orgId, transactionId, suffix: 'split' }), body);
  return response.data;
};

export const bulkConfirmTransactions = async ({ orgId, transactionIds }) => {
  const response = await axios.post(buildUrl({ orgId, suffix: 'bulk-confirm' }), { transactionIds });
  return response.data;
};

export const bulkUploadTransactions = async ({ orgId, transactions, params }) => {
  const response = await axios.post(buildUrl({ orgId, suffix: 'bulk-upload' }), { transactions }, { params });

  if (response?.data?.data?.createdTransactions?.length || response?.data?.data?.updatedTransactions?.length) {
    response.data.data.createdTransactions = hydrateTransactionsBTF(response.data?.data.createdTransactions);
    response.data.data.updatedTransactions = hydrateTransactionsBTF(response.data?.data.updatedTransactions);
  }

  return response.data;
};

export const bulkDeleteTransactions = async ({ orgId, transactionIds, params }) => {
  const response = await axios.delete(
    buildUrl({ orgId, suffix: 'bulk-delete' }),
    { data: { transactionIds } },
    { params },
  );
  return response.data;
};

export const getTransactionSpreads = async ({ orgId, transactionId, params }) => {
  const response = await axios.get(buildUrl({ orgId, transactionId, suffix: 'spreads' }), { params });
  return response.data;
};

export const generateAccountingSpreads = async ({ orgId, body }) => {
  const response = await axios.post(buildUrl({ orgId, suffix: 'generate-accounting-spreads' }), body);
  return response.data;
};

export const getTransactionAudits = async ({ orgId, transactionId }) => {
  const response = await axios.get(buildUrl({ orgId, transactionId, suffix: 'audits' }));
  return response.data;
};

export const getTransactionAnalysis = async ({ orgId, transactionId, params }) => {
  const response = await axios.get(buildUrl({ orgId, transactionId, suffix: 'analysis' }), { params });
  return response.data;
};

export const bulkReviewTransactions = async ({ orgId, transactionIds }) => {
  const response = await axios.post(buildUrl({ orgId, suffix: 'review' }), { transactionIds });
  return response.data;
};

export const getTillCancelMonthlyIncrementPercentages = async ({ orgId, params }) => {
  const response = await axios.get(buildUrl({ orgId, suffix: 'till-cancel-monthly-increment-percentages' }), {
    params,
  });
  return response.data;
};
