import styled from 'styled-components';

import { Centerer, Flexer } from 'components/Core';
import { TrashIcon } from 'components/Icons';
import { ReactComponent as PencilIcon } from 'images/pencil-new.svg';
import { ReactComponent as CheckIcon } from 'images/check-no-circle.svg';

export const TitleSubText = styled.div`
  font-size: 14px;
`;

export const AddButton = styled(Centerer)`
  width: fit-content;
  border-radius: 8px;
  padding: 8px;
  background: var(--primaryBlue10);
  color: var(--primaryBlue);
  font-size: 10px;
  font-weight: 900;

  cursor: pointer;

  &:hover {
    background: ${({ disabled }) => (disabled ? 'var(--primaryBlue10)' : 'var(--primaryBlue20)')};
  }
`;

export const Table = styled.div`
  width: 100%;
  margin: 16px 0;
`;

export const TableSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1.5fr 1.5fr 1fr 1fr 1fr 1fr;
  border-radius: 16px;
  border: 1px solid #f3f3f3;
  background: white;
  padding: ${({ padding }) => padding};
`;

export const TableHeader = styled(Flexer)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
  color: var(--primaryBlack75);
  align-self: center;
  align-items: center;
  width: 100%;
`;

export const TableMainCell = styled(Flexer)`
  align-items: center;
  align-self: center;
  border: 1px solid #f3f3f3;
  border-left: none;
  height: 100%;
  font-size: 12px;
  width: 100%;
  padding: ${({ padding }) => padding};
  background-color: ${({ nonEditable }) => (nonEditable ? 'var(--primaryBlack2)' : 'white')};

  // every last cell of a row
  &:nth-child(7n + 7) {
    border-right: none;
  }

  // the first 7 cells
  &:nth-child(-n + 7) {
    border-top: none;
  }

  // the last 7 cells
  &:nth-last-child(-n + 7) {
    border-top: none;
    border-bottom: none;
  }

  &:first-child {
    border-top-left-radius: 16px;
  }

  &:nth-child(7) {
    border-top-right-radius: 16px;
  }

  &:nth-last-child(7) {
    border-bottom-left-radius: 16px;
  }

  &:last-child {
    border-bottom-right-radius: 16px;
  }
`;

export const Counter = styled(Centerer)`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: var(--primaryBlack5);
  font-weight: 900;
  font-size: 9px;
`;

export const CurrencySymbol = styled.div`
  font-weight: 700;
  color: var(--primaryBlack50);
`;

export const DatePickerWrapper = styled.div`
  & svg {
    path {
      fill: var(--primaryBlack50);
    }
  }

  & input {
    border: none;
    height: 50px;

    &:hover {
      border: none;
    }
  }
`;

export const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
`;

export const StyledPencilIcon = styled(PencilIcon)`
  cursor: pointer;
  width: 16px;
  height: 16px;

  path {
    fill: var(--primaryBlack50);
  }

  &:hover {
    path {
      fill: var(--primaryBlack);
    }
  }
`;

export const StyledCheckIcon = styled(CheckIcon)`
  cursor: pointer;
`;
