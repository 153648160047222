import React from 'react';
import styled from 'styled-components';
import { Radio } from 'components/Controls';
import { Centerer, Flexer, FlexBetweenContainer } from 'components/Core';
import { CircleCheckIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { ReactComponent as HelpIcon } from 'images/related-help.svg';
import { ReactComponent as BlankCardIcon } from 'images/blank-card-icon.svg';
import CardBrandVisa from 'images/card-brand-visa.svg';
import CardBrandMastercard from 'images/card-brand-mastercard.svg';
import CardBrandUnionPay from 'images/card-brand-union-pay.svg';

const Wrapper = styled(FlexBetweenContainer)`
  padding: 8px 12px 8px 12px;
  width: 100%;
  border: ${({ showBorder }) => (showBorder ? '1px solid var(--accentGraySecond)' : '')};
  border-top-left-radius: ${({ showBorder, isFirst }) => (showBorder && isFirst ? '8px' : 0)};
  border-top-right-radius: ${({ showBorder, isFirst }) => (showBorder && isFirst ? '8px' : 0)};
  border-bottom-left-radius: ${({ showBorder, isLast }) => (showBorder && isLast ? '8px' : 0)};
  border-bottom-right-radius: ${({ showBorder, isLast }) => (showBorder && isLast ? '8px' : 0)};
`;

const CardNumber = styled.div`
  padding-right: ${({ backgroundImage }) => (backgroundImage ? '50px' : '8px')};
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 16px;
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : undefined)};
  background-repeat: no-repeat;
  background-position-x: 22px;
  background-position-y: -24px;
`;

const StyledHelpIcon = styled(HelpIcon)`
  margin-left: 8px;
  margin-top: 2px;
  width: 14px;
  height: 14px;
`;

const StyledCheckIcon = styled(CircleCheckIcon)`
  margin-left: 8px;
  margin-top: 2px;
  width: 14px;
  height: 14px;
`;

const CARD_BRAND_IMAGE_SRC = {
  visa: CardBrandVisa,
  master: CardBrandMastercard,
  union: CardBrandUnionPay,
};

export const PaymentMethodLineItem = ({
  paymentMethod,
  isSelected,
  onSelect,
  isFirst,
  isLast,
  showRadio,
  showBorder,
}) => {
  const matchingBrand = paymentMethod?.card?.brand
    ? Object.keys(CARD_BRAND_IMAGE_SRC).find((brand) => paymentMethod.card.brand.toLowerCase()?.includes(brand))
    : null;
  const cardBrandImageSrc = matchingBrand ? CARD_BRAND_IMAGE_SRC[matchingBrand] : null;

  return (
    <Wrapper showBorder={showBorder} isFirst={isFirst} isLast={isLast}>
      <Flexer>
        {showRadio && <Radio checked={isSelected} onChange={() => onSelect(paymentMethod?.id ?? null)} />}
        <span>{paymentMethod?.id ?? 'Let Subscript choose the payment method'}</span>
        {!paymentMethod?.id && (
          <TooltipContainer toolTipContent="If one of the below payment methods was recently used to charge an invoice successfully, it will be used to charge the next invoices. Otherwise, Subscript will use the payment method marked as default for the customer on Stripe. If that information is also missing, Subscript will use the most recently created payment method of the customer.">
            <StyledHelpIcon size="12px" />
          </TooltipContainer>
        )}
        {paymentMethod?.isActive && (
          <TooltipContainer toolTipContent="This is currently the customer's default payment method (which may change if the default payment method changes on Stripe)">
            <StyledCheckIcon />
          </TooltipContainer>
        )}
      </Flexer>
      <Centerer>
        {paymentMethod?.type === 'card' ? (
          <CardNumber backgroundImage={cardBrandImageSrc}>**** {paymentMethod.card?.last4 ?? '****'}</CardNumber>
        ) : paymentMethod?.type ? (
          <CardNumber>{paymentMethod?.type}</CardNumber>
        ) : null}
        {paymentMethod?.id && !cardBrandImageSrc && <BlankCardIcon />}
      </Centerer>
    </Wrapper>
  );
};
