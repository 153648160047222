import {
  INVOICE_STATUSES,
  SERVICE_WITH_DELETE_INVOICE,
  SERVICE_WITH_TEST_SEND_EMAIL,
  SERVICE_WITHOUT_INVOICE_VOID,
} from 'views/Billing/consts';
import { GL_INTEGRATION_SERVICES, INTEGRATION_SERVICES } from 'consts/integrations';
import { INVOICE_ACTIONS } from './consts';
import { getSortedInvoices } from '../../utils';

export const isActionAvailable = ({
  headerView,
  action,
  invoicingService,
  invoiceFormValues,
  modalInvoiceStatus,
  isNotSendOrRemindInvoice,
  sendEmailFromSubscript,
}) => {
  switch (action) {
    case INVOICE_ACTIONS.VOID:
      // [BE 12/20/23] If an invoice hasn't been sent, it will be in SUBMITTED status, so we should allow deleting but not voiding. And if it has been sent, we only allow voiding.
      if (invoicingService === INTEGRATION_SERVICES.XERO && !invoiceFormValues?.sent_at) return false;

      return (
        !SERVICE_WITHOUT_INVOICE_VOID.includes(invoicingService) &&
        ![INVOICE_STATUSES.VOID].includes(modalInvoiceStatus) &&
        invoiceFormValues?.id
      );

    case INVOICE_ACTIONS.MARK_SENT:
      return (
        isNotSendOrRemindInvoice &&
        ![INVOICE_STATUSES.SENT, INVOICE_STATUSES.PAID, INVOICE_STATUSES.VOID].includes(modalInvoiceStatus)
      );

    case INVOICE_ACTIONS.TEST_SEND:
      return (
        (sendEmailFromSubscript || SERVICE_WITH_TEST_SEND_EMAIL.includes(invoicingService)) &&
        isNotSendOrRemindInvoice &&
        invoiceFormValues?.id
      );

    case INVOICE_ACTIONS.INTERNAL_NOTE:
    case INVOICE_ACTIONS.CREDIT_NOTE:
      // [AT 2024-03-11] For unclear reasons, it doesn't work from the sidebar
      return headerView && !!invoiceFormValues?.id;
    case INVOICE_ACTIONS.VIEW_HISTORY:
      return true;

    case INVOICE_ACTIONS.CHARGE_MANUALLY:
      return true;

    case INVOICE_ACTIONS.DUPLICATE:
      return true;

    case INVOICE_ACTIONS.MARK_PAID:
      return ![INVOICE_STATUSES.PAID, INVOICE_STATUSES.VOID].includes(modalInvoiceStatus);

    case INVOICE_ACTIONS.MARK_UNPAID:
      return [INVOICE_STATUSES.PAID].includes(modalInvoiceStatus);

    case INVOICE_ACTIONS.DELETE:
      return (
        // Org doesn't have an invoicing service
        !GL_INTEGRATION_SERVICES.includes(invoicingService) ||
        // Or if it's a draft invoice
        invoiceFormValues?.unsavedId ||
        // Or invoices hasn't been created in the accounting system or haven't been sent
        (SERVICE_WITH_DELETE_INVOICE.includes(invoicingService) &&
          (!invoiceFormValues?.invoice_external_id || !invoiceFormValues?.sent_at))
      );
    case INVOICE_ACTIONS.VIEW_INVOICES_IN_EXTERNAL_SYSTEM:
    case INVOICE_ACTIONS.DETACH:
      return invoiceFormValues?.invoice_external_id;
    case INVOICE_ACTIONS.REMIND:
      return !invoiceFormValues?.auto_charge && [INVOICE_STATUSES.REMIND].includes(modalInvoiceStatus);
    case INVOICE_ACTIONS.COPY_INVOICES_SUBSCRIPT_LINK:
      return !!invoiceFormValues?.id;
    default:
  }
};

export const findFirstInvoiceAfterRemoval = ({
  removedInvoicesIds,
  invoicingScheduleFormValues,
  setSelectedInvoiceId,
  setFetchedSelectedInvoice,
}) => {
  const invoices = (invoicingScheduleFormValues?.invoices || []).filter(
    (invoice) => !removedInvoicesIds?.includes(invoice.id ?? invoice.unsavedId),
  );
  if (invoices.length === 0) {
    setFetchedSelectedInvoice(null);
    setSelectedInvoiceId(null);
    return;
  }
  const firstInvoice = getSortedInvoices({ invoices })[0];
  setFetchedSelectedInvoice(firstInvoice);
  setSelectedInvoiceId(firstInvoice?.id ?? firstInvoice?.unsavedId);
};
