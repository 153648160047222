import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { AppContext } from 'AppContext';
import { TransactionContext } from 'shared/TransactionContent/TransactionContext';
import {
  ACCOUNTING_RECOGNITION_TYPES,
  EVENT_RECOGNITION_TYPES,
  ISO_CODE_TO_SYMBOL,
  NUMBER_FORMATS,
  RECOGNITION_TYPES,
} from 'consts/global';
import { ACCOUNTING_SPREAD_REASON } from 'views/Spreads/utils';
import { numberFormatter } from 'utils/formatters';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { BorderedTrashIcon, QuestionIcon } from 'components/Icons';
import { CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { CentererVertical, FlexBetweenContainer, FlexerColumn } from 'components/Core';
import { accountingSpreadsChanged, transformRecognitionEventsToSpreads } from 'shared/TransactionContent/utils';
import { TRANSACTION_MODAL_MODE } from 'shared/TransactionContent/consts';
import { generateAccountingSpreads } from 'api/transactions';

const Border = styled(FlexerColumn)`
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--primaryBlack3);
`;

const RecognizedRevenueHeader = styled.span`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
`;

const RecognizedAmountText = styled.span`
  color: ${({ isError }) => (isError ? 'var(--primaryRed)' : 'inherit')};
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
`;

const LockDateErrorText = styled.span`
  color: var(--primaryRed);
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
`;

const Information = styled.span`
  background: var(--primaryYellow10);
  font-size: 12px;
  padding: 8px;
  border-radius: 8px;
  width: fit-content;
`;

const RevRecButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const ChangeAccountingSpreadsButton = styled.div`
  ${({ disabled }) => !disabled && 'cursor: pointer;'}
  padding: 4px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  font-size: 10px;
  font-weight: 900;
  font-style: normal;
  color: var(--primaryBlue);
  width: fit-content;
  height: fit-content;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1.0')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  width: fit-content;
  white-space: nowrap;

  &:hover {
    background: ${({ disabled }) => (disabled ? 'var(--primaryBlue10)' : 'var(--primaryBlue20)')};
  }
`;

const Number = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--primaryBlack5);
  font-size: 9px;
  font-weight: 900;
  line-height: 12px;
  color: var(--primaryBlack);
`;

const StyledBorderedTrashIcon = styled(BorderedTrashIcon)`
  cursor: pointer;

  &:hover {
    path {
      fill: var(--primaryBlack40);
    }
  }
`;

const ROUNDING_EPSILON = 0.0001;
const CENT_EPSILON = 0.01;

export const AccountingScheduleTab = ({ disabled }) => {
  const {
    appSettings: { currencyISOCode },
    orgConfigs: { accountingSpreadsLockBeforeDate },
  } = useContext(AppContext);
  const {
    originalAccountingSpreads,
    setOriginalAccountingSpreads,
    transactionFormValues,
    currentTransactionSpread,
    mode,
    organization,
  } = useContext(TransactionContext);
  const { setFieldValue, validateForm, setTouched } = useFormikContext();

  const accountingSpreadLockBeforeDateDayJS = dayjs.utc(accountingSpreadsLockBeforeDate);

  const currency = transactionFormValues?.currency ?? currencyISOCode;
  const currencySymbol = ISO_CODE_TO_SYMBOL[currency] ?? '$';

  const accountingSpreads = transactionFormValues?.accounting_spreads ?? [];

  const recognizedAmount = accountingSpreads.reduce(
    (total, accountingSpread) => total + (accountingSpread.amount ? parseFloat(accountingSpread.amount) : 0),
    0,
  );

  const recognizedAmountFormatted = numberFormatter({
    type: NUMBER_FORMATS.CURRENCY,
    rawValue: recognizedAmount,
    decimalPlaces: 2,
    currency,
  });

  const transactionAmountFormatted = numberFormatter({
    type: NUMBER_FORMATS.CURRENCY,
    rawValue: transactionFormValues?.amount ? parseFloat(transactionFormValues?.amount) : 0,
    decimalPlaces: 2,
    currency,
  });

  let recognizedAmountText = `${recognizedAmountFormatted} out of ${transactionAmountFormatted}`;
  const recognizedAmountIsOff =
    transactionFormValues?.amount && Math.abs(transactionFormValues?.amount - recognizedAmount) >= CENT_EPSILON;
  if (recognizedAmountIsOff) {
    recognizedAmountText += ` (off by ${numberFormatter({
      type: NUMBER_FORMATS.CURRENCY,
      rawValue: transactionFormValues?.amount - recognizedAmount,
      decimalPlaces: 2,
      currency,
    })})`;
  }

  const addAccountingSpreadMonth = () => {
    const sortedSpreads = [...accountingSpreads].sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1));
    const dateOfLastAccountingSpread = sortedSpreads[sortedSpreads.length - 1]?.date;
    let newSpreadDate = dateOfLastAccountingSpread
      ? dayjs.utc(dateOfLastAccountingSpread).add(1, 'month')
      : dayjs.utc().startOf('month');
    if (accountingSpreadsLockBeforeDate && dayjs.utc(newSpreadDate).isBefore(accountingSpreadLockBeforeDateDayJS))
      newSpreadDate = accountingSpreadLockBeforeDateDayJS;
    const newAccountingSpreads = [{ date: newSpreadDate, amount: 0 }, ...accountingSpreads];
    setFieldValue('accounting_spreads', newAccountingSpreads);
  };

  const addDifferenceToLastMonth = () => {
    if (!accountingSpreads?.length) return;
    const lastSpread = accountingSpreads[accountingSpreads.length - 1];
    const lastSpreadIndex = accountingSpreads.length - 1;
    const newAccountingSpreads = [...accountingSpreads];
    newAccountingSpreads[lastSpreadIndex] = {
      ...lastSpread,
      amount: lastSpread.amount + (transactionFormValues.amount - recognizedAmount),
    };
    setFieldValue('accounting_spreads', newAccountingSpreads);
  };

  const removeAccountingSpreadMonth = (index) => {
    const newAccountingSpreads = [...accountingSpreads];
    newAccountingSpreads.splice(index, 1);
    setFieldValue('accounting_spreads', newAccountingSpreads);
  };

  const spreadsChanged = accountingSpreadsChanged({
    originalAccountingSpreads,
    currentAccountingSpreads: transactionFormValues?.accounting_spreads,
  });

  const isDisabledBecauseOfRecognition = [
    RECOGNITION_TYPES.eventRecurring,
    RECOGNITION_TYPES.eventNotRecurring,
  ].includes(transactionFormValues?.recognition);

  const isAccountingRecognitionContract =
    transactionFormValues?.accounting_recognition === ACCOUNTING_RECOGNITION_TYPES.CONTRACT;

  const generateAccountingSpreadsAction = async () => {
    // handle event
    const recognitionEvents = EVENT_RECOGNITION_TYPES.includes(transactionFormValues?.recognition)
      ? transformRecognitionEventsToSpreads(currentTransactionSpread?.recognitionEvents ?? [])
      : {};

    // Using formik validation to make sure we have everything we need to calculate the spreads
    const formFieldsTouched = Object.keys(transactionFormValues).reduce((acc, key) => ({ ...acc, [key]: true }), {});
    setTouched(formFieldsTouched);
    const errors = await validateForm();

    if (Object.keys(errors).length === 0) {
      const generatedAccountingSpreads = await generateAccountingSpreads({
        orgId: organization.id,
        body: { ...transactionFormValues, recognitionEvents },
      });
      if (mode === TRANSACTION_MODAL_MODE.CREATE) {
        // because there's no original to do manual
        setOriginalAccountingSpreads(generatedAccountingSpreads);
      }
      setFieldValue('accounting_spreads', generatedAccountingSpreads);
    }
  };

  const [showLockedErrorForIndex, setShowLockedErrorForIndex] = useState(null);

  return (
    <Border gap="12px">
      <FlexBetweenContainer>
        <FlexerColumn gap="12px" marginRight="8px">
          {isAccountingRecognitionContract ? (
            <TooltipContainer toolTipContent="You can change the method of the accounting revenue recognition above">
              <CentererVertical gap="8px">
                <RecognizedRevenueHeader>
                  Subscript will recognize the revenue grouped on the <b>contract</b>
                </RecognizedRevenueHeader>
                <QuestionIcon />
              </CentererVertical>
            </TooltipContainer>
          ) : (
            <>
              <RecognizedRevenueHeader>
                Recognized Accounting Revenue:{' '}
                <RecognizedAmountText isError={recognizedAmountIsOff}>{recognizedAmountText}</RecognizedAmountText>
                {recognizedAmountIsOff && (
                  <ChangeAccountingSpreadsButton
                    onClick={addDifferenceToLastMonth}
                    disabled={isDisabledBecauseOfRecognition || disabled || !accountingSpreads?.length}
                  >
                    ADD DIFFERENCE TO LAST MONTH
                  </ChangeAccountingSpreadsButton>
                )}
              </RecognizedRevenueHeader>
              {spreadsChanged && <Information>Accounting spreads changed</Information>}
              {!isDisabledBecauseOfRecognition && !accountingSpreads?.length && (
                <Information>Spreads will be regenerated. If you don't want that, create a $0 spread</Information>
              )}
              {isDisabledBecauseOfRecognition && (
                <Information>Cannot edit accounting spread amounts for transactions with event recognition</Information>
              )}
            </>
          )}
        </FlexerColumn>
        <RevRecButtons>
          <ChangeAccountingSpreadsButton
            onClick={generateAccountingSpreadsAction}
            data-cy="generate-accounting-spreads-button"
            disabled={
              isDisabledBecauseOfRecognition ||
              isAccountingRecognitionContract ||
              disabled ||
              !!transactionFormValues?.replaced_by
            }
          >
            {accountingSpreads?.length ? 'RECALCULATE' : 'GENERATE'}
          </ChangeAccountingSpreadsButton>
          <ChangeAccountingSpreadsButton
            onClick={addAccountingSpreadMonth}
            data-cy="add-new-month-button"
            disabled={isDisabledBecauseOfRecognition || isAccountingRecognitionContract || disabled}
          >
            ADD NEW MONTH
          </ChangeAccountingSpreadsButton>
        </RevRecButtons>
      </FlexBetweenContainer>

      <FlexerColumn gap="4px">
        {accountingSpreads.map((accountingSpread, index) => {
          const isDisabledBecauseOfLock = dayjs
            .utc(accountingSpread.date)
            .isBefore(accountingSpreadLockBeforeDateDayJS);
          const tooltipInputDisplay = isDisabledBecauseOfLock
            ? `Accounting spreads locked before ${accountingSpreadLockBeforeDateDayJS.format('MMM DD, YYYY')}`
            : accountingSpread.reason === ACCOUNTING_SPREAD_REASON.BEFORE_LOCK_DATE
            ? 'This accounting spread is the aggregate of the revenue that would have been recognized before the transaction lock date'
            : null;
          return (
            <FlexBetweenContainer>
              <CentererVertical gap="16px">
                <Number>{index + 1}</Number>
                <CustomDatePicker
                  formik
                  name={`accounting_spreads.${index}.date`}
                  showMonthYearPicker
                  dateFormat="MMM yyyy"
                  onChange={(name, date) => {
                    if (
                      !accountingSpreadsLockBeforeDate ||
                      dayjs.utc(date).isSameOrAfter(accountingSpreadLockBeforeDateDayJS, 'day')
                    ) {
                      setFieldValue(name, updateDateFromDatePicker(date));
                      setShowLockedErrorForIndex(null);
                    } else {
                      setShowLockedErrorForIndex(index);
                    }
                  }}
                  selected={formatDateForDatepicker(accountingSpread.date)}
                  tooltipInputDisplay={tooltipInputDisplay}
                  disabled={isDisabledBecauseOfLock || isAccountingRecognitionContract || disabled}
                />
                <FormikCustomInput
                  name={`accounting_spreads.${index}.amount`}
                  data-cy={`accounting-spread-amount-${index}`}
                  suffix={currencySymbol}
                  type="number"
                  handleChange={(value) => setFieldValue(`accounting_spreads.${index}.amount`, value)}
                  precision={
                    (accountingSpread.amount * 100) % 1 < ROUNDING_EPSILON ||
                    (accountingSpread.amount * 100) % 1 > 1 - ROUNDING_EPSILON
                      ? 2
                      : 3
                  }
                  isDisabled={
                    isDisabledBecauseOfRecognition ||
                    isDisabledBecauseOfLock ||
                    isAccountingRecognitionContract ||
                    disabled
                  }
                  tooltipInputDisplay={tooltipInputDisplay}
                />
              </CentererVertical>
              {showLockedErrorForIndex === index && (
                <LockDateErrorText>
                  Pick a date after lock date {accountingSpreadLockBeforeDateDayJS.format('MMM DD, YYYY')}
                </LockDateErrorText>
              )}
              {!isDisabledBecauseOfRecognition &&
                !isDisabledBecauseOfLock &&
                !isAccountingRecognitionContract &&
                !disabled && (
                  <StyledBorderedTrashIcon
                    fill="var(--primaryBlack20)"
                    onClick={() => removeAccountingSpreadMonth(index)}
                  />
                )}
            </FlexBetweenContainer>
          );
        })}
      </FlexerColumn>
    </Border>
  );
};
