import { omit } from 'lodash';
import { productNameToId } from 'views/Transactions/TransactionsCsvUpload/consts';

export const DEFAULT_INVOICE_ITEMS_MAPPER = {
  id: 'Invoice Item ID',
  invoice_id: 'Invoice ID',
  description: 'Description',
  name: 'Name',
  type: 'Type',
  transactions: 'Transactions',
  product_id: 'Product',
  amount: 'Amount',
  seats: 'Seats',
  metadata: 'Metadata',
};

export const DEFAULT_INVOICE_ITEMS_CREATE_MAPPER = omit(DEFAULT_INVOICE_ITEMS_MAPPER, 'id');

export const DEFAULT_INVOICE_ITEMS_TRANSFORMATIONS = {
  product_id: productNameToId,
  transactions: (value) =>
    JSON.parse(value)?.map((transaction) => {
      if (typeof transaction === 'string' || typeof transaction === 'number') {
        return {
          transaction_id: String(transaction),
        };
      } else if (typeof transaction === 'object') {
        return {
          transaction_id: String(transaction?.transaction_id),
          initial_amount: transaction?.initial_amount,
        };
      } else {
        return transaction;
      }
    }),
};
