import { INTERNAL_CUSTOMER_METADATA } from 'models/customer';

export const METADATA_FILTER_TYPES = {
  TRANSACTIONS: 'transactionMetadata',
  CUSTOMERS: 'customerMetadata',
  PRODUCTS: 'productsMetadata',
};

export const FILTER_TYPE_OPTIONS = [
  { label: 'Transactions Filters', value: METADATA_FILTER_TYPES.TRANSACTIONS },
  { label: 'Customers Filters', value: METADATA_FILTER_TYPES.CUSTOMERS },
];

export const FILTER_OPERATORS = {
  notIn: 'exclude',
  in: 'include',
  '>': 'More than',
  '<': 'Less than',
};

export const MULTI_VALUE_OPERATORS = ['in', 'notIn'];

export const FILTER_FIELD_LABELS = {
  topLevel__product_id: 'Product',
  topLevel__id: 'Name',
  topLevel__root_customer_id: 'Root Customer',
  topLevel__product_category_id: 'Product Category',
  topLevel__amount: 'Amount',
  topLevel__amount_left_to_recognize: 'Amount left to recognize',
  topLevel__currency: 'Currency',
  topLevel__arr_growth: 'ARR Growth',
  topLevel__recognition: 'Recognition',
  topLevel__date: 'Date',
  topLevel__start_date: 'Start Date',
  topLevel__end_date: 'End Date',
  [INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID]: 'General Ledger',
};

export const DATES_FIELDS = ['topLevel__date', 'topLevel__start_date', 'topLevel__end_date'];

export const MULTI_VALUE_FIELDS = [
  INTERNAL_CUSTOMER_METADATA.SELECTED_GL_INTEGRATION_ID,
  'topLevel__product_id',
  'topLevel__product_category_id',
  'topLevel__id',
  'topLevel__root_customer_id',
  'topLevel__currency',
];

export const emptyFilter = {
  filterType: '',
  field: '',
  value: [],
};

export const METADATA_SEGMENT_BUCKET_TYPES = {
  DISCRETE: 'discrete',
  CONTINUOUS: 'continuous',
};

export const METADATA_SEGMENT_OBJECT_TYPES = {
  CUSTOMER: 'customer',
  TRANSACTION: 'transaction',
};

export const CUSTOMER_TIMESERIES_METRICS_SUFFIX = '-Timeseries';

export const NULL_SEGMENT_STRING_TIMESERIES = 'Undefined';

export const MAX_DROPDOWN_OPTIONS = 250;
