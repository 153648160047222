import { useContext, useMemo, useState } from 'react';
import { generateColumns } from './subscriptionColumns';
import { AppContext } from 'AppContext';
import { useSubscriptionEditModal } from 'views/Billing/UsageBasedEngine/Subscriptions/SubscriptionEditModal';
import { PreviewTable } from './PreviewTable';

export const SubscriptionsPreviewTable = ({ data = [], setDataHook }) => {
  const { formatDateWithLocale } = useContext(AppContext);
  const [tableData, setTableData] = useState(data);
  const { openModal, SubscriptionEditModal } = useSubscriptionEditModal();

  const columns = useMemo(
    () =>
      generateColumns({
        formatDateWithLocale,
        onEditClick: (row) => {
          openModal({
            selectedSubscription: row.original,
            saveSubscription: false,
            onSubscriptionCreated: (data) => {
              const tableDataClone = [...tableData];
              tableDataClone[row.index] = {
                ...tableDataClone[row.index],
                ...data,
              };
              delete tableDataClone[row.index].customer_confidence;
              delete tableDataClone[row.index].pricing_plan_confidence;
              setTableData(tableDataClone);
            },
          });
        },
      }),
    [formatDateWithLocale, openModal, tableData],
  );

  return (
    <PreviewTable
      entityName="usage-based subscriptions"
      columns={columns}
      tableData={tableData}
      setDataHook={setDataHook}
    >
      <SubscriptionEditModal />
    </PreviewTable>
  );
};
