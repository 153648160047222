import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useToasts } from 'components/Toasts';
import { CACHE_KEY as IMPORTS_CACHE_KEY } from 'api/imports';
import { CACHE_KEY as BILLING_CACHE_KEY } from 'api/billing';
import { getExternalResourceById } from 'api/billing/requests';
import {
  getCustomer,
  getCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  bulkUploadCustomers,
  createCustomerInGl as _createCustomerInGl,
  getExternalParentChildren,
  bulkUpsertInvoicingDetails,
  bulkUpdateExternalCustomers as _bulkUpdateExternalCustomers,
  deleteCustomerImport,
} from './requests';

export const CACHE_KEY = 'customers';

export const useCustomersAPI = ({
  orgId,
  filters,
  autoFetch = true,
  enableToasts = true,
  disableInvalidate,
  onBulkUploadSettled = () => {},
}) => {
  // Key to identify data by - has to include orgId and filters
  const dataKey = [CACHE_KEY, orgId, filters];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, refetch, dataUpdatedAt } = useQuery(
    dataKey,
    () => getCustomers({ orgId, filters }),
    {
      enabled: autoFetch,
    },
  );

  const history = useHistory();

  const addCustomer = useMutation(({ data }) => createCustomer({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create customer.');
    },

    onSuccess: () => {
      pushToast('Successfully created customer!', 'success');
    },

    onSettled: () => {
      !disableInvalidate && queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const editCustomer = useMutation(
    ({ id, data, overwriteAllMetadata }) => updateCustomer({ orgId, customerId: id, body: data, overwriteAllMetadata }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update customer.');
      },

      onSuccess: () => {
        pushToast('Successfully updated customer!', 'success');
      },

      onSettled: () => {
        if (!disableInvalidate) {
          queryClient.invalidateQueries(CACHE_KEY);
          queryClient.invalidateQueries(BILLING_CACHE_KEY);
        }
      },
    },
  );

  const removeCustomer = useMutation(({ id }) => deleteCustomer({ orgId, customerId: id }), {
    onError: (err) => {
      pushError(err, 'Failed to remove customer.');
    },

    onSuccess: () => {
      pushToast('Successfully removed customer!', 'success');
    },

    onSettled: () => {
      !disableInvalidate && queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  const removeCustomerImport = useMutation(
    ({ id, importId }) => deleteCustomerImport({ orgId, customerId: id, importId }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update customer.');
      },

      onSuccess: () => {
        pushToast('Successfully updated customer!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(IMPORTS_CACHE_KEY);
        !disableInvalidate && queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const bulkUpload = useMutation(({ data }) => bulkUploadCustomers({ orgId, customers: data }), {
    onError: (err) => {
      pushError(err, 'Failed to upload customers.');
    },

    onSuccess: () => {
      pushToast('Successfully uploaded customers!', 'success');

      setTimeout(() => {
        history.push('/customers');
      }, 1000);
    },

    onSettled: async () => {
      !disableInvalidate && queryClient.invalidateQueries(CACHE_KEY);
      await onBulkUploadSettled?.();
    },
  });

  const bulkUpdateInvoicingDetails = useMutation(
    ({ customers }) => bulkUpsertInvoicingDetails({ orgId, body: { customers } }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update customers.');
      },

      onSuccess: () => {
        pushToast('Successfully updated customers!', 'success');
      },

      onSettled: () => {
        !disableInvalidate && queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  return {
    data: data?.data,
    metadata: data?.metadata,
    error,
    isLoading,
    isFetching,
    dataUpdatedAt,
    operations: {
      addCustomer: addCustomer.mutateAsync,
      editCustomer: editCustomer.mutateAsync,
      removeCustomer: removeCustomer.mutateAsync,
      removeCustomerImport: removeCustomerImport.mutateAsync,
      bulkUpload: bulkUpload.mutate,
      bulkUpdateInvoicingDetails: bulkUpdateInvoicingDetails.mutateAsync,
      refetch,
    },
  };
};

export const useCustomerAPI = ({ orgId, customerId, filters, enabled = true, enableToasts = true }) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [CACHE_KEY, orgId, customerId, filters],
    () => getCustomer({ orgId, customerId, params: filters }),
    {
      enabled,
    },
  );

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const queryClient = useQueryClient();

  const edit = useMutation(
    ({ id, data, params, overwriteMetadata }) =>
      updateCustomer({
        orgId,
        customerId: id ?? customerId,
        body: data,
        params,
        overwriteMetadata,
      }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update customer');
      },

      onSuccess: () => {
        pushToast('Successfully updated customer!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries(CACHE_KEY);
      },
    },
  );

  const remove = useMutation(({ id }) => deleteCustomer({ orgId, customerId: id ?? customerId }), {
    onError: (err) => {
      pushError(err, 'Failed to remove customer');
    },

    onSuccess: () => {
      pushToast('Successfully removed customer!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries(CACHE_KEY);
    },
  });

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      edit: edit.mutateAsync,
      remove: remove.mutate,
      refetch,
      invalidate: () => queryClient.invalidateQueries(CACHE_KEY),
    },
  };
};

export const useExternalCustomerAPI = ({
  orgId,
  externalCustomerId,
  integrationId,
  service,
  autoFetch = true,
  enableToasts = true,
}) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [CACHE_KEY, externalCustomerId, orgId],
    () =>
      getExternalResourceById({
        orgId,
        id: externalCustomerId,
        params: { resource: 'customer', integrationId, service },
      }),
    {
      enabled: autoFetch,
    },
  );

  const queryClient = useQueryClient();

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const createCustomerInGl = useMutation(({ data }) => _createCustomerInGl({ orgId, body: data }), {
    onError: (err) => {
      pushError(err, 'Failed to create customer in GL');
    },

    onSuccess: () => {
      pushToast('Successfully created customer in GL!', 'success');
    },

    onSettled: () => {
      queryClient.invalidateQueries('imports');
    },
  });

  const bulkUpdateExternalCustomers = useMutation(
    ({ customers, integrationId }) => _bulkUpdateExternalCustomers({ orgId, body: { customers, integrationId } }),
    {
      onError: (err) => {
        pushError(err, 'Failed to update customers in GL');
      },

      onSuccess: () => {
        pushToast('Successfully updated customers in GL!', 'success');
      },

      onSettled: () => {
        queryClient.invalidateQueries('imports');
      },
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
      createCustomerInGl: createCustomerInGl.mutateAsync,
      bulkUpdateExternalCustomers: bulkUpdateExternalCustomers.mutateAsync,
    },
  };
};

export const useExternalParentChildrenAPI = ({ orgId, externalParentId, filters, scopes, autoFetch = true }) => {
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    [CACHE_KEY, externalParentId, orgId, 'children'],
    () =>
      getExternalParentChildren({
        orgId,
        externalParentId: externalParentId,
        params: { filters, scopes },
      }),
    {
      enabled: autoFetch,
    },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};
