import { useContext } from 'react';
import { AppContext } from 'AppContext';
import { getIntegrationDisplayName } from 'models/integration';
import { FormikCustomSelector } from 'components/Controls';
import { FieldBody, FieldTitle } from 'views/Wizard/styles';

export const DefaultLedgerSettings = () => {
  const { glIntegrations } = useContext(AppContext);

  return (
    <FieldBody>
      <FieldTitle>
        <span>Pick the default general ledger to invoice and connect customers to</span>
      </FieldTitle>
      <FormikCustomSelector
        name="defaultLedgerSettings.integration_id"
        options={glIntegrations.map((integration) => ({
          label: `${getIntegrationDisplayName(integration)} / ${integration.id}`,
          value: integration.id,
        }))}
      />
    </FieldBody>
  );
};
