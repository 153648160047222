import dayjs from 'dayjs';

import { RECOGNITION_TYPES } from 'consts/global';
import { updateDateFromDatePicker } from 'utils/dateUtils';
import { calculateTotalAmount, calculateRecurringAmount } from 'models/transaction';
import { getTillCancelMonthlyIncrementPercentages } from 'api/transactions/requests';

import { calculateIncludeEndMonth } from './TransactionRecognitionsSchedule/utils';
import { DEFAULT_AUTO_INCREASE_MONTHS } from '../consts';

const setAdditionalAmountValue = ({
  setFieldValue,
  recognition,
  recurringAmount,
  totalAmount,
  startDate,
  endDate,
  includeEndMonth,
  useAutoIncrease,
  incrementPercentagesByDate,
}) => {
  switch (recognition) {
    case RECOGNITION_TYPES.linear:
      //setting recurring amount
      if (totalAmount && startDate && endDate) {
        setFieldValue(
          'recurring_amount',
          calculateRecurringAmount({
            startDate,
            endDate,
            totalAmount,
            includeEndMonth,
          }),
        );
      } else {
        setFieldValue('recurring_amount', 0);
      }
      break;
    case RECOGNITION_TYPES.tillCanceled:
      //setting total amount
      if (recurringAmount && startDate) {
        setFieldValue(
          'amount',
          calculateTotalAmount({
            startDate,
            endDate,
            recurringAmount,
            includeEndMonth: includeEndMonth || !endDate,
            useAutoIncrease,
            incrementPercentagesByDate,
          }),
        );
      } else {
        setFieldValue('amount', 0);
      }
      break;
    case RECOGNITION_TYPES.eventRecurring:
      // do nothing since we want to allow them to set this in the UI
      break;
    default:
      //for any other recognition type, the recurring amount is 0
      setFieldValue('recurring_amount', 0);
  }
};

export const recognitionHandleChange = (setFieldValue, values) => (recognition) => {
  setFieldValue('recognition', recognition);

  const useAutoIncrease = recognition === RECOGNITION_TYPES.tillCanceled && values.use_auto_increase;
  if (useAutoIncrease !== values.use_auto_increase) setFieldValue('use_auto_increase', useAutoIncrease);

  setAdditionalAmountValue({
    setFieldValue,
    recognition,
    recurringAmount: values.recurring_amount,
    totalAmount: values.amount,
    startDate: values.start_date,
    endDate: values.end_date,
    includeEndMonth: values.include_end_month,
    useAutoIncrease,
    incrementPercentagesByDate: values.incrementPercentagesByDate,
  });
};

export const recurringAmountHandleChange = (setFieldValue, values) => (value) => {
  setFieldValue('recurring_amount', value);
  if (values.recognition === RECOGNITION_TYPES.tillCanceled)
    setAdditionalAmountValue({
      setFieldValue,
      recognition: values.recognition,
      recurringAmount: value,
      startDate: values.start_date,
      endDate: values.end_date,
      includeEndMonth: values.include_end_month,
      useAutoIncrease: values.use_auto_increase,
      incrementPercentagesByDate: values.incrementPercentagesByDate,
    });
};

export const includeLastMonthChange = (setFieldValue, values) => (value) => {
  setFieldValue('include_end_month', value);
  setAdditionalAmountValue({
    setFieldValue,
    recognition: values.recognition,
    totalAmount: values.amount,
    recurringAmount: values.recurring_amount,
    startDate: values.start_date,
    endDate: values.end_date,
    includeEndMonth: value,
    useAutoIncrease: values.use_auto_increase,
    incrementPercentagesByDate: values.incrementPercentagesByDate,
  });
};

export const totalAmountHandleChange = (setFieldValue, values) => (value) => {
  setFieldValue('amount', value);
  setAdditionalAmountValue({
    setFieldValue,
    recognition: values.recognition,
    totalAmount: value,
    startDate: values.start_date,
    endDate: values.end_date,
    includeEndMonth: values.include_end_month,
    useAutoIncrease: values.use_auto_increase,
    incrementPercentagesByDate: values.incrementPercentagesByDate,
  });
};

export const startDateHandleChange = (setFieldValue, values) => (name, date) => {
  const startDate = updateDateFromDatePicker(date);
  const endDate = updateDateFromDatePicker(values.end_date);
  setFieldValue(name, startDate);
  const includeEndMonthForCalculation = calculateIncludeEndMonth({
    recognition: values.recognition,
    startDate,
    endDate,
  });
  setAdditionalAmountValue({
    setFieldValue,
    recognition: values.recognition,
    totalAmount: values.amount,
    recurringAmount: values.recurring_amount,
    startDate,
    endDate,
    includeEndMonth: includeEndMonthForCalculation,
    useAutoIncrease: values.use_auto_increase,
    incrementPercentagesByDate: values.incrementPercentagesByDate,
  });
};

export const endDateHandleChange = (setFieldValue, values) => async ({ name, date, orgId }) => {
  const startDate = updateDateFromDatePicker(values.start_date);
  const endDate = updateDateFromDatePicker(date);
  setFieldValue(name, endDate);

  let incrementPercentagesByDate = values.incrementPercentagesByDate;
  // checking the end date prevents us from calling the API too many times
  // endDate can be null or undefined, but if it exists, it must be a valid date
  if (values?.use_auto_increase && (!endDate || dayjs(endDate).isValid())) {
    incrementPercentagesByDate = await getTillCancelMonthlyIncrementPercentages({
      orgId,
      params: {
        usesAutoIncrease: values.use_auto_increase,
        increments: values.auto_increase_increments,
        monthlyAmount: values.recurring_amount || 0,
        endDate,
        autoIncreaseMonths: values.auto_increase_months || DEFAULT_AUTO_INCREASE_MONTHS,
      },
    });

    setFieldValue('incrementPercentagesByDate', incrementPercentagesByDate);
  }

  const includeEndMonthForCalculation = calculateIncludeEndMonth({
    recognition: values.recognition,
    startDate,
    endDate,
  });

  setAdditionalAmountValue({
    setFieldValue,
    recognition: values.recognition,
    totalAmount: values.amount,
    recurringAmount: values.recurring_amount,
    startDate,
    endDate,
    includeEndMonth: includeEndMonthForCalculation,
    useAutoIncrease: values.use_auto_increase,
    incrementPercentagesByDate,
  });
};

export const autoIncreaseOptionHandleChange = (setFieldValue, values) => async ({ value, orgId }) => {
  setFieldValue('use_auto_increase', value);

  let incrementPercentagesByDate = values.incrementPercentagesByDate;
  /**
   * Because in endDateHandleChange, we only get incrementPercentagesByDate if usesAutoIncrease is true,
   * so there may be cases where the endDate is updated when usesAutoIncrease is false,
   * thus, we also have to update incrementPercentagesByDate when usesAutoIncrease becomes true
   */
  if (value && values.incrementPercentagesByDate?.length) {
    incrementPercentagesByDate = await getTillCancelMonthlyIncrementPercentages({
      orgId,
      params: {
        usesAutoIncrease: value,
        increments: values.auto_increase_increments,
        monthlyAmount: values.recurring_amount || 0,
        endDate: values.end_date,
        autoIncreaseMonths: values.auto_increase_months || DEFAULT_AUTO_INCREASE_MONTHS,
      },
    });

    setFieldValue('incrementPercentagesByDate', incrementPercentagesByDate);
  }

  setAdditionalAmountValue({
    setFieldValue,
    recognition: values.recognition,
    totalAmount: values.amount,
    recurringAmount: values.recurring_amount,
    startDate: values.start_date,
    endDate: values.end_date,
    includeEndMonth: values.include_end_month,
    useAutoIncrease: value,
    incrementPercentagesByDate,
  });
};

export const autoIncreaseIncrementsHandleChange = (setFieldValue, values) => ({
  increments,
  autoIncreaseMonths,
  incrementPercentagesByDate,
}) => {
  setFieldValue('incrementPercentagesByDate', incrementPercentagesByDate);
  setFieldValue('auto_increase_increments', increments);
  setFieldValue('auto_increase_months', autoIncreaseMonths);

  setAdditionalAmountValue({
    setFieldValue,
    recognition: values.recognition,
    totalAmount: values.amount,
    recurringAmount: values.recurring_amount,
    startDate: values.start_date,
    endDate: values.end_date,
    includeEndMonth: values.include_end_month,
    useAutoIncrease: values.use_auto_increase,
    incrementPercentagesByDate,
  });
};
