import React from 'react';
import styled from 'styled-components';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { transactionStartAndEndDates } from 'models/transaction';
import { CentererVertical, Flexer, FlexerColumn } from 'components/Core';
import { METRIC_KEY_TO_ROW_LABEL } from './CustomerAccountingMetricRows';
import { TableBodyRow, TableBodyTile } from './styles';

const TransactionRow = styled(CentererVertical)`
  position: sticky;
  left: 0px;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const TransactionLink = styled(CentererVertical)`
  cursor: pointer;
  gap: 8px;
`;

const TransactionName = styled.span`
  color: var(--primaryBlue);
  font-size: 12px;
  font-weight: 700;
  padding-left: 24px;
`;

const TransactionDetails = styled.span`
  font-size: 12px;
  font-weight: 700;
`;

const Subtext = styled.span`
  color: var(--primaryBlack50);
  font-size: 12px;
  font-weight: 700;
`;

export const CustomerTransactionAccountingMetricRows = ({
  transactionId,
  transaction,
  customerName,
  monthKeys,
  accountingCurrency,
}) => {
  const transactionProductName = transaction.product_name;
  const transactionStartAndEndDate = transactionStartAndEndDates({ transaction });
  const transactionAmount = numberFormatter({
    rawValue: transaction.transaction_amount,
    type: NUMBER_FORMATS.CURRENCY,
    currency: transaction.currency,
    decimalPlaces: 2,
  });
  const transactionDetails = `${transactionProductName}: ${transactionStartAndEndDate} (${transactionAmount} total)`;

  return (
    <FlexerColumn justifyContent="center">
      <Flexer>
        <TransactionRow>
          <TransactionLink onClick={() => window.open(`/transactions/${transactionId}`, '_blank')}>
            <TransactionName>{transaction.transactionName || transactionId}</TransactionName>
          </TransactionLink>
          <TransactionDetails>{transactionDetails}</TransactionDetails>
          <Subtext>transaction of {customerName}</Subtext>
        </TransactionRow>
      </Flexer>

      {Object.entries(METRIC_KEY_TO_ROW_LABEL).map(([metricKey, rowLabel]) => (
        <TableBodyRow key={metricKey} rowHeight="32px">
          <TableBodyTile>{rowLabel}</TableBodyTile>
          {monthKeys?.map((monthKey) => (
            <TableBodyTile key={monthKey}>
              {numberFormatter({
                rawValue: transaction?.[metricKey]?.[monthKey] ?? 0,
                type: NUMBER_FORMATS.CURRENCY,
                currency: accountingCurrency,
                decimalPlaces: 2,
              })}
            </TableBodyTile>
          ))}
        </TableBodyRow>
      ))}
    </FlexerColumn>
  );
};
