import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { DEFAULT_AVAILABLE_TAGS } from 'consts/billing';
import { INTEGRATION_SERVICES } from 'consts/integrations';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { convertToPlainText, templateToHTMLString } from 'utils/htmlUtils';
import { stringToBoolean } from 'utils/stringUtils';
import {
  CustomDatePicker,
  FormikEmailSelector,
  FormikCustomInput,
  FormikCustomSelector,
  SwitchWithLabel,
  FormikCustomCheckbox,
} from 'components/Controls';
import { CentererVertical, Column, Flexer, FlexerColumn, FlexerRow } from 'components/Core';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { PopoverWrapper } from 'components/Portal';
import { InlineButton } from 'components/Buttons';
import {
  hasBillingAddress as _hasBillingAddress,
  formatBillingAddress as _formatBillingAddress,
  formatShippingAddress as _formatShippingAddress,
} from 'models/customer';
import { formatAddress } from 'models/common';
import { CustomersActionsModal, CUSTOMERS_MODAL_ACTIONS, ADDRESS_TABS } from 'views/Customers/CustomersActionsModal';
import { getModalInvoiceStatus } from 'views/Billing/InvoiceModal/utils';
import { RemindersBanner } from 'views/Billing/InvoicingTables/RemindersTableBanner';
import { RemindersByAIBanner } from 'views/Billing/InvoicingTables/RemindersByAIBanner';
import { addRemindersInfoFieldsToInvoice } from 'views/Billing/InvoicingTables/utils';
import { shouldSendEmailFromSubscript } from 'views/Billing/utils';
import { useConfirmModal } from 'shared/ConfirmModal';
import { MAGIC_METADATA } from 'consts/global';
import { useCustomerAPI } from 'api/customers';
import {
  INVOICE_MAGIC_METADATA,
  INVOICE_STATUSES,
  SELECTOR_PAYMENT_OPTIONS,
  SERVICE_WITHOUT_EMAIL_MODIFICATIONS,
} from '../../../consts';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { MemoTemplateDropdown } from './MemoTemplateDropdown';
import { UnlockFieldsButton, ScheduleEmailSection, SectionTitle } from '../../InvoiceScheduleWarnings/styles';
import { InvoiceHeader } from './InvoiceHeader';
import { AutoChargeCard } from './AutoChargeCard';
import { InvoiceFailedToSaveWarning } from './InvoiceWarnings/InvoiceFailedToSaveWarning';
import { InvoiceFailedToSendWarning } from './InvoiceWarnings/InvoiceFailedToSendWarning';
import { InvoicePaymentFailedWarning } from './InvoiceWarnings/InvoicePaymentFailedWarning';
import { InvoiceCreditNotes } from './InvoiceCreditNotes';
import { InvoiceItemsAndTaxes } from './InvoiceItems/InvoiceItemsAndTaxes';
import { InvoiceCustomFields } from './InvoiceCustomFields';
import { useDateChangePopovers } from '../../useDateChangePopovers';
import { getSortedInvoices } from '../../utils';
import { FormWrapper, InvoiceWrapper } from '../../styles';
import { getFieldChangesProps } from './utils';
import { InvoiceProcessingInfoCard } from './InvoiceProcessingInfoCard';

export const SingleInvoice = ({ invoice, values, getFieldMeta, setFieldValue }) => {
  const { orgId, orgConfigs } = useContext(AppContext);
  const {
    setAllowResend,
    openInvoicePreviewModal,
    invoicingScheduleFormValues,
    currentInvoicingSchedule,
    invoicingService,
    customerDetails,
    productData,
    scheduleFormRef,
    OpenReplacedTemplateVariablesModalButton,
    transactionMetadataTags,
    customerMetadataTags,
    transactionCustomerMetadataTags,
    refetchCustomer,
    refetchCurrentInvoice,
    invoicingServiceDisplayName,
  } = useContext(InvoicingScheduleContext);

  const invoices = useMemo(() => getSortedInvoices({ invoices: invoicingScheduleFormValues?.invoices }), [
    invoicingScheduleFormValues?.invoices,
  ]);

  const { moveDatesPopover, onDateChange } = useDateChangePopovers({
    invoice,
    invoices,
    invoiceFormValues: values,
    scheduleFormValues: invoicingScheduleFormValues,
    scheduleFormRef,
    moveDatesPopoverYOffset: 65,
    index: invoices?.findIndex(
      (arrInvoice) => (arrInvoice?.id ?? arrInvoice?.unsavedId) === (invoice?.id ?? invoice?.unsavedId),
    ),
    setFieldValue,
    shouldSaveOnNo: false,
  });

  const childCustomerId = customerDetails?.metadata?.[MAGIC_METADATA.USE_CHILD_CUSTOMER_SHIPPING_INFO]
    ? values.invoice_items
        ?.map((item) =>
          invoicingScheduleFormValues.transactions?.find((transaction) => transaction.id === item.transaction_id),
        )
        ?.filter((transaction) => !!transaction)
        ?.find((transaction) => transaction.customer_id !== customerDetails?.id)?.customer_id
    : null;

  const { data: childCustomer } = useCustomerAPI({
    orgId,
    customerId: childCustomerId,
    filters: {
      scopes: ['invoicing_details'],
    },
    enabled: !!childCustomerId,
  });

  const { billingInvoiceDefaults, billingSenderDefaults, billingReminderDefaults } = orgConfigs;
  const invoiceNumberManagedBySubscript = !!billingInvoiceDefaults?.assign_incremental_invoice_number;
  const availableTags = [
    ...DEFAULT_AVAILABLE_TAGS,
    ...transactionMetadataTags,
    ...customerMetadataTags,
    ...transactionCustomerMetadataTags,
  ];
  const invoiceStatus = invoice ? getModalInvoiceStatus({ invoice }) : '';

  const [disableEditing, setDisableEditing] = useState(false);

  useEffect(() => {
    setAllowResend(false);

    const disablePaidInvoiceEditing = !!invoice.paid_at && !!invoice.invoice_external_id;

    setDisableEditing(
      // draft invoices are always editable
      Boolean(
        invoice?.id &&
          (([
            INVOICE_STATUSES.SENT,
            INVOICE_STATUSES.PAID,
            INVOICE_STATUSES.REMIND,
            INVOICE_STATUSES.QUEUED_UP,
            INVOICE_STATUSES.PROCESSING,
          ].includes(invoiceStatus) &&
            !invoice?.auto_charge) ||
            disablePaidInvoiceEditing),
      ),
    );
  }, [invoiceStatus, invoicingService, invoice, setAllowResend]);

  const [showCustomersActionModal, setShowCustomersActionModal] = useState(false);
  const [addressTab, setAddressTab] = useState();

  const hasBillingAddress = useMemo(() => _hasBillingAddress({ customer: customerDetails }), [customerDetails]);
  const formattedBillingAddress = useMemo(() => _formatBillingAddress({ customer: customerDetails }), [
    customerDetails,
  ]);

  const formattedShippingAddress = useMemo(() => {
    const customer = childCustomer
      ? {
          ...childCustomer,
          invoicing_details: {
            ...childCustomer.invoicing_details,
            shipping_address: childCustomer.invoicing_details?.shipping_address ?? childCustomer.invoicing_details,
          },
        }
      : customerDetails;
    const customerShippingAddress = _formatShippingAddress({ customer });

    return invoice.shipping_address?.isTransactionAddress
      ? formatAddress(invoice.shipping_address) || customerShippingAddress
      : customerShippingAddress;
  }, [childCustomer, customerDetails, invoice.shipping_address]);

  const sendEmailFromSubscript = shouldSendEmailFromSubscript({ orgConfigs });
  const isXeroSender =
    !(sendEmailFromSubscript || invoice.auto_charge) && invoicingService === INTEGRATION_SERVICES.XERO;
  const isNetsuiteSender =
    !(sendEmailFromSubscript || invoice.auto_charge) && invoicingService === INTEGRATION_SERVICES.NETSUITE;

  const allowEditingToolTipContent = () => {
    let toolTipText;
    switch (invoiceStatus) {
      case INVOICE_STATUSES.SENT:
      case INVOICE_STATUSES.REMIND:
        toolTipText = 'This invoice was already sent. If you make any changes, remember to re-send it!';
        break;
      case INVOICE_STATUSES.PAID:
        toolTipText = 'This invoice was already paid. Are you sure you want to make changes?';
        break;
      case INVOICE_STATUSES.QUEUED_UP:
      case INVOICE_STATUSES.PROCESSING:
        toolTipText = 'This invoice is still processing!';
        break;
      default:
    }
    return (
      <FlexerColumn gap="8px">
        <span>{toolTipText}</span>
        {![INVOICE_STATUSES.QUEUED_UP, INVOICE_STATUSES.PROCESSING].includes(invoiceStatus) && (
          <UnlockFieldsButton
            onClick={() => {
              setDisableEditing(false);
              setAllowResend(true);
            }}
            data-cy="single-invoice-unlock-fields-button"
          >
            UNLOCK ALL FIELDS TO RESEND
          </UnlockFieldsButton>
        )}
      </FlexerColumn>
    );
  };

  const {
    openConfirmModal: openConfirmTurnOffAutoSendModal,
    ConfirmModal: ConfirmTurnOffAutoSendModal,
  } = useConfirmModal({
    title: 'Turn off auto-send?',
    width: '500px',
    content: (
      <Column>
        Turning off this setting means you will have to manually save and send this invoice. Subscript will no longer
        retry automatically.
      </Column>
    ),
    denyButtonText: 'No, cancel',
    confirmButtonText: 'Yes, turn off auto-send',
    onConfirm: () => {
      setFieldValue('auto_send', false);
      setFieldValue('metadata', {
        ...invoice.metadata,
        [`${INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_FAILED_AT}`]: null,
        [`${INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_ERROR}`]: null,
        [`${INVOICE_MAGIC_METADATA.SEND_EMAIL_ERROR_COUNT}`]: null,
      });
    },
    onDeny: () => {},
  });

  const autoSendToggleOnChange = useCallback(
    (selectedValue) => {
      if (invoiceStatus === INVOICE_STATUSES.SENT_FAILED && stringToBoolean(selectedValue) === false) {
        openConfirmTurnOffAutoSendModal();
        return;
      }
      setFieldValue('auto_send', selectedValue);
    },
    [invoiceStatus, openConfirmTurnOffAutoSendModal, setFieldValue],
  );

  const aiRemindersToSend = invoice?.reminders?.find(
    (reminder) => !reminder.sent_at && reminder.ai_generated && !reminder.dismissed_at,
  );

  const aiRemindersEnabled = aiRemindersToSend || billingReminderDefaults?.ai_enabled;

  return (
    <InvoiceWrapper data-cy="single-invoice-view">
      <InvoiceHeader />
      <TooltipContainer
        width={300}
        toolTipContent={allowEditingToolTipContent()}
        hideArrow
        isVisible={
          [
            INVOICE_STATUSES.SENT,
            INVOICE_STATUSES.REMIND,
            INVOICE_STATUSES.PAID,
            INVOICE_STATUSES.QUEUED_UP,
            INVOICE_STATUSES.PROCESSING,
          ].includes(invoiceStatus) && disableEditing
        }
        direction={DIRECTIONS.TOP}
        yOffset={-200}
      >
        {[INVOICE_STATUSES.PROCESSING, INVOICE_STATUSES.TAXES_PROCESSING].includes(invoiceStatus) && (
          <InvoiceProcessingInfoCard invoice={invoice} invoicingServiceDisplayName={invoicingServiceDisplayName} />
        )}
        {![INVOICE_STATUSES.PROCESSING, INVOICE_STATUSES.TAXES_PROCESSING].includes(invoiceStatus) && (
          <AutoChargeCard invoice={values} customer={customerDetails} invoicingSchedule={currentInvoicingSchedule} />
        )}

        <FormWrapper>
          <FlexerColumn width="100%" gap="32px">
            <InvoiceFailedToSaveWarning invoice={invoice} />
            <InvoiceFailedToSendWarning invoice={invoice} />
            <InvoicePaymentFailedWarning invoice={invoice} />

            {(aiRemindersEnabled
              ? [INVOICE_STATUSES.SENT, INVOICE_STATUSES.REMIND]
              : [INVOICE_STATUSES.REMIND]
            ).includes(invoiceStatus) &&
              (aiRemindersEnabled ? (
                <RemindersByAIBanner
                  aiRemindersToSend={aiRemindersToSend}
                  invoice={addRemindersInfoFieldsToInvoice({ invoice })}
                  onReminderOpen={() => openInvoicePreviewModal()}
                  onGenerateNow={refetchCurrentInvoice}
                />
              ) : (
                <RemindersBanner
                  onInvoiceClick={() => openInvoicePreviewModal()}
                  invoiceModalView
                  invoice={addRemindersInfoFieldsToInvoice({ invoice })}
                />
              ))}

            <FlexerColumn width="100%" gap="12px">
              <FlexerRow width="100%" gap="12px">
                <FlexerColumn gap="12px" flexGrow="1">
                  <PopoverWrapper>
                    <CustomDatePicker
                      formik
                      name="date"
                      isChanged={!!values?.changedFieldsWithOldValues?.date}
                      label="Invoice Date"
                      dateFormat="MMM dd, yyyy"
                      meta={getFieldMeta('date')}
                      selected={values?.date ? formatDateForDatepicker(values?.date) : null}
                      onChange={onDateChange}
                      disabled={disableEditing}
                      {...getFieldChangesProps({
                        values,
                        field: `date`,
                        tooltipInputDisplay: (
                          <>
                            <div>Old value:</div>{' '}
                            <div> {dayjs.utc(values?.changedFieldsWithOldValues?.date).format('MMM DD, YYYY')} </div>
                          </>
                        ),
                      })}
                    />

                    {moveDatesPopover}
                  </PopoverWrapper>
                </FlexerColumn>

                <FlexerColumn gap="12px">
                  <FormikCustomInput
                    width="72px"
                    data-cy="schedule-modal__invoice__days-to-pay"
                    name="days_to_pay"
                    label="Days to pay"
                    type="number"
                    isDisabled={disableEditing}
                    handleChange={(value) => !Number.isNaN(Number(value)) && setFieldValue('days_to_pay', value)}
                    {...getFieldChangesProps({
                      values,
                      field: `days_to_pay`,
                    })}
                  />
                </FlexerColumn>

                {values?.paid_at ? (
                  <FlexerColumn gap="12px" flexGrow="1">
                    <CustomDatePicker
                      formik
                      name="paid_at"
                      isChanged={!!values?.changedFieldsWithOldValues?.paid_at}
                      toolTipWidth={150}
                      tooltipInputDisplay={
                        values?.changedFieldsWithOldValues?.paid_at ? (
                          <>
                            <div>Old value:</div>{' '}
                            <div> {dayjs.utc(values?.changedFieldsWithOldValues?.paid_at).format('MMM DD, YYYY')} </div>{' '}
                          </>
                        ) : null
                      }
                      label="Paid On"
                      dateFormat="MMM dd, yyyy"
                      selected={values?.paid_at ? formatDateForDatepicker(values?.paid_at) : null}
                      onChange={(name, date) => setFieldValue(name, updateDateFromDatePicker(date))}
                    />
                  </FlexerColumn>
                ) : (
                  <FlexerColumn gap="12px" flexGrow="1">
                    <CustomDatePicker
                      formik
                      label="Invoice due date"
                      dateFormat="MMM dd, yyyy"
                      selected={
                        values?.date
                          ? formatDateForDatepicker(dayjs.utc(values.date).add(values.days_to_pay, 'days').toDate())
                          : null
                      }
                      disabled
                    />
                  </FlexerColumn>
                )}

                <FlexerColumn gap="12px" flexGrow="1">
                  <FormikCustomInput
                    data-cy="schedule-modal__invoice__number"
                    name="invoice_number"
                    label="Invoice number"
                    placeholder="Optional"
                    type="text"
                    labelTooltipContent={
                      invoiceNumberManagedBySubscript
                        ? 'We recommend leaving it empty so that Subscript can generate the invoice numbers and make sure there is no accounting gap'
                        : `Leave this blank to have the invoice number be generated by ${invoicingServiceDisplayName}`
                    }
                    isDisabled={disableEditing}
                    {...getFieldChangesProps({
                      values,
                      field: `invoice_number`,
                    })}
                  />
                </FlexerColumn>

                {billingSenderDefaults?.show_po_number && (
                  <FlexerColumn gap="12px" flexGrow="1">
                    <FormikCustomInput
                      data-cy="schedule-modal__po__number"
                      name="po_number"
                      label="PO #"
                      placeholder="Optional"
                      type="text"
                      isDisabled={disableEditing}
                      {...getFieldChangesProps({
                        values,
                        field: `po_number`,
                      })}
                    />
                  </FlexerColumn>
                )}

                <FlexerColumn gap="12px" flexGrow="1">
                  <CustomDatePicker
                    formik
                    name="service_start"
                    isChanged={!!values?.changedFieldsWithOldValues?.service_start}
                    toolTipWidth={150}
                    tooltipInputDisplay={
                      values?.changedFieldsWithOldValues?.service_start ? (
                        <>
                          <div>Old value:</div>{' '}
                          <div>
                            {dayjs.utc(values?.changedFieldsWithOldValues?.service_start).format('MMM DD, YYYY')}{' '}
                          </div>{' '}
                        </>
                      ) : null
                    }
                    label="Service start date"
                    dateFormat="MMM dd, yyyy"
                    selected={values?.service_start ? formatDateForDatepicker(values?.service_start) : null}
                    onChange={(name, date) => setFieldValue(name, updateDateFromDatePicker(date))}
                  />
                </FlexerColumn>

                <FlexerColumn gap="12px" flexGrow="1">
                  <CustomDatePicker
                    formik
                    name="service_end"
                    isChanged={!!values?.changedFieldsWithOldValues?.service_end}
                    toolTipWidth={150}
                    tooltipInputDisplay={
                      values?.changedFieldsWithOldValues?.service_end ? (
                        <>
                          <div>Old value:</div>{' '}
                          <div>
                            {dayjs.utc(values?.changedFieldsWithOldValues?.service_end).format('MMM DD, YYYY')}{' '}
                          </div>{' '}
                        </>
                      ) : null
                    }
                    label="Service end date"
                    dateFormat="MMM dd, yyyy"
                    selected={values?.service_end ? formatDateForDatepicker(values?.service_end) : null}
                    onChange={(name, date) => setFieldValue(name, updateDateFromDatePicker(date))}
                  />
                </FlexerColumn>
              </FlexerRow>

              {[INVOICE_STATUSES.UNSENT, INVOICE_STATUSES.SAVE_FAILED, INVOICE_STATUSES.SENT_FAILED].includes(
                invoiceStatus,
              ) &&
                !invoice.auto_charge && (
                  <ScheduleEmailSection width="100%" gap="8px" height="40px" alignItems="center">
                    <FlexerColumn gap="12px">
                      <SwitchWithLabel
                        bolded
                        onChange={autoSendToggleOnChange}
                        checked={values?.auto_send}
                        label="Auto-send this invoice"
                        labelSize="12px"
                        name="auto-send-invoice"
                      />
                    </FlexerColumn>
                    {values.auto_send && (
                      <CustomDatePicker
                        formik
                        name="send_date"
                        isChanged={!!values?.changedFieldsWithOldValues?.send_date}
                        toolTipWidth={150}
                        tooltipInputDisplay={
                          values?.changedFieldsWithOldValues?.send_date ? (
                            <>
                              <div>Old value:</div>{' '}
                              <div>
                                {dayjs.utc(values?.changedFieldsWithOldValues?.send_date).format('MMM DD, YYYY')}{' '}
                              </div>{' '}
                            </>
                          ) : null
                        }
                        meta={getFieldMeta('send_date')}
                        filtersViewDateFormat="MMM D, YYYY"
                        filtersView
                        selected={values?.send_date ? formatDateForDatepicker(values?.send_date) : null}
                        onChange={(name, send_date) => setFieldValue(name, updateDateFromDatePicker(send_date))}
                      />
                    )}
                  </ScheduleEmailSection>
                )}
            </FlexerColumn>

            <FlexerRow width="100%" gap="12px">
              <FlexerColumn width="100%">
                <FormikCustomInput
                  name="memo"
                  disableLabelOpacity
                  label={
                    <CentererVertical gap="8px">
                      <SectionTitle>Memo: </SectionTitle>
                      {invoice?.id && (
                        <OpenReplacedTemplateVariablesModalButton dataCy="schedule-modal__invoice__memo__preview" />
                      )}
                    </CentererVertical>
                  }
                  data-cy="schedule-modal__invoice__memo"
                  inputHeight="166px"
                  HTMLString={templateToHTMLString({ text: values?.memo, availableTags })}
                  availableTags={availableTags}
                  type="HTMLEdit"
                  TopRightHeader={
                    <CentererVertical gap="8px">
                      <MemoTemplateDropdown
                        values={values}
                        isDisabled={disableEditing}
                        invoicingScheduleFormValues={invoicingScheduleFormValues}
                        onSelect={({ templateId, content }) => {
                          setFieldValue('memo', content || '');
                          setFieldValue('invoice_memo_template_id', templateId ?? null);
                        }}
                      />
                    </CentererVertical>
                  }
                  isDisabled={disableEditing}
                  background="var(--lightGray)"
                  {...getFieldChangesProps({
                    values,
                    field: `memo`,
                    toolTipWidth: 600,
                    tooltipInputDisplay: (
                      <>
                        <div>Old value:</div>
                        <div>
                          {convertToPlainText(
                            templateToHTMLString({ text: values?.changedFieldsWithOldValues?.memo, availableTags }),
                          )}
                        </div>
                      </>
                    ),
                  })}
                />
              </FlexerColumn>

              {sendEmailFromSubscript && (
                <FlexerColumn width="100%">
                  <FormikCustomInput
                    name="secondary_memo"
                    disableLabelOpacity
                    label={
                      <CentererVertical gap="8px">
                        <SectionTitle>Footer Memo: </SectionTitle>
                        {invoice?.id && (
                          <OpenReplacedTemplateVariablesModalButton dataCy="schedule-modal__invoice__secondary_memo__preview" />
                        )}
                      </CentererVertical>
                    }
                    data-cy="schedule-modal__invoice__secondary_memo"
                    inputHeight="166px"
                    HTMLString={templateToHTMLString({ text: values?.secondary_memo, availableTags })}
                    availableTags={availableTags}
                    type="HTMLEdit"
                    TopRightHeader={
                      <CentererVertical gap="8px">
                        <MemoTemplateDropdown
                          values={values}
                          isDisabled={disableEditing}
                          invoicingScheduleFormValues={invoicingScheduleFormValues}
                          onSelect={({ templateId, content }) => {
                            setFieldValue('secondary_memo', content || '');
                            setFieldValue('invoice_secondary_memo_template_id', templateId ?? null);
                          }}
                        />
                      </CentererVertical>
                    }
                    isDisabled={disableEditing}
                    {...getFieldChangesProps({
                      values,
                      field: `secondary_memo`,
                      toolTipWidth: 600,
                      tooltipInputDisplay: (
                        <>
                          <div>Old value:</div>
                          <div>
                            {convertToPlainText(
                              templateToHTMLString({
                                text: values?.changedFieldsWithOldValues?.secondary_memo,
                                availableTags,
                              }),
                            )}
                          </div>
                        </>
                      ),
                    })}
                  />
                </FlexerColumn>
              )}

              {/*
                <BillingDynamicFieldsTooltip
                  tags={{
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.GENERAL_TAGS]: DEFAULT_AVAILABLE_TAGS,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.TRANSACTION_METADATA_TAGS]: transactionMetadataTags,
                    [DYNAMIC_FIELD_TOOLTIP_SECTIONS.CUSTOMER_METADATA_TAGS]: customerMetadataTags,
                  }}
                  XOffset={100}
                  centerContent
                />
            */}
            </FlexerRow>

            <FlexerRow width="100%" gap="16px">
              <FlexerColumn width="100%" gap="16px">
                <SectionTitle>Payment Options</SectionTitle>
                {(!SERVICE_WITHOUT_EMAIL_MODIFICATIONS.includes(invoicingService) ||
                  // If you are using the subscript payment link, we want you to be able to pick payment methods always
                  billingInvoiceDefaults.use_subscript_payment_link) && (
                  <FormikCustomSelector
                    name="payment_options"
                    containerWidth="100%"
                    options={SELECTOR_PAYMENT_OPTIONS}
                    handleChange={(selectedValues) =>
                      setFieldValue('payment_options', selectedValues?.map((option) => option.value) ?? [])
                    }
                    isDisabled={disableEditing}
                    isMulti
                    {...getFieldChangesProps({
                      values,
                      field: `payment_options`,
                      tooltipInputDisplay: (
                        <>
                          <div>Old value:</div>
                          <div>{values?.changedFieldsWithOldValues?.payment_options?.join(', ')}</div>
                        </>
                      ),
                    })}
                  />
                )}
              </FlexerColumn>
              {customerDetails?.invoicing_details?.taxID && !isNetsuiteSender && (
                <FlexerColumn width="100%" gap="16px">
                  <FlexerRow gap="4px" alignItems="center">
                    <SectionTitle>Tax ID</SectionTitle>
                    <InlineButton
                      type="button"
                      data-cy="billing__single-invoice__edit-tax-id-button"
                      onClick={() => setShowCustomersActionModal(true)}
                      lineHeight="12px"
                      fontSize="12px"
                      isSecondary
                      withBackground
                    >
                      Edit
                    </InlineButton>
                  </FlexerRow>
                  <FormikCustomInput
                    name="taxID"
                    data-cy="schedule-modal__invoice__tax_id"
                    value={customerDetails.invoicing_details.taxID}
                    isDisabled
                    {...getFieldChangesProps({
                      values,
                      field: `taxID`,
                    })}
                  />
                </FlexerColumn>
              )}
            </FlexerRow>

            <FlexerRow width="100%" gap="16px">
              <FlexerColumn width="100%" gap="12px">
                <SectionTitle>Email</SectionTitle>
                <Flexer width="100%">
                  <FormikEmailSelector
                    name="email_addresses"
                    label="Recipient email(s)"
                    containerWidth="100%"
                    smallVersion
                    value={values.email_addresses?.filter((e) => typeof e === 'string')} // Strip out any non-string values
                    onChange={(selectedValues) => setFieldValue('email_addresses', selectedValues)}
                    // Don't let a user change this once it's paid or sent
                    isDisabled={disableEditing}
                    {...getFieldChangesProps({
                      values,
                      field: `email_addresses`,
                      tooltipInputDisplay: (
                        <>
                          <div>Old value:</div>
                          <div>{values?.changedFieldsWithOldValues?.email_addresses?.join(', ')}</div>
                        </>
                      ),
                    })}
                  />
                </Flexer>

                <Flexer width="100%">
                  <TooltipContainer
                    toolTipContent="Xero does not support CC recipients when sending invoices."
                    tooltipWrapperStyles={{ width: '100%' }}
                    width={200}
                    fontSize="12px"
                    isVisible={isXeroSender}
                    hideArrow
                  >
                    <FormikEmailSelector
                      name="email_cc"
                      label="CC"
                      containerWidth="100%"
                      smallVersion
                      value={values.email_cc?.filter((e) => typeof e === 'string')} // Strip out any non-string values
                      onChange={(selectedValues) => setFieldValue('email_cc', selectedValues)}
                      // Don't let a user change this once it's paid or sent
                      isDisabled={disableEditing || isXeroSender}
                      {...getFieldChangesProps({
                        values,
                        field: `email_cc`,
                        tooltipInputDisplay: (
                          <>
                            <div>Old value:</div>
                            <div>{values?.changedFieldsWithOldValues?.email_cc?.join(', ')}</div>
                          </>
                        ),
                      })}
                    />
                  </TooltipContainer>
                </Flexer>
              </FlexerColumn>

              <FlexerColumn width="100%" gap="12px">
                <SectionTitle>Addresses</SectionTitle>

                <FlexerColumn gap="8px" width="100%">
                  <FormikCustomInput
                    name="ignore_billing_address"
                    data-cy="schedule-modal__invoice__billing_address"
                    label="Billing Address"
                    value={hasBillingAddress ? formattedBillingAddress : 'No billing address'}
                    isDisabled
                    TopRightHeader={
                      <InlineButton
                        lineHeight="12px"
                        fontSize="12px"
                        isSecondary
                        withBackground
                        onClick={(e) => {
                          e.preventDefault();
                          setAddressTab(ADDRESS_TABS.BILLING);
                          setShowCustomersActionModal(true);
                        }}
                        disabled={disableEditing}
                        type="button"
                        data-cy="billing__single-invoice__edit-billing-address-button"
                      >
                        {hasBillingAddress ? 'Edit' : 'Add billing address'}
                      </InlineButton>
                    }
                  />
                </FlexerColumn>

                <FlexerColumn gap="8px" width="100%">
                  <FormikCustomInput
                    name="ignore_shipping_address"
                    data-cy="schedule-modal__invoice__shipping_address"
                    label="Shipping Address"
                    value={formattedShippingAddress ? formattedShippingAddress : 'Same as billing address'}
                    isDisabled
                    TopRightHeader={
                      <InlineButton
                        lineHeight="12px"
                        fontSize="12px"
                        isSecondary
                        withBackground
                        onClick={(e) => {
                          e.preventDefault();
                          setAddressTab(ADDRESS_TABS.SHIPPING);
                          setShowCustomersActionModal(true);
                        }}
                        type="button"
                        data-cy="billing__single-invoice__edit-shipping-address-button"
                      >
                        Edit
                      </InlineButton>
                    }
                    LabelAction={
                      <FormikCustomCheckbox
                        label="Show in the invoice PDF"
                        name="metadata.show_shipping_address"
                        isDisabled={disableEditing}
                        bold
                        containerStyle={{
                          marginLeft: 'auto',
                        }}
                      />
                    }
                  />
                </FlexerColumn>
              </FlexerColumn>
            </FlexerRow>

            <InvoiceCustomFields values={values} setFieldValue={setFieldValue} disableEditing={disableEditing} />

            <InvoiceCreditNotes creditNotes={invoice?.credit_notes ?? []} />

            <InvoiceItemsAndTaxes
              disableEditing={disableEditing}
              invoice={invoice}
              values={values}
              invoicingService={invoicingService}
              setFieldValue={setFieldValue}
              productData={productData}
            />
          </FlexerColumn>
          {showCustomersActionModal && (
            <CustomersActionsModal
              organizationId={orgId}
              closeModal={() => {
                setShowCustomersActionModal(false);
                refetchCustomer();
              }}
              modalAction={CUSTOMERS_MODAL_ACTIONS.EDIT}
              customer={customerDetails}
              showCustomerEmails={true}
              defaultAddressTab={addressTab}
            />
          )}
        </FormWrapper>
      </TooltipContainer>
      <ConfirmTurnOffAutoSendModal />
    </InvoiceWrapper>
  );
};
