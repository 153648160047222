import React, { useContext, useCallback, useMemo, useState } from 'react';

import { AppContext } from 'AppContext';

import { TransactionSingleContent, TRANSACTION_MODAL_MODE } from 'shared/TransactionContent';
import { generateColumns } from './transactionColumns';
import { PreviewTable } from './PreviewTable';

const TransactionPreviewTable = ({ data = [], setDataHook }) => {
  const [tableData, setTableData] = useState(data);
  const [modalTransactionIndex, setModalTransactionIndex] = useState(null);

  const updateTableData = useCallback(
    ({ data }) => {
      const updatedTableData = [...tableData];
      updatedTableData[modalTransactionIndex] = data;
      delete updatedTableData[modalTransactionIndex].product_confidence;
      delete updatedTableData[modalTransactionIndex].customer_confidence;
      setTableData(updatedTableData);
    },
    [tableData, modalTransactionIndex],
  );

  const {
    organizations,
    appSettings: { isARR, currencyISOCode },
  } = useContext(AppContext);

  const columns = useMemo(
    () =>
      generateColumns({
        onTransactionClick: (row) => {
          setModalTransactionIndex(row.index);
        },
        onCustomerClick: () => {},
        isARR,
        currencyISOCode,
      }),
    [isARR, currencyISOCode],
  );

  const selectedRowData = useMemo(() => {
    return tableData[modalTransactionIndex];
  }, [tableData, modalTransactionIndex]);

  return (
    <PreviewTable entityName="transactions" columns={columns} tableData={tableData} setDataHook={setDataHook}>
      {modalTransactionIndex !== null && (
        <TransactionSingleContent
          transaction={selectedRowData}
          mode={TRANSACTION_MODAL_MODE.CREATE}
          saveNewTransaction={false}
          organization={organizations[0]}
          closeModal={() => setModalTransactionIndex(null)}
          onTransactionCreated={({ transactionData }) => {
            updateTableData({ data: transactionData });
          }}
        />
      )}
    </PreviewTable>
  );
};

export { TransactionPreviewTable };
