import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { capitalize, isEmpty } from 'lodash';
import { useTable, useGroupBy, usePagination } from 'react-table';
import { setNestedObjectValues, useFormikContext } from 'formik';
import { ReactComponent as ErrorIcon } from 'images/alert-circle-csv.svg';
import { ReactComponent as CheckIcon } from 'images/circle-check-csv.svg';
import { ReactComponent as HelpCircleIcon } from 'images/help-circle-yellow.svg';
import { PaginationButton } from 'components/Buttons';
import { selectStyles } from 'components/Controls';
import { Row, Spacer } from 'components/Core';
import { GreyDot } from 'components/Icons';
import {
  ReactTable,
  ReactTableBody,
  ReactTableCell,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableRow,
  TablePageSeparator,
} from 'components/Table';
import { TooltipContainer } from 'components/Tooltip';
import { SEARCH_CONFIDENCE } from 'utils/stringUtils';
import { CircleLoader } from 'components/Loaders';
import { CSV_MODES } from '../CsvUpload';
import { validateAsync } from './validateAsync';

const VIEW_MODES = {
  ALL: 'all',
  ERRORS: 'errors',
  FUZZY: 'fuzzy',
};

const ViewModes = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
`;

const ModeTab = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  pointer-events: ${({ active }) => active && 'none'};
  cursor: pointer;
  padding: 8px 12px;
  box-shadow: 4px 4px 24px 0px var(--primaryBlack4);
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
  background: ${({ active }) => (active ? 'var(--primaryBlue10)' : 'white')};
  color: ${({ active }) => active && 'var(--primaryBlue)'};
  margin-left: 12px;

  &:first-child {
    margin-left: 0;
  }

  svg {
    margin-right: 8px;
  }
`;

const ErrorsTooltip = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: white;
  text-align: left;
`;

const FormValidating = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  margin-left: 8px;
`;

const ErrorItem = styled.div`
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const ReValidate = styled.div`
  cursor: pointer;
  font-style: italic;
  color: var(--primaryBlue);

  &:hover {
    color: var(--secondaryBlue);
  }
`;

const ErrorItemDot = styled.div`
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 100px;
  margin-right: 6px;
`;

const StyledReactTableRow = styled(ReactTableRow)`
  border-bottom: 0;
  height: 42px;
  padding: 8px 14px;
  position: relative;

  &:first-child {
    margin-top: 6px;
  }

  &:last-child {
    margin-bottom: 6px;
  }
`;

export const ColumnMapperSelector = ({ id, field, csvColumnsMapper, setCsvColumnsMapper, csvColumns }) => {
  const csvColumnByColumnsMapper = ({ columnName }) => {
    const columnMapperValue = csvColumnsMapper?.[columnName];

    const csvColumn = csvColumns?.find(
      (column) => column?.value === columnMapperValue || column?.label === columnMapperValue,
    );

    if (csvColumn) {
      return { label: csvColumn?.value, value: csvColumn?.value };
    }
  };

  return (
    <Select
      id={id}
      className="react-select"
      classNamePrefix="react-select"
      menuPlacement="auto"
      placeholder="Select column..."
      value={csvColumnByColumnsMapper({ columnName: field }) ?? null}
      menuPortalTarget={document.querySelector('body')}
      styles={selectStyles({ width: 160 })}
      onChange={(newColumn) => {
        setCsvColumnsMapper((prevMapper) => ({ ...prevMapper, [field]: newColumn.value }));
      }}
      options={csvColumns ? [...csvColumns, { value: null, label: <i>N/A</i> }] : []}
    />
  );
};

const shouldShowRow = ({ viewMode, errors, fuzzyRowsMapper, row }) => {
  switch (viewMode) {
    case VIEW_MODES.ERRORS:
      return Object.values(errors?.[row.rowIndex] ?? {}).length > 0;
    case VIEW_MODES.FUZZY:
      return Object.values(fuzzyRowsMapper?.[row.rowIndex] ?? {})?.some(
        (fuzzyCell) => fuzzyCell?.confidence === SEARCH_CONFIDENCE.FUZZY,
      );
    default:
      return true;
  }
};

export const CsvTable = ({
  columns,
  fuzzyRowsMapper,
  setFormIsValid,
  entityName,
  currentPageIndex,
  createOrUpdateMode,
  setCurrentPageIndex,
  formRef,
  showViewModes = true,
}) => {
  const { values, errors } = useFormikContext();
  const [viewMode, setViewMode] = useState(VIEW_MODES.ALL);
  const [validatedRows, setValidatedRows] = useState(undefined);

  const rowsByViewMode = useMemo(() => {
    const allRows = values ?? [];

    return Object.values(VIEW_MODES).reduce((acc, viewMode) => {
      acc[viewMode] = allRows.filter((row) =>
        shouldShowRow({
          viewMode,
          errors,
          fuzzyRowsMapper,
          row,
        }),
      );
      return acc;
    }, {});
  }, [values, fuzzyRowsMapper, errors]);

  const data = useMemo(() => rowsByViewMode[viewMode], [rowsByViewMode, viewMode]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPageIndex,
        pageSize: 20,
      },
    },
    useGroupBy,
    usePagination,
  );

  useEffect(() => {
    setCurrentPageIndex(pageIndex);
  }, [pageIndex, setCurrentPageIndex]);

  // We validate form manually here as it is more stable

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const validateSync = async () => {
      const errors = await formRef?.current?.validateForm(values);

      if (!isEmpty(errors)) {
        formRef?.current?.setTouched?.(setNestedObjectValues(errors, true));
      }
      setFormIsValid(isEmpty(errors));
    };

    if (values) {
      if (values?.length < 1000) {
        validateSync();
      } else if (values?.length >= 1000) {
        if (validatedRows === undefined && values?.some((row) => Object.keys(row)?.length > 1)) {
          validateAsync({ values, formRef, setValidatedRows, setFormIsValid });
        }
      }
    }
  }, [values, formRef, validatedRows, setFormIsValid, columns]);

  const fuzzyRowsCount = rowsByViewMode[VIEW_MODES.FUZZY].length;

  return (
    <>
      <Row horizontal="space-between" style={{ marginTop: 30, marginBottom: 14 }}>
        <ViewModes>
          {showViewModes && (
            <>
              <ModeTab onClick={() => setViewMode(VIEW_MODES.ALL)} active={viewMode === VIEW_MODES.ALL}>
                {capitalize(entityName)} will be {createOrUpdateMode === CSV_MODES.UPDATE ? 'updated' : 'created'}:{' '}
                {rowsByViewMode[VIEW_MODES.ALL].length}
              </ModeTab>
              {!!Object.values(errors ?? {})?.filter(Boolean).length && (
                <>
                  <GreyDot style={{ marginLeft: 12 }} spacing="0" size="4px" />
                  <ModeTab onClick={() => setViewMode(VIEW_MODES.ERRORS)} active={viewMode === VIEW_MODES.ERRORS}>
                    <ErrorIcon /> Only errors ({rowsByViewMode[VIEW_MODES.ERRORS].length})
                  </ModeTab>
                </>
              )}
              {fuzzyRowsCount > 0 && (
                <>
                  <GreyDot style={{ marginLeft: 12 }} spacing="0" size="4px" />
                  <ModeTab onClick={() => setViewMode(VIEW_MODES.FUZZY)} active={viewMode === VIEW_MODES.FUZZY}>
                    <HelpCircleIcon /> Fuzzy matches ({fuzzyRowsCount})
                  </ModeTab>
                </>
              )}

              {!!validatedRows && (
                <Row>
                  <GreyDot spacing="12px" size="4px" />
                  <CircleLoader width={15} height={15} />
                  <FormValidating>Validating: {`${validatedRows}/${values?.length}`}</FormValidating>
                </Row>
              )}

              {validatedRows === null ? (
                <>
                  <GreyDot spacing="12px" size="4px" />
                  <ReValidate onClick={() => validateAsync({ values, formRef, setValidatedRows, setFormIsValid })}>
                    Validate Again
                  </ReValidate>
                </>
              ) : null}
            </>
          )}
        </ViewModes>

        <Row>
          <PaginationButton
            pageIndex={pageIndex + 1}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </Row>
      </Row>

      <TablePageSeparator />
      <ReactTable style={{ minHeight: 400 }} pageView {...getTableProps()}>
        <ReactTableHeader pageView>
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow height="104px" pageView {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn customWidth={column.width} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        <ReactTableBody pageView {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            const rowErrors = Object.values(errors?.[row.original.rowIndex] ?? {});
            const isFuzzy = Object.values(fuzzyRowsMapper?.[row.original.rowIndex] ?? {})?.some(
              (fuzzyCell) => fuzzyCell?.confidence === SEARCH_CONFIDENCE.FUZZY,
            );

            return (
              <StyledReactTableRow
                rowDepth={row.depth}
                noBorderBottom={row.canExpand && row.isExpanded}
                data-cy={'upload-csv__row'}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <ReactTableCell customWidth={cell.column.width} {...cell.getCellProps()}>
                    {cell?.column?.id === 'status' ? (
                      <TooltipContainer
                        width={230}
                        isVisible={rowErrors.length > 0 || isFuzzy}
                        backgroundColor="rgba(64, 80, 98, 0.98)"
                        toolTipContent={
                          <ErrorsTooltip>
                            {rowErrors.length > 0 ? (
                              <>
                                Correct the file or manually enter data:
                                <Spacer height="4px" />
                                {rowErrors.map((error) => (
                                  <ErrorItem key={error}>
                                    <ErrorItemDot />
                                    {error}
                                  </ErrorItem>
                                ))}
                              </>
                            ) : (
                              <span>
                                Check for the <b>fuzzy name match</b> and correct if necessary
                              </span>
                            )}
                          </ErrorsTooltip>
                        }
                      >
                        {rowErrors.length ? (
                          <ErrorIcon data-cy="upload-csv__row-error" />
                        ) : isFuzzy ? (
                          <HelpCircleIcon data-cy="upload-csv__row-fuzzy" />
                        ) : (
                          <CheckIcon data-cy="upload-csv__row-checked" />
                        )}
                      </TooltipContainer>
                    ) : (
                      cell.render('Cell')
                    )}
                  </ReactTableCell>
                ))}

                {/* <RemoveButton
                  onClick={() => setValues(values?.filter((valuesRow) => valuesRow.rowIndex !== row.original.rowIndex))}
                >
                  <BinIcon />
                </RemoveButton> */}
              </StyledReactTableRow>
            );
          })}
        </ReactTableBody>
      </ReactTable>
    </>
  );
};
