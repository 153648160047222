import styled, { css } from 'styled-components';
import { Centerer } from 'components/Core';

export const RecognitionToggler = styled(Centerer)`
  font-size: 16px;
  line-height: 22px;
  padding: 6px 11px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--primaryBlack10);
  background-color: white;

  svg {
    width: 10px;
    margin-left: 10px;
    transform: ${({ active }) => active && 'rotate(180deg)'};
    path {
      fill: #cccccc;
    }
  }

  ${({ empty, bannerVersion }) =>
    empty && !bannerVersion
      ? css`
          font-weight: 400;
          background: ${({ active }) => (active ? 'var(--primaryRed20)' : 'var(--primaryRed10)')};
          color: ${({ active }) => (active ? 'var(--secondaryRed)' : 'var(--primaryRed)')};

          &:hover {
            background: var(--primaryRed20);
            color: var(--secondaryRed);
          }
        `
      : bannerVersion
      ? css`
          font-weight: 700;
          padding: 2px 4px;
          border-radius: 4px;
          font-size: 12px;
          line-height: 16px;
          color: ${({ active }) =>
            empty ? 'var(--primaryBlue50)' : active ? 'var(--secondaryBlue)' : 'var(--primaryBlue)'};
          background: var(--primaryBlue10);

          svg {
            display: none;
          }

          &:hover {
            color: var(--secondaryBlue);
          }
        `
      : css`
          font-weight: 900;
          color: ${({ active }) => (active ? 'var(--secondaryBlue)' : 'var(--primaryBlue)')};

          &:hover {
            border: 1px solid var(--accentGraySecond);
            color: var(--secondaryBlue);
          }
        `}
`;
