import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { AppContext } from 'AppContext';
import { NUMBER_FORMATS } from 'consts/global';
import { SNAPSHOT_METRICS } from 'consts/snapshotMetrics';
import { numberFormatter } from 'utils/formatters';
import { ToolTipContentContainer, ToolTipRow, ToolTipLabel, ToolTipValue, ToolTipDivider } from './SpreadsTable.styles';
import { formatCurrency } from './utils';

export const RetentionToolTip = ({ spreadsData, month, retentionMetricKey, retentionMetricTitle }) => {
  const {
    appSettings: { isARR, currencyISOCode: currency },
  } = useContext(AppContext);

  const currentMonth = dayjs(month, 'YYYY-MM');
  const startMonth = dayjs(spreadsData.months[0], 'YYYY-MM');

  const currentValue = [
    SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION.monthDataKey,
    SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM.monthDataKey,
  ].includes(retentionMetricKey)
    ? numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: spreadsData.totalAmountsEachMonth[month] })
    : formatCurrency(spreadsData.totalAmountsEachMonth[month], isARR, currency);

  const startValue = [
    SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION.monthDataKey,
    SNAPSHOT_METRICS.GROSS_CUSTOMER_RETENTION_TTM.monthDataKey,
  ].includes(retentionMetricKey)
    ? numberFormatter({ type: NUMBER_FORMATS.NUMBER, rawValue: spreadsData.startingAmountByMonth[month] })
    : formatCurrency(spreadsData.startingAmountByMonth[month], isARR, currency);

  const retentionValue = numberFormatter({
    type: NUMBER_FORMATS.PERCENT,
    rawValue: spreadsData.retentionByMonth[month],
  });

  return (
    <ToolTipContentContainer>
      <ToolTipLabel>Calculations for {currentMonth.format('MMM YYYY')}</ToolTipLabel>
      <ToolTipDivider />
      <ToolTipRow>
        <ToolTipLabel>{currentMonth.format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>{currentValue}</ToolTipValue>
      </ToolTipRow>
      <ToolTipRow>
        <ToolTipLabel>{startMonth.format('MMM YYYY')}:</ToolTipLabel>
        <ToolTipValue>{startValue}</ToolTipValue>
      </ToolTipRow>
      <ToolTipRow>
        <ToolTipLabel>{retentionMetricTitle}:</ToolTipLabel>
        <ToolTipValue>{retentionValue}</ToolTipValue>
      </ToolTipRow>
      <ToolTipDivider />
      <ToolTipRow>
        <ToolTipLabel>{`${currentValue} / ${startValue} = `}</ToolTipLabel>
        <ToolTipValue>{retentionValue}</ToolTipValue>
      </ToolTipRow>
    </ToolTipContentContainer>
  );
};
