export const INVOICES_TABLE_COLUMN_KEY = {
  CUSTOMER: 'customer_name',
  INVOICE_NUMBER: 'invoice_number',
  INVOICE_STATUS: 'invoice_status',
  DATE: 'date',
  SENT_DATE: 'sent_date',
  DUE_DATE: 'due_date',
  CHARGE_DATE: 'charge_date',
  PAID_DATE: 'paid_date',
  PRODUCT_NAMES: 'product_names',
  VOIDED_DATE: 'voided_date',
  AMOUNT: 'amount',
  TERMS: 'terms',
  EMAILS: 'email_addresses',
  INVOICING_FREQUENCY: 'invoicing_frequency',
  PAYMENT_OPTIONS: 'payment_options',
  AUTO_SEND: 'send_date',
  TRANSACTION_NAMES: 'transaction_names',
  SYNC_STATUS: 'sync_status',
  NOTES: 'internal_notes',
  SERVICE_START: 'service_start',
  SERVICE_END: 'service_end',
};

export const INVOICES_SORT_BY_COLUMNS_NAME_MAPPING = {
  [INVOICES_TABLE_COLUMN_KEY.DATE]: 'date',
  [INVOICES_TABLE_COLUMN_KEY.CUSTOMER]: 'customer_name',
  [INVOICES_TABLE_COLUMN_KEY.AMOUNT]: 'amount',
  [INVOICES_TABLE_COLUMN_KEY.INVOICE_STATUS]: 'invoice_status',
  [INVOICES_TABLE_COLUMN_KEY.INVOICE_NUMBER]: 'invoice_number',
  [INVOICES_TABLE_COLUMN_KEY.SENT_DATE]: 'sent_at',
  [INVOICES_TABLE_COLUMN_KEY.CHARGE_DATE]: 'charge_date',
  [INVOICES_TABLE_COLUMN_KEY.PAID_DATE]: 'paid_at',
  [INVOICES_TABLE_COLUMN_KEY.PRODUCT_NAMES]: 'product_names',
  [INVOICES_TABLE_COLUMN_KEY.TRANSACTION_NAMES]: 'transaction_names',
  [INVOICES_TABLE_COLUMN_KEY.VOIDED_DATE]: 'voided_at',
  [INVOICES_TABLE_COLUMN_KEY.DUE_DATE]: 'due_date',
  [INVOICES_TABLE_COLUMN_KEY.NOTES]: 'internal_notes',
  [INVOICES_TABLE_COLUMN_KEY.INVOICING_FREQUENCY]: 'invoicing_frequency',
  [INVOICES_TABLE_COLUMN_KEY.EMAILS]: 'email_addresses',
  [INVOICES_TABLE_COLUMN_KEY.SERVICE_START]: 'service_start',
  [INVOICES_TABLE_COLUMN_KEY.SERVICE_END]: 'service_end',
};

export const INVOICES_DEFAULT_SORT_BY = [
  { column: INVOICES_SORT_BY_COLUMNS_NAME_MAPPING[INVOICES_TABLE_COLUMN_KEY.DATE], order: 'desc' },
];

export const INVOICES_TABLE_COLUMN_TITLES_BY_ID = {
  [INVOICES_TABLE_COLUMN_KEY.CUSTOMER]: 'Customer',
  [INVOICES_TABLE_COLUMN_KEY.INVOICE_NUMBER]: 'Invoice Number',
  [INVOICES_TABLE_COLUMN_KEY.INVOICE_STATUS]: 'Status',
  [INVOICES_TABLE_COLUMN_KEY.DATE]: 'Inv. Date',
  [INVOICES_TABLE_COLUMN_KEY.SENT_DATE]: 'Sent Date',
  [INVOICES_TABLE_COLUMN_KEY.NOTES]: 'Notes',
  [INVOICES_TABLE_COLUMN_KEY.DUE_DATE]: 'Due Date',
  [INVOICES_TABLE_COLUMN_KEY.CHARGE_DATE]: 'Charge Date',
  [INVOICES_TABLE_COLUMN_KEY.PAID_DATE]: 'Paid Date',
  [INVOICES_TABLE_COLUMN_KEY.VOIDED_DATE]: 'Voided Date',
  [INVOICES_TABLE_COLUMN_KEY.PRODUCT_NAMES]: 'Products',
  [INVOICES_TABLE_COLUMN_KEY.TRANSACTION_NAMES]: 'Transactions',
  [INVOICES_TABLE_COLUMN_KEY.AMOUNT]: 'Amount',
  [INVOICES_TABLE_COLUMN_KEY.TERMS]: 'Terms',
  [INVOICES_TABLE_COLUMN_KEY.INVOICING_FREQUENCY]: 'Frequency',
  [INVOICES_TABLE_COLUMN_KEY.PAYMENT_OPTIONS]: 'Payment Options',
  [INVOICES_TABLE_COLUMN_KEY.AUTO_SEND]: 'Auto send',
  [INVOICES_TABLE_COLUMN_KEY.SYNC_STATUS]: 'Sync Status',
  [INVOICES_TABLE_COLUMN_KEY.EMAILS]: 'Emails',
  [INVOICES_TABLE_COLUMN_KEY.SERVICE_START]: 'Service Start',
  [INVOICES_TABLE_COLUMN_KEY.SERVICE_END]: 'Service End',
};

export const INVOICE_SYNC_STATUSES = {
  SYNCED: 'Synced',
  UNSYNCED: 'Unsynced',
  NO_SYNC: 'No Sync',
};

export const HIDDEN_DEFAULT_COLUMNS = [
  INVOICES_TABLE_COLUMN_KEY.SENT_DATE,
  INVOICES_TABLE_COLUMN_KEY.DUE_DATE,
  INVOICES_TABLE_COLUMN_KEY.CHARGE_DATE,
  INVOICES_TABLE_COLUMN_KEY.PAID_DATE,
  INVOICES_TABLE_COLUMN_KEY.AUTO_SEND,
  INVOICES_TABLE_COLUMN_KEY.VOIDED_DATE,
  INVOICES_TABLE_COLUMN_KEY.EMAILS,
  INVOICES_TABLE_COLUMN_KEY.SERVICE_START,
  INVOICES_TABLE_COLUMN_KEY.SERVICE_END,
];

export const HIDDEN_REMIND_STATUS_COLUMNS = [
  INVOICES_TABLE_COLUMN_KEY.CHARGE_DATE,
  INVOICES_TABLE_COLUMN_KEY.PAID_DATE,
  INVOICES_TABLE_COLUMN_KEY.TERMS,
  INVOICES_TABLE_COLUMN_KEY.INVOICING_FREQUENCY,
  INVOICES_TABLE_COLUMN_KEY.PAYMENT_OPTIONS,
  INVOICES_TABLE_COLUMN_KEY.AUTO_SEND,
  INVOICES_TABLE_COLUMN_KEY.PRODUCT_NAMES,
  INVOICES_TABLE_COLUMN_KEY.VOIDED_DATE,
  INVOICES_TABLE_COLUMN_KEY.EMAILS,
  INVOICES_TABLE_COLUMN_KEY.SERVICE_START,
  INVOICES_TABLE_COLUMN_KEY.SERVICE_END,
];
