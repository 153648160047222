import React, { Fragment, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useTable, useSortBy } from 'react-table';
import { AppContext } from 'AppContext';
import { useInvoicesAPI } from 'api/billing';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';
import { Loader } from 'components/Loaders';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
  HeaderCellWrapper,
  SortIconWrapper,
} from 'components/Table';
import { getColumns } from './columns';
import { Centerer } from 'components/Core';

const TableContainer = styled.div`
  width: 100%;
  overflow: scroll;
  margin-bottom: 24px;
`;

const TableLabel = styled.div`
  margin: 20px 0px;
  font-size: 18px;
  font-weight: 900;
  color: var(--primaryBlack);

  span {
    color: var(--primaryBlack50);
  }
`;

export const ContractInvoicesTable = ({ transactionsData }) => {
  const { orgId } = useContext(AppContext);

  const transactionIds = transactionsData.map((transaction) => transaction.id);

  const { data: invoicesData, isLoading, isFetching } = useInvoicesAPI({
    orgId,
    transactionIds,
    autoFetch: transactionIds.length > 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => invoicesData?.data || [], [JSON.stringify(invoicesData?.data)]);

  const columns = useMemo(() => getColumns(), []);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  if (isLoading || isFetching) {
    return (
      <Centerer width="100%" marginTop="32px">
        <Loader containerStyles={{ width: 40 }} />
      </Centerer>
    );
  }

  if (data.length === 0) {
    return <span>No invoices found</span>;
  }

  return (
    <div data-cy="contract-details-invoice-table">
      <TableLabel>
        Invoices <span>of Contract</span>
      </TableLabel>
      <TableContainer {...getTableProps()}>
        <ReactTableHeader>
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <ReactTableHeaderColumn
                  key={column.id}
                  customWidth={column.width}
                  alignRight={column.alignRight}
                  {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                >
                  <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                    {column.render('Header')}
                    {column.isSorted && (
                      <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                        <TotalIcon />
                      </SortIconWrapper>
                    )}
                  </HeaderCellWrapper>
                </ReactTableHeaderColumn>
              ))}
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        {rows.length ? (
          <ReactTableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.id}>
                  <ReactTableRow data-cy="contract-details-invoice-table_row" {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <ReactTableCell
                        key={cell.column.id}
                        {...cell.getCellProps()}
                        customWidth={cell.column.width}
                        alignRight={cell.column.alignRight}
                      >
                        {cell.render('Cell')}
                      </ReactTableCell>
                    ))}
                  </ReactTableRow>
                </Fragment>
              );
            })}
          </ReactTableBody>
        ) : null}
      </TableContainer>
    </div>
  );
};
