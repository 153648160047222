import React, { useCallback, useState, useRef, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { AppContext } from 'AppContext';
import { TableColumnSettings } from 'shared/Common';
import { ReactComponent as CheckIcon } from 'images/circle-check.svg';
import { Modal, ModalBody, ModalCloseIcon, ModalContainer } from 'components/Modal';
import { CircleLoader } from 'components/Loaders';
import { Centerer } from 'components/Core';
import { SwitchWithLabel } from 'components/Controls';
import { useInvoicesAPI } from 'api/billing';
import { sortByToKnexOrderBy } from 'utils/tableUtils';
import { usePagination } from 'utils/hooks';
import { INVOICE_STATUSES } from '../consts';
import { TableContainerLayout } from '../Common/TableContainerLayout';
import {
  HIDDEN_DEFAULT_COLUMNS,
  INVOICES_DEFAULT_SORT_BY,
  INVOICES_SORT_BY_COLUMNS_NAME_MAPPING,
  INVOICES_TABLE_COLUMN_TITLES_BY_ID,
} from '../InvoicingTables/consts';
import { groupInvoicesByMonth, InvoicesTable } from '../InvoicingTables';
import { BillingContext } from '../BillingContext';

const DEFAULT_PAGE_SIZE = 50;

const ColumnsSettingsWrapper = styled.div`
  position: relative;
`;

const TableTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-left: 40px;

  span {
    font-style: italic;
    color: var(--primaryBlack50);
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 28px 40px;
  border-bottom: 1px solid var(--primaryBlack3);
  background: var(--primaryBlack3);
  font-size: 14px;
  font-style: italic;
  line-height: 20px;
  gap: 4px;

  span {
    padding: 0 2px;
    background-color: var(--primaryYellow);
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;

    path {
      fill: var(--primaryGreen);
    }
  }
`;

const TableHeader = styled.div`
  display: flex;
  align-items: last baseline;
`;

const ToggleContainer = styled.div`
  display: flex;
  height: 32px;
  padding: 4px 12px 4px 8px;
  align-items: center;
  border-radius: 100px;
  color: var(--primaryBlack75);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-left: 12px;
  background: ${(props) => (props.active ? '#fff' : 'var(--primaryBlack3)')};
  border: ${(props) => (props.active ? '1px solid var(--accentGraySecond)' : 'initial')};
`;

const getHiddenColumnsWithoutChangedColumns = ({ updatedFieldsWithNewValues }) =>
  HIDDEN_DEFAULT_COLUMNS?.filter((column) => updatedFieldsWithNewValues?.[column] === undefined);

export const UpdateInvoiceFieldsModal = ({
  onClose,
  updatedFieldsWithNewValues,
  customerId,
  onCloseRef,
  customerName,
}) => {
  const { orgId } = useContext(AppContext);
  const [hiddenInvoicesColumns, setHiddenInvoicesColumns] = useState(
    getHiddenColumnsWithoutChangedColumns({ updatedFieldsWithNewValues }),
  );
  const [sortBy, setSortBy] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [invoicesSearchQuery, setInvoicesSearchQuery] = useState('');
  const [allColumnsState, setAllColumnsState] = useState();
  const [allRowsSelectedAcrossPages, setAllRowsSelectedAcrossPages] = useState(true);
  const [includeSent, setIncludeSent] = useState(false);

  const {
    appSettings: { currencyISOCode: currency },
  } = useContext(AppContext);

  const billingContext = useContext(BillingContext);
  const openInvoiceModal = billingContext?.openInvoiceModal;

  const {
    currentPageIndex: invoicesCurrentPageIndex = 1,
    setCurrentPageIndex: invoicesSetCurrentPageIndex,
    pageSize: invoicesPageSize,
    setPageSize: invoicesSetPageSize,
  } = usePagination({
    cacheSuffix: 'invoices-updates-modal',
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const {
    data: { data: invoices, metadata },
    isFetching: isFetchingInvoices,
    operations: { bulkUpdateInvoicesInSubscript },
  } = useInvoicesAPI({
    orgId,
    customerId,
    invoiceStatus: includeSent ? [INVOICE_STATUSES.UNSENT, INVOICE_STATUSES.SENT] : [INVOICE_STATUSES.UNSENT],
    orderBy: isEmpty(sortBy)
      ? INVOICES_DEFAULT_SORT_BY
      : sortByToKnexOrderBy(sortBy, INVOICES_SORT_BY_COLUMNS_NAME_MAPPING),
    includeCount: true,
    searchQuery: invoicesSearchQuery,
    page: invoicesCurrentPageIndex,
    limit: invoicesPageSize,
  });

  const {
    data: { metadata: unpaidMetadata },
  } = useInvoicesAPI({
    orgId,
    customerId,
    invoiceStatus: [INVOICE_STATUSES.SENT],
    includeCount: true,
    page: 1,
    limit: 1,
  });

  const [invoicesPageCount, setInvoicesPageCount] = useState();

  useEffect(() => {
    if (!isFetchingInvoices) {
      setInvoicesPageCount(metadata?.maxPage);
    }
  }, [isFetchingInvoices, invoices, metadata?.maxPage]);

  useEffect(() => {
    invoicesSetCurrentPageIndex(1);
    // If invoice statuses or search query changes, we need to reset the page index
  }, [invoicesSetCurrentPageIndex, invoicesSearchQuery]);

  useEffect(() => {
    if (!isFetchingInvoices) {
      if (!invoices.length && !invoicesSearchQuery) {
        onCloseRef?.current?.();
      }
    }
  }, [invoices, onCloseRef, invoicesSearchQuery, isFetchingInvoices]);

  const handleApply = useCallback(
    async ({ selectedInvoices, allInvoicesSelected, limitedAllSelectableInvoicesCount }) => {
      if (!allInvoicesSelected) {
        await bulkUpdateInvoicesInSubscript({
          invoices: selectedInvoices?.map((invoice) => ({
            id: invoice.id,
            ...updatedFieldsWithNewValues,
          })),
        });
      } else {
        await bulkUpdateInvoicesInSubscript({
          updatedFieldsWithNewValues,
          filtersCount: limitedAllSelectableInvoicesCount,
          filters: {
            customerId,
            invoiceStatus: includeSent ? [INVOICE_STATUSES.UNSENT, INVOICE_STATUSES.SENT] : [INVOICE_STATUSES.UNSENT],
            searchQuery: invoicesSearchQuery,
            orderBy: isEmpty(sortBy)
              ? INVOICES_DEFAULT_SORT_BY
              : sortByToKnexOrderBy(sortBy, INVOICES_SORT_BY_COLUMNS_NAME_MAPPING),
          },
        });
      }
      onCloseRef?.current?.();
    },
    [
      onCloseRef,
      updatedFieldsWithNewValues,
      bulkUpdateInvoicesInSubscript,
      customerId,
      invoicesSearchQuery,
      sortBy,
      includeSent,
    ],
  );

  const onInvoiceClick = useCallback(
    (invoice) => {
      openInvoiceModal({ invoice });
    },
    [openInvoiceModal],
  );

  const dataForTable = useMemo(() => {
    if (!invoices) return [];
    return groupInvoicesByMonth({ invoices });
  }, [invoices]);

  return (
    <ModalContainer data-cy="update-invoice-fields-modal">
      <Modal height="auto" minHeight="95vh" width="100vw" padding="0" style={{ bottom: '-20px' }}>
        <ModalBody paddingLeft="0" paddingRight="0" paddingBottom="0" height="800px">
          <ModalCloseIcon data-cy="update-invoice-fields-modal__close" onClose={onClose} />
          {isFetchingInvoices && !invoicesSearchQuery ? (
            <Centerer>
              <CircleLoader />
            </Centerer>
          ) : (
            <>
              <ModalHeader>
                <CheckIcon />
                You’ve updated the details of the <b>{customerName}</b> customer. Hover over <span>the changes</span> to
                review them.
              </ModalHeader>
              <TableHeader>
                <TableTitle>
                  Invoices of <span>{customerName}</span> that may need to be updated:
                </TableTitle>
                <ToggleContainer active={includeSent}>
                  <SwitchWithLabel
                    size="small"
                    label={`Include sent, unpaid invoices (${unpaidMetadata.count})`}
                    labelSize="12px"
                    onChange={() => setIncludeSent((p) => !p)}
                    checked={includeSent}
                  />
                </ToggleContainer>
              </TableHeader>
              <TableContainerLayout
                columnsSettingsComponent={
                  hiddenInvoicesColumns && (
                    <ColumnsSettingsWrapper>
                      <TableColumnSettings
                        tableName="invoices"
                        columnsTitles={INVOICES_TABLE_COLUMN_TITLES_BY_ID}
                        allColumns={allColumnsState}
                        numberValue={invoicesPageSize}
                        handleShowResultsChange={(number) => invoicesSetPageSize(number?.value ?? 10)}
                        resultsLabel={'# of invoices on page'}
                        options={[
                          { label: '10', value: 10 },
                          { label: '50', value: 50 },
                          { label: '100', value: 100 },
                        ]}
                      />
                    </ColumnsSettingsWrapper>
                  )
                }
                setSearchQuery={setInvoicesSearchQuery}
                isFetching={isFetchingInvoices}
                rowsCount={tableRows?.length}
                currentPageIndex={invoicesCurrentPageIndex}
                setCurrentPageIndex={invoicesSetCurrentPageIndex}
                pageCount={invoicesPageCount}
              >
                {dataForTable.length > 0 ? (
                  <InvoicesTable
                    allRowsSelectedAcrossPages={allRowsSelectedAcrossPages}
                    onAllInvoicesSelected={setAllRowsSelectedAcrossPages}
                    initialSortBy={sortBy}
                    setSortBy={setSortBy}
                    customBulkAction={{ name: 'Bulk Update Invoices', fn: handleApply }}
                    currency={currency}
                    updatedFields={updatedFieldsWithNewValues}
                    isFetchingInvoices={isFetchingInvoices}
                    hiddenInvoicesColumns={hiddenInvoicesColumns}
                    setHiddenInvoicesColumns={setHiddenInvoicesColumns}
                    setAllColumnsState={setAllColumnsState}
                    setTableRows={setTableRows}
                    dataForTable={dataForTable}
                    onInvoiceClick={onInvoiceClick}
                    invoicesCountData={[{ invoice_status: INVOICE_STATUSES.UNSENT, count: metadata?.count }]}
                    bulkActionsLimit={metadata?.bulkActionsLimit}
                    fixedSelectAllCheckbox={true}
                    selectAllCheckboxXOffset={100}
                  />
                ) : (
                  <Centerer>No invoices found</Centerer>
                )}
              </TableContainerLayout>
            </>
          )}
        </ModalBody>
      </Modal>
    </ModalContainer>
  );
};

export const useUpdateInvoiceFieldsModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [updatedFieldsWithNewValues, setUpdatedFieldsWithNewValues] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const onCloseRef = useRef(null);

  const closeModal = useCallback(() => {
    setShowModal(false);
    onCloseRef.current?.();
  }, []);

  const openModal = useCallback(({ afterCloseFn, customerId, updatedFieldsWithNewValues, customerName }) => {
    setUpdatedFieldsWithNewValues(updatedFieldsWithNewValues);
    setCustomerId(customerId);
    setCustomerName(customerName);
    onCloseRef.current = afterCloseFn;
    setShowModal(true);
  }, []);

  const Modal = useCallback(
    () =>
      showModal ? (
        <UpdateInvoiceFieldsModal
          onClose={closeModal}
          customerId={customerId}
          onCloseRef={onCloseRef}
          updatedFieldsWithNewValues={updatedFieldsWithNewValues}
          customerName={customerName}
        />
      ) : (
        <></>
      ),
    [showModal, updatedFieldsWithNewValues, customerId, customerName, closeModal],
  );

  return {
    openModal,
    UpdateInvoiceFieldsModal: Modal,
  };
};
