import { createFilter } from 'react-select';
import { SEARCH_CONFIDENCE } from 'utils/stringUtils';
import { FormikCustomSelector } from 'components/Controls';
import { getFuzzyCellData } from 'shared/CsvUpload/CsvTable/getFuzzyCellData';

export const renderProductIdCell = ({
  row,
  cell: { value },
  organization,
  formCurrent,
  fuzzyRowsMapper,

  setFuzzyRowsMapper,
}) => {
  const index = row.original.rowIndex;

  if (value && formCurrent && !fuzzyRowsMapper?.[index]?.product_id?.fuzzySearchCompleted) {
    const {
      candidates: candidatesForMapper,
      fuzzyTooltip: fuzzyTooltipForMapper,
      confidence: confidenceForMapper,
      value: fuzzyValue,
    } = getFuzzyCellData({
      rowValue: value,
      dataset: organization?.products,
      candidateValueKey: 'id',
      candidateTitleKey: 'name',
      columns: ['name', 'id'],
    });

    setFuzzyRowsMapper((prevMapper) => ({
      ...prevMapper,
      [index]: {
        ...(prevMapper?.[index] ?? {}),
        product_id: {
          fuzzySearchCompleted: true,
          fuzzyTooltip: fuzzyTooltipForMapper,
          candidates: candidatesForMapper,
          confidence: confidenceForMapper,
        },
      },
    }));

    formCurrent?.setFieldValue(`[${index}].product_id`, fuzzyValue);
  }

  const { fuzzyTooltip, confidence } = fuzzyRowsMapper?.[index]?.product_id ?? {};

  return (
    <FormikCustomSelector
      height={32}
      minWidth={160}
      menuWidth={200}
      //optimization thing
      filterOption={createFilter({ ignoreAccents: false })}
      errorWithoutTooltip
      isClearable={true}
      placeholder="Select product..."
      name={`[${row.original.rowIndex}].product_id`}
      hasWarning={confidence === SEARCH_CONFIDENCE.FUZZY}
      tooltipInputDisplay={fuzzyTooltip}
      handleChange={(option) => {
        if (option) {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].product_id`, option.value);
        } else {
          formCurrent?.setFieldValue(`[${row.original.rowIndex}].product_id`, null);
        }
      }}
      value={
        value
          ? {
              label: organization?.products.find((product) => product.id === value)?.name ?? value,
              value: value,
            }
          : null
      }
      options={
        organization?.products?.map((productObject) => ({
          label: productObject.name ?? `No name / ${productObject.id}`,
          value: productObject.id,
          recognition: productObject.recognition,
        })) ?? []
      }
    />
  );
};
