import React, { useContext } from 'react';
import styled from 'styled-components';
import { ReactComponent as RemoveIcon } from 'images/circle-x.svg';
import { ReactComponent as TooltipIcon } from 'images/help-table-header.svg';
import { InlineButton } from 'components/Buttons';
import { FillDot, GreyDot } from 'components/Icons';
import { CustomDatePicker } from 'components/Controls';
import { InsightsContext } from 'shared/Insights/InsightsContext';
import {
  numberFormatter,
  numberParser,
  StyledNumberInput,
} from 'components/Controls/FormikCustomInput/FormikCustomInput';
import { Spacer } from 'components/Core';
import { DEFAULT_FILTER_MONTHS_FROM_START, MAGIC_METADATA, COMMITTED_POLICY } from 'consts/global';
import { GlobalTogglesContext } from 'shared/GlobalToggles';
import { TooltipContainer } from 'components/Tooltip';
import { EVENTS } from 'consts/analytics';
import { useAnalytics } from 'utils/hooks';
import { ExploreInsightsLabel, ListDot, OrSeparator, SegmentLabelWrapper, YellowBold, TrashButton } from '../styles';
import { CUSTOMER_TIMESERIES_METRICS_SUFFIX } from '../MetadataFilter/consts';
import { SegmentByPopover } from './SegmentByPopover';
import { SETTINGS_NAME_MAPPINGS, SETTINGS_VALUE_MAPPINGS } from 'shared/Insights/utils';
import { identity } from 'lodash';

const SegmentByWrapper = styled.div`
  position: absolute;
  z-index: 15;
  left: ${({ hasSegment, showOrSeparator }) => (hasSegment ? '56px' : showOrSeparator ? '131px' : '48px')};
  display: flex;
  bottom: ${({ hasSegment }) => !hasSegment && '20px'};
  top: ${({ hasSegment }) => hasSegment && '66px'};
  align-items: center;
`;

// draftDataFilter, updateDraftDataFilter, setMetadataFilterFormValues props come from the Filters component
export const SegmentByFilter = ({
  segmentName,
  draftDataFilter,
  updateDraftDataFilter,
  setMetadataFilterFormValues,
  showChangeCategory,
  segmentOptions,
  showCustomerMetadata,
  showTransactionMetadata,
  showTransaction,
  showSize,
  showOrSeparator = true,
  relativeMonthSelectorForTimeseries = false,
  isSelectingSegment,
  setIsSelectingSegment,
}) => {
  const { trackEvent } = useAnalytics();
  const { getInsights, orgHasInsights } = useContext(InsightsContext);
  const { draftGlobalToggles, committedPolicy, setDraftGlobalToggles } = useContext(GlobalTogglesContext);
  const hasInsights = orgHasInsights({
    chartType: segmentName?.match(/cohort/) ? 'cohort' : segmentName?.match(/waterfall/) ? 'waterfall' : null,
  });

  return (
    <SegmentByWrapper
      showOrSeparator={showOrSeparator}
      hasSegment={draftDataFilter?.[segmentName] || isSelectingSegment}
    >
      <>
        {draftDataFilter?.[segmentName] || isSelectingSegment ? (
          <>
            <ListDot />
            <span>Segment by: </span>
            <SegmentByPopover
              segmentName={segmentName}
              segmentOptions={segmentOptions}
              showChangeCategory={showChangeCategory}
              showCustomerMetadata={showCustomerMetadata}
              showTransactionMetadata={showTransactionMetadata}
              showTransaction={showTransaction}
              showSize={showSize}
              selected={draftDataFilter?.[segmentName]}
              onSelect={(segmentBy) => {
                const chartType = segmentName?.match(/cohort/)
                  ? 'cohort'
                  : segmentName?.match(/waterfall/)
                  ? 'waterfall'
                  : null;
                const insights = getInsights({
                  segmentBy,
                  chartType,
                });
                if (insights?.length) {
                  // Clicked on a segment with insights
                  trackEvent({
                    name: EVENTS.VIEW_SEGMENT_WITH_INSIGHTS,
                    properties: { chartType, segmentBy },
                  });
                  const { customization } = insights[0];
                  const mappedSettingName = (settingName) => SETTINGS_NAME_MAPPINGS[settingName] ?? settingName;
                  const getMappedSettingValue = (settingName) =>
                    (SETTINGS_VALUE_MAPPINGS[settingName] ?? identity)(customization[mappedSettingName(settingName)]);
                  updateDraftDataFilter({
                    [segmentName]: segmentBy,
                    startMonth: getMappedSettingValue('startMonth'),
                    endMonth: getMappedSettingValue('endMonth'),
                    revenueTypes: getMappedSettingValue('revenueTypes'),
                    resolution: getMappedSettingValue('resolution'),
                    cohortInstallBy: getMappedSettingValue('cohortInstallBy'),
                    monthsFromStart: getMappedSettingValue('monthsFromStart'),
                    period: getMappedSettingValue('period'),
                  });
                  setDraftGlobalToggles({
                    name: 'optimisticAnalytics',
                    value: getMappedSettingValue('optimisticAnalytics'),
                  });
                  setDraftGlobalToggles({ name: 'rollup', value: getMappedSettingValue('rollup') });
                  setDraftGlobalToggles({ name: 'isCommitted', value: getMappedSettingValue('isCommitted') });
                  setMetadataFilterFormValues([]); // clear any transaction and customer metadata filters
                } else {
                  updateDraftDataFilter({
                    [segmentName]: segmentBy,
                    monthsFromStart: DEFAULT_FILTER_MONTHS_FROM_START[segmentBy],
                  });
                }
              }}
            />

            {!relativeMonthSelectorForTimeseries &&
              (draftDataFilter?.[segmentName] ?? '').includes(CUSTOMER_TIMESERIES_METRICS_SUFFIX) && (
                <>
                  <GreyDot spacing="4px" />
                  <span style={{ marginLeft: 4 }}>Which month to get the value for: </span>
                  <CustomDatePicker
                    name="timeseriesMonth"
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    filtersView
                    selected={draftDataFilter?.[`${segmentName}TimeseriesMonth`]}
                    onChange={(date) => updateDraftDataFilter({ [`${segmentName}TimeseriesMonth`]: date })}
                  />
                </>
              )}

            <TrashButton
              data-cy="segment-by-filter__delete-button"
              onClick={() => {
                updateDraftDataFilter({ [segmentName]: null });
                setIsSelectingSegment(false);
              }}
            >
              <RemoveIcon />
            </TrashButton>
            {((draftDataFilter?.[segmentName] ?? '').includes(MAGIC_METADATA.ARR_GROWTH_PERCENT) ||
              // TODO: use MAGIC_METADATA.SEGMENT_SIZE variable name, after https://github.com/ChifferCo/chiffer/issues/5167
              draftDataFilter?.[segmentName] === 'size' ||
              (relativeMonthSelectorForTimeseries &&
                (draftDataFilter?.[segmentName] ?? '').includes(CUSTOMER_TIMESERIES_METRICS_SUFFIX))) && (
              <>
                <span>At month: </span>
                <Spacer width="4px" />
                <StyledNumberInput
                  data-cy={'number-input--atMonth'}
                  blueVer={true}
                  inputWidth={160}
                  value={draftDataFilter?.monthsFromStart}
                  defaultValue={DEFAULT_FILTER_MONTHS_FROM_START[draftDataFilter?.[segmentName]]}
                  placeholder={'Enter value'}
                  formatter={numberFormatter}
                  parser={numberParser}
                  onChange={(value) => updateDraftDataFilter({ monthsFromStart: value })}
                  size="large"
                  min={1}
                />
              </>
            )}
          </>
        ) : (
          <>
            {showOrSeparator && <OrSeparator>or</OrSeparator>}

            <TooltipContainer
              toolTipContent="Segmentation with the ContractHighest committed policy is work in progress. Please switch to Classical mode if you want to segment."
              isVisible={committedPolicy === COMMITTED_POLICY.CONTRACT_HIGHEST && draftGlobalToggles?.isCommitted}
            >
              <InlineButton
                data-cy="filters__add-segment"
                onClick={() => setIsSelectingSegment(true)}
                withBackground
                lineHeight="20px"
                isSecondary
                fontSize="14px"
                disabled={committedPolicy === COMMITTED_POLICY.CONTRACT_HIGHEST && draftGlobalToggles?.isCommitted}
              >
                + Add Segment
              </InlineButton>
            </TooltipContainer>
            <SegmentLabelWrapper gap="8px" alignItems="center">
              {hasInsights ? <FillDot size={'12px'} /> : null}
            </SegmentLabelWrapper>
          </>
        )}
        {hasInsights ? (
          <>
            <ExploreInsightsLabel>
              Explore <YellowBold>insights</YellowBold>
              <TooltipContainer
                width={300}
                isVisible={true}
                underline={false}
                toolTipContent={
                  'Subscript finds insights based on segments that perform better or worse than others. To learn more, select any segment with a yellow dot.'
                }
              >
                <TooltipIcon />
              </TooltipContainer>
            </ExploreInsightsLabel>
          </>
        ) : null}
      </>
    </SegmentByWrapper>
  );
};
