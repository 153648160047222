import { useMemo, useState } from 'react';
import { generateColumns } from './pricingPlanColumns';
import { PreviewTable } from './PreviewTable';
import { useEditPricingPlanModal } from 'views/Billing/UsageBasedEngine/PricingPlans/PricingPlanModal/EditPricingPlanModal';
import { PRICING_PLAN_MODAL_MODE } from 'views/Billing/UsageBasedEngine/PricingPlans/PricingPlanModal/consts';
import { useCurrencyNumberFormatter } from 'utils/hooks';

export const PricingPlansPreviewTable = ({ data = [], setDataHook }) => {
  const [tableData, setTableData] = useState(data);
  const { openModal, EditPricingPlanModal } = useEditPricingPlanModal();

  const numberFormatter = useCurrencyNumberFormatter();

  const columns = useMemo(
    () =>
      generateColumns({
        numberFormatter,
        onEditClick: (row) => {
          openModal({
            pricingPlan: row.original,
            savePricingPlan: false,
            mode: PRICING_PLAN_MODAL_MODE.CREATE,
            onPricingPlanCreated: (data) => {
              const tableDataClone = [...tableData];
              tableDataClone[row.index] = {
                ...tableDataClone[row.index],
                ...data,
              };
              delete tableDataClone[row.index].product_confidence;
              setTableData(tableDataClone);
            },
          });
        },
      }),
    [openModal, tableData, numberFormatter],
  );

  return (
    <PreviewTable
      entityName="usage-based pricing plans"
      columns={columns}
      tableData={tableData}
      setDataHook={setDataHook}
    >
      <EditPricingPlanModal />
    </PreviewTable>
  );
};
