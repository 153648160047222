import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from 'AppContext';
import { useInvoicesAPI } from 'api/billing';
import { CSV_MODES } from 'shared/CsvUpload/CsvUpload';
import { CsvUpload } from 'shared/CsvUpload';
import { useCSVImportsAPI } from 'api/csvImports';

import { getSchema } from './schema';
import { getColumns } from './columns';
import {
  DEFAULT_INVOICE_ITEMS_CREATE_MAPPER,
  DEFAULT_INVOICE_ITEMS_MAPPER,
  DEFAULT_INVOICE_ITEMS_TRANSFORMATIONS,
} from './consts';
import { getFormFinalInvoiceItems } from './utils';

export const InvoiceItemsCsvUpload = () => {
  const history = useHistory();
  const { organizations } = useContext(AppContext);

  const [submittingForm, setSubmittingForm] = useState(false);

  const { data: dataInvoices, isLoading: isInvoicesLoading } = useInvoicesAPI({
    orgId: organizations?.[0]?.id,
    filters: {
      limit: 30000,
    },
    includeInvoiceItems: true,
    autoFetch: true,
  });

  const {
    operations: { uploadCSV },
  } = useCSVImportsAPI({ orgId: organizations?.[0]?.id, autoFetch: false });

  const customerNameByInvoiceId = useMemo(() => {
    return dataInvoices?.data?.reduce((acc, invoice) => {
      acc[invoice.id] = invoice.customer_name;
      return acc;
    }, {});
  }, [dataInvoices?.data]);

  const invoices = useMemo(() => dataInvoices, [dataInvoices]);

  const invoicesSelectOptions = useMemo(
    () =>
      invoices?.data
        ?.map((invoice) => ({
          value: invoice.id,
          label: `${customerNameByInvoiceId[invoice.id] ?? 'Unknown customer name'} - ${invoice.date} - ${
            invoice.amount
          }${invoice.invoice_number ? ` / ${invoice.invoice_number}` : ''}`,
        }))
        .sort((a, b) => a?.label?.localeCompare(b?.label)),
    [invoices?.data, customerNameByInvoiceId],
  );

  const tableSchema = useMemo(() => {
    const { updateSchema, createSchema } = getSchema({
      existingInvoices: invoices?.data,
      products: organizations[0]?.products,
    });

    return { updateSchema, createSchema };
  }, [invoices?.data, organizations]);

  const defaultMapperWithCreateOrUpdateMode = useCallback(({ createOrUpdateMode }) => {
    return createOrUpdateMode === CSV_MODES.UPDATE
      ? DEFAULT_INVOICE_ITEMS_MAPPER
      : createOrUpdateMode === CSV_MODES.CREATE
      ? DEFAULT_INVOICE_ITEMS_CREATE_MAPPER
      : undefined;
  }, []);

  const getColumnsWithCsvUploadState = useCallback(
    ({
      fuzzyRowsMapper,
      setFuzzyRowsMapper,
      formRef,
      csvColumnsMapper,
      createOrUpdateMode,
      setCsvColumnsMapper,
      csvColumns,
    }) =>
      getColumns({
        organization: organizations[0],

        formRef,
        csvColumns,
        csvColumnsMapper,
        setCsvColumnsMapper,

        createOrUpdateMode,

        invoices,
        invoicesSelectOptions,

        fuzzyRowsMapper,
        setFuzzyRowsMapper,
      }),
    [invoicesSelectOptions, invoices, organizations],
  );

  const handleSubmit = async ({ values, extraData }) => {
    setSubmittingForm(true);

    const formInvoiceItems = getFormFinalInvoiceItems({
      formInvoiceItems: values,
      orgId: organizations[0]?.id,
      invoices: invoices?.data,
    });

    try {
      await uploadCSV.mutateAsync({
        rawFile: extraData,
        data: formInvoiceItems,
        settings: { entity: 'invoices' },
      });

      setTimeout(() => {
        history.push('/billing/invoices');
        setSubmittingForm(false);
      }, 1000);
    } catch {
      setSubmittingForm(false);
    }
  };

  const isLoading = submittingForm || isInvoicesLoading;

  return (
    <CsvUpload
      entityName="Invoice-Items"
      isLoading={isLoading}
      createSchema={tableSchema?.createSchema}
      updateSchema={tableSchema?.updateSchema}
      handleSubmit={handleSubmit}
      defaultMapperWithCreateOrUpdateMode={defaultMapperWithCreateOrUpdateMode}
      csvColumnsTransformations={DEFAULT_INVOICE_ITEMS_TRANSFORMATIONS}
      getColumnsWithCsvUploadState={getColumnsWithCsvUploadState}
      backLink="/billing/invoices"
    />
  );
};
