import { useState } from 'react';
import styled from 'styled-components';
import { FlexEndContainer, Spacer } from 'components/Core';
import {
  ModalContainer,
  Modal,
  ModalHeader,
  ModalCloseIcon,
  ModalTitle,
  ModalTitleText,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'components/Modal';
import { PaymentMethodsList } from './PaymentMethodsList';

const StyledModalBody = styled(ModalBody)`
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const PaymentMethodsSelectorModal = ({
  customerId,
  onClose,
  onPaymentMethodSelected,
  selectedPaymentMethodId: initialSelectedPaymentMethodId,
}) => {
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(initialSelectedPaymentMethodId);

  return (
    <ModalContainer data-cy="payment-methods-selector-modal">
      <Modal width="600px" height="auto" compact>
        <ModalCloseIcon onClose={onClose} data-cy="payment-methods-selector-modal__close-button" />
        <ModalHeader>
          <ModalTitle>
            <ModalTitleText compact>
              <b>Select payment method</b>
            </ModalTitleText>
          </ModalTitle>
        </ModalHeader>
        <StyledModalBody>
          <PaymentMethodsList
            customerId={customerId}
            selectedPaymentMethodId={selectedPaymentMethodId}
            setSelectedPaymentMethodId={setSelectedPaymentMethodId}
          />
        </StyledModalBody>
        <ModalFooter>
          <FlexEndContainer>
            <ModalButton onClick={onClose}>Cancel</ModalButton>
            <Spacer width="10px" />
            <ModalButton primary onClick={() => onPaymentMethodSelected(selectedPaymentMethodId)}>
              Save
            </ModalButton>
          </FlexEndContainer>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};
