import React, { useMemo, useState } from 'react';
import { get, unset } from 'lodash';
import { Form, Formik } from 'formik';
import { CsvTable } from './CsvTable';

export const ImportForm = ({
  currentTab,
  mappedData,
  parsedCsv,
  formRef,
  csvColumnsMapper = {},
  entityName,
  setCsvColumnsMapper,
  createOrUpdateMode,
  handleSubmit,
  fuzzyErrors,
  getColumnsWithCsvUploadState,
  defaultMapper,
  isLoading,
  showViewModes,
}) => {
  const csvColumns = Object.keys(parsedCsv?.data?.[0] ?? {}).map((columnKey) => ({
    value: columnKey,
    label: columnKey,
  }));

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const columns = useMemo(
    () =>
      getColumnsWithCsvUploadState({
        csvColumnsMapper,
        setCsvColumnsMapper,
        csvColumns,
        fuzzyErrors,
        mappedData,
        createOrUpdateMode,
        formRef,
        defaultMapper,
      }),
    [
      csvColumnsMapper,
      setCsvColumnsMapper,
      csvColumns,
      mappedData,
      formRef,
      fuzzyErrors,
      createOrUpdateMode,
      defaultMapper,
      getColumnsWithCsvUploadState,
    ],
  );

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={mappedData}
      innerRef={formRef}
    >
      {({ handleChange }) => (
        <Form
          onChange={(e) => {
            handleChange(e);

            // Clear errors when values change
            if (formRef.current?.errors) {
              const fieldName = e?.target?.name;
              if (fieldName && get(formRef.current.errors, fieldName)) {
                const newErrors = { ...formRef.current.errors };
                unset(newErrors, fieldName);
                formRef.current.setErrors(newErrors);
              }
            }
          }}
        >
          <CsvTable
            isLoading={isLoading}
            fuzzyErrors={fuzzyErrors}
            currentTab={currentTab}
            createOrUpdateMode={createOrUpdateMode}
            columns={columns}
            entityName={entityName}
            currentPageIndex={currentPageIndex}
            setCurrentPageIndex={setCurrentPageIndex}
            showViewModes={showViewModes}
          />
        </Form>
      )}
    </Formik>
  );
};
