import styled from 'styled-components';
import { useContext } from 'react';
import { AppContext } from 'AppContext';
import { useCustomerPaymentMethods } from 'api/billing/hooks';
import { CircleLoader } from 'components/Loaders';
import { PaymentMethodLineItem } from './PaymentMethodLineItem';

const Wrapper = styled.div`
  min-height: 40px;
`;

const DEFAULT_PAYMENT_METHOD = { id: null };

export const PaymentMethodsList = ({ customerId, selectedPaymentMethodId, setSelectedPaymentMethodId }) => {
  const { orgId } = useContext(AppContext);

  const { data, isLoading } = useCustomerPaymentMethods({ orgId, customerId });

  const handleSelect = (paymentMethodId) => {
    setSelectedPaymentMethodId(paymentMethodId);
  };

  if (isLoading || !data) {
    return (
      <Wrapper>
        <CircleLoader width={32} height={32} />
      </Wrapper>
    );
  }

  const options = [DEFAULT_PAYMENT_METHOD, ...data];

  return (
    <Wrapper>
      {options.map((paymentMethod, index) => (
        <PaymentMethodLineItem
          key={paymentMethod.id}
          paymentMethod={paymentMethod}
          isSelected={selectedPaymentMethodId === paymentMethod.id}
          onSelect={handleSelect}
          isFirst={index === 0}
          isLast={index === options.length - 1}
          showRadio
          showBorder
        />
      ))}
    </Wrapper>
  );
};
