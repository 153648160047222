import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId, customerId }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/customers`;
  if (!customerId) return baseUrl;

  return `${baseUrl}/${customerId}`;
};

export const getCustomers = async ({ orgId, filters }) => {
  const { params, body } = filters ?? {};
  const response = await axios.post(`${buildUrl({ orgId })}/filter`, body, { params });
  return response.data;
};

export const getCustomersMetadataValues = async ({ orgId, key, service }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/customersMetadataValues`, {
    params: { key, service },
  });
  return response.data;
};

export const getCustomer = async ({ orgId, customerId, params }) => {
  const response = await axios.get(buildUrl({ orgId, customerId }), { params });
  return response.data;
};

export const createCustomer = async ({ orgId, body }) => {
  const response = await axios.post(buildUrl({ orgId }), body);
  return response.data;
};

export const updateCustomer = async ({
  orgId,
  customerId,
  body,
  overwriteAllMetadata,
  overwriteMetadata = true,
  params,
}) => {
  const response = await axios.patch(buildUrl({ orgId, customerId }), body, {
    params: { overwriteMetadata, overwriteInternalMetadata: overwriteAllMetadata ?? false, ...params },
  });
  return response.data;
};

export const deleteCustomer = async ({ orgId, customerId }) => {
  const response = await axios.delete(buildUrl({ orgId, customerId }));
  return response.data;
};

export const deleteCustomerImport = async ({ orgId, customerId, importId }) => {
  const response = await axios.delete(`${buildUrl({ orgId, customerId })}/imports/${importId}`);
  return response.data;
};

export const bulkUploadCustomers = async ({ orgId, customers }) => {
  const response = await axios.post(`${buildUrl({ orgId })}/bulk-upload`, { customers });
  return response.data;
};

export const upsertInvoicingDetails = async ({ orgId, customerId, body }) => {
  const response = await axios.post(`${buildUrl({ orgId, customerId })}/invoicing-details`, body);
  return response.data;
};

export const bulkUpsertInvoicingDetails = async ({ orgId, body }) => {
  const response = await axios.post(`${buildUrl({ orgId })}/bulk-upsert-invoicing-details`, body);
  return response.data;
};

export const bulkUpdateExternalCustomers = async ({ orgId, body }) => {
  const response = await axios.post(`${buildUrl({ orgId })}/bulk-external-update`, body);
  return response.data;
};

export const createCustomerInGl = async ({ orgId, body }) => {
  const response = await axios.post(`${buildUrl({ orgId })}/external-upsert`, body);
  return response.data;
};

export const getExternalParentChildren = async ({ orgId, externalParentId, params }) => {
  const response = await axios.get(`${buildUrl({ orgId })}/${externalParentId}/children`, { params });
  return response.data;
};
