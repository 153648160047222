export const RECOGNITION_TYPES = {
  linear: 'linear',
  linearNotRecurring: 'linear not recurring',
  eventRecurring: 'event recurring',
  eventNotRecurring: 'event not recurring',
  immediate: 'immediate',
  tillCanceled: 'till-canceled',
};

export const RECOGNITION_TYPES_LABELS = {
  linear: 'Linear (recurring)',
  'linear not recurring': 'Linear (not recurring)',
  'event recurring': 'Event (recurring)',
  'event not recurring': 'Event (not recurring)',
  immediate: 'Immediate (not recurring)',
  'till-canceled': 'Till Canceled (recurring)',
};

export const ACCOUNTING_RECOGNITION_TYPES = {
  TRANSACTION: 'TRANSACTION',
  CONTRACT: 'CONTRACT',
};

export const ACCOUNTING_RECOGNITION_TYPES_LABELS = {
  [ACCOUNTING_RECOGNITION_TYPES.TRANSACTION]: 'Separately based on SaaS rev.',
  [ACCOUNTING_RECOGNITION_TYPES.CONTRACT]: 'Grouped on the Contract',
};

export const ACCOUNTING_RECOGNITION_TYPES_DESCRIPTIONS = {
  [ACCOUNTING_RECOGNITION_TYPES.TRANSACTION]:
    "Uses the SaaS transaction revenue schedule, but you'll be able to fully customize it",
  [ACCOUNTING_RECOGNITION_TYPES.CONTRACT]:
    'When transaction accounting revenue is recognized together in a contract group',
};

export const RECOGNITION_TYPES_GROUPS = {
  RECURRING: {
    title: 'Recurring',
    values: [
      {
        key: RECOGNITION_TYPES.linear,
        label: 'Linear',
        description: 'When a start and an end date are defined',
      },
      {
        key: RECOGNITION_TYPES.tillCanceled,
        label: 'Till Canceled',
        description:
          'When a start date and a recurring amount are defined, but no defined end date until the customer cancels',
      },
      {
        key: RECOGNITION_TYPES.eventRecurring,
        label: 'Event',
        description: 'Where the actual amount varies by month to month',
      },
    ],
  },
  NOT_RECURRING: {
    title: 'Not-recurring',
    values: [
      {
        key: RECOGNITION_TYPES.linearNotRecurring,
        label: 'Linear',
        description: 'When a start and an end date are defined',
      },
      {
        key: RECOGNITION_TYPES.immediate,
        label: 'Immediate',
        description: 'Where all the revenue is recognized on booking date',
      },
      {
        key: RECOGNITION_TYPES.eventNotRecurring,
        label: 'Event',
        description: 'For revenue that is recognized on specific events',
      },
    ],
  },
};

export const RECOGNITION_TYPE_TO_GROUP = Object.entries(RECOGNITION_TYPES_GROUPS).reduce((acc, [key, value]) => {
  for (const type of value.values) {
    acc[type.key] = key;
  }
  return acc;
}, {});

export const RECURRING_RECOGNITION_TYPES = [
  RECOGNITION_TYPES.linear,
  RECOGNITION_TYPES.tillCanceled,
  RECOGNITION_TYPES.eventRecurring,
];

export const EVENT_RECOGNITION_TYPES = [RECOGNITION_TYPES.eventRecurring, RECOGNITION_TYPES.eventNotRecurring];

export const SPREAD_TYPE = {
  RECURRING: 'recurring',
  NON_RECURRING: 'non-recurring',
};

export const CUSTOMERS_TABLE_COLUMN_TITLES_BY_ID = {
  currentMRR: 'ARR',
  transactionSum: 'Total Rev.',
  nonRecurringRevenue: 'Non-recurring Rev.',
  start_date: 'Dates',
  external_ids: 'External Id',
  invoices: 'Billing',
  contacts: 'Contacts',
  billing_address: 'Billing Address',
  shipping_address: 'Shipping Address',
};

export const EMOJI = {
  JOY_FACE: {
    icon: '😂',
    label: 'Starting value is $0',
  },
  NO_GOOD: {
    icon: '🙅🏾‍♀️',
    label: 'Calculation contains missing or $0 values',
  },
  SPEAK_NO_EVIL: {
    icon: '🙊',
    label: 'Calculation result is 0%',
  },
  EXPRESSIONLESS: {
    icon: '😑',
    label: 'Missing costs for this period',
  },
  NO_MOUTH: {
    icon: '😶',
    label: 'Invalid currency',
  },
  INFINITY: {
    icon: '♾️',
    label: 'Calculation has value 0 denominator',
  },
  OUT_OF_BOUNDS: {
    icon: '😵‍💫',
    label: 'Calculation produces an invalid result',
  },
};

export const MAGIC_METADATA = {
  SEGMENT_SIZE: 'Segment Size',
  CANCEL_DATE: 'Cancel Date',
  STAGE_NAME: 'Stage Name',
  INITIAL_SEGMENT_SIZE: 'Initial Size',
  IS_WON: 'Is Won',
  ARR_GROWTH_PERCENT: 'ARR Growth %',
  BILLING_FREQUENCY: 'Billing Frequency',
  REV_REC_FX_RATE: 'RevRec FX Rate',
  SHIPPING_ADDRESS: 'shipping_address',
  USAGE_PRICING_BREAKDOWN: 'Usage Pricing Breakdown',
  USAGE_EVENT_NAME: 'Usage Event Name',
  EXTERNAL_TAX_CODE: 'External Tax Code',
  UP_FOR_RENEWAL_DATE: 'Up for Renewal Date',
  EXTERNAL_PARENT_CUSTOMER_ID: 'External Parent Customer ID',
  QUICKBOOKS_BILLWITHPARENT: 'Quickbooks BillWithParent',
  BILLING_CUSTOMER_NAME: 'Billing Customer Name',
  USE_CHILD_CUSTOMER_SHIPPING_INFO: 'Use Child Shipping Info',
  IS_HISTORICAL: 'Historical Data',
};

export const BUSINESS_SNAPSHOT_COLUMNS = {
  MONTHS: 'months',
  QUARTERS: 'quarters',
  YEARS: 'years',
  CUMULATIVE: 'cumulative',
};

export const GROWTH_TYPE_KEYS = {
  MoM: 'monthOverMonth',
  QoQ: 'quarterOverQuarter',
  YoY: 'yearOverYear',
};

export const GROWTH_TYPE_LABEL = {
  [GROWTH_TYPE_KEYS.MoM]: 'MoM',
  [GROWTH_TYPE_KEYS.QoQ]: 'QoQ',
  [GROWTH_TYPE_KEYS.YoY]: 'YoY',
};

export const GROWTH_TYPE_OPTIONS = {
  [GROWTH_TYPE_KEYS.MoM]: 'Month over Month',
  [GROWTH_TYPE_KEYS.QoQ]: 'Quarter over Quarter',
  [GROWTH_TYPE_KEYS.YoY]: 'Year over Year',
};

export const MAX_SEGMENTS_ALLOWED = 100;

export const SEGMENT_BY_OPTIONS = {
  SIZE: 'size',
  PRODUCT: 'product',
  PRODUCT_CATEGORY: 'product category',
  CUSTOMER_METADATA: 'Customers',
  TRANSACTION_METADATA: 'Transactions',
  CUSTOMER_METADATA_CUSTOM: 'Custom_Customers',
  TRANSACTION_METADATA_CUSTOM: 'Custom_Transactions',
  CHANGE_CATEGORY: 'change category',
  TRANSACTION: 'transaction',
};

export const WATERFALL_UNSUPPORTED_SEGMENT_BY_OPTIONS = {
  ARR_GROWTH_PERCENT: MAGIC_METADATA.ARR_GROWTH_PERCENT,
};

export const WATERFALL_GRAPH_PERIOD_OPTIONS = {
  MONTHS: 'Months',
  QUARTERS: 'Quarters',
  YEARS: 'Years',
};

export const SEGMENT_TYPES = {
  [SEGMENT_BY_OPTIONS.PRODUCT]: 'productBreakdowns',
  [SEGMENT_BY_OPTIONS.PRODUCT_CATEGORY]: 'productCategoryBreakdowns',
  [SEGMENT_BY_OPTIONS.CUSTOMER_METADATA]: 'customerMetadataBreakdowns',
  [SEGMENT_BY_OPTIONS.TRANSACTION_METADATA]: 'transactionMetadataBreakdowns',
  [SEGMENT_BY_OPTIONS.CUSTOMER_METADATA_CUSTOM]: 'customerCustomMetadataBreakdowns',
  [SEGMENT_BY_OPTIONS.TRANSACTION_METADATA_CUSTOM]: 'transactionCustomMetadataBreakdowns',
  [SEGMENT_BY_OPTIONS.CHANGE_CATEGORY]: 'changeCategory',
};

export const DEFAULT_ICON_SIZE = 20;

export const NUMBER_FORMATS = {
  CURRENCY: 'currency',
  NUMBER: 'number',
  PERCENT: 'percent',
  MONTHS: 'months',
  EMOJI: 'emoji',
};

export const COMMITTED_POLICY = {
  HIGHEST: 'Highest',
  NEW_ONLY: 'NewOnly',
  CONTRACT_HIGHEST: 'ContractHighest',
};

export const RATES_TYPE = {
  BOOKING_DATES: 'BookingDates',
  PARTICULAR_DATE: 'ParticularDate',
  SPREAD_DATES: 'SpreadDates',
  MONTHLY_AVERAGE: 'MonthlyAverage',
};

export const ACCOUNTING_RATE_TYPES = {
  BOOKING_DATES: 'BookingDates',
  INVOICE_DATES: 'InvoiceDates',
};

export const COMMITTED_START = {
  BOOK_DATE: 'BookDate',
  EARLIEST_DATE: 'EarliestDate',
};

export const NIL_LABELS = ['null', 'Null', 'undefined', 'Undefined', 'none', 'None'];

export const ORG_CONFIGS = {
  CUSTOMER_METADATA: 'customer_metadata',
  TRANSACTIONS_LOCK_BEFORE_DATE: 'transactions_lock_before_date',
  TRANSACTION_METADATA: 'transaction_metadata',
  TRANSACTION_RECOGNITION: 'default_transaction_recognition',
  SEGMENTS: 'segments',
  CUSTOM_SETTINGS: 'custom_settings',
  LINE_ITEM_MAPPINGS: 'line_item_mappings',
  TRANSACTION_MAPPINGS: 'transaction_mappings',
  CLOSED_WON_STAGES: 'closed_won_stages',
  AUTOMATIC_CONFIRM_RULESET: 'automatic_confirm_transactions_detecting_ruleset',
  QUARTERS: 'quarters',
  QUARTERS_YEAR_OFFSET: 'quartersYearOffset',
  UPLOAD_CSV_CUSTOMERS_ID_SOURCE: 'upload_csv_customers_id_source',
  FORECASTS: 'forecasts',
  CUSTOMER_CUSTOM_FIELDS: 'customer_custom_fields',
  TRANSACTION_CUSTOM_FIELDS: 'transaction_custom_fields',
  FEATURES: 'features',
  BILLING_REMINDER_DEFAULTS: 'billing_reminder_defaults',
  BILLING_INVOICE_DEFAULTS: 'billing_invoice_defaults',
  BILLING_SENDER_DEFAULTS: 'billing_sender_defaults',
  BILLING_TILL_CANCELLED_INVOICE_MONTHS_IN_ADVANCE: 'billing_till_cancelled_invoice_months_in_advance',
  CONTRACT_READER_ASSISTANT: 'contract_reader_assistant',
  ACCOUNTING_SPREAD_POLICY: 'accounting_spread_policy',
  INCOME_ACCOUNT_REF_ID: 'income_account_ref_id',
  INCOME_ACCOUNT_OPTIONS: 'income_account_options',
  BILLING_UPDATES_EMAILS_TO_SEND: 'billing_updates_emails_to_send',
  BILLING_CUSTOM_FIELDS: 'billing_invoice_custom_fields',
  BILLING_TRACKING_CATEGORIES: 'billing_invoice_tracking_categories',
  BILLING_RECEIPT_DEFAULTS: 'billing_receipt_defaults',
  BILLING_CREDIT_NOTE_DEFAULTS: 'billing_credit_note_defaults',
  AUTO_CHARGE_INVOICE_SENDING_DEFAULTS: 'auto_charge_invoice_sending_defaults',
  ACCOUNTING_SPREADS_LOCK_BEFORE_DATE: 'accounting_spreads_lock_before_date',
  LOCALE_REGION: 'locale_region',
  DEFAULT_ACCOUNTING_RATE_TYPE: 'default_accounting_rate_type',
  ACCOUNTING_CURRENCY: 'accounting_currency',
  ACCOUNTING_CURRENCIES: 'accounting_currencies',
  INVOICE_TEMPLATE_ID: 'invoice_template_id',
  DEFAULT_GL: 'default_gl',
};

// for keys that we use for data handling and don't actually store in the backend
export const ADDITIONAL_ORG_CONFIGS = {
  INCOME_ACCOUNT_REF_ID_BY_INTEGRATION_ID: `${ORG_CONFIGS.INCOME_ACCOUNT_REF_ID}_by_integration_id`,
  BILLING_CUSTOM_FIELDS_BY_INTEGRATION_ID: `${ORG_CONFIGS.BILLING_CUSTOM_FIELDS}_by_integration_id`,
  BILLING_TRACKING_CATEGORIES_BY_INTEGRATION_ID: `${ORG_CONFIGS.BILLING_TRACKING_CATEGORIES}_by_integration_id`,
  INVOICE_TEMPLATE_ID_BY_INTEGRATION_ID: `${ORG_CONFIGS.INVOICE_TEMPLATE_ID}_by_integration_id`,
};

export const CONFIGS_TO_FETCH = [
  ORG_CONFIGS.CUSTOMER_METADATA,
  ORG_CONFIGS.TRANSACTION_RECOGNITION,
  ORG_CONFIGS.SEGMENTS,
  ORG_CONFIGS.TRANSACTION_METADATA,
  ORG_CONFIGS.LINE_ITEM_MAPPINGS,
  ORG_CONFIGS.TRANSACTION_MAPPINGS,
  ORG_CONFIGS.CLOSED_WON_STAGES,
  ORG_CONFIGS.TRANSACTIONS_LOCK_BEFORE_DATE,
  ORG_CONFIGS.ACCOUNTING_SPREADS_LOCK_BEFORE_DATE,
  ORG_CONFIGS.QUARTERS,
  ORG_CONFIGS.AUTOMATIC_CONFIRM_RULESET,
  ORG_CONFIGS.QUARTERS_YEAR_OFFSET,
  ORG_CONFIGS.UPLOAD_CSV_CUSTOMERS_ID_SOURCE,
  ORG_CONFIGS.FORECASTS,
  ORG_CONFIGS.FEATURES,
  ORG_CONFIGS.BILLING_REMINDER_DEFAULTS,
  ORG_CONFIGS.BILLING_INVOICE_DEFAULTS,
  ORG_CONFIGS.BILLING_SENDER_DEFAULTS,
  ORG_CONFIGS.ACCOUNTING_SPREAD_POLICY,
  ORG_CONFIGS.INCOME_ACCOUNT_REF_ID,
  ORG_CONFIGS.INCOME_ACCOUNT_OPTIONS,
  ORG_CONFIGS.BILLING_CUSTOM_FIELDS,
  ORG_CONFIGS.BILLING_TRACKING_CATEGORIES,
  ORG_CONFIGS.BILLING_RECEIPT_DEFAULTS,
  ORG_CONFIGS.AUTO_CHARGE_INVOICE_SENDING_DEFAULTS,
  ORG_CONFIGS.LOCALE_REGION,
  ORG_CONFIGS.INVOICE_TEMPLATE_ID,
  ORG_CONFIGS.BILLING_CREDIT_NOTE_DEFAULTS,
  ORG_CONFIGS.ACCOUNTING_CURRENCY,
  ORG_CONFIGS.ACCOUNTING_CURRENCIES,
  ORG_CONFIGS.DEFAULT_GL,
];

export const SYSTEM_USER_ID = 0;

// Number of milliseconds to wait until retrying again, for tasks running on background jobs
export const POLLING_DURATION = parseInt(process.env.REACT_APP_POLLING_DURATION) || 1000;

// Number of times to poll until we raise an exception to Sentry (but still keep polling)
// Note that MAX_POLLING_COUNT * POLLING_DURATION is roughly how long to wait until we raise an exception
export const MAX_POLLING_COUNT = parseInt(process.env.MAX_POLLING_COUNT) || 150;

export const PROGRESS_POLLING_COUNT = 10;

export const DEFAULT_FILTER_MONTHS_FROM_START = {
  // TODO: reference MAGIC_METADATA.SEGMENT_SIZE instead, after https://github.com/ChifferCo/chiffer/issues/5167
  size: 1,
  [`${SEGMENT_BY_OPTIONS.CUSTOMER_METADATA_CUSTOM}-${[MAGIC_METADATA.ARR_GROWTH_PERCENT]}`]: 12,
};

export const ISO_CODE_TO_SYMBOL = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CHF: 'CHF',
  CAD: 'C$',
  AUD: 'A$',
  CNY: '¥',
  SEK: 'kr',
  NZD: 'NZ$',
  MXN: 'MX$',
  SGD: 'S$',
  HKD: 'HK$',
  NOK: 'kr',
  KRW: '₩',
  TRY: '₺',
  RUB: '₽',
  INR: '₹',
  BRL: 'R$',
  ZAR: 'R',
  DKK: 'kr',
  PLN: 'zł',
  THB: '฿',
  IDR: 'Rp',
  HUF: 'Ft',
  CZK: 'Kč',
  ILS: '₪',
  CLP: 'CLP$',
  PHP: '₱',
  AED: 'د.إ',
  COP: 'COL$',
  SAR: 'ر.س',
  MYR: 'RM',
  RON: 'lei',
  BGN: 'лв',
  HRK: 'kn',
  UAH: '₴',
  JOD: 'د.ا',
  VND: '₫',
  NGN: '₦',
  TWD: 'NT$',
  PKR: '₨',
  LKR: 'රු',
  ARS: '$',
  PEN: 'S/',
  KZT: '₸',
  QAR: 'ر.ق',
  OMR: 'ر.ع.',
  BDT: '৳',
  ISK: 'kr',
};

export const ACCOUNTING_SPREAD_POLICY_SPREAD_BY = {
  MONTHLY_AMOUNT: 'monthly_amount', // figure out per month amount amount, then give each month the fraction that the contract is live
  DAILY_AMOUNT: 'daily_amount', // figure out number of days in contract, and then each month gets an amount based on number of days
  WHOLE_MONTH: 'whole_month', // just like saas spreads, don't account for partial months at all
  ROUNDED_MONTH: 'rounded_month', // round the amount to either 0 or whole month
};

export const ACCOUNTING_SPREAD_POLICY_LABELS = {
  [ACCOUNTING_SPREAD_POLICY_SPREAD_BY.MONTHLY_AMOUNT]:
    'Monthly With Proration: spread revenue based on number of months in contract, pro-rating for partial months',
  [ACCOUNTING_SPREAD_POLICY_SPREAD_BY.DAILY_AMOUNT]:
    'Daily With Proration: spread revenue based on number of days in contract, pro-rating for partial months',
  [ACCOUNTING_SPREAD_POLICY_SPREAD_BY.WHOLE_MONTH]:
    'Whole Month: spread revenue based on number of months in contract, no pro-rating for partial months',
  [ACCOUNTING_SPREAD_POLICY_SPREAD_BY.ROUNDED_MONTH]:
    "Rounded month: if the start date is on or before the 15th, include whole month, otherwise don't include start month",
};

export const SUPPORTED_LOCALES_BY_COUNTRY = {
  CZECH: 'czech',
  USA: 'usa',
  UK: 'uk',
  CANADA: 'canada',
  SOUTH_AFRICA: 'southAfrica',
  AUSTRALIA: 'australia',
  SOUTH_AMERICA: 'southAmerica',
  MEXICO: 'mexico',
  GERMANY: 'germany',
  FRANCE: 'france',
  INDONESIA: 'indonesian',
  ITALY: 'italy',
  BELGIUM: 'belgium',
  NETHERLANDS: 'netherlands',
  PORTUGAL: 'portugal',
  BRAZIL: 'brazil',
  ROMANIA: 'romania',
  RUSSIA: 'russia',
  VIETNAM: 'vietnam',
  SINGAPORE: 'singapore',
  HONG_KONG: 'hongKong',
};

export const LOCALES_LABEL = {
  [SUPPORTED_LOCALES_BY_COUNTRY.CZECH]: 'Czech',
  [SUPPORTED_LOCALES_BY_COUNTRY.USA]: 'USA',
  [SUPPORTED_LOCALES_BY_COUNTRY.UK]: 'UK',
  [SUPPORTED_LOCALES_BY_COUNTRY.CANADA]: 'Canada',
  [SUPPORTED_LOCALES_BY_COUNTRY.SOUTH_AFRICA]: 'South Africa',
  [SUPPORTED_LOCALES_BY_COUNTRY.AUSTRALIA]: 'Australia',
  [SUPPORTED_LOCALES_BY_COUNTRY.SOUTH_AMERICA]: 'South America',
  [SUPPORTED_LOCALES_BY_COUNTRY.MEXICO]: 'Mexico',
  [SUPPORTED_LOCALES_BY_COUNTRY.GERMANY]: 'Germany',
  [SUPPORTED_LOCALES_BY_COUNTRY.FRANCE]: 'France',
  [SUPPORTED_LOCALES_BY_COUNTRY.INDONESIA]: 'Indonesian',
  [SUPPORTED_LOCALES_BY_COUNTRY.ITALY]: 'Italy',
  [SUPPORTED_LOCALES_BY_COUNTRY.BELGIUM]: 'Belgium',
  [SUPPORTED_LOCALES_BY_COUNTRY.NETHERLANDS]: 'Netherlands',
  [SUPPORTED_LOCALES_BY_COUNTRY.PORTUGAL]: 'Portugal',
  [SUPPORTED_LOCALES_BY_COUNTRY.BRAZIL]: 'Brazil',
  [SUPPORTED_LOCALES_BY_COUNTRY.ROMANIA]: 'Romania',
  [SUPPORTED_LOCALES_BY_COUNTRY.RUSSIA]: 'Russia',
  [SUPPORTED_LOCALES_BY_COUNTRY.VIETNAM]: 'Vietnam',
  [SUPPORTED_LOCALES_BY_COUNTRY.SINGAPORE]: 'Singapore',
  [SUPPORTED_LOCALES_BY_COUNTRY.HONG_KONG]: 'Hong Kong',
};
