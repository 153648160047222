import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { isEmpty, uniqBy } from 'lodash';
import { useFormikContext } from 'formik';

import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn, FlexerRow } from 'components/Core';
import { Button } from 'components/Buttons';
import { ReactComponent as CheckIcon } from 'images/check-with-no-circle.svg';
import { useAnalytics } from 'utils/hooks';
import { EVENTS } from 'consts/analytics';
import { INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE } from 'consts/integrations';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { getServiceCategory } from 'models/integration';
import { getInvoiceData, getModalInvoiceStatus } from 'views/Billing/InvoiceModal/utils';
import { INVOICE_STATUSES } from 'views/Billing/consts';
import { BillingContext } from 'views/Billing/BillingContext';
import { MAGIC_METADATA } from 'consts/global';

import { useInvoicingScheduleFrequencyDropdown } from './useInvoicingScheduleFrequencyDropdown';
import { AddInvoice } from './AddInvoice';
import { Tab, TabsContainer, TabCounter, WhiteButtonScheduleHeader, TabText } from './styles';
import { getSortedInvoices } from '../utils';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { CreditNoteContext } from '../CreditNoteContext';
import { ScheduleGenerationInfoModal } from './ScheduleGenerationInfoModal';
import { INVOICING_SCHEDULE_TABS_PANEL_TABS } from './consts';
import { InvoicesTab } from './InvoicesTab';
import { CreditNotesTab } from './CreditNotesTab';
import { ContractsButton } from './ContractsButton';
import { InvoicingScheduleFormErrors } from './InvoicingScheduleFormErrors';
import { InvoiceSettingsButton } from './InvoiceSettings/InvoiceSettingsButton';
import { InvoicingScheduleInternalNotes } from './InvoicingScheduleInternalNotes';
import { InvoicingScheduleHeader } from './InvoicingScheduleHeader/InvoicingScheduleHeader';

const Container = styled(Flexer)`
  position: relative;
`;

const Wrapper = styled(FlexerColumn)`
  width: 419px;
  gap: 4px;
  min-width: 419px;
  height: fit-content;
  border-radius: 8px;
  position: relative;
  pointer-events: ${(props) => props.disabled && 'none'};
  padding-bottom: 12px;
  display: ${(props) => props.hide && 'none'};
  z-index: 20;
  background-color: var(--primaryBlack2);
`;

const SectionWrapper = styled(FlexerColumn)`
  width: 100%;
  padding: 0 32px;
`;

const ChangedCounter = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--primaryBlack15);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: white;
`;

const SaveBulkChangesButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 4px;
  border: 1px solid var(--primaryBlack5);
  background: var(--primaryGreen);
  margin: 8px 32px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: white;
  cursor: pointer;

  &:hover {
    background: var(--secondaryGreen);
  }
`;

const CheckIconWrapper = styled.div`
  svg {
    width: 16px;
    height: 16px;
    position: relative;
    top: 1px;

    path {
      fill: white;
    }
  }
`;

const GenerationInfoButton = styled(Button)`
  font-size: 12px;
  margin-bottom: 8px;
`;

export const InvoicingScheduleTabsPanel = ({ WarningsButton }) => {
  const { creditNotes } = useContext(CreditNoteContext);
  const { errors: invoicingScheduleFormErrors } = useFormikContext();

  const { bulkCreateInvoices, bulkUpdateInvoicesInSubscript, refetchInvoicingScheduleRef } = useContext(BillingContext);
  const {
    invoicingScheduleFormValues,
    setCurrentInvoicingSchedule,
    scheduleFormRef,
    disableSaveScheduleButton,
    isScheduleDraft,
    invoicesCreatedInGl,
    selectedTabsPanelTab,
    setSelectedTabsPanelTab,
    setLoading,
    invoicingServiceDisplayName,
    invoicingService,
    handleImportInvoicesSave,
    validateInvoiceFormAndExecuteAction,
    setSelectedInvoiceId,
  } = useContext(InvoicingScheduleContext);

  const { trackEvent } = useAnalytics();

  const invoices = getSortedInvoices({ invoices: invoicingScheduleFormValues?.invoices });

  const setFieldValue = scheduleFormRef?.current?.setFieldValue;

  const {
    InvoicingScheduleFrequencyDropdown,
    isLoading: loading,
    generatedInvoicesExplanations,
  } = useInvoicingScheduleFrequencyDropdown({
    invoices,
    setFieldValue,
    importedInvoices: uniqBy(
      [
        ...(invoicesCreatedInGl ?? []),
        ...invoices.filter((invoice) => invoice.metadata?.[MAGIC_METADATA.IS_HISTORICAL]),
      ],
      'id',
    ),
    showOptionToIgnoreImports: !!invoicingScheduleFormValues?.id,
  });

  const [showGenerationInfoModal, setShowGenerationInfoModal] = useState(false);

  const showSaveScheduleButton = isScheduleDraft && !!invoicingScheduleFormValues?.invoices?.length;

  const disableEditing = useMemo(
    () =>
      (invoices ?? []).some((invoice) => {
        const invoiceStatus = invoice ? getModalInvoiceStatus({ invoice }) : '';
        return [INVOICE_STATUSES.QUEUED_UP, INVOICE_STATUSES.PROCESSING].includes(invoiceStatus);
      }),
    [invoices],
  );

  const changedInvoiceCount = invoices.filter(
    (invoice) => (!isEmpty(invoice?.changedFieldsWithOldValues ?? {}) && !!invoice?.id) || !!invoice?.isDuplicate,
  ).length;

  const handleSaveBulkChanges = useCallback(async () => {
    try {
      setLoading(true);
      const duplicateInvoices = invoices.filter((invoice) => !!invoice?.isDuplicate);
      if (duplicateInvoices.length) {
        await validateInvoiceFormAndExecuteAction({
          action: async () => {
            const createdInvoices = await bulkCreateInvoices({
              invoices: duplicateInvoices.map((invoice) => getInvoiceData(invoice)),
            });
            await setSelectedInvoiceId(createdInvoices[0].id);
          },
        });
        await setCurrentInvoicingSchedule((prev) => ({
          ...prev,
          invoices: prev.invoices?.filter((i) => !i?.isDuplicate),
        }));
      }
      await bulkUpdateInvoicesInSubscript({
        invoices: invoices
          .filter(
            (invoice) =>
              !!invoice?.changedFieldsWithOldValues && !isEmpty(invoice?.changedFieldsWithOldValues) && !!invoice?.id,
          )
          ?.map((invoice) => getInvoiceData(invoice)),
      });
      await refetchInvoicingScheduleRef.current({ saveInvoicesFormChanges: false });
    } catch (err) {
      console.error({ message: err.message, component: 'InvoicingScheduleTabsPanel', stack: err });
    } finally {
      setLoading(false);
    }
  }, [
    bulkCreateInvoices,
    bulkUpdateInvoicesInSubscript,
    validateInvoiceFormAndExecuteAction,
    refetchInvoicingScheduleRef,
    setSelectedInvoiceId,
    setCurrentInvoicingSchedule,
    invoices,
    setLoading,
  ]);

  return invoices.length || invoicingScheduleFormValues?.id ? (
    <Container>
      <Wrapper disabled={!invoicingScheduleFormValues?.customer_id}>
        <InvoicingScheduleHeader />

        <InvoicingScheduleInternalNotes invoicingScheduleFormValues={invoicingScheduleFormValues} />

        <SectionWrapper gap="8px" width="100%">
          <FlexBetweenContainer gap="4px">
            <FlexerColumn gap="8px">
              <Flexer>
                <AddInvoice
                  onImportInvoicesSave={handleImportInvoicesSave}
                  allowImport={!isScheduleDraft}
                  importTooltipOverride={isScheduleDraft ? 'Please save the schedule first' : null}
                />
              </Flexer>
            </FlexerColumn>

            <FlexerRow
              style={{
                width: '100%',
                border: '1px solid var(--accentGraySecond)',
                borderRadius: '8px',
              }}
            >
              <ContractsButton />
              <InvoiceSettingsButton disableEditing={disableEditing} />
              <InvoicingScheduleFrequencyDropdown iconVersion />
            </FlexerRow>
          </FlexBetweenContainer>

          {showSaveScheduleButton && (
            <FlexerColumn gap="8px">
              <WhiteButtonScheduleHeader
                green
                disabled={disableSaveScheduleButton || invoicingScheduleFormErrors?.invoices?.length > 0}
                onClick={() => !disableSaveScheduleButton && scheduleFormRef?.current?.submitForm()}
                data-cy="billing__invoice-schedule-modal__schedule-save-button"
              >
                <CheckIconWrapper>
                  <CheckIcon />
                </CheckIconWrapper>
                Save Invoicing Schedule
              </WhiteButtonScheduleHeader>

              {invoicingScheduleFormErrors?.invoices?.length > 0 &&
                invoicingScheduleFormValues?.invoices?.length > 0 && (
                  <InvoicingScheduleFormErrors
                    errors={invoicingScheduleFormErrors.invoices}
                    invoices={invoicingScheduleFormValues.invoices}
                  />
                )}

              <GenerationInfoButton
                onClick={() => {
                  trackEvent({
                    name: EVENTS.SEE_HOW_SCHEDULE_WAS_GENERATED_CLICKED,
                  });
                  setShowGenerationInfoModal(true);
                }}
              >
                See how this schedule was generated
              </GenerationInfoButton>
            </FlexerColumn>
          )}
        </SectionWrapper>
        {changedInvoiceCount ? (
          <SaveBulkChangesButton
            data-cy="billing__invoice-schedule-modal__save-bulk-changes-button"
            onClick={handleSaveBulkChanges}
          >
            <div />
            Save in Subscript: Unsaved invoice
            <ChangedCounter data-cy="billing__invoice-schedule-modal__save-bulk-changes-button-counter">
              {changedInvoiceCount}
            </ChangedCounter>
          </SaveBulkChangesButton>
        ) : null}
        <SectionWrapper style={{ padding: '0' }}>
          <FlexerRow alignItems="center" style={{ margin: '0 32px' }}>
            <WarningsButton />
            <TabsContainer>
              <Tab
                isSelected={selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES}
                onClick={() => setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES)}
                data-cy="billing__invoice-schedule-modal__sidebar-tab--invoices"
              >
                <TabCounter>{invoices.length}</TabCounter>
                <TabText>Invoices</TabText>
              </Tab>

              <Tab
                isSelected={selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES}
                onClick={() => {
                  INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE.includes(getServiceCategory(invoicingService)) &&
                    setSelectedTabsPanelTab(INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES);
                }}
                disabled={!INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE.includes(getServiceCategory(invoicingService))}
                data-cy="billing__invoice-schedule-modal__sidebar-tab--credit-notes"
              >
                <TooltipContainer
                  width={250}
                  tooltipStyles={{ borderRadius: '12px' }}
                  fontSize="12px"
                  backgroundColor={'var(--opaquePrimaryBlack50)'}
                  direction={DIRECTIONS.TOP}
                  hideArrow
                  yOffset={10}
                  xOffset={10}
                  toolTipContent={
                    invoicingServiceDisplayName
                      ? `We don't support ${invoicingServiceDisplayName} yet`
                      : 'You need to connect to a general ledger in order to use this feature'
                  }
                  isVisible={
                    !INTEGRATION_SERVICES_WITH_CREATE_CREDIT_NOTE.includes(getServiceCategory(invoicingService))
                  }
                >
                  <Centerer gap="8px">
                    <TabCounter data-cy="billing__invoice-schedule-modal__credit-notes-counter">
                      {creditNotes?.length ?? 0}
                    </TabCounter>
                    <TabText>Credit Note</TabText>
                  </Centerer>
                </TooltipContainer>
              </Tab>
            </TabsContainer>
          </FlexerRow>

          {(selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.INVOICES ||
            selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.WARNINGS) && (
            <InvoicesTab invoices={invoices} loading={loading} />
          )}

          {selectedTabsPanelTab === INVOICING_SCHEDULE_TABS_PANEL_TABS.CREDIT_NOTES && <CreditNotesTab />}
        </SectionWrapper>
        {showGenerationInfoModal && (
          <ScheduleGenerationInfoModal
            hideModal={() => setShowGenerationInfoModal(false)}
            generatedInvoicesExplanations={generatedInvoicesExplanations}
          />
        )}
      </Wrapper>
    </Container>
  ) : null;
};
