import { useEffect } from 'react';
import { useRowSelect, useTable } from 'react-table';
import {
  ReactTable,
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  HeaderCellWrapper,
  ReactTableRow,
  ReactTableCell,
} from 'components/Table';
import { Row, Spacer, TextBox } from 'components/Core';

export const PreviewTable = ({ columns, tableData, setDataHook, entityName, children }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useRowSelect,
  );

  useEffect(() => {
    // Filter tableData to include only the selected rows
    const filteredData = tableData.filter((_, index) => selectedRowIds[index]);
    setDataHook(filteredData);
  }, [setDataHook, tableData, selectedRowIds]);

  return (
    <>
      <Row horizontal="flex-start">
        <TextBox bold>Check and select the {entityName} that </TextBox>
        <Spacer width="5px" />
        <TextBox bold italic opacity="0.5">
          Subscript AI will create
        </TextBox>
      </Row>
      <div data-cy={`${entityName}-table`}>
        {tableData?.length ? (
          <ReactTable pageView={true} data-cy={`${entityName}-table`} {...getTableProps()}>
            <ReactTableHeader pageView={true}>
              {headerGroups.map((headerGroup) => {
                return (
                  <ReactTableHeaderRow pageView={true} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <ReactTableHeaderColumn
                        alignRight={column?.alignRight}
                        customWidth={column.width}
                        {...column.getHeaderProps()}
                      >
                        <HeaderCellWrapper>
                          {column.id === 'actions' ? <></> : column.render('Header')}
                        </HeaderCellWrapper>
                      </ReactTableHeaderColumn>
                    ))}
                  </ReactTableHeaderRow>
                );
              })}
            </ReactTableHeader>
            <ReactTableBody pageView={true} {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <ReactTableRow data-cy={'transaction-row'} {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <ReactTableCell
                        isAbsolute={row.canExpand}
                        customWidth={cell.column.width}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </ReactTableCell>
                    ))}
                  </ReactTableRow>
                );
              })}
            </ReactTableBody>
          </ReactTable>
        ) : (
          <div>
            Subscript AI was unable to parse any {entityName} out of this contract. Please try again or reach out to us
            on Slack!
          </div>
        )}
      </div>
      {children}
    </>
  );
};
