import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { getDiscountsFromSearch } from 'api/usageBasedEngine';
import { AppContext } from 'AppContext';
import { InlineButton } from 'components/Buttons';
import { FormikCustomSelector } from 'components/Controls';
import { Centerer, Flexer, FlexerRow, Spacer } from 'components/Core';
import { CirclePlusIcon, EditCircleIcon } from 'components/Icons';
import { ReactComponent as DiscountIcon } from 'images/discount-icon.svg';
import { DiscountEditModal } from './DiscountEditModal';
import { DiscountTypeValue } from './DiscountTypeValue';
import { FormRow } from './styles';

const DiscountRow = styled(FormRow)`
  > div:first-child {
    width: 46%;
  }

  > div:not(:first-child) {
    width: 27%;
  }
`;

const EmptyDiscountOption = styled(Flexer)`
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
    path {
      fill: var(--primaryBlack30);
    }
  }
`;

const LabelText = styled.span`
  opacity: 0.4;
`;

const EditDiscountButton = styled(Flexer)`
  gap: 8px;
  cursor: pointer;
`;

const StyledEditCircleIcon = styled(EditCircleIcon)`
  width: 16px !important;
  height: 16px !important;
`;

const DiscountText = styled.div`
  color: var(--primaryBlack);
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  margin-right: 16px;
  margin-left: 8px;
`;

const TabsContainer = styled.div`
  display: flex;
  width: 300px;
  padding: 4px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--neutralGray);
  background: #fff;
  box-shadow: 4px 4px 24px 0px var(--primaryBlack4);
`;

const Tab = styled.div`
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  background: ${(props) => (props.active ? 'var(--primaryBlue10)' : 'initial')};
  color: ${(props) => (props.active ? '#0075FF' : 'initial')};
  border-radius: 4px;
  font-size: 12px;
  font-weight: ${(props) => (props.active ? '700' : '600')};
  border-radius: 4px;
  cursor: pointer;
`;

export const FormikDiscountSection = ({ onDiscountUpdated }) => {
  const { orgId } = useContext(AppContext);
  const { setFieldValue, values } = useFormikContext();

  const createNewDiscountCodeOption = useMemo(
    () => ({
      label: (
        <EmptyDiscountOption>
          <CirclePlusIcon />
          Create new
        </EmptyDiscountOption>
      ),
      value: null,
    }),
    [],
  );

  const [discountToEdit, setDiscountToEdit] = useState(null);

  const handleDiscountEdit = (event) => {
    event.preventDefault();

    setDiscountToEdit({
      id: values?.discount_id,
      code: values?.discount_code,
      type: values?.discount_type,
      value: values?.discount_value,
    });
  };

  return (
    <>
      <FlexerRow alignItems="center">
        <DiscountIcon style={{ marginBottom: '2px' }} />
        <DiscountText>Discount:</DiscountText>
        <TabsContainer>
          <Tab active={!values?.has_discount} onClick={() => setFieldValue('has_discount', false)}>
            No discount
          </Tab>
          <Tab
            active={values?.has_discount}
            onClick={() => setFieldValue('has_discount', true)}
            data-cy="formik-discount-section__has-discount"
          >
            Apply a discount
          </Tab>
        </TabsContainer>
      </FlexerRow>
      {values?.has_discount && (
        <>
          <Spacer height="16px" />
          <DiscountRow>
            <FormikCustomSelector
              isPaginateable
              label={
                <Centerer gap="8px">
                  <LabelText>Discount Code</LabelText>
                  {values?.discount_id && (
                    <EditDiscountButton
                      onClick={handleDiscountEdit}
                      data-cy="formik-discount-section__edit-discount-button"
                    >
                      <StyledEditCircleIcon />
                      <InlineButton isSecondary>Edit</InlineButton>
                    </EditDiscountButton>
                  )}
                </Centerer>
              }
              labelOpacity={1}
              name="discount_id"
              value={
                values?.discount_id
                  ? {
                      label: values?.discount_code,
                      value: values?.discount_id,
                    }
                  : createNewDiscountCodeOption
              }
              loadOptions={async (searchQuery) => {
                const result = await getDiscountsFromSearch({ searchQuery, orgId });
                return {
                  ...result,
                  options: [createNewDiscountCodeOption, ...result.options],
                };
              }}
              handleChange={(option) => {
                setFieldValue('discount_id', option ? option.value : null);
                if (option.data) {
                  setFieldValue('discount_id', option.data.id);
                  setFieldValue('discount_code', option.data.code);
                  setFieldValue('discount_type', option.data.type);
                  setFieldValue('discount_value', option.data.value);
                }
              }}
            />
            <DiscountTypeValue values={values} />
          </DiscountRow>
        </>
      )}

      {discountToEdit && (
        <DiscountEditModal
          discount={discountToEdit}
          onClose={() => setDiscountToEdit(null)}
          onDiscountUpdated={onDiscountUpdated}
        />
      )}
    </>
  );
};
