import { useContext, useEffect } from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { omit } from 'lodash';
import { useTransactionsAPI, getTransactionAnalysis } from 'api/transactions';
import { EVENT_RECOGNITION_TYPES, RECOGNITION_TYPES, RECURRING_RECOGNITION_TYPES } from 'consts/global';
import { normalizeAmountFields } from 'models/transaction';
import { generateChanges } from 'views/ExternalUpdates/utils';
import { TRANSACTION_BILLING_FIELDS, TRANSACTION_MODAL_MODE, TRANSACTION_VIEW_MODE } from './consts';
import { TransactionContext } from './TransactionContext';
import { StyledForm } from './TransactionForm.styles';
import { formValidations } from './TransactionForm.utils';
import {
  accountingSpreadsChanged,
  getUnsentInvoicesToBeUpdated,
  getWarnings,
  transformRecognitionEventsToSpreads,
} from './utils';

dayjs.extend(utc);

export const TransactionForm = ({ children }) => {
  const {
    view,
    closeModal,
    currentBulkIndex,
    currentTransaction,
    currentTransactionSpread,
    formRef,
    mode,
    saveNewTransaction = true,
    onTransactionCreated,
    onTransactionUpdated,
    onFormSubmitted,
    organization,
    setFlaggingReasons,
    setLoading,
    setTransactionsBulk,
    setCurrentTransaction,
    setTransactionFormValues,
    transactionsBulk,
    transactionWithChangedData,
    originalAccountingSpreads,
    setInvoicesToUpdate,
    hasConfirmedUnsentInvoices,
    setShowUpdateInvoicesModal,
    currentInvoicingScheduleId,
    fetchTransactionRef,
    storedTotalAmountMethod,
  } = useContext(TransactionContext);

  const {
    operations: { addTransaction, restoreTransaction, editTransaction, bulkReview },
  } = useTransactionsAPI({ orgId: organization.id, autoFetch: false });

  useEffect(() => {
    if (currentTransaction?.id)
      bulkReview.mutate({
        ids: [currentTransaction.id],
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransaction?.id]);

  const buildTransactionPayload = (transactionData) => {
    transactionData.replaced_transaction_ids = transactionData.replaced_transactions?.map((t) => t.id) ?? [];

    delete transactionData.integrationMetadata;
    delete transactionData.portalId;
    delete transactionData.dealId;
    delete transactionData.service;
    delete transactionData.flaggingReasons;
    delete transactionData.replaced_transactions;

    return transactionData;
  };

  const resyncInvoicingSchedule = async (updatedTransaction) => {
    const changedFields = generateChanges({
      source: currentTransaction,
      target: updatedTransaction,
      fields: TRANSACTION_BILLING_FIELDS,
    });
    const hasChanges = Object.keys(changedFields).length > 0;

    if (
      updatedTransaction.invoicing_schedule_id &&
      hasChanges &&
      !EVENT_RECOGNITION_TYPES.includes(updatedTransaction.recognition)
    ) {
      // Skipping invoice resync here for event-based transactions since
      //  it's already handled with the transaction update request

      // Check for invoicing changes if transaction is already billed
      const invoicesToUpdate = await getUnsentInvoicesToBeUpdated({
        values: updatedTransaction,
        hasConfirmedUnsentInvoices,
        transactionWithChangedData,
        currentInvoicingScheduleId,
        orgId: organization.id,
      });
      const hasInvoiceChanges =
        invoicesToUpdate?.invoicesToDelete?.length > 0 ||
        invoicesToUpdate?.invoicesToInsert?.length > 0 ||
        invoicesToUpdate?.changedInvoices?.length > 0;

      if (hasInvoiceChanges) {
        setInvoicesToUpdate(invoicesToUpdate);
        // Display confirmation modal first if needed before updating transaction
        setShowUpdateInvoicesModal(true);
        return;
      } else {
        closeModal?.();
        setLoading(false);
      }
    }
  };

  const performTransactionCreate = async ({ transactionData, transactionSpread }) => {
    setLoading(true);

    try {
      if (saveNewTransaction) {
        const newTransaction = await addTransaction.mutateAsync({
          data: {
            ...buildTransactionPayload(transactionData),
            ...transactionSpread,
          },
        });
        closeModal();
        onTransactionCreated?.({ newTransaction });
      } else {
        closeModal();
        onTransactionCreated?.({ transactionData });
      }
    } catch (err) {
      console.error({ message: err.message, component: 'TransactionModal.js', stack: err });
    }

    setLoading(false);
  };

  const performTransactionUpdate = async ({
    transactionData,
    transactionSpread,
    skipInvoicesSync,
    externalUpdatesMode,
  }) => {
    if (mode === TRANSACTION_MODAL_MODE.EDIT) {
      setLoading(true);
    }

    try {
      const updatedTransaction = await editTransaction.mutateAsync({
        id: transactionData.id,
        data: { ...buildTransactionPayload(transactionData), confirmed: true, ...transactionSpread },
        params: {
          skipInvoicesSync,
          externalUpdatesMode,
        },
      });

      if (updatedTransaction.archived_at) {
        await restoreTransaction.mutateAsync({ id: updatedTransaction.id });
      }

      await bulkReview.mutateAsync({
        ids: [updatedTransaction.id],
      });

      await resyncInvoicingSchedule(updatedTransaction);

      if (mode === TRANSACTION_MODAL_MODE.EDIT) {
        if (view === TRANSACTION_VIEW_MODE.PAGE) {
          normalizeAmountFields(updatedTransaction);
          setCurrentTransaction({ id: updatedTransaction.id });
        } else if (!updatedTransaction.invoicing_schedule_id) {
          closeModal();
        }
      }

      if (typeof fetchTransactionRef.current === 'function') {
        await fetchTransactionRef.current(organization.id, updatedTransaction.id);
      }

      onTransactionUpdated?.({
        updatedTransaction,
        isLastInBulk: mode === TRANSACTION_MODAL_MODE.EDIT_BULK && currentBulkIndex === transactionsBulk.length - 1,
      });
    } catch (err) {
      console.error({ message: err.message, component: 'TransactionModal', stack: err });
    }

    if (mode === TRANSACTION_MODAL_MODE.EDIT) {
      setLoading(false);
    }
  };

  const performExternalUpdate = async ({ transactionData, externalUpdatesMode }) => {
    setLoading(true);

    // onFormSubmitted is set in `src/views/ExternalUpdates/TableCoordinator.js`
    await onFormSubmitted?.({ data: transactionData, externalUpdatesMode });

    if (transactionData.invoicing_schedule_id) {
      await resyncInvoicingSchedule(transactionData);
    } else {
      closeModal();
    }
    setLoading(false);
  };

  const handleFormSubmit = async ({ values }) => {
    let transactionSpread = { ...currentTransactionSpread };

    if (
      values.recognition === RECOGNITION_TYPES.eventNotRecurring ||
      values.recognition === RECOGNITION_TYPES.eventRecurring
    ) {
      transactionSpread.recognitionEvents = transformRecognitionEventsToSpreads(
        transactionSpread?.recognitionEvents ?? [],
      );

      if (!transactionSpread.recognitionEvents.length) {
        transactionSpread = {};
      }
    } else {
      transactionSpread = {};
    }

    if (!RECURRING_RECOGNITION_TYPES.includes(values.recognition)) {
      values.recurring_amount = null;
    }

    if (values.amount === '') values.amount = 0; // we may change this in the future to allow null amount
    if (values.recurring_amount === '') values.recurring_amount = null;

    const transactionArguments = {
      transactionData: values,
      transactionSpread,
      skipInvoicesSync: !EVENT_RECOGNITION_TYPES.includes(values.recognition), // send `false` when transaction is event-based to sync invoices
    };

    // if the accounting spreads did not change, then remove them from the transactionData
    if (transactionArguments.transactionData.accounting_spreads) {
      // make sure that none of the amounts are empty
      transactionArguments.transactionData.accounting_spreads = transactionArguments.transactionData.accounting_spreads.map(
        (s) => ({ ...s, amount: s.amount || 0 }),
      );

      if (
        !accountingSpreadsChanged({
          originalAccountingSpreads,
          currentAccountingSpreads: transactionArguments.transactionData.accounting_spreads,
        })
      ) {
        transactionArguments.transactionData.accounting_spreads = null;
      }
    }

    switch (mode) {
      case TRANSACTION_MODAL_MODE.EDIT:
        await performTransactionUpdate(transactionArguments);
        break;
      case TRANSACTION_MODAL_MODE.EDIT_BULK:
        await performTransactionUpdate(transactionArguments);
        setTransactionsBulk(transactionsBulk.filter((ignoredTransaction, index) => index !== currentBulkIndex));
        break;
      case TRANSACTION_MODAL_MODE.CREATE:
        await performTransactionCreate(transactionArguments);
        break;
      case TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE:
        await performExternalUpdate({ transactionData: values });
        break;
      default:
        break;
    }
  };

  const initialValues =
    mode === TRANSACTION_MODAL_MODE.EXTERNAL_UPDATE ? transactionWithChangedData : currentTransaction;
  initialValues.total_amount_method = storedTotalAmountMethod;

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={formValidations}
      enableReinitialize={true}
      onSubmit={(values) => handleFormSubmit({ values })}
    >
      {({ values, dirty }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          const reanalyzeTransaction = async () => {
            if (!currentTransaction.id || currentTransaction.replaced_by) return;
            if (currentTransaction.confirmed && !dirty) return;

            try {
              const flaggingReasons = await getTransactionAnalysis({
                orgId: organization.id,
                transactionId: currentTransaction.id,
                params: omit(values, ['incrementPercentagesByDate', 'auto_increase_increments']),
              });

              const warnings = getWarnings({
                data: omit(values, ['incrementPercentagesByDate', 'auto_increase_increments']),
              });

              setFlaggingReasons({ ...flaggingReasons, localWarnings: warnings } ?? {});
            } catch (err) {
              console.error({ message: err.message, component: 'TransactionForm.js', stack: err });
            }
          };
          reanalyzeTransaction();

          setTransactionFormValues(values);
        }, [values, dirty]);

        return <StyledForm>{children}</StyledForm>;
      }}
    </Formik>
  );
};
