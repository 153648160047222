import React from 'react';
import styled from 'styled-components';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { usePortal } from 'components/Portal';
import { Row } from 'components/Core';
import { QuestionIcon } from 'components/Icons';
import Tooltip from 'components/Tooltip/Tooltip';

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 180px;
  background-color: white;
  border: 1px solid var(--neutralGray);
`;

const Counter = styled.div`
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  height: 16px;
  text-transform: uppercase;
  color: var(--primaryBlack);
  padding: 2px 4px;
  border-radius: 4px;
  background: var(--primaryBlack5);

  span {
    color: ${({ lineThrough }) => (lineThrough ? 'var(--primaryBlack50)' : 'var(--primaryBlack)')};
    text-decoration: ${({ lineThrough }) => (lineThrough ? 'line-through' : 'none')};
    text-decoration-thickness: 1.5px;
  }
`;

const SelectRow = styled.div`
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid var(--neutralGray);

  &:last-child {
    border-bottom: none;
  }
`;

const SelectText = styled.div`
  margin-left: 8px;
`;

export const AllRowsSelectorDropdown = ({
  getToggleAllPageRowsSelectedProps = {},
  getToggleAllRowsSelectedProps = {},
  disableSelectAll = false,
  fixedSelectAllCheckbox = false,
  selectAllCheckboxXOffset,
  selectAllLimit,
  ...props
}) => {
  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({
    XOffset: selectAllCheckboxXOffset ?? 0,
    YOffset: -10,
    fixed: fixedSelectAllCheckbox,
  });

  const isChecked = getToggleAllPageRowsSelectedProps?.()?.checked ?? getToggleAllRowsSelectedProps?.()?.checked;
  const limitedSelectAll = selectAllLimit > 0 && props?.rows?.length > selectAllLimit;

  return (
    <div ref={triggerRef}>
      <Checkbox data-cy="table__select-all-dropdown" onClick={togglePortal} checked={isChecked} />

      {isPortalVisible && (
        <Portal>
          <Dropdown>
            <SelectRow>
              <Row>
                <Checkbox
                  data-cy="table__select-all-dropdown__select-page"
                  {...getToggleAllPageRowsSelectedProps()}
                  disabled={getToggleAllRowsSelectedProps().checked}
                />
                <SelectText>Select shown</SelectText>
              </Row>

              <Counter>{props?.page?.length}</Counter>
            </SelectRow>

            {!disableSelectAll && (
              <SelectRow>
                <Row>
                  <Checkbox data-cy="table__select-all-dropdown__select-all" {...getToggleAllRowsSelectedProps()} />
                  <SelectText>Select all</SelectText>
                </Row>

                <Counter lineThrough={limitedSelectAll}>
                  {limitedSelectAll ? `${selectAllLimit} ` : null}
                  <span>{props?.rows?.length}</span>
                </Counter>

                {limitedSelectAll && (
                  <Tooltip
                    toolTipContent={`Subscript can only process up to ${selectAllLimit} records at a time. Please filter the table to select only the records you need.`}
                  >
                    <QuestionIcon size="14px" />
                  </Tooltip>
                )}
              </SelectRow>
            )}
          </Dropdown>
        </Portal>
      )}
    </div>
  );
};
