import React, { useContext, useMemo } from 'react';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';

import { AppContext } from 'AppContext';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'components/Modal';
import { FlexBetweenContainer, Spacer } from 'components/Core';
import { CustomDatePicker, FormikCustomInput } from 'components/Controls';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { roundToDecimalPlaces } from 'utils/mathUtils';
import { ISO_CODE_TO_SYMBOL, NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { useToasts } from 'components/Toasts';
import calendarIcon from 'images/calendar-icon-grey.svg';
import { getTillCancelMonthlyIncrementPercentages } from 'api/transactions/requests';

import {
  TableSectionWrapper,
  TitleSubText,
  Table,
  TableHeader,
  TableMainCell,
  Counter,
  CurrencySymbol,
  DatePickerWrapper,
  StyledTrashIcon,
  AddButton,
} from './TransactionAutoIncreaseModal.styles';
import { EditSubsequentMonthsInput } from './EditSubsequentMonthsInput';
import { DEFAULT_AUTO_INCREASE_MONTHS } from '../consts';

export const TransactionAutoIncreaseModal = ({ increments, onSave, onClose, transaction }) => {
  const {
    appSettings: { currencyISOCode: currency },
    orgId,
  } = useContext(AppContext);

  const { pushError } = useToasts();

  const currencySymbol = ISO_CODE_TO_SYMBOL[transaction?.currency] ?? ISO_CODE_TO_SYMBOL[currency] ?? '$';

  const handleDateChange = ({ setFieldValue, isEndDate, increments, index }) => (name, date) => {
    if (!date) return;
    const newDate = updateDateFromDatePicker(date);
    const newDateObject = dayjs.utc(newDate).startOf('month');
    setFieldValue(name, newDateObject.toDate());

    if (isEndDate && !!increments[index + 1]?.start_date) {
      const nextStartDate = newDateObject.add(1, 'month').toDate();
      setFieldValue(`increments[${index + 1}].start_date`, nextStartDate);
    }
  };

  const handlePercentageChange = ({ setFieldValue, name }) => (value) => {
    setFieldValue(name, roundToDecimalPlaces({ number: (value ?? 0) / 100, decimalPlaces: 4 }));
  };

  const getMRR = ({ increments, index }) => {
    const currentIncrement = increments[index];
    if (!currentIncrement.start_date || !currentIncrement.end_date) return null;
    if (!transaction.recurring_amount) return 0;

    const accumulatedIncrease = increments.slice(0, index + 1).reduce((acc, increment) => {
      return acc * (1 + increment.percentage);
    }, 1);

    return accumulatedIncrease * transaction.recurring_amount;
  };

  const getARR = ({ increments, index, mrr: _mrr }) => {
    const mrr = _mrr ?? getMRR({ increments, index });

    if (!mrr) return mrr;

    return mrr * 12;
  };

  const addPeriod = ({ setFieldValue, increments, autoIncreaseMonths }) => {
    const newIncrements = cloneDeep(increments);

    const secondLastIncrement = newIncrements[newIncrements.length - 2];
    const startDate = secondLastIncrement?.end_date
      ? dayjs.utc(secondLastIncrement.end_date).add(1, 'month').toDate()
      : secondLastIncrement.start_date
      ? dayjs.utc(secondLastIncrement.start_date).add(autoIncreaseMonths, 'month').toDate()
      : null;
    const endDate = startDate
      ? dayjs
          .utc(startDate)
          .add(Math.max(autoIncreaseMonths - 1, 0), 'month')
          .toDate()
      : null;

    // we want the new period to be added as the second last item
    newIncrements.splice(increments.length - 1, 0, { start_date: startDate, end_date: endDate, percentage: 0 });

    setFieldValue('increments', newIncrements);
  };

  const removePeriod = ({ setFieldValue, increments, index }) => {
    const newIncrements = cloneDeep(increments);

    // The next period's start date should be replaced with the removed period's start date
    // so that the increments remain in sequence
    if (newIncrements[index + 1]?.start_date) newIncrements[index + 1].start_date = newIncrements[index].start_date;
    newIncrements.splice(index, 1);

    setFieldValue(
      'increments',
      newIncrements.map((increment, i) => ({ ...increment, id: i })),
    );
  };

  const tableHeaders = [
    { label: '#' },
    { label: 'Start Month' },
    { label: 'End Month' },
    { label: 'Auto-Increase' },
    { label: 'MRR', justifyContent: 'flex-end' },
    { label: 'ARR', justifyContent: 'flex-end' },
  ];

  const handleSave = async (values) => {
    const increments = values.increments;
    const autoIncreaseMonths = values.autoIncreaseMonths;

    const finalIncrementIndex = increments.length - 1;

    if (Number.isNaN(autoIncreaseMonths) || autoIncreaseMonths <= 0) {
      pushError('Subsequent months should be greater than 0 and a number.');
      return;
    }

    //check that all increments have a start date and end date except for the last one
    if (
      increments.some(
        (increment, index) => index !== finalIncrementIndex && (!increment.start_date || !increment.end_date),
      )
    ) {
      pushError('Missing start date or end date. End date is required for all but the last increment.');
      return;
    }
    // Check that all end dates are after or the same as their start dates
    if (
      increments.some(
        (increment, index) =>
          index !== finalIncrementIndex && dayjs.utc(increment.start_date).isAfter(increment.end_date, 'month'),
      )
    ) {
      pushError('Some start dates are after their end dates.');
      return;
    }

    // set the last increment's start date
    const lastIncrement = increments[finalIncrementIndex];
    lastIncrement.start_date = dayjs
      .utc(increments[finalIncrementIndex - 1].end_date)
      .startOf('month')
      .add(1, 'month')
      .toDate();

    const incrementPercentagesByDate = await getTillCancelMonthlyIncrementPercentages({
      orgId,
      params: {
        usesAutoIncrease: true,
        increments,
        monthlyAmount: transaction.recurring_amount || 0,
        endDate: transaction.end_date,
        autoIncreaseMonths,
      },
    });

    onSave({ increments, autoIncreaseMonths, incrementPercentagesByDate });
    onClose();
  };

  const initialIncrements = useMemo(() => {
    const startDateObject = transaction?.start_date ? dayjs.utc(transaction.start_date).startOf('month') : null;

    return increments?.length
      ? increments
      : [
          {
            start_date: startDateObject?.toDate(),
            end_date: startDateObject ? startDateObject.add(1, 'year').subtract(1, 'month').toDate() : null,
            percentage: 0,
          },
          {
            start_date: null,
            end_date: null,
            percentage: 0,
          },
        ];
  }, [increments, transaction.start_date]);

  return (
    <ModalContainer>
      <Formik
        initialValues={{
          increments: initialIncrements,
          autoIncreaseMonths: transaction?.auto_increase_months || DEFAULT_AUTO_INCREASE_MONTHS,
        }}
        onSubmit={handleSave}
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form>
            <Modal maxWidth="940px" height="auto" overflow="visible" background="var(--primaryGray)">
              <ModalHeader>
                <ModalTitle>
                  <b>Auto-increase</b>
                  <TitleSubText>
                    Subscript will auto-increase the recurring amount every specified period by the percentage that you
                    specify below:
                  </TitleSubText>
                </ModalTitle>

                <ModalCloseIcon onClick={onClose} />
              </ModalHeader>

              <ModalBody>
                <Table>
                  <TableSectionWrapper padding="16px 20px">
                    {tableHeaders.map((header) => (
                      <TableHeader justifyContent={header.justifyContent}>{header.label}</TableHeader>
                    ))}
                    <TableHeader justifyContent="flex-end">
                      <AddButton
                        data-cy="transaction__auto-increase-modal--add-period-button"
                        onClick={() =>
                          addPeriod({
                            setFieldValue,
                            increments: values.increments,
                            autoIncreaseMonths: values.autoIncreaseMonths,
                          })
                        }
                      >
                        Add Period
                      </AddButton>
                    </TableHeader>
                  </TableSectionWrapper>

                  <Spacer height="16px" />

                  <TableSectionWrapper>
                    {values.increments.map((increment, index) => {
                      const mrr = getMRR({ increments: values.increments, index });
                      const arr = getARR({ increments: values.increments, index, mrr });
                      const isLast = index === values.increments.length - 1;

                      return (
                        <React.Fragment key={increment.id ?? `increment-${index}`}>
                          <TableMainCell justifyContent="center" nonEditable>
                            <Counter>{index + 1}</Counter>
                          </TableMainCell>

                          <TableMainCell padding={isLast ? '0 12px' : '0px'} nonEditable={index !== 0}>
                            {isLast ? (
                              'Every subsequent:'
                            ) : (
                              <DatePickerWrapper>
                                <CustomDatePicker
                                  formik
                                  name={`increments[${index}].start_date`}
                                  selected={
                                    increment?.start_date ? formatDateForDatepicker(increment.start_date) : null
                                  }
                                  showMonthYearPicker
                                  dateFormat="MMM yyyy"
                                  onChange={handleDateChange({
                                    setFieldValue,
                                    increments: values.increments,
                                    index,
                                  })}
                                  disabled={index !== 0}
                                  calendarIcon={calendarIcon}
                                />
                              </DatePickerWrapper>
                            )}
                          </TableMainCell>

                          <TableMainCell padding={isLast ? '0 12px' : '0px'}>
                            {isLast ? (
                              <EditSubsequentMonthsInput />
                            ) : (
                              <DatePickerWrapper>
                                <CustomDatePicker
                                  formik
                                  name={`increments[${index}].end_date`}
                                  selected={increment?.end_date ? formatDateForDatepicker(increment.end_date) : null}
                                  showMonthYearPicker
                                  dateFormat="MMM yyyy"
                                  onChange={handleDateChange({
                                    setFieldValue,
                                    isEndDate: true,
                                    increments: values.increments,
                                    index,
                                  })}
                                  calendarIcon={calendarIcon}
                                />
                              </DatePickerWrapper>
                            )}
                          </TableMainCell>

                          <TableMainCell>
                            <FormikCustomInput
                              name={`increments[${index}].percentage`}
                              placeholder="Enter percentage..."
                              suffix="%"
                              type="number"
                              handleChange={handlePercentageChange({
                                setFieldValue,
                                name: `increments[${index}].percentage`,
                              })}
                              value={(increment?.percentage ?? 0) * 100}
                              precision={2}
                              customSuffixColor="var(--primaryBlack)"
                              noBorder
                              noShadow
                              height="50px"
                              data-cy={`transaction__auto-increase-modal__percentage--input-${index}`}
                              fontSize="12px"
                            />
                          </TableMainCell>

                          <TableMainCell padding="0 12px" nonEditable>
                            {mrr === null ? (
                              'TBD'
                            ) : (
                              <FlexBetweenContainer width="100%">
                                <CurrencySymbol>{currencySymbol}</CurrencySymbol>
                                <div>
                                  {numberFormatter({
                                    type: NUMBER_FORMATS.NUMBER,
                                    rawValue: mrr,
                                    decimalPlaces: 2,
                                  })}
                                </div>
                              </FlexBetweenContainer>
                            )}
                          </TableMainCell>

                          <TableMainCell padding="0 12px" nonEditable>
                            {arr === null ? (
                              'TBD'
                            ) : (
                              <FlexBetweenContainer width="100%">
                                <CurrencySymbol>{currencySymbol}</CurrencySymbol>
                                <div>
                                  {numberFormatter({
                                    type: NUMBER_FORMATS.NUMBER,
                                    rawValue: arr,
                                    decimalPlaces: 2,
                                  })}
                                </div>
                              </FlexBetweenContainer>
                            )}
                          </TableMainCell>

                          <TableMainCell padding="0 12px" nonEditable={index === 0 || isLast}>
                            {index !== 0 && !isLast && (
                              <StyledTrashIcon
                                data-cy={`transaction__auto-increase-modal__remove-period-button--${index}`}
                                onClick={() => removePeriod({ setFieldValue, increments: values.increments, index })}
                                fill="var(--primaryBlack30)"
                              />
                            )}
                          </TableMainCell>
                        </React.Fragment>
                      );
                    })}
                  </TableSectionWrapper>
                </Table>
              </ModalBody>

              <ModalFooter flexEnd>
                <ModalButton data-cy="transaction__auto-increase-modal--cancel-button" onClick={onClose}>
                  <b>Cancel</b>
                </ModalButton>
                <ModalButton data-cy="transaction__auto-increase-modal--save-button" primary onClick={handleSubmit}>
                  <b>Save</b>
                </ModalButton>
              </ModalFooter>
            </Modal>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};
