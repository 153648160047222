import dayjs from 'dayjs';
import { identity, isEqual, isNil } from 'lodash';
import { EXTERNAL_UPDATES_TABLE_COLUMNS } from 'views/ExternalUpdates/consts';
import { RECOGNITION_TYPES } from 'consts/global';
import { MAPPING_FUNCTIONS } from './transaction';
import { calculateTotalAmount } from './transaction';

// Disclaimer: Modify updateData in-place
export const normalizeAmountFields = (updateData) => {
  if (
    !isNil(updateData.amount) ||
    isNil(updateData.recurring_amount) ||
    isNil(updateData.end_date) ||
    isNil(updateData.start_date)
  )
    return;

  updateData.amount = calculateTotalAmount({
    startDate: updateData.start_date,
    endDate: updateData.end_date,
    includeEndMonth:
      updateData.include_end_month ||
      (updateData.recognition === RECOGNITION_TYPES.tillCanceled && !updateData.end_date),
    recurringAmount: updateData.recurring_amount,
    useAutoIncrease: updateData.use_auto_increase,
    autoIncreaseIncrements: updateData.auto_increase_increments,
  });
};

export const prepareData = ({ originalData, updateData }) => {
  const prepData = {
    name: {
      label: 'Name',
      original: originalData?.name,
      updated: updateData?.name,
    },
    date: {
      label: 'Date',
      original: originalData?.date !== undefined ? dayjs.utc(originalData?.date).format('YYYY-MM-DD') : undefined,
      updated: updateData?.date !== undefined ? dayjs.utc(updateData?.date).format('YYYY-MM-DD') : undefined,
    },
    customer_id: {
      label: 'Customer ID',
      original: originalData?.customer_id,
      updated: updateData?.customer_id,
    },
    customer_name: {
      label: 'Customer Name',
      original: originalData?.customer_name,
      updated: updateData?.customer_name,
    },
    product_id: {
      label: 'Product ID',
      original: originalData?.product_id,
      updated: updateData?.product_id,
    },
    product_name: {
      label: 'Product Name',
      original: originalData?.product_name,
      updated: updateData?.product_name,
    },
    seats: {
      label: 'Seats',
      original: originalData?.seats,
      updated: updateData?.seats,
    },
    amount: {
      label: 'Amount',
      original: originalData?.amount,
      updated: updateData?.amount,
    },
    recurring_amount: {
      label: 'Recurring Amount',
      original: originalData?.recurring_amount,
      updated: updateData?.recurring_amount,
    },
    recognition: {
      label: 'Recognition',
      original: originalData?.recognition,
      updated: updateData?.recognition,
    },
    start_date: {
      label: 'Start Date',
      original:
        originalData?.start_date !== undefined ? dayjs.utc(originalData?.start_date).format('YYYY-MM-DD') : undefined,
      updated:
        updateData?.start_date !== undefined ? dayjs.utc(updateData?.start_date).format('YYYY-MM-DD') : undefined,
    },
    end_date: {
      label: 'End Date',
      original:
        originalData?.end_date !== undefined ? dayjs.utc(originalData?.end_date).format('YYYY-MM-DD') : undefined,
      updated: updateData?.end_date !== undefined ? dayjs.utc(updateData?.end_date).format('YYYY-MM-DD') : undefined,
    },
    replaced_by: {
      label: 'Replaced By',
      original: originalData?.replaced_by,
      updated: updateData?.replaced_by ?? null,
    },
    metadata: {},
  };

  if (updateData?.spread_updates) {
    prepData.spread_updates = {
      label: 'Spreads',
    };
  }

  for (const [key, value] of Object.entries(originalData?.metadata ?? {})) {
    prepData.metadata[key] = {
      label: key,
      original: value,
    };
  }
  for (const [key, value] of Object.entries(updateData?.metadata ?? {})) {
    prepData.metadata[key] = {
      ...prepData.metadata[key],
      label: key,
      updated: value,
    };
  }

  return prepData;
};

export const getDifferingFields = ({ data }) => {
  const fields = [];
  for (const [key, value] of Object.entries(data)) {
    if (
      [EXTERNAL_UPDATES_TABLE_COLUMNS.product_name.label, EXTERNAL_UPDATES_TABLE_COLUMNS.customer_name.label].includes(
        key,
      )
    ) {
      continue;
    } else if (
      ['customer_id', 'product_id'].includes(key) &&
      value.updated !== undefined &&
      !isEqual(value.original, value.updated)
    ) {
      fields.push(
        key === 'customer_id'
          ? EXTERNAL_UPDATES_TABLE_COLUMNS.customer_name.name
          : EXTERNAL_UPDATES_TABLE_COLUMNS.product_name.name,
      );
    } else if (key === 'metadata') {
      for (const metadataValue of Object.values(data.metadata)) {
        if (metadataValue.updated !== undefined && metadataValue.updated !== metadataValue.original) {
          fields.push(metadataValue.label);
        }
      }
    } else if (key === 'spread_updates') {
      fields.push(value.label);
    } else {
      const mapFunc = MAPPING_FUNCTIONS[key] ?? identity;
      const mappedOriginalField = mapFunc(value.original);
      const mappedUpdatedField = mapFunc(value.updated);
      let compareFunc = isEqual;
      if (value.updated !== undefined && !compareFunc(mappedOriginalField, mappedUpdatedField))
        fields.push(value.label);
    }
  }

  return fields;
};

export const getDiffering = ({ data }) => {
  const diff = {};
  for (const [key, value] of Object.entries(data)) {
    if (key === 'metadata') continue;
    if (value.updated !== undefined && value.updated !== value.original) diff[key] = value;
  }

  return diff;
};
