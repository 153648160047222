import React from 'react';
import { MultiselectRibbon } from 'components/Blocks';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableCell,
  ReactTableRow,
  EmptyTableContent,
  SortIconWrapper,
  HeaderCellWrapper,
} from 'components/Table';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';

export const UsageEventsTable = ({ tableProps, rowsCount, allUsageEventsSelected, handleBulkDeleteUsageEvents }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = tableProps;

  return (
    <div {...getTableProps()} data-cy="billing__usage-events-table">
      <ReactTableHeader backgroundColor="var(--secondaryGray)">
        {headerGroups.map((headerGroup) => (
          <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <ReactTableHeaderColumn
                {...column.getHeaderProps(column.id !== 'actions' && column.getSortByToggleProps({ title: undefined }))}
                customWidth={column.width}
              >
                <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                  {column.id === 'actions' ? <></> : column.render('Header')}

                  {column.isSorted && (
                    <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                      <TotalIcon />
                    </SortIconWrapper>
                  )}
                </HeaderCellWrapper>
              </ReactTableHeaderColumn>
            ))}
          </ReactTableHeaderRow>
        ))}
      </ReactTableHeader>

      {rows.length ? (
        <ReactTableBody {...getTableBodyProps()}>
          {Object.keys(selectedRowIds).length > 0 && (
            <MultiselectRibbon
              label={`${allUsageEventsSelected ? rowsCount : Object.keys(selectedRowIds).length} selected rows:`}
              actions={[
                {
                  role: 'destructive',
                  label: 'Delete',
                  onClick: handleBulkDeleteUsageEvents,
                },
              ]}
              onResetSelection={() => toggleAllRowsSelected(false)}
            />
          )}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <ReactTableRow {...row.getRowProps()} noRowHover>
                {row.cells.map((cell) => (
                  <ReactTableCell {...cell.getCellProps()} customWidth={cell.column.width}>
                    {cell.render('Cell')}
                  </ReactTableCell>
                ))}
              </ReactTableRow>
            );
          })}
        </ReactTableBody>
      ) : (
        <EmptyTableContent>No Usage Events Found</EmptyTableContent>
      )}
    </div>
  );
};
