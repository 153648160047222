import { useContext } from 'react';
import { useFormikContext } from 'formik';

import { FormikCustomSelector } from 'components/Controls';
import { createCustomer } from 'api';
import { AppContext } from 'AppContext';
import { getCustomersFromSearch } from 'shared/TransactionContent';

import { DEFAULT_INVOICE_LANGUAGE } from '../consts';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';

export const InvoicingScheduleCustomerSelector = (props = {}) => {
  const { orgId } = useContext(AppContext);

  const { invoicingScheduleFormValues, setCustomer, setIncludedTransactions, preselectedCustomer } = useContext(
    InvoicingScheduleContext,
  );

  const { setFieldValue } = useFormikContext();

  return (
    <FormikCustomSelector
      name="customer_id"
      key={invoicingScheduleFormValues?.customer_id}
      containerWidth="100%"
      label="Customer in Subscript"
      placeholder="Select customer..."
      value={
        invoicingScheduleFormValues?.customer_id
          ? {
              label: `${invoicingScheduleFormValues?.customer_name} / ${invoicingScheduleFormValues?.customer_id}`,
              value: invoicingScheduleFormValues?.customer_id,
            }
          : null
      }
      handleChange={(option) => {
        if (option) {
          setFieldValue('customer_id', option.value);
          setFieldValue('customer_name', option.label);
          setFieldValue('language', option.metadata?.invoice_language ?? DEFAULT_INVOICE_LANGUAGE);
          setCustomer({ id: option.value, name: option.label });
        } else {
          setFieldValue('customer_id', null);
          setFieldValue('customer_name', null);
          setFieldValue('language', DEFAULT_INVOICE_LANGUAGE);
          setCustomer(null);
        }

        setFieldValue('invoices', []);
        setFieldValue('invoicing_frequency', null);
        setIncludedTransactions([]);
      }}
      onCreateOption={async (newCustomerName) => {
        const newCustomer = await createCustomer({
          orgId,
          customerName: newCustomerName,
        });
        setFieldValue('customer_id', newCustomer.id);
        setFieldValue('customer_name', newCustomer.name);

        setFieldValue('invoices', []);
        setFieldValue('invoicing_frequency', null);
        setIncludedTransactions([]);
      }}
      loadOptions={(searchQuery, prevOptions, additional) =>
        getCustomersFromSearch({
          searchQuery,
          orgId,
          prevOptions,
          additional,
        })
      }
      isDisabled={preselectedCustomer}
      isPaginateable
      isClearable
      creatable
      showErrors={false}
      {...props}
    />
  );
};
