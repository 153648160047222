import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { useInvoicesAPI } from 'api/billing';
import { Modal, ModalCloseIcon, ModalContainer, ModalFooter, ModalHeader, ModalTitle } from 'components/Modal';
import { AlertCircleIcon, ChevronRight, TrashIcon } from 'components/Icons';
import { Button } from 'components/Buttons';
import { Row } from 'components/Core';
import { COLORS } from 'consts/colors';
import { BillingContext } from '../BillingContext';
import { TableRow } from './TableRow';
import { humanize, pluralize } from 'utils/stringUtils';

const ModalTitleSubtitle = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 7px;
  margin-bottom: 16px;
`;

const GlWarningWrapper = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: var(--newRed10);
  width: 100%;
  margin-bottom: 28px;
`;

const GlWarningText = styled.div`
  color: var(--primaryBlack);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 1px;
`;

const TableHeader = styled.div`
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 16px;
`;

const TableHeaderText = styled.div`
  color: var(--primaryBlack);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  opacity: 0.5;
`;

const Table = styled.div`
  overflow: hidden;
  border-radius: 16px;
`;

const Badge = styled.div`
  display: inline-flex;
  height: 28px;
  min-width: 28px;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  background: #fff;
  color: var(--primaryBlack75);
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  margin-left: 8px;
`;

const PagingButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PageButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--accentGraySecond);
  background: #fff;
  box-shadow: 4px 4px 24px 0px var(--primaryBlack4);
  cursor: pointer;
`;

export const BulkDeleteInvoicesModal = ({ selectedInvoices, bulkFilters, setShowModal }) => {
  const { orgId } = useContext(AppContext);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [invoices, setInvoices] = useState(null);
  const [localInvoices, setLocalInvoices] = useState([]);
  const [externalInvoices, setExternalInvoices] = useState([]);

  const { bulkDeleteInvoices, bulkDetachInvoices } = useContext(BillingContext);

  const {
    data: { data: fetchedInvoices },
    operations: { refetch: fetchAllInvoices },
  } = useInvoicesAPI({
    orgId,
    ...bulkFilters,
    includeExternalUrls: true,
    autoFetch: false,
    page: 1,
    limit: 1000, // Same as BULK_SELECT_ALL_LIMIT
  });

  useEffect(() => {
    if (selectedInvoices) {
      setInvoices(selectedInvoices);
    } else {
      fetchAllInvoices();
    }
  }, [selectedInvoices, setInvoices, fetchAllInvoices]);

  useEffect(() => {
    if (!selectedInvoices && fetchedInvoices?.length) {
      setInvoices(fetchedInvoices);
    }
  }, [selectedInvoices, fetchedInvoices]);

  useEffect(() => {
    if (invoices) {
      setPageCount(Math.ceil(invoices.length / 5));
      setPage(1);
      setLocalInvoices(invoices.filter((i) => !i.provider_object_id));
      setExternalInvoices(invoices.filter((i) => i.provider_object_id));
    }
  }, [invoices]);

  const handlePrevPageClick = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const handleNextPageClick = () => {
    if (page + 1 <= pageCount) {
      setPage(page + 1);
    }
  };

  const handleConfirm = async () => {
    if (externalInvoices.length) {
      await bulkDetachInvoices({
        data: externalInvoices.map((i) => ({ id: i.id })),
      });
    }
    if (localInvoices.length) {
      await bulkDeleteInvoices({
        data: localInvoices.map((i) => i.id),
      });
    }
    setShowModal(false);
  };

  if (invoices === null) {
    return null;
  }

  return (
    <ModalContainer data-cy="bulk-send-invoices-modal">
      <Modal width="940px" height={externalInvoices.length ? '550px' : '475px'} overflow="visible">
        <ModalHeader>
          <ModalCloseIcon onClose={() => setShowModal(false)} width={36} height={36} />
          <ModalTitle bold style={{ width: '100%', marginRight: '36px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>Delete Invoices</span> <Badge>{invoices.length}</Badge>
            </div>
            <ModalTitleSubtitle>
              Please confirm that you want to{' '}
              {localInvoices.length ? `delete ${pluralize(localInvoices.length, 'invoice')}` : ''}
              {localInvoices.length && externalInvoices.length ? ' and ' : ''}
              {externalInvoices.length ? `detach ${pluralize(externalInvoices.length, 'invoice')}` : ''}. This cannot be
              un-done.
            </ModalTitleSubtitle>
            {externalInvoices.length > 0 ? (
              <GlWarningWrapper>
                <AlertCircleIcon width={20} height={20} />
                <div>
                  <GlWarningText>
                    Your {humanize(externalInvoices[0].provider_name)}{' '}
                    <b style={{ color: 'var(--tertiaryRed)' }}>will not allow Subscript to delete</b> the invoices below
                    automatically, but you can do it manually in your {humanize(externalInvoices[0].provider_name)}
                  </GlWarningText>
                </div>
              </GlWarningWrapper>
            ) : (
              ''
            )}
          </ModalTitle>
        </ModalHeader>
        <ModalFooter height="100%" padding="12px 36px" style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ flexGrow: 1, overflowY: 'auto' }}>
            <TableHeader>
              <TableHeaderText>Invoices: {invoices.length} rows</TableHeaderText>
              <PagingButtonsWrapper>
                <PageButton onClick={handlePrevPageClick}>
                  <ChevronRight width={16} height={16} style={{ transform: 'rotate(180deg)' }} />
                </PageButton>
                <TableHeaderText>
                  {page} of {pageCount} page
                </TableHeaderText>
                <PageButton onClick={handleNextPageClick}>
                  <ChevronRight width={16} height={16} />
                </PageButton>
              </PagingButtonsWrapper>
            </TableHeader>
            <Table>
              {invoices.map((i, index) => (
                <div key={i.id}>{index < page * 5 && index >= (page - 1) * 5 ? <TableRow invoice={i} /> : ''}</div>
              ))}
            </Table>
          </div>
        </ModalFooter>
        <ModalFooter padding="12px 36px">
          <Row horizontal="flex-end">
            <Button
              color={COLORS.GREY}
              border
              filled
              style={{ marginRight: '8px' }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              color={COLORS.RED}
              border
              filled
              active
              onClick={handleConfirm}
              data-cy="invoices-table__confirm-bulk-delete-button"
            >
              Detach & Delete <TrashIcon fill={'white'} height={16} width={16} style={{ marginLeft: '8px' }} />
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};
