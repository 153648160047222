import React, { useState, useContext } from 'react';
import { isNil } from 'lodash';
import { Divider, Spacer } from 'components/Core';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
} from 'components/Modal';
import { AppContext } from 'AppContext';
import { TransactionPreviewTable } from './TransactionsPreviewTable';
import { SubscriptionsPreviewTable } from './SubscriptionsPreviewTable';
import { useContractsAPI } from 'api/contracts';
import { calculateTotalAmount } from 'models/transaction';
import { RECOGNITION_TYPES } from 'consts/global';
import styled from 'styled-components';
import { PricingPlansPreviewTable } from './PricingPlansPreviewTable';

const TableDivider = styled(Divider)`
  width: 100%;
  margin: 28px 0;
  background-color: var(--accentGray);
`;

export const ContractAIPreviewModal = ({ data, onClose, onSubmit }) => {
  const [transactionsToBulkUpload, setTransactionsToBulkUpload] = useState([]);
  const [subscriptionsToBulkUpload, setSubscriptionsToBulkUpload] = useState([]);
  const [pricingPlansToBulkUpload, setPricingPlansToBulkUpload] = useState([]);

  const { orgId } = useContext(AppContext);

  const {
    operations: { generateFromContract },
  } = useContractsAPI({
    orgId,
    autoFetch: false,
  });

  const handleConfirm = async () => {
    const result = await generateFromContract.mutateAsync({
      data: {
        transactions: transactionsToBulkUpload,
        subscriptions: subscriptionsToBulkUpload,
        pricingPlans: pricingPlansToBulkUpload,
      },
      id: data.contract_id,
    });
    onSubmit?.(result);
  };

  const convertInterpretedValuesToTransactionsTableData = ({ data }) => {
    return (
      data?.lineItems?.map((lineItem) => {
        let amount = lineItem.amount;
        const isLinearRecurring = lineItem.recognition
          ? lineItem.recognition === RECOGNITION_TYPES.linear
          : lineItem.recurring;
        if (
          isNil(amount) &&
          !isNil(lineItem.recurring_amount) &&
          isLinearRecurring &&
          lineItem.start_date &&
          lineItem.end_date
        ) {
          amount = calculateTotalAmount({
            startDate: lineItem.start_date,
            endDate: lineItem.end_date,
            recurringAmount: lineItem.recurring_amount,
            includeEndMonth: lineItem.include_end_month,
            useAutoIncrease: lineItem.use_auto_increase,
            autoIncreaseIncrements: lineItem.auto_increase_increments,
          });
        }

        const result = {
          organization_id: data.organization_id ?? orgId,
          contract_id: data.contract_id,

          interpreter_product_name: lineItem.product_name ?? lineItem.name,
          interpreter_customer_name: lineItem.customer_name,
          customer_confidence: lineItem.guessed_customer_confidence,
          customer_id: lineItem.guessed_customer_id,
          customer_name: lineItem.guessed_customer_name,
          product_confidence: lineItem.guessed_product_confidence,
          product_id: lineItem.guessed_product_id,
          product_name: lineItem.guessed_product_name,

          name: lineItem.name,
          amount,
          recurring_amount: lineItem.recurring_amount,
          seats: lineItem.seats,
          recognition: lineItem.recognition ?? (lineItem.recurring ? 'linear' : 'immediate'),

          metadata: { ...data?.metadata, ...lineItem?.metadata },
          confirmed: true,

          date: lineItem.date,
          start_date: lineItem.start_date,
          end_date: lineItem.end_date,
        };

        return result;
      }) ?? []
    );
  };

  const convertInterpretedValuesToSubscriptionsTableData = ({ data }) => {
    return (
      data?.subscriptions?.map((subscription) => {
        const result = {
          organization_id: data.organization_id ?? orgId,

          customer_id: subscription.guessed_customer_id,
          customer_confidence: subscription.guessed_customer_confidence,
          customer_name: subscription.guessed_customer_name,
          interpreter_customer_name: subscription.customer_name,

          pricing_plan_id: subscription.guessed_pricing_plan_id,
          pricing_plan_confidence: subscription.guessed_pricing_plan_confidence,
          pricing_plan_name: subscription.guessed_pricing_plan_name,
          interpreter_pricing_plan_name: subscription.pricing_plan_name,

          start_date: subscription.start_date,
          end_date: subscription.end_date,

          trial_units: subscription.trial_units,

          discount_id: subscription.guessed_discount_id,
          discount: subscription.guessed_discount_id
            ? {
                id: subscription.guessed_discount_id,
                code: subscription.guessed_discount_code,
                type: subscription.guessed_discount_type,
                value: subscription.guessed_discount_value,
              }
            : null,
        };

        return result;
      }) ?? []
    );
  };

  const convertInterpretedValuesToPricingPlansTableData = ({ data }) => {
    return (
      data?.pricingPlans?.map((pricingPlan) => ({
        organization_id: data.organization_id ?? orgId,

        product_id: +pricingPlan.guessed_product_id,
        interpreter_product_name: pricingPlan.product_name,
        product_confidence: pricingPlan.guessed_product_confidence,
        product_name: pricingPlan.guessed_product_name,

        name: pricingPlan.name,
        description: pricingPlan.description,
        event_name: pricingPlan.event_name,
        currency: pricingPlan.currency,
        invoicing_frequency: pricingPlan.invoicing_frequency,
        use_single_rate_tier: pricingPlan.use_single_rate_tier,
        bill_in_advance: pricingPlan.bill_in_advance,
        tiers: pricingPlan.tiers,
      })) ?? []
    );
  };

  const transactionsTableData = convertInterpretedValuesToTransactionsTableData({ data });
  const subscriptionsTableData = convertInterpretedValuesToSubscriptionsTableData({ data });
  const pricingPlansTableData = convertInterpretedValuesToPricingPlansTableData({ data });

  return (
    <ModalContainer data-cy="contract-files-modal">
      <Modal width="80vw" overflow="visible" padding="0px">
        <ModalHeader addPadding>
          <ModalCloseIcon onClose={onClose} />
        </ModalHeader>

        <ModalBody centered>
          <TransactionPreviewTable data={transactionsTableData} setDataHook={setTransactionsToBulkUpload} />
          <TableDivider />
          <SubscriptionsPreviewTable data={subscriptionsTableData} setDataHook={setSubscriptionsToBulkUpload} />
          <TableDivider />
          <PricingPlansPreviewTable data={pricingPlansTableData} setDataHook={setPricingPlansToBulkUpload} />
        </ModalBody>

        <ModalFooter flexEnd>
          <ModalButton default onClick={onClose}>
            Cancel
          </ModalButton>
          <Spacer width="14px" />
          <ModalButton
            primary
            disabled={!transactionsToBulkUpload?.length && !subscriptionsToBulkUpload?.length}
            onClick={handleConfirm}
          >
            Confirm
          </ModalButton>
        </ModalFooter>
      </Modal>
    </ModalContainer>
  );
};
