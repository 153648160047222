import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';

import { AppContext } from 'AppContext';
import { FormikCustomSelector } from 'components/Controls';
import { Centerer, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { HelpCircleIcon } from 'components/Icons';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';
import { getTillCancelTransactionAmountInMonth } from 'models/transaction';

import { TransactionAutoIncreaseModal } from './TransactionAutoIncreaseModal';
import { autoIncreaseIncrementsHandleChange, autoIncreaseOptionHandleChange } from '../TransactionBody/utils';
import { DEFAULT_AUTO_INCREASE_MONTHS } from '../consts';

const StyledHelpCircleIcon = styled(HelpCircleIcon)`
  margin-left: 4px !important;

  & > g {
    opacity: 1;
  }

  & > path {
    fill: var(--primaryBlack);
  }
`;

const EditButton = styled(Centerer)`
  width: fit-content;
  border-radius: 4px;
  padding: 2px 4px;
  background: var(--primaryBlue10);
  color: var(--primaryBlue);
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;

  position: absolute;
  bottom: 8px;
  right: 38px;

  &:hover {
    background: ${({ disabled }) => (disabled ? 'var(--primaryBlue10)' : 'var(--primaryBlue20)')};
  }
`;

const SelectorContainer = styled.div`
  position: relative;
`;

const InformationWrapper = styled(FlexerColumn)`
  font-size: 12px;
`;

const InformationSection = styled(FlexerColumn)`
  border-top: 1px solid #ffffff1a;
  padding: 8px;

  &:first-child {
    border-top: none;
  }
`;

const DarkText = styled.div`
  display: inline-block;
  opacity: 0.5;
`;

const InformationEditButton = styled(Flexer)`
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.15);
  margin-top: 8px;
  border-radius: 4px;
  color: white;
  font-size: 9px;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const TransactionAutoIncreaseInput = ({ values = {}, currency }) => {
  const { orgId } = useContext(AppContext);

  const { setFieldValue } = useFormikContext();

  const [openIncrementsModal, setOpenIncrementsModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  const isPassedEndDate = useMemo(() => {
    return values.end_date && dayjs.utc().isAfter(dayjs.utc(values.end_date), 'day');
  }, [values.end_date]);

  const currentAndNextPeriodInfo = useMemo(() => {
    if (!values.use_auto_increase || !values.auto_increase_increments?.length) return null;

    const periodInMonths = values.auto_increase_months || DEFAULT_AUTO_INCREASE_MONTHS;

    const lastIncrementIndex = values.auto_increase_increments.length - 1;

    const currentDateObject = isPassedEndDate
      ? dayjs.utc(values.end_date).startOf('month')
      : dayjs.utc().startOf('month');

    const currentPeriodIndex = values.auto_increase_increments.findIndex(
      (increment) =>
        currentDateObject.isSameOrAfter(dayjs.utc(increment.start_date), 'month') &&
        (!increment.end_date || currentDateObject.isSameOrBefore(dayjs.utc(increment.end_date), 'month')),
    );

    const currentMRR = getTillCancelTransactionAmountInMonth({
      incrementPercentagesByDate: values.incrementPercentagesByDate,
      formattedDate: currentDateObject.format('YYYY-MM'),
      monthlyAmount: values.recurring_amount,
    });
    const currentARR = currentMRR * 12;

    if (isPassedEndDate)
      return {
        currentMRR,
        currentARR,
      };

    const nextDateObject =
      currentPeriodIndex === lastIncrementIndex || currentPeriodIndex === -1
        ? currentDateObject.add(periodInMonths, 'month')
        : dayjs.utc(values.auto_increase_increments[currentPeriodIndex + 1].start_date);

    const adjustedIncrements = values.incrementPercentagesByDate?.adjustedIncrements ?? [];

    const lastAdjustedIncrement = adjustedIncrements[adjustedIncrements.length - 1];
    const nexPeriodIncrement = adjustedIncrements.find(
      (increment) =>
        nextDateObject.isSameOrAfter(dayjs.utc(increment.start_date), 'month') &&
        (!increment.end_date || nextDateObject.isSameOrBefore(dayjs.utc(increment.end_date), 'month')),
    ) || {
      // if the above returns undefined, this means that the next period is in the next increment
      ...lastAdjustedIncrement,
      start_date: lastAdjustedIncrement?.start_date
        ? dayjs.utc(lastAdjustedIncrement.start_date).add(periodInMonths, 'month')
        : null,
      end_date: null,
    };

    const nextMRR = currentMRR * (1 + (nexPeriodIncrement?.percentage ?? 0));
    const nextARR = nextMRR * 12;

    return {
      currentMRR,
      currentARR,
      nextMRR,
      nextARR,
      nextPeriodPercentageIncrease: nexPeriodIncrement?.percentage ?? 0,
      nextPeriodStartDate: dayjs.utc(nexPeriodIncrement?.start_date).format('MMM YYYY'),
    };
  }, [
    isPassedEndDate,
    values.auto_increase_increments,
    values.auto_increase_months,
    values.end_date,
    values.incrementPercentagesByDate,
    values.recurring_amount,
    values.use_auto_increase,
  ]);

  return (
    <>
      <SelectorContainer>
        <FormikCustomSelector
          label={
            <Flexer alignItems="center">
              <span>Auto Increase [Beta]</span>

              <StyledHelpCircleIcon />
            </Flexer>
          }
          options={options}
          boldValue
          name="use_auto_increase"
          value={options.find((option) => option.value === !!values.use_auto_increase)}
          handleChange={async (option) =>
            await autoIncreaseOptionHandleChange(setFieldValue, values)({ value: !!option?.value, orgId })
          }
          tooltipInputDisplay={
            <InformationWrapper>
              <InformationSection justifyContent="center" alignItems="center">
                Subscript will auto-increase the recurring amount every{' '}
                {values.auto_increase_months || DEFAULT_AUTO_INCREASE_MONTHS} months
              </InformationSection>
              {!!currentAndNextPeriodInfo && (
                <>
                  <InformationSection>
                    <FlexBetweenContainer width="100%" gap="8px">
                      <DarkText>Current monthly recurring</DarkText>
                      <DarkText>
                        {numberFormatter({
                          type: NUMBER_FORMATS.CURRENCY,
                          rawValue: currentAndNextPeriodInfo.currentMRR,
                          currency,
                          decimalPlaces: 0,
                        })}
                      </DarkText>
                    </FlexBetweenContainer>
                    <FlexBetweenContainer width="100%" gap="8px">
                      <DarkText>Current ARR</DarkText>
                      <DarkText>
                        {numberFormatter({
                          type: NUMBER_FORMATS.CURRENCY,
                          rawValue: currentAndNextPeriodInfo.currentARR,
                          currency,
                          decimalPlaces: 0,
                        })}
                      </DarkText>
                    </FlexBetweenContainer>
                  </InformationSection>

                  {!isPassedEndDate && (
                    <InformationSection>
                      <FlexBetweenContainer width="100%" gap="8px">
                        <b>Auto-increase for the next period</b>
                        <b>
                          {numberFormatter({
                            type: NUMBER_FORMATS.PERCENT,
                            rawValue: currentAndNextPeriodInfo.nextPeriodPercentageIncrease,
                            decimalPlaces: 2,
                          })}
                        </b>
                      </FlexBetweenContainer>
                    </InformationSection>
                  )}

                  {!isPassedEndDate && (
                    <InformationSection>
                      <FlexBetweenContainer width="100%" gap="8px">
                        <DarkText>New monthly rec. from {currentAndNextPeriodInfo.nextPeriodStartDate}</DarkText>
                        <DarkText>
                          {numberFormatter({
                            type: NUMBER_FORMATS.CURRENCY,
                            rawValue: currentAndNextPeriodInfo.nextMRR,
                            currency,
                            decimalPlaces: 0,
                          })}
                        </DarkText>
                      </FlexBetweenContainer>
                      <FlexBetweenContainer width="100%" gap="8px">
                        <DarkText>New ARR</DarkText>
                        <DarkText>
                          {numberFormatter({
                            type: NUMBER_FORMATS.CURRENCY,
                            rawValue: currentAndNextPeriodInfo.nextARR,
                            currency,
                            decimalPlaces: 0,
                          })}
                        </DarkText>
                      </FlexBetweenContainer>
                    </InformationSection>
                  )}

                  <InformationEditButton onClick={() => setOpenIncrementsModal(true)}>
                    Manage % for all periods
                  </InformationEditButton>
                </>
              )}
            </InformationWrapper>
          }
          tooltipProps={{
            hidePortal: isMenuOpen || openIncrementsModal,
          }}
          toolTipWidth={300}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
        />

        {values.use_auto_increase && (
          <EditButton data-cy="transaction__auto-increase--edit-button" onClick={() => setOpenIncrementsModal(true)}>
            Edit %
          </EditButton>
        )}
      </SelectorContainer>

      {openIncrementsModal && (
        <TransactionAutoIncreaseModal
          increments={values.auto_increase_increments}
          transaction={values}
          onSave={autoIncreaseIncrementsHandleChange(setFieldValue, values)}
          onClose={() => setOpenIncrementsModal(false)}
        />
      )}
    </>
  );
};
