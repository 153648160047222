import { FlexerColumn, FlexerRow } from 'components/Core';
import { USAGE_BASED_BILLING_SCHEMES, USAGE_BASED_BILLING_SCHEMES_LABELS } from './consts';
import { isTieredPlan } from './utils';
import { CountTag, StyledLink, SubText } from './styles';
import { NUMBER_FORMATS } from 'consts/global';

export const columnGenerator = {
  billingScheme: ({ numberFormatter, openEditPricingPlanModal }) => ({
    Header: 'Billing Scheme',
    accessor: 'tiers',
    id: 'tiers',
    width: 180,
    Cell: ({ row }) => {
      const tiers = row.original.tiers ?? [];
      const showTieredDisplay =
        tiers.length === 2
          ? !(
              tiers[0].billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID &&
              tiers[1].billing_scheme === USAGE_BASED_BILLING_SCHEMES.PER_UNIT
            )
          : isTieredPlan({ tiers });
      const perUnitTier = tiers.find(({ billing_scheme }) => billing_scheme === USAGE_BASED_BILLING_SCHEMES.PER_UNIT);
      return (
        <>
          {showTieredDisplay ? (
            <FlexerRow gap="10px" alignItems="center">
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                Tiered
              </span>
              <CountTag>{row.original.tiers.length}</CountTag>
              <StyledLink
                onClick={() => {
                  openEditPricingPlanModal(row);
                }}
              >
                See more
              </StyledLink>
            </FlexerRow>
          ) : (
            perUnitTier && (
              <FlexerColumn gap="5px">
                <span
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {`${USAGE_BASED_BILLING_SCHEMES_LABELS[perUnitTier.billing_scheme]} ${numberFormatter({
                    type: NUMBER_FORMATS.CURRENCY,
                    rawValue: perUnitTier.amount,
                    decimalPlaces: 2,
                    overrideCurrency: row.original.currency,
                  })}`}
                </span>

                {row.original.tiers[0].billing_scheme === USAGE_BASED_BILLING_SCHEMES.PREPAID && (
                  <SubText>
                    Minimum amount:{' '}
                    {numberFormatter({
                      type: NUMBER_FORMATS.CURRENCY,
                      rawValue: row.original.tiers[0].amount,
                      decimalPlaces: 2,
                      overrideCurrency: row.original.currency,
                    })}
                  </SubText>
                )}
              </FlexerColumn>
            )
          )}
        </>
      );
    },
    disableSortBy: true,
  }),
};
