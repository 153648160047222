import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { ExternalLinkFullIcon } from 'components/Icons';
import { Row } from 'components/Core';
import { ReactComponent as AlertCircleFilledIcon } from 'images/alert-circle-filled.svg';
import { INVOICE_STATUS_TAG_TO_LABEL, InvoiceStatusTag } from '../InvoicingTables/columns';

const InvoiceWrapper = styled.div`
  background: #fff;
  display: flex;
  justifycontent: space-between;
  padding: 12px 0;
  border-bottom: 1px solid var(--accentGraySecond);
  border-left: 1px solid var(--accentGraySecond);
  border-right: 1px solid var(--accentGraySecond);
`;

const ExternalLinkIconWrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--primaryBlue10);
  margin-left: 8px;
`;

const TableCell = styled.div`
  flex-basis: 25%;
  margin-left: ${({ marginLeft }) => (marginLeft ? '12px' : '0')};
  cursor: ${({ pointerCursor }) => (pointerCursor ? 'pointer' : 'initial')};
  color: ${({ blue }) => (blue ? 'var(--primaryBlue)' : 'initial')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'initial')};
  ${({ row }) =>
    row &&
    `
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
  font-size: 12px;
`;

export const TableRow = ({ invoice }) => {
  const statusLabel = INVOICE_STATUS_TAG_TO_LABEL[invoice.invoice_status] ?? invoice.invoice_status;

  const getFormattedDate = () => {
    return dayjs.utc(invoice.date).format('MMM DD, YYYY');
  };

  return (
    <>
      <InvoiceWrapper
        style={{
          background: invoice.provider_object_id ? 'var(--newRed5)' : '#fff',
        }}
      >
        <TableCell
          marginLeft
          pointerCursor={invoice.external_url}
          blue={invoice.external_url}
          bold
          onClick={() => invoice.external_url && window.open(invoice.external_url, '_blank')}
        >
          <Row horizontal="initial">
            {invoice.invoice_number ? invoice.invoice_number : 'No invoice number'}
            {invoice.external_url ? (
              <ExternalLinkIconWrapper>
                <ExternalLinkFullIcon size={12} />
              </ExternalLinkIconWrapper>
            ) : null}
          </Row>
        </TableCell>
        <TableCell>{getFormattedDate()}</TableCell>
        <TableCell>
          <InvoiceStatusTag style={{ display: 'initial' }} status={invoice.invoice_status}>
            {statusLabel}
          </InvoiceStatusTag>
        </TableCell>
        <TableCell row>
          <i>
            <b>Will be {invoice.provider_object_id ? 'detached' : 'deleted'}</b> in Subscript
          </i>
          {invoice.provider_object_id ? (
            <AlertCircleFilledIcon style={{ marginLeft: '4px' }} width={16} height={16} />
          ) : null}
        </TableCell>
      </InvoiceWrapper>
    </>
  );
};
