import dayjs from 'dayjs';

export const calculateRecognizePercent = (currentAmount, transactionAmount) => {
  return ((currentAmount / transactionAmount) * 100).toFixed();
};

export const calculateRecognizeAmount = (currentPercent, transactionAmount) => {
  return Math.round(transactionAmount * currentPercent) / 100;
};

export const sortRecognitionEventsByDate = (a, b) => {
  if (a.date < b.date) return -1;
  else if (b.date < a.date) return 1;
  else return 0;
};

export const mergeRecognitionEvents = ({ existingEvents, externalUpdateEvents }) => {
  const result = [...(existingEvents ?? [])];

  // Find if there is a spread with a matching external_id and if there is, replace it.
  // If not, then add new spread to array.
  for (const externalUpdateEvent of externalUpdateEvents) {
    let found = false;
    for (let i = 0; i < result.length; i++) {
      const existingEvent = result[i];
      if (
        externalUpdateEvent.external_id === existingEvent.external_id &&
        externalUpdateEvent.spread_id === existingEvent.spread_id
      ) {
        // if the external_id and spread_id match, then merge the spreads
        result[i] = externalUpdateEvent;
        found = true;
        break;
      }
    }

    if (!found) {
      // if we reach the end of the array and didn't update an existing spread, then we add the new spread to the array
      result.push(externalUpdateEvent);
    }
  }

  return result.map((event) => ({
    ...event,
    date: dayjs.utc(event.date).toDate(),
  })); // convert all date fields to js Date for DatePicker;
};
