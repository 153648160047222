import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useExpanded, useGlobalFilter, useSortBy, useTable } from 'react-table';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { INTEGRATION_TYPES } from 'consts/integrations';
import { useInvoicePdfAPI, useInvoicesAPI } from 'api/billing';
import { useToasts } from 'utils/hooks';
import { downloadBlobFile } from 'utils/export';
import { ReactComponent as SortIcon } from 'images/sort-descending.svg';
import { ReactComponent as Link2Icon } from 'images/link2.svg';
import { ReactComponent as Trash2Icon } from 'images/trash.svg';
import { Button } from 'components/Buttons';
import { SizedLoader } from 'components/Loaders';
import { Centerer, Row, Spacer } from 'components/Core';
import { Popover, PopoverActions, PopoverButton, PopoverPrompt } from 'components/Portal';
import {
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  ReactTableRow,
  ReactTableCell,
  EmptyTableContent,
  EmptyTableContentAction,
  customGlobalFilter,
  HeaderCellWrapper,
  SortIconWrapper,
} from 'components/Table';
import { DIRECTIONS, TooltipContainer } from 'components/Tooltip';
import { MultiselectRibbon } from 'components/Blocks';
import { AllRowsSelectorDropdown } from 'components/Table/AllRowsSelectorDropdown';
import { BulkSendInvoicesModal } from '../BulkSendInvoicesModal';
import { BulkDeleteInvoicesModal } from '../BulkDeleteInvoicesModal';
import { BILLING_PAGES_ROUTES, INVOICE_STATUSES, SERVICE_WITHOUT_INVOICE_SEND_EMAIL } from '../consts';
import { getAutoChargeJobRunsAt, isInvoiceOverdue } from '../utils';
import { MarkAsPaidPopoverBody } from '../InvoicingScheduleModal/InvoicingScheduleTabsPanel/InvoiceActions/InvoiceActions';
import { BillingContext } from '../BillingContext';
import { HIDDEN_DEFAULT_COLUMNS } from './consts';
import { AVAILABLE_STATUSES_FOR_BULK_ACTIONS, generateColumns } from './columns';
import { BulkPdfDownloadModal } from './BulkPdfDownloadModal';
import { BULK_INVOICE_ACTIONS, useBulkInvoiceActionModal } from './useBulkInvoiceActionModal';

const LinkIcon = styled(Link2Icon)`
  margin-left: 16px;
  cursor: pointer;
`;

const TrashIcon = styled(Trash2Icon)`
  width: 16px;
  height: 16px;
  path {
    fill: #ffffff;
  }
`;

const InvoiceTableRow = styled(ReactTableRow)`
  position: relative;

  ${LinkIcon} {
    display: none;
  }

  ${({ overdueInvoiceWithReminders }) =>
    overdueInvoiceWithReminders &&
    `
    padding-bottom: 52px;
    height: auto;
  `}

  &:hover {
    ${LinkIcon} {
      display: flex;
    }
  }
`;

const TableWrapper = styled.div`
  position: relative;
  height: 75vh;
  overflow: scroll;
`;

const BulkCreateButton = styled(Button)`
  width: fit-content;
  color: white;
  background: var(--primaryGreen);
`;

const BulkActionButton = styled(Button)`
  color: white;
  background: transparent;
  border: 1px solid var(--dark10);
`;

const SelectAllCheckbox = ({
  dataForTable,
  allSelectableInvoicesCount,
  selectAllLimit,
  allInvoicesSelected,
  setAllInvoicesSelected,
  onAllInvoicesSelected,
  invoicesSelected,
  setInvoicesSelected,
  fixedSelectAllCheckbox = false,
  selectAllCheckboxXOffset,
  useOnePageSelectAllCheckbox = false,
}) => {
  const allPageInvoices = useMemo(() => dataForTable?.flatMap(({ subRows }) => subRows), [dataForTable]);

  const allPageInvoicesSelected = useMemo(
    () =>
      invoicesSelected.length > 0 &&
      allPageInvoices.every(
        ({ id, invoice_status }) =>
          invoicesSelected.find((invoice) => invoice.id === id) ||
          !AVAILABLE_STATUSES_FOR_BULK_ACTIONS.includes(invoice_status),
      ),
    [allPageInvoices, invoicesSelected],
  );

  const allPageSelectableInvoices = useMemo(
    () => allPageInvoices.filter(({ invoice_status }) => AVAILABLE_STATUSES_FOR_BULK_ACTIONS.includes(invoice_status)),
    [allPageInvoices],
  );

  useEffect(() => {
    if (allPageSelectableInvoices.length > 0 && allInvoicesSelected) {
      setInvoicesSelected(allPageSelectableInvoices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPageSelectableInvoices]);

  const handleAllPageInvoicesSelectedChange = ({ target: { checked } }) => {
    if (!checked) {
      setInvoicesSelected([]);
    } else {
      setInvoicesSelected(allPageSelectableInvoices);
    }
  };

  const handleAllInvoicesSelectedChange = (props) => {
    handleAllPageInvoicesSelectedChange(props);
    setAllInvoicesSelected(props?.target?.checked);
    onAllInvoicesSelected(props?.target?.checked);
  };

  return (
    <AllRowsSelectorDropdown
      selectAllLimit={selectAllLimit}
      disableSelectAll={useOnePageSelectAllCheckbox}
      getToggleAllPageRowsSelectedProps={() => ({
        checked: allPageInvoicesSelected,
        indeterminate: invoicesSelected.length > 0 && !allPageInvoicesSelected,
        onChange: handleAllPageInvoicesSelectedChange,
      })}
      getToggleAllRowsSelectedProps={() => ({
        checked: allInvoicesSelected,
        indeterminate: invoicesSelected.length > 0 && !allInvoicesSelected,
        onChange: handleAllInvoicesSelectedChange,
      })}
      page={allPageSelectableInvoices}
      rows={Array.from({ length: allSelectableInvoicesCount }).map((_, index) => ({ id: index }))}
      fixedSelectAllCheckbox={fixedSelectAllCheckbox}
      selectAllCheckboxXOffset={selectAllCheckboxXOffset}
    />
  );
};

export const InvoicesTable = ({
  currency,
  dataForTable,
  invoicesCountData = [],
  bulkActionsLimit,
  setTableRows = () => {},
  onInvoiceClick,
  setAllColumnsState,
  isFetchingInvoices,
  customBulkAction,
  allRowsSelected = false,
  allRowsSelectedAcrossPages = false,
  onAllInvoicesSelected = () => {},
  updatedFields,
  initialSortBy = [],
  setSortBy,
  hiddenInvoicesColumns,
  useOnePageSelectAllCheckbox,
  setHiddenInvoicesColumns,
  listInvoicesParams,
  fixedSelectAllCheckbox,
  selectAllCheckboxXOffset,
}) => {
  const { pushError } = useToasts();
  const { integrations, dateFormat, orgId } = useContext(AppContext);
  const {
    billingDataFilter,
    invoiceStatuses,
    invoiceSyncStatusFilter,
    invoicesSearchQuery,
    metadataFilter,
    bulkMarkInvoicesAsSent,
    bulkMarkInvoicesAsPaid,
    openInvoicingScheduleModal,
    invoicesGroupBy,
  } = useContext(BillingContext);

  const {
    operations: { bulkDownloadInvoicesPdf },
  } = useInvoicePdfAPI({ orgId, autoFetch: false });

  const {
    operations: { bulkSaveInvoicesToGl },
  } = useInvoicesAPI({ orgId, autoFetch: false });

  const history = useHistory();
  const { state } = useLocation();

  const isAllInvoicesPage = state?.status === INVOICE_STATUSES.ALL;

  const invoicingService = integrations?.find((i) => i.type === INTEGRATION_TYPES.GL)?.service;
  const canSendEmail = !SERVICE_WITHOUT_INVOICE_SEND_EMAIL?.includes(invoicingService);

  const [allInvoicesSelected, setAllInvoicesSelected] = useState(allRowsSelectedAcrossPages);
  const allSelectableInvoicesCount = useMemo(
    () =>
      invoicesCountData.reduce((acc, curr) => {
        if (AVAILABLE_STATUSES_FOR_BULK_ACTIONS.includes(curr?.invoice_status)) {
          return acc + parseInt(curr?.count, 10) ?? 0;
        }
        return acc;
      }, 0),
    [invoicesCountData],
  );
  const limitedAllSelectableInvoicesCount =
    allSelectableInvoicesCount > bulkActionsLimit ? bulkActionsLimit : allSelectableInvoicesCount;

  const [invoicesSelected, setInvoicesSelected] = useState([]);
  const [invoicesForDownloadCount, setInvoicesForDownloadCount] = useState();
  const [fileResponse, setFileResponse] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bulkPaidAtDate, setBulkPaidAtDate] = useState(new Date());
  const [showBulkMarkAsPaidPopover, setShowBulkMarkAsPaidPopover] = useState(false);
  const [showBulkMarkAsSentPopover, setShowBulkMarkAsSentPopover] = useState(false);
  const columns = useMemo(() => {
    const autoChargeJobRunsAt = getAutoChargeJobRunsAt();
    return generateColumns({
      allInvoicesSelected,
      invoicesSelected,
      setInvoicesSelected,
      updatedFields,
      currency,
      onInvoiceClick,
      autoChargeJobRunsAt,
      invoicesGroupBy,
      dateFormat,
    });
  }, [allInvoicesSelected, invoicesSelected, currency, updatedFields, onInvoiceClick, invoicesGroupBy, dateFormat]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    allColumns,
    toggleAllRowsExpanded,
    state: { hiddenColumns, sortBy },
  } = useTable(
    {
      columns,
      data: dataForTable,
      getSubRows: useCallback((row) => row.subRows || [], []),
      globalFilter: customGlobalFilter,
      expandSubRows: true,
      manualSortBy: true,
      initialState: {
        sortBy: initialSortBy ?? [],
        hiddenColumns: hiddenInvoicesColumns ?? HIDDEN_DEFAULT_COLUMNS,
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
  );

  useEffect(() => {
    setTimeout(toggleAllRowsExpanded(true));
  }, [toggleAllRowsExpanded, dataForTable]);

  useEffect(() => {
    setSortBy(sortBy);
  }, [sortBy, setSortBy]);

  useEffect(() => {
    if (setHiddenInvoicesColumns) setHiddenInvoicesColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenInvoicesColumns]);

  useEffect(() => {
    if (setAllColumnsState) setAllColumnsState(allColumns);
  }, [allColumns, setAllColumnsState]);

  useEffect(() => {
    if (!allRowsSelected && !allInvoicesSelected) {
      // TODO: Change this logic so it allows to select rows from multiple pages
      setInvoicesSelected([]);
    } else if (dataForTable?.length) {
      setInvoicesSelected(dataForTable?.flatMap((row) => row?.subRows));
    }
  }, [
    billingDataFilter,
    invoiceStatuses,
    invoiceSyncStatusFilter,
    invoicesSearchQuery,
    allRowsSelected,
    allInvoicesSelected,
    metadataFilter,
    dataForTable,
  ]);

  useEffect(() => setTableRows(rows?.filter((row) => !row.canExpand)), [rows, setTableRows]);

  const handleBulkSendInvoices = () => setShowModal(true);
  const handleBulkDeleteInvoices = () => setShowDeleteModal(true);

  const {
    Modal: BulkInvoiceActionModal,
    openModal: openBulkInvoiceActionModal,
    setModalLoading: setBulkInvoiceActionModalLoading,
  } = useBulkInvoiceActionModal();

  const someInvoicesWithoutEmails = invoicesSelected.some((invoice) => !invoice?.customerInvoicingDetails?.contacts);
  const invoiceIds = invoicesSelected.map(({ id }) => id);

  const getEmptyTableText = () =>
    isAllInvoicesPage ? (
      <EmptyTableContent>
        You don't have any invoices, but you can
        <EmptyTableContentAction onClick={openInvoicingScheduleModal}>create them manually</EmptyTableContentAction>
      </EmptyTableContent>
    ) : (
      <span>
        You don't have any invoices with these types Check your
        <EmptyTableContentAction
          onClick={() =>
            history.push({
              pathname: BILLING_PAGES_ROUTES.INVOICES,
              state: { status: INVOICE_STATUSES.ALL },
            })
          }
        >
          all invoices
        </EmptyTableContentAction>
      </span>
    );

  // Bulk filters to use when allInvoicesSelected
  const bulkFilters = useMemo(() => {
    const filters = { ...listInvoicesParams };
    delete filters.page;
    delete filters.limit;
    delete filters.orderBy;
    delete filters.includeCount;
    delete filters.includeCountByStatus;
    delete filters.includeReminders;
    return filters;
  }, [listInvoicesParams]);

  const handleBulkPdfDownload = async () => {
    try {
      setFileResponse(null);
      setInvoicesForDownloadCount(invoicesSelected.length);
      setInvoicesSelected([]);
      setAllInvoicesSelected(false);
      const response = await bulkDownloadInvoicesPdf({ invoiceIds: invoicesSelected.map(({ id }) => id) });

      if (response) {
        setFileResponse(response);
        downloadBlobFile({ file: response?.data, filename: response?.name, rawName: true });
      }
    } catch (err) {
      setFileResponse(null);
      setInvoicesForDownloadCount();
      setInvoicesSelected([]);
      setAllInvoicesSelected(false);
    }
  };

  const handleBulkSaveToGl = async () => {
    try {
      openBulkInvoiceActionModal({
        invoiceIds: allInvoicesSelected ? Array.from(Array(limitedAllSelectableInvoicesCount).keys()) : invoiceIds,
        action: BULK_INVOICE_ACTIONS.SAVE_TO_GL,
        loading: true,
      });
      setInvoicesSelected([]);

      await bulkSaveInvoicesToGl.mutateAsync({
        invoiceIds: allInvoicesSelected ? null : invoiceIds,
        filters: allInvoicesSelected ? bulkFilters : null,
        filtersCount: allInvoicesSelected ? limitedAllSelectableInvoicesCount : null,
      });

      setBulkInvoiceActionModalLoading(false);
    } catch (err) {
      setBulkInvoiceActionModalLoading(false);
      pushError('Could not save invoices to GL. Please try again.');
    } finally {
      setAllInvoicesSelected(false);
      setBulkInvoiceActionModalLoading(false);
    }
  };

  const handleBulkMarkAsSent = async () => {
    try {
      openBulkInvoiceActionModal({
        invoiceIds: allInvoicesSelected ? Array.from(Array(limitedAllSelectableInvoicesCount).keys()) : invoiceIds,
        action: BULK_INVOICE_ACTIONS.MARK_AS_SENT,
        loading: true,
      });
      setInvoicesSelected([]);
      setShowBulkMarkAsSentPopover(false);

      await bulkMarkInvoicesAsSent({
        data: {
          invoiceIds: allInvoicesSelected ? null : invoiceIds,
          filters: allInvoicesSelected ? bulkFilters : null,
          filtersCount: allInvoicesSelected ? limitedAllSelectableInvoicesCount : null,
        },
      });

      setBulkInvoiceActionModalLoading(false);
    } catch (err) {
      setBulkInvoiceActionModalLoading(false);
      pushError('Could not mark invoices as sent. Please try again.');
    } finally {
      setAllInvoicesSelected(false);
      setBulkInvoiceActionModalLoading(false);
    }
  };

  const handleBulkMarkAsPaid = async () => {
    try {
      openBulkInvoiceActionModal({
        invoiceIds: allInvoicesSelected ? Array.from(Array(limitedAllSelectableInvoicesCount).keys()) : invoiceIds,
        action: BULK_INVOICE_ACTIONS.MARK_AS_PAID,
        loading: true,
      });
      setInvoicesSelected([]);
      setShowBulkMarkAsPaidPopover(false);

      await bulkMarkInvoicesAsPaid({
        data: {
          invoiceIds: allInvoicesSelected ? null : invoiceIds,
          filters: allInvoicesSelected ? bulkFilters : null,
          filtersCount: allInvoicesSelected ? limitedAllSelectableInvoicesCount : null,
          paidAt: dayjs.utc(bulkPaidAtDate).format('YYYY-MM-DD'),
        },
      });

      setBulkInvoiceActionModalLoading(false);
    } catch (err) {
      setBulkInvoiceActionModalLoading(false);
      pushError('Could not mark invoices as paid. Please try again.');
    } finally {
      setAllInvoicesSelected(false);
      setBulkInvoiceActionModalLoading(false);
    }
  };

  return (
    <>
      <TableWrapper {...getTableProps()} data-cy="invoices-table">
        <ReactTableHeader backgroundColor="var(--secondaryGray)">
          {headerGroups.map((headerGroup) => (
            <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()} noSpaceBetween>
              <Centerer>
                {(allSelectableInvoicesCount > 0 || useOnePageSelectAllCheckbox) && (
                  <SelectAllCheckbox
                    dataForTable={dataForTable}
                    allSelectableInvoicesCount={allSelectableInvoicesCount}
                    selectAllLimit={bulkActionsLimit}
                    allInvoicesSelected={allInvoicesSelected}
                    setAllInvoicesSelected={setAllInvoicesSelected}
                    onAllInvoicesSelected={onAllInvoicesSelected}
                    invoicesSelected={invoicesSelected}
                    setInvoicesSelected={setInvoicesSelected}
                    fixedSelectAllCheckbox={fixedSelectAllCheckbox}
                    selectAllCheckboxXOffset={selectAllCheckboxXOffset}
                    useOnePageSelectAllCheckbox={useOnePageSelectAllCheckbox}
                  />
                )}

                <Spacer width="16px" />

                {headerGroup.headers.map((column) => (
                  <ReactTableHeaderColumn
                    {...column.getHeaderProps(
                      column.id !== 'actions' && column.getSortByToggleProps({ title: undefined }),
                    )}
                    customWidth={column.width}
                    alignRight={column.alignRight}
                    data-cy={`invoices-table__${column?.id}-header`}
                  >
                    <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                      {column.render('Header')}

                      {column.isSorted && (
                        <SortIconWrapper
                          data-cy={`invoices-table__${column?.id}-sort-icon--${column?.isSortedDesc ? 'desc' : 'asc'}`}
                          isSortedDesc={column.isSortedDesc}
                        >
                          <SortIcon />
                        </SortIconWrapper>
                      )}
                    </HeaderCellWrapper>
                  </ReactTableHeaderColumn>
                ))}
              </Centerer>
            </ReactTableHeaderRow>
          ))}
        </ReactTableHeader>
        {isFetchingInvoices || bulkSaveInvoicesToGl?.isLoading ? (
          <Centerer height="100px">
            <SizedLoader size={40} />
          </Centerer>
        ) : rows.length ? (
          <ReactTableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <InvoiceTableRow
                  {...row.getRowProps()}
                  subRowBackgroundColor={
                    !row.canExpand &&
                    ([INVOICE_STATUSES.SAVE_FAILED, INVOICE_STATUSES.SENT_FAILED].includes(
                      row.original?.invoice_status,
                    ) ||
                      isInvoiceOverdue({ invoice: row.original })) &&
                    'var(--newRed5)'
                  }
                  rowBackgroundColor={row.original?.isOverdueGroup && 'var(--newRed5)'}
                  overdueInvoiceWithReminders={
                    row?.original?.has_unsent_reminders && row?.original?.sent_at && !row?.original?.auto_charge
                  }
                  subRow={!row.canExpand}
                  height={row.canExpand ? '68px' : undefined}
                  noRowHover
                  noSpaceBetween
                  data-cy={!row.canExpand ? 'invoices-table--invoice-row' : 'invoices-table--date-row'}
                >
                  {row.cells.map((cell) =>
                    row.canExpand ? (
                      <div {...cell.getCellProps()}>{cell.render('Cell')}</div>
                    ) : (
                      <ReactTableCell
                        {...cell.getCellProps()}
                        customWidth={cell.column.width}
                        alignRight={cell.column.alignRight}
                      >
                        {cell.render('Cell')}
                      </ReactTableCell>
                    ),
                  )}
                </InvoiceTableRow>
              );
            })}
          </ReactTableBody>
        ) : (
          <EmptyTableContent>{getEmptyTableText()}</EmptyTableContent>
        )}
        {invoicesSelected.length > 0 && (
          <MultiselectRibbon
            label={`${allInvoicesSelected ? allSelectableInvoicesCount : invoicesSelected.length} selected items:`}
            insertActionsDirectly={true}
            actions={
              customBulkAction?.fn ? (
                <Row gap="8px" style={{ marginLeft: 8 }}>
                  <BulkCreateButton
                    fontWeight="700"
                    onClick={() =>
                      customBulkAction?.fn({
                        selectedInvoices: invoicesSelected,
                        allInvoicesSelected,
                        limitedAllSelectableInvoicesCount,
                      })
                    }
                    data-cy="invoices-table__custom-action"
                  >
                    <span>{customBulkAction.name}</span>
                  </BulkCreateButton>
                </Row>
              ) : (
                <Row gap="8px" style={{ marginLeft: 8 }}>
                  {canSendEmail && (
                    <TooltipContainer
                      width={300}
                      hideArrow
                      yOffset={40}
                      direction={DIRECTIONS.TOP}
                      isVisible={allInvoicesSelected || someInvoicesWithoutEmails}
                      toolTipContent={
                        allInvoicesSelected
                          ? 'You cannot bulk send invoices when all invoices are selected'
                          : 'One or more customers do not have emails. Please send invoice manually'
                      }
                    >
                      <BulkCreateButton
                        fontWeight="700"
                        disabled={allInvoicesSelected || someInvoicesWithoutEmails}
                        onClick={handleBulkSendInvoices}
                        data-cy="invoices-table__bulk-send-invoices-button"
                      >
                        <span>Bulk Send Invoices</span>
                      </BulkCreateButton>
                    </TooltipContainer>
                  )}
                  <BulkActionButton
                    fontWeight="700"
                    onClick={() => setShowBulkMarkAsSentPopover(!showBulkMarkAsSentPopover)}
                    data-cy="invoices-table__bulk-mark-as-sent-button"
                  >
                    <span>Mark as Sent</span>
                  </BulkActionButton>
                  <BulkActionButton
                    fontWeight="700"
                    onClick={() => setShowBulkMarkAsPaidPopover(!showBulkMarkAsPaidPopover)}
                    data-cy="invoices-table__bulk-mark-as-paid-button"
                  >
                    <span>Mark as Paid</span>
                  </BulkActionButton>

                  <BulkActionButton
                    disabled={invoicesSelected?.some(({ invoice_status }) =>
                      [INVOICE_STATUSES.QUEUED_UP, INVOICE_STATUSES.PROCESSING].includes(invoice_status),
                    )}
                    fontWeight="700"
                    onClick={handleBulkSaveToGl}
                    data-cy="invoices-table__bulk-save-to-gl-button"
                  >
                    <span>Save to GL</span>
                  </BulkActionButton>

                  <TooltipContainer
                    width={300}
                    hideArrow
                    yOffset={40}
                    direction={DIRECTIONS.TOP}
                    isVisible={allInvoicesSelected}
                    toolTipContent="You cannot bulk download PDFs when all invoices are selected"
                  >
                    <BulkActionButton
                      disabled={
                        allInvoicesSelected ||
                        invoicesSelected?.some(({ invoice_status }) =>
                          [INVOICE_STATUSES.QUEUED_UP, INVOICE_STATUSES.PROCESSING].includes(invoice_status),
                        )
                      }
                      fontWeight="700"
                      onClick={handleBulkPdfDownload}
                      data-cy="invoices-table__bulk-pdf-download-button"
                    >
                      <span>Download PDFs</span>
                    </BulkActionButton>
                  </TooltipContainer>

                  <BulkActionButton onClick={handleBulkDeleteInvoices} data-cy="invoices-table__bulk-delete-button">
                    <TrashIcon />
                  </BulkActionButton>

                  {!!showBulkMarkAsPaidPopover && (
                    <Popover XOffset={350} YOffset={-150} zIndex={51} darkMode width="220px">
                      {MarkAsPaidPopoverBody({
                        paidAt: bulkPaidAtDate,
                        bulk: true,
                        setPaidAt: setBulkPaidAtDate,
                        onClose: () => setShowBulkMarkAsPaidPopover(false),
                        onSubmit: handleBulkMarkAsPaid,
                      })}
                    </Popover>
                  )}
                  {!!showBulkMarkAsSentPopover && (
                    <Popover XOffset={350} YOffset={-90} zIndex={51} darkMode width="220px">
                      <PopoverPrompt>Are you sure you want to mark these invoices as sent?</PopoverPrompt>
                      <PopoverActions>
                        <PopoverButton onClick={() => setShowBulkMarkAsSentPopover(false)}>No</PopoverButton>
                        <PopoverButton
                          data-cy="invoices-table__confirm-mark-as-sent-option"
                          onClick={handleBulkMarkAsSent}
                          primary
                        >
                          Yes
                        </PopoverButton>
                      </PopoverActions>
                    </Popover>
                  )}
                </Row>
              )
            }
            onResetSelection={() => {
              setInvoicesSelected([]);
              setShowDeleteModal(false);
            }}
          />
        )}
      </TableWrapper>

      <BulkPdfDownloadModal
        fileResponse={fileResponse}
        setFileResponse={setFileResponse}
        invoicesForDownloadCount={invoicesForDownloadCount}
        setInvoicesForDownloadCount={setInvoicesForDownloadCount}
      />

      {showModal && (
        <BulkSendInvoicesModal
          invoiceIds={invoiceIds}
          setShowModal={setShowModal}
          setInvoicesSelected={setInvoicesSelected}
        />
      )}

      {showDeleteModal && (
        <BulkDeleteInvoicesModal
          selectedInvoices={allInvoicesSelected ? null : invoicesSelected}
          bulkFilters={allInvoicesSelected ? bulkFilters : null}
          setShowModal={setShowDeleteModal}
        />
      )}

      <BulkInvoiceActionModal />
    </>
  );
};
