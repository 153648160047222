import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BinIcon } from 'images/bx-trash.svg';
import { ReactComponent as ViewDetailsIcon } from 'images/report-analytics.svg';
import { ReactComponent as EditIcon } from 'images/edit-table-icon.svg';
import { ReactComponent as UnconfirmIcon } from 'images/bx-reset.svg';
import { ReactComponent as RowActionsIcon } from 'images/row_actions.svg';
import { DuplicateIcon } from 'components/Icons';
import { usePortal } from 'components/Portal';

const StyledViewDetailsIcon = styled(ViewDetailsIcon)`
  width: 14px;
  height: 14px;
`;

const RowActionToogle = styled.div`
  height: 28px;
  cursor: pointer;
`;

const StyledRowActionsIcon = styled(RowActionsIcon)`
  path {
    fill-opacity: 0;
  }

  circle {
    fill: var(--primaryBlack50);
  }

  &:hover {
    path {
      fill-opacity: 0.05;
    }

    circle {
      fill: var(--primaryBlack);
    }
  }
`;

const ActionPopover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  border: 1px solid var(--accentGray);
  box-shadow: 8px 8px 40px var(--primaryBlack10);
  border-radius: 12px;
  padding: 0 6px;
  white-space: nowrap;
  z-index: 5;
`;

const ActionItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 12px;
  color: ${(props) => (props.remove ? 'var(--primaryRed)' : 'var(--dark7)')};
  border-bottom: 1px solid var(--accentGray);
  cursor: pointer;

  color: ${(props) => (props.disabled ? 'var(--primaryBlack10)' : undefined)};
  opacity: ${(props) => props.disabled && '0.7'};
  pointer-events: ${(props) => props.disabled && 'none'};

  &:last-child {
    border-bottom: none;
  }

  p {
    height: 26px;
    font-weight: 700;
    height: auto;
    font-size: 12px;
    line-height: 16px;
    display: block;
    margin: 0;
    text-transform: capitalize;
  }

  svg {
    height: 16px;
    margin-right: 8px;

    &:path {
      fill: ${(props) => props.remove && 'var(--primaryRed)'};
      opacity: 1;
    }
  }
`;

export const ACTION_OPTIONS = {
  edit: 'Edit',
  viewDetails: 'View Details',
  delete: 'Remove',
  unconfirm: 'Unconfirm',
  duplicate: 'Duplicate',
};

export const RowActionPopover = ({
  currentSelection,
  onDeleteClick,
  onViewDetailsClick,
  onEditClick,
  onUnconfirmClick,
  onDuplicateClick,
  dataCyPrefix,
  deleteIcon,
  deleteText,
  XOffset,
  editText,
  viewDetailsText,
  isLocked,
  isEditLocked,
  noIcons,
}) => {
  const { triggerRef, togglePortal, isPortalVisible, hidePortal, Portal } = usePortal({
    XOffset: XOffset ?? -52,
    YOffset: -5,
  });

  const onClickHandler = (action) => {
    hidePortal();
    switch (action) {
      case ACTION_OPTIONS.viewDetails:
        return onViewDetailsClick(currentSelection);
      case ACTION_OPTIONS.edit:
        return onEditClick(currentSelection);
      case ACTION_OPTIONS.delete:
        return onDeleteClick(currentSelection);
      case ACTION_OPTIONS.unconfirm:
        return onUnconfirmClick(currentSelection);
      case ACTION_OPTIONS.duplicate:
        return onDuplicateClick(currentSelection);
      default:
    }
  };

  return (
    <RowActionToogle>
      <StyledRowActionsIcon ref={triggerRef} onClick={togglePortal} data-cy={`${dataCyPrefix}__actions-button`} />
      {isPortalVisible && (
        <Portal>
          <ActionPopover data-cy={`${dataCyPrefix}__actions-popover`}>
            {onViewDetailsClick && (
              <ActionItem
                disabled={isEditLocked}
                onClick={() => onClickHandler(ACTION_OPTIONS.viewDetails)}
                data-cy={`${dataCyPrefix}__actions-select--View-details`}
              >
                {!noIcons && <StyledViewDetailsIcon />}
                <p>{viewDetailsText ?? ACTION_OPTIONS.viewDetails}</p>
              </ActionItem>
            )}

            {onEditClick && (
              <ActionItem
                disabled={isEditLocked}
                onClick={() => onClickHandler(ACTION_OPTIONS.edit)}
                data-cy={`${dataCyPrefix}__actions-select--Edit`}
              >
                {!noIcons && <EditIcon />}
                <p>{editText ?? ACTION_OPTIONS.edit}</p>
              </ActionItem>
            )}

            {onDuplicateClick && (
              <ActionItem
                disabled={isLocked}
                onClick={() => onClickHandler(ACTION_OPTIONS.duplicate)}
                data-cy={`${dataCyPrefix}__actions-select--Duplicate`}
              >
                {!noIcons && <DuplicateIcon width={16} height={16} color="black" />}
                <p>{ACTION_OPTIONS.duplicate}</p>
              </ActionItem>
            )}

            {onUnconfirmClick && (
              <ActionItem
                remove
                disabled={isLocked}
                onClick={() => onClickHandler(ACTION_OPTIONS.unconfirm)}
                data-cy={`${dataCyPrefix}__actions-select--Unconfirm`}
              >
                {!noIcons && <UnconfirmIcon />}
                <p>{ACTION_OPTIONS.unconfirm}</p>
              </ActionItem>
            )}

            {onDeleteClick && (
              <ActionItem
                onClick={() => onClickHandler(ACTION_OPTIONS.delete)}
                remove
                disabled={isLocked}
                data-cy={`${dataCyPrefix}__actions-select--Remove`}
              >
                {!noIcons && (deleteIcon ?? <BinIcon />)}
                <p>{deleteText ?? ACTION_OPTIONS.delete}</p>
              </ActionItem>
            )}
          </ActionPopover>
        </Portal>
      )}
    </RowActionToogle>
  );
};
