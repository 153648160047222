import React, { useCallback, useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { AppContext } from 'AppContext';
import { ISO_CODE_TO_SYMBOL, NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { TrashIcon } from 'components/Icons';
import { FormikCustomInput, FormikCustomSelector, CustomDatePicker } from 'components/Controls';
import { Flexer, FlexerColumn } from 'components/Core';
import { formatDateForDatepicker, updateDateFromDatePicker } from 'utils/dateUtils';
import { humanize } from 'utils/stringUtils';
import { getTooltipForTransaction } from 'views/Billing/InvoicingScheduleModal/InvoiceScheduleSingleInvoicePanel/SingleInvoice/InvoiceItems/InvoiceItem';
import { CreditNoteContext } from '../CreditNoteContext';
import { TransactionTypeSelector, TRANSACTION_TYPES } from './TransactionTypeSelector';
import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { ExternalProductSelector } from '../ExternalProductSelector';
import { RemoveWrapper, InvoiceItemRecognition } from '../InvoiceScheduleWarnings/styles';

const TopRow = styled(Flexer)`
  gap: 10px;
  width: 100%;
  align-items: center;
  display: flex;
`;

const BottomRow = styled(Flexer)`
  gap: 10px;
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  flex-grow: 1;
`;

const DatePickerWrapper = styled(Flexer)`
  gap: 3px;
  align-items: center;
`;

const DateSeparator = styled.span`
  margin: 0 0px;
`;

export const CreditNoteItem = ({ index, readOnly = false, allocatedExternally = false }) => {
  const { currencyISOCode: orgCurrency } = useContext(AppContext);
  const { invoicingService, currentInvoicingSchedule } = useContext(InvoicingScheduleContext);
  const { creditNoteTransactionOptions } = useContext(CreditNoteContext);
  const { values, setFieldValue } = useFormikContext();

  const disabledExplanation = useMemo(
    () =>
      allocatedExternally
        ? `You can only update the transaction now as this credit note has already been allocated in ${humanize(
            invoicingService,
          )}`
        : null,
    [allocatedExternally, invoicingService],
  );

  const existingTransactionOptions = useMemo(
    () =>
      creditNoteTransactionOptions?.map((option) => ({
        ...option,
        label: `${option.label} (${numberFormatter({
          type: NUMBER_FORMATS.CURRENCY,
          rawValue: option.transaction.amount,
          currency: option.transaction.currency ?? orgCurrency,
          decimalPlaces: 2,
        })})`,
      })),
    [creditNoteTransactionOptions, orgCurrency],
  );

  const removeCreditNoteItem = useCallback(
    (itemIndex) => {
      const newInvoiceItems = ([...values.items] ?? []).filter((_, index) => index !== itemIndex);
      setFieldValue(`items`, newInvoiceItems);
    },
    [setFieldValue, values],
  );

  const showStartAndEndDatePickers = useMemo(() => {
    return [TRANSACTION_TYPES.NEW_LINEAR_NONRECURRING, TRANSACTION_TYPES.NEW_LINEAR_RECURRING].includes(
      values.items[index].transaction_type,
    );
  }, [values.items, index]);

  const showStartDatePicker = useMemo(() => values.items[index].transaction_type === TRANSACTION_TYPES.NEW_IMMEDIATE, [
    values.items,
    index,
  ]);

  return (
    <FlexerColumn data-cy={`credit-note-view__credit-note-item--${index}`} style={{ gap: '8px' }}>
      <TopRow alignItems="flex-end">
        <InvoiceItemRecognition
          index={index}
          disableEditing={readOnly || allocatedExternally}
          style={{ marginTop: '24px' }}
        >
          {index + 1}
        </InvoiceItemRecognition>
        <TransactionTypeSelector index={index} allocatedExternally={allocatedExternally} readOnly={readOnly} />
        <ExternalProductSelector
          index={index}
          valuesEntity={`items`}
          tooltipInputDisplay={disabledExplanation}
          disableEditing={allocatedExternally || readOnly || !!values.items[index].transaction_id}
          data-cy={`credit-note-view__credit-note-item--${index}__product-selector`}
        />
        <FormikCustomInput
          name={`items[${index}].seats`}
          data-cy={`credit-note-view__credit-note-item--${index}__seats`}
          label={index === 0 && 'Quantity'}
          type="number"
          handleChange={(val) => setFieldValue(`items[${index}].seats`, val)}
          labelFlex="end"
          isDisabled={allocatedExternally || readOnly}
          tooltipInputDisplay={disabledExplanation}
          inputWidth="80px"
        />
        <FormikCustomInput
          inputWidth="120px"
          name={`items[${index}].amount`}
          data-cy={`credit-note-view__credit-note-item--${index}__amount`}
          label={index === 0 && 'Amount'}
          suffix={ISO_CODE_TO_SYMBOL[values?.currency ?? currentInvoicingSchedule.currency ?? orgCurrency] ?? '$'}
          precision={2}
          type="number"
          handleChange={(val) => setFieldValue(`items[${index}].amount`, val)}
          labelFlex="end"
          isDisabled={allocatedExternally || readOnly}
          tooltipInputDisplay={disabledExplanation}
        />
        <FormikCustomInput
          inputWidth="120px"
          name={`items[${index}].tax_amount`}
          data-cy={`credit-note-view__credit-note-item--${index}__tax-amount`}
          label={index === 0 && 'Tax amount'}
          suffix={ISO_CODE_TO_SYMBOL[values?.currency ?? currentInvoicingSchedule.currency ?? orgCurrency] ?? '$'}
          precision={2}
          type="number"
          labelFlex="end"
          tooltipInputDisplay={
            invoicingService ? 'We auto calculate the taxes at the moment (taxes override coming soon)' : null
          }
          isDisabled={!!invoicingService}
        />
        {!readOnly && !allocatedExternally && (
          <RemoveWrapper
            data-cy={`credit-note-view__credit-note-item--${index}__remove-button`}
            onClick={() => removeCreditNoteItem(index)}
          >
            <TrashIcon />
          </RemoveWrapper>
        )}
      </TopRow>
      <BottomRow alignItems="center">
        <InvoiceItemRecognition
          index={index}
          disableEditing={readOnly || allocatedExternally}
          style={{ visibility: 'hidden' }}
        >
          {index + 1}
        </InvoiceItemRecognition>
        {values.items[index].transaction_type === TRANSACTION_TYPES.USE_EXISTING && (
          <FormikCustomSelector
            placeholder="Select transaction"
            name={`items[${index}].transaction_id`}
            options={existingTransactionOptions}
            handleChange={({ value, transaction }) => {
              setFieldValue(`items[${index}].transaction_id`, value);
              if (!allocatedExternally && !values.allocated_invoice) {
                setFieldValue(`items[${index}].product_id`, transaction?.product_id ?? null);
                setFieldValue(`items[${index}].seats`, transaction?.seats ?? null);
                setFieldValue(`items[${index}].amount`, transaction?.amount ?? null);
              }
            }}
            containerWidth="44%"
            greyVer
            noSort
            data-cy={`credit-note-view__credit-note-item--${index}__transaction-selector`}
            isDisabled={readOnly}
            tooltipInputDisplay={
              values?.items?.[index]?.transaction_id
                ? getTooltipForTransaction(
                    values?.items?.[index]?.transaction_id,
                    creditNoteTransactionOptions?.map((option) => option.transaction),
                    values?.currency ?? currentInvoicingSchedule.currency ?? orgCurrency,
                  )
                : null
            }
          />
        )}
        {showStartAndEndDatePickers && (
          <DatePickerWrapper>
            <CustomDatePicker
              placeholderText="Start date"
              name={`items[${index}].start_date`}
              selected={values.items[index].start_date ? formatDateForDatepicker(values.items[index].start_date) : null}
              onChange={(val) => setFieldValue(`items[${index}].start_date`, updateDateFromDatePicker(val))}
              isDisabled={allocatedExternally || readOnly}
              tooltipInputDisplay={disabledExplanation}
              data-cy={`credit-note-view__credit-note-item--${index}__start-date`}
            />
            <DateSeparator>-</DateSeparator>
            <CustomDatePicker
              placeholderText="End date"
              name={`items[${index}].end_date`}
              selected={values.items[index].end_date ? formatDateForDatepicker(values.items[index].end_date) : null}
              onChange={(val) => setFieldValue(`items[${index}].end_date`, updateDateFromDatePicker(val))}
              isDisabled={allocatedExternally || readOnly}
              tooltipInputDisplay={disabledExplanation}
              data-cy={`credit-note-view__credit-note-item--${index}__end-date`}
            />
          </DatePickerWrapper>
        )}
        {showStartDatePicker && (
          <DatePickerWrapper>
            <CustomDatePicker
              name={`items[${index}].start_date`}
              placeholderText="Start date"
              selected={values.items[index].start_date ? formatDateForDatepicker(values.items[index].start_date) : null}
              onChange={(val) => setFieldValue(`items[${index}].start_date`, updateDateFromDatePicker(val))}
              isDisabled={allocatedExternally || readOnly}
              tooltipInputDisplay={disabledExplanation}
              data-cy={`credit-note-view__credit-note-item--${index}__start-date`}
            />
          </DatePickerWrapper>
        )}
        <DescriptionWrapper>
          <FormikCustomInput
            name={`items[${index}].description`}
            data-cy={`credit-note-view__credit-note-item--${index}__description`}
            placeholder="Enter description..."
            handleChange={(val) => setFieldValue(`items[${index}].description`, val)}
            isDisabled={allocatedExternally || readOnly}
            tooltipInputDisplay={disabledExplanation}
            containerWidth="100%"
          />
        </DescriptionWrapper>
        {!readOnly && !allocatedExternally && (
          // Just to match the width of the top row
          <RemoveWrapper></RemoveWrapper>
        )}
      </BottomRow>
    </FlexerColumn>
  );
};
