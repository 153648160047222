import { useMemo, useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { RECOGNITION_TYPES } from 'consts/global';
import { AppContext } from 'AppContext';
import { Flexer, FlexBetweenContainer } from 'components/Core';
import { EditCircleIcon } from 'components/Icons';
import { DIRECTIONS, Popover, PopoverActions, PopoverButton, PopoverPrompt, PopoverWrapper } from 'components/Portal';
import { TooltipContainer } from 'components/Tooltip';
import { hasAutoChargeSupport } from 'views/Billing/utils';
import { PaymentMethodsSelectorModal } from 'shared/PaymentMethods/PaymentMethodsSelectorModal';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { Button, AutoChargeBlock, AutoChargeTitle } from './styles';

const HeaderWrapper = styled(FlexBetweenContainer)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: -4px;
`;

const StyledEditCircleIcon = styled(EditCircleIcon)`
  margin-left: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const PaymentMethodSelectorButton = ({ disabled, onClick }) => (
  <TooltipContainer
    toolTipContent={
      disabled
        ? 'This customer has no payment method on Stripe. You will be asked to send the payment link to the customer. Once a payment method becomes available, the invoices will be auto-charged.'
        : 'Select a payment method (optional step)'
    }
    width={disabled ? 340 : 160}
  >
    <StyledEditCircleIcon
      disabled={disabled}
      onClick={() => {
        if (!disabled) onClick();
      }}
    />
  </TooltipContainer>
);

export const AutoChargeSettingsSection = () => {
  const { integrations } = useContext(AppContext);

  const {
    currentInvoicingSchedule,
    includedTransactions,
    invoicingScheduleFormValues,
    customerDetails,
    isCustomerDetailsLoading,
    scheduleFormRef,
  } = useContext(InvoicingScheduleContext);

  const [showPaymentMethodsSelectorModal, setShowPaymentMethodsSelectorModal] = useState(false);

  const setFieldValue = scheduleFormRef?.current?.setFieldValue;
  const isCreateMode = !currentInvoicingSchedule?.id;
  const hasAutoCharge = useMemo(() => hasAutoChargeSupport({ integrations }), [integrations]);
  const hasTillCanceledTransaction = useMemo(
    () => includedTransactions?.some(({ recognition }) => recognition === RECOGNITION_TYPES.tillCanceled),
    [includedTransactions],
  );
  const hasActivePaymentMethod = !!customerDetails?.has_active_payment_method;
  const showAutoChargeCallToAction =
    hasAutoCharge &&
    (hasTillCanceledTransaction || hasActivePaymentMethod) &&
    [null, undefined].includes(invoicingScheduleFormValues?.auto_charge) &&
    !isCustomerDetailsLoading &&
    isCreateMode;

  const handleAutoChargeClick = useCallback(
    (value) => {
      setFieldValue('auto_charge', value);
      setFieldValue('auto_send', false);
    },
    [setFieldValue],
  );

  const handlePaymentMethodSelected = (paymentMethodId) => {
    setFieldValue('auto_charge_payment_method', paymentMethodId);
    setShowPaymentMethodsSelectorModal(false);
  };

  return (
    <AutoChargeBlock>
      <HeaderWrapper>
        <Flexer>
          <AutoChargeTitle isOn={invoicingScheduleFormValues?.auto_charge}>
            Auto-charging <span>{invoicingScheduleFormValues?.auto_charge ? 'on' : 'off'}</span>
          </AutoChargeTitle>
          {invoicingScheduleFormValues?.auto_charge && (
            <PaymentMethodSelectorButton
              disabled={!hasActivePaymentMethod}
              onClick={() => {
                setShowPaymentMethodsSelectorModal(true);
              }}
            />
          )}
        </Flexer>
        <PopoverWrapper>
          <StyledButton
            data-cy="billing__invoice-schedule-modal__auto-charge-button"
            onClick={() => handleAutoChargeClick(!invoicingScheduleFormValues?.auto_charge)}
          >
            {invoicingScheduleFormValues?.auto_charge ? 'Stop auto-charge' : 'Auto-charge'} the customer
          </StyledButton>
          {showAutoChargeCallToAction && (
            <Popover
              XOffset={130}
              YOffset={35}
              zIndex={51}
              darkMode
              width={hasActivePaymentMethod ? '320px' : '300px'}
              showArrow
              arrowDirection={DIRECTIONS.TOP}
            >
              <PopoverPrompt>
                {hasActivePaymentMethod
                  ? 'This customer already has an active payment method on Stripe. We recommend enabling Auto-charge to make the payment process more seamless.'
                  : 'Do you want to auto-charge customers via Stripe?'}
              </PopoverPrompt>
              <PopoverActions>
                <PopoverButton onClick={() => handleAutoChargeClick(false)}>No</PopoverButton>
                <PopoverButton onClick={() => handleAutoChargeClick(true)} primary>
                  Yes
                </PopoverButton>
              </PopoverActions>
            </Popover>
          )}
        </PopoverWrapper>
      </HeaderWrapper>
      {showPaymentMethodsSelectorModal && (
        <PaymentMethodsSelectorModal
          customerId={invoicingScheduleFormValues?.customer_id}
          selectedPaymentMethodId={invoicingScheduleFormValues?.auto_charge_payment_method ?? null}
          onPaymentMethodSelected={handlePaymentMethodSelected}
          onClose={() => setShowPaymentMethodsSelectorModal(false)}
        />
      )}
    </AutoChargeBlock>
  );
};
