import { useContext } from 'react';
import { useFormikContext } from 'formik';
import { ISO_CODE_TO_SYMBOL } from 'consts/global';
import { AppContext } from 'AppContext';
import { FormikCustomSelector, FormikCustomInput } from 'components/Controls';
import { TooltipContainer } from 'components/Tooltip';
import { DISCOUNT_TYPE } from 'views/Billing/consts';

const DISCOUNT_TYPE_OPTIONS = [
  {
    label: 'Percentage',
    value: DISCOUNT_TYPE.PERCENTAGE,
  },
  {
    label: 'Flat amount',
    value: DISCOUNT_TYPE.FLAT_AMOUNT,
  },
];
export const DiscountTypeValue = ({ fullyEditable = false }) => {
  const { currencyISOCode: orgCurrency } = useContext(AppContext);
  const { values } = useFormikContext();

  return (
    <>
      <FormikCustomSelector
        label="Discount type"
        name="discount_type"
        options={DISCOUNT_TYPE_OPTIONS}
        isDisabled={!fullyEditable && !!values?.discount_id}
        data-cy="formik-discount-section__discount-type"
      />
      <FormikCustomInput
        label="Discount"
        name="discount_value"
        precision={2}
        type="number"
        labelFlex="end"
        inputTextAlign="right"
        suffix={
          values?.discount_type === DISCOUNT_TYPE.PERCENTAGE ? (
            '%'
          ) : (
            <TooltipContainer
              toolTipContent="Showing your organization's currency here, but the currency of the discount will respect the object it is applied to. For example, when the discount is applied to a usage subscription, the discount will have the currency of the usage subscription's pricing plan."
              isVisible={fullyEditable}
            >
              {ISO_CODE_TO_SYMBOL[values?.currency ?? orgCurrency] ?? '$'}
            </TooltipContainer>
          )
        }
        disabled={!fullyEditable && !!values?.discount_id}
        data-cy="formik-discount-section__discount-value"
      />
    </>
  );
};
