import { useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { FormikCustomInput } from 'components/Controls';
import { FlexBetweenContainer, Flexer } from 'components/Core';
import { DEFAULT_AUTO_INCREASE_MONTHS } from '../consts';
import { StyledCheckIcon, StyledPencilIcon } from './TransactionAutoIncreaseModal.styles';

const DisplayWrapper = styled(FlexBetweenContainer)`
  width: 100%;
  padding-right: 18px;
`;

const HighlightedText = styled.span`
  color: var(--primaryBlack50);
`;

const IconWrapper = styled.div`
  padding-right: 8px;
`;

export const EditSubsequentMonthsInput = () => {
  const { values, setFieldValue } = useFormikContext();

  const [isEdit, setIsEdit] = useState(false);

  return isEdit ? (
    <FormikCustomInput
      name="autoIncreaseMonths"
      placeholder="Enter percentage..."
      suffix={
        <IconWrapper>
          <StyledCheckIcon
            onClick={() => setIsEdit(false)}
            data-cy="transaction__auto-increase-modal__auto-increase-months--done-icon"
          />
        </IconWrapper>
      }
      customSuffixColor="var(--primaryBlack)"
      handleChange={(value) => {
        const parsedValue = Number(value);
        if (!Number.isNaN(parsedValue)) setFieldValue('autoIncreaseMonths', Math.abs(parsedValue));
      }}
      noBorder
      noShadow
      wrapperHeight="50px"
      data-cy="transaction__auto-increase-modal__auto-increase-months--input"
      fontSize="12px"
      onBlur={() => {
        if (!values.autoIncreaseMonths) setFieldValue('autoIncreaseMonths', DEFAULT_AUTO_INCREASE_MONTHS);
      }}
    />
  ) : (
    <DisplayWrapper data-cy="transaction__auto-increase-modal__auto-increase-months--display">
      <Flexer gap="8px">
        {values.autoIncreaseMonths}
        <HighlightedText>months</HighlightedText>
      </Flexer>
      <StyledPencilIcon
        data-cy="transaction__auto-increase-modal__auto-increase-months--edit-button"
        onClick={() => setIsEdit(true)}
      />
    </DisplayWrapper>
  );
};
