import axios from 'axios';
import { API_URL } from 'api/consts';

const buildUrl = ({ orgId, productId }) => {
  const baseUrl = `${API_URL}/organizations/${orgId}/products`;
  if (!productId) return baseUrl;

  return `${baseUrl}/${productId}`;
};

export const getProducts = async ({ orgId, params }) => {
  // TODO [TC 2023-04-24]: Paginate properly instead of using limit: 5000,
  // the backend endpoint supports it already
  const response = await axios.get(buildUrl({ orgId }), { params: { ...params, limit: 5000 } });
  return response.data;
};

export const getProduct = async ({ orgId, productId }) => {
  const response = await axios.get(buildUrl({ orgId, productId }));
  return response.data;
};

export const createProduct = async ({ orgId, body }) => {
  const response = await axios.post(buildUrl({ orgId }), body);
  return response.data;
};

export const updateProduct = async ({ orgId, productId, body }) => {
  const response = await axios.put(buildUrl({ orgId, productId }), body);
  return response.data;
};

export const deleteProduct = async ({ orgId, productId }) => {
  const response = await axios.delete(buildUrl({ orgId, productId }));
  return response.data;
};

export const getProductCategories = async ({ orgId, params }) => {
  const response = await axios.get(`${API_URL}/organizations/${orgId}/product-categories`, { params });
  return response.data;
};

export const createProductCategory = async ({ orgId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/product-categories`;
  const response = await axios.post(url, data);
  return response.data;
};

export const updateProductCategory = async ({ orgId, categoryId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/product-categories/${categoryId}`;
  const response = await axios.patch(url, data);
  return response.data;
};

export const bulkUpdateProducts = async ({ orgId, productIds, data }) => {
  const url = `${API_URL}/organizations/${orgId}/products/bulk-update`;
  const response = await axios.put(url, {
    productIds,
    data,
  });
  return response.data;
};

export const bulkCreateProducts = async ({ orgId, data }) => {
  const url = `${API_URL}/organizations/${orgId}/products/bulk-create`;
  const response = await axios.post(url, data);
  return response.data;
};

export const bulkDeleteProducts = async ({ orgId, productIds }) => {
  const url = `${API_URL}/organizations/${orgId}/products/bulk-delete`;
  const response = await axios.delete(url, { data: { productIds } });
  return response.data;
};
