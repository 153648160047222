import axios from 'axios';
import config from 'config';
import { getRequest } from './requests';

export const {
  app: { API_URL },
} = config;

const onError = (err) => {
  console.log('Error', err.message);
};
// DASHBOARD PAGE

export const getMonthlyCustomerCount = async (orgId) => {
  try {
    const url = `${API_URL}/monthly-customer-count/${orgId}`;
    const data = await getRequest(url);
    return data;
  } catch (err) {
    onError(err);
  }
};

// TRANSACTIONS PAGE

// TODO: Change these for consistency, let's not use destructured args in one place and
// plain arguments in another

// !!!! DEPRECATED !!! Use calls/hooks in transactions folder
// TODO: Remove these
export const getTransactions = async ({ page = 1, limit = 10, orgId, params, body, scopes = [] }) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/transactions/filter`;
    const { data } = await axios.post(
      url,
      {
        ...body,
        scopes,
      },
      {
        params: { page: page, limit: limit, ...params },
      },
    );

    return data;
  } catch (err) {
    onError(err);
  }
};

export const deleteTransaction = async (orgId, transactionId) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/transactions/${transactionId}`;
    const response = await axios.delete(url);
    return response.data;
  } catch (err) {
    onError(err);
  }
};

export const duplicateTransaction = async (orgId, transactionId) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/transactions/${transactionId}/duplicate`;
    const response = await axios.post(url);
    return response.data;
  } catch (err) {
    onError(err);
  }
};

export const bulkConfirmTransactions = async ({ orgId, transactionIds }) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/transactions/bulk-confirm`;
    const response = await axios.post(url, { transactionIds });
    return response.data;
  } catch (err) {
    onError(err);
  }
};

export const bulkDeleteTransactions = async ({ orgId, transactionIds }) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/transactions-bulk-delete`;
    const response = await axios.delete(url, { data: { transactionIds: transactionIds } });
    return response.data;
  } catch (err) {
    onError(err);
  }
};

export const getTransactionsSpread = async (transactionId, orgId) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/transactions/${transactionId}/spreads`;
    const { data } = await axios.get(url);
    return data;
  } catch (err) {
    onError(err);
  }
};

export const getTransactionsAudits = async (transactionId, orgId) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/transactions/${transactionId}/audits`;
    const { data } = await axios.get(url);
    return data;
  } catch (err) {
    onError(err);
  }
};

export const updateTransaction = async (transaction, orgId) => {
  const url = `${API_URL}/organizations/${orgId}/transactions/${transaction.id}`;
  const { data } = await axios.patch(url, transaction);
  return data;
};

export const createTransaction = async (transaction, orgId) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/transactions`;
    const { data } = await axios.post(url, transaction);
    return data;
  } catch (err) {
    onError(err);
  }
};

export const searchCustomers = async ({ orgId, searchQuery, withoutProspects = false, page, limit = 10 }) => {
  try {
    let url = `${API_URL}/organizations/${orgId}/customers/search?searchQuery=${searchQuery}&withoutProspects=${withoutProspects}&limit=${limit}`;
    if (page) url = `${url}&page=${page}`;
    return getRequest(url);
  } catch (err) {
    onError(err);
  }
};

export const createSpread = async (orgId, body) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/spreads`;
    const { data } = await axios.post(url, body);
    return data;
  } catch (err) {
    onError(err);
  }
};

export const updateSpread = async (orgId, spreadId, body) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/spreads/${spreadId}`;
    const { data } = await axios.patch(url, body);
    return data;
  } catch (err) {
    onError(err);
  }
};

export const removeSpread = async (orgId, spreadId) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/spreads/${spreadId}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (err) {
    onError(err);
  }
};

export const getSpreads = async (orgId, params) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/spreads`;
    const { data } = await axios.get(url, { params });
    return data;
  } catch (err) {
    onError(err);
  }
};

export const getTransaction = async (orgId, transactionId, params) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/transactions/${transactionId}`;
    const { data } = await axios.get(url, { params });
    return data;
  } catch (err) {
    onError(err);
  }
};

export const getTransactionAnalysis = async ({ orgId, transactionId, params }) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/transactions/${transactionId}/analysis`;
    const { data } = await axios.get(url, { params });
    return data;
  } catch (err) {
    onError(err);
  }
};

// CUSTOMERS PAGE
export const getCustomers = async (orgId, params = {}) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/customers`;
    const { data } = await axios.get(url, {
      params,
    });
    return data;
  } catch (err) {
    onError(err);
  }
};

export const createCustomer = async ({ orgId, customerName, customerMetadata = {}, customerParent = null }) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/customers`;
    const response = await axios.post(url, {
      name: customerName,
      metadata: customerMetadata,
      parent_customer_id: customerParent,
    });
    return response.data;
  } catch (err) {
    onError(err);
  }
};

export const updateCustomer = async ({
  orgId,
  customerName,
  customerId,
  customerMetadata = {},
  overwriteMetadata,
  customerParent = null,
}) => {
  try {
    let url = `${API_URL}/organizations/${orgId}/customers/${customerId}`;
    const response = await axios.patch(
      url,
      {
        name: customerName,
        metadata: customerMetadata,
        parent_customer_id: customerParent,
      },
      { params: { overwriteMetadata } },
    );
    return response.data;
  } catch (err) {
    onError(err);
  }
};

export const deleteCustomer = async (orgId, customerId) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/customers/${customerId}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (err) {
    onError(err);
  }
};

export const getCustomer = async (orgId, customerId, params = {}) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/customers/${customerId}`;
    const { data } = await axios.get(url, {
      params,
    });
    return data;
  } catch (err) {
    onError(err);
  }
};

export const createProduct = async (orgId, product) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/products`;
    const response = await axios.post(url, product);
    return response.data;
  } catch (err) {
    onError(err);
  }
};

export const updateProduct = async (orgId, product) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/products/${product.id}`;
    const response = await axios.put(url, product);
    return response.data;
  } catch (err) {
    onError(err);
  }
};

export const getProduct = async (orgId, productId) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/products/${productId}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    onError(err);
  }
};

export const deleteProduct = async (orgId, productId) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/products/${productId}`;
    const response = await axios.delete(url);
    return response.data;
  } catch (err) {
    onError(err);
  }
};

export const bulkDeleteProducts = async ({ orgId, productIds }) => {
  const url = `${API_URL}/organizations/${orgId}/products/bulk-delete`;
  const response = await axios.delete(url, { data: { productIds } });
  return response.data;
};

//Revenue Details

export const getRevenueDetails = async (orgId, params) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/revenue-details`;
    const { data } = await axios.get(url, { params });
    return data;
  } catch (err) {
    onError(err);
  }
};

//Up For Renewal
export const getUpForRenewalData = async (orgId, params) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/up-for-renewal`;
    const { data } = await axios.get(url, { params });
    return data;
  } catch (err) {
    onError(err);
  }
};

//Customer Timeseries Metrics
export const getCustomerTimeseriesMetricsKeys = async ({ orgId, params }) => {
  try {
    const url = `${API_URL}/organizations/${orgId}/customer-timeseries-metrics-keys`;
    const { data } = await axios.get(url, { params });
    return data;
  } catch (err) {
    onError(err);
  }
};
