import { INITIAL_EVENT } from 'shared/TransactionContent/TransactionBody/TransactionSpread/consts';

export const TRANSACTION_MODAL_MODE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  EDIT_BULK: 'BULK',
  EXTERNAL_UPDATE: 'EXTERNAL_UPDATE',
};

export const TRANSACTION_VIEW_MODE = {
  PAGE: 'PAGE',
};

export const EMPTY_TRANSACTION = {
  amount: '',
  recurring_amount: '',
  name: '',
  confirmed: true,
  customer_id: null,
  customer_name: null,
  date: new Date(),
  end_date: null,
  id: null,
  product_id: null,
  product_name: null,
  recognition: null,
  replaced_by: null,
  seats: 0,
  start_date: new Date(),
  renewal_deadline: null,
};

export const INITIAL_SPREAD_VALUE = {
  recognitionEvents: [
    {
      ...INITIAL_EVENT,
    },
  ],
};

// Should match backend TRANSACTION_BILLING_FIELDS on backend/src/components/invoicingSchedules/resync/consts.js
export const TRANSACTION_BILLING_FIELDS = [
  'amount',
  'recurring_amount',
  'recognition',
  'seats',
  'start_date',
  'end_date',
  'product_id',
  'metadata',
];

export const TOTAL_AMOUNT_METHOD = {
  PREDETERMINED: 'predetermined',
  AUTO_CALCULATED: 'auto_calculated',
};

export const DEFAULT_AUTO_INCREASE_MONTHS = 12;
