import styled from 'styled-components';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { AppContext } from 'AppContext';
import { INVOICE_ITEM_TYPES, INVOICE_JOBS_OPERATIONS, INVOICE_MAGIC_METADATA } from 'views/Billing/consts';
import { ReactComponent as WaitingIcon } from 'images/icon_clock-waiting.svg';
import { ReactComponent as HelpCircleIcon } from 'images/help-table-header.svg';
import { TooltipContainer } from 'components/Tooltip';
import { humanizeTime } from 'utils/stringUtils';
import { useInvoiceAPI } from 'api/billing';
import { CircleLoader } from 'components/Loaders';
import { useToasts } from 'components/Toasts';
import { numberFormatter } from 'utils/formatters';
import { NUMBER_FORMATS } from 'consts/global';

const { Centerer, Spacer } = require('components/Core');

const LinkCard = styled(Centerer)`
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  align-items: center;
  justify-content: space-between;

  background: #fff;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 8px 8px 60px 0px var(--primaryBlack2);
  border-radius: 8px;

  span {
    color: var(--primaryBlue);
    cursor: pointer;
  }

  &:hover {
    border: 1px solid var(--neutralGray);
    box-shadow: 0px 4px 20px var(--primaryBlack4);
  }
`;

const ContentWrapper = styled(Centerer)`
  justify-content: space-between;
  width: 100%;
  padding: 12px 8px;
  gap: 8px;
`;

const ActionButton = styled(Centerer)`
  padding: 4px 8px;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid var(--primaryBlack10);
  cursor: pointer;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 16px;
`;

const Divider = styled.hr`
  height: 1px;
  width: 100%;
  border: none;
  margin: 5px 0;
  background-color: var(--dark10);
`;

const ItalicInfo = styled.div`
  font-style: italic;
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
`;

const INVOICE_OPERATIONS = {
  SEND: 'send',
  CHARGE: 'charge',
  SAVE: 'save',
};

export const InvoiceProcessingInfoCard = ({ invoice, invoicingServiceDisplayName = '' }) => {
  const {
    orgId,
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);
  const {
    operations: { skipTaxPolling },
  } = useInvoiceAPI({ orgId, invoiceId: invoice?.id, autoFetch: false });
  const { pushError } = useToasts();

  const currency = invoice?.currency ?? defaultOrgCurrency;
  const [isLoading, setIsLoading] = useState(false);

  if (!invoice?.jobOperation) return null;
  if (!invoice?.polling_taxes_job_id) return null;

  const handleClickSkipPolling = async () => {
    try {
      setIsLoading(true);
      await skipTaxPolling({ id: invoice.id });
    } catch (error) {
      pushError('Failed to end tax processing');
    } finally {
      setIsLoading(false);
    }
  };

  const existingTaxLineItem = invoice?.invoice_items?.find((item) => item.type === INVOICE_ITEM_TYPES.TAXES);

  const pollingDuration = dayjs
    .utc()
    .diff(dayjs.utc(invoice?.metadata?.[INVOICE_MAGIC_METADATA.POLLING_TAXES_LAST_ATTEMPT_AT]), 'milliseconds');

  const invoiceJobOperation = INVOICE_JOBS_OPERATIONS[invoice.jobOperation];
  return (
    <Centerer padding="0px 40px 20px 40px">
      <LinkCard>
        <ContentWrapper>
          <Centerer>
            <WaitingIcon />
            <Spacer width="5px" />
            <div>
              <b>
                <i>
                  {invoiceJobOperation === INVOICE_OPERATIONS.SEND
                    ? `Before sending, we have to save the invoice in ${invoicingServiceDisplayName} and `
                    : invoiceJobOperation === INVOICE_OPERATIONS.CHARGE
                    ? `Before charging, we have to save the invoice in ${invoicingServiceDisplayName} and `
                    : `Subscript is saving the invoice in ${invoicingServiceDisplayName} and `}
                  <TooltipContainer
                    leftAlign
                    hideArrow
                    fontSize="12px"
                    width={400}
                    toolTipContent={
                      <div>
                        <b>Syncing taxes</b>
                        {pollingDuration && (
                          <>
                            <div>Time elapsed: {humanizeTime(pollingDuration)}.</div>
                            <Divider />
                          </>
                        )}
                        <ItalicInfo>
                          <div>If you edit this invoice, we'll restart the tax sync with the updated info.</div>
                          <div>If you send it now, it'll be scheduled after tax sync.</div>
                          <div>If you manually charge now, we'll sync taxes first and then charge.</div>
                        </ItalicInfo>
                      </div>
                    }
                  >
                    <span>
                      {[INVOICE_OPERATIONS.SEND, INVOICE_OPERATIONS.CHARGE].includes(invoiceJobOperation)
                        ? 'sync taxes '
                        : 'syncing taxes '}
                    </span>
                    <HelpCircleIcon />
                  </TooltipContainer>
                </i>
              </b>{' '}
              The response from third-party services may take some time
            </div>
          </Centerer>

          {invoice.polling_taxes_job_id && (
            <TooltipContainer
              hideArrow
              fontSize="12px"
              width={200}
              toolTipContent={`Accept current tax value: ${numberFormatter({
                rawValue: existingTaxLineItem?.amount ?? 0,
                type: NUMBER_FORMATS.CURRENCY,
                decimalPlaces: 2,
                currency: existingTaxLineItem?.currency ?? currency,
              })}`}
            >
              <ActionButton data-cy="skip-tax-processing-invoice-button" onClick={handleClickSkipPolling}>
                {isLoading ? <CircleLoader width={15} height={15} /> : `Stop syncing taxes`}
              </ActionButton>
            </TooltipContainer>
          )}
        </ContentWrapper>
      </LinkCard>
    </Centerer>
  );
};
