import * as Yup from 'yup';
import { INVOICE_ITEM_TYPES } from '../consts';

export const getSchema = ({ existingInvoices, products }) => {
  const existingInvoicesSet = new Set(existingInvoices?.map((invoice) => invoice.id));

  const baseSchema = {
    amount: Yup.number().typeError('Total must be a number').nullable(),
    invoice_id: Yup.string()
      .required('Invoice is required')
      .test('existing-invoice', 'Invalid invoice ID', (value) => existingInvoicesSet.has(value)),
    type: Yup.string()
      .oneOf([...Object.values(INVOICE_ITEM_TYPES), null])
      .nullable(),
    product_id: Yup.string()
      .nullable()
      .when('type', {
        is: (type) => !type,
        then: (schema) =>
          schema
            .required('Product is required')
            .oneOf([null, ...products?.map((product) => product.id)], "We can't find that product"),
        otherwise: (schema) => schema.nullable(),
      }),
    transactions: Yup.array()
      .of(
        Yup.object({
          transaction_id: Yup.string().required('Transaction ID is required'),
          allocation: Yup.number().typeError('Allocation must be a number').nullable(),
          amount: Yup.number().typeError('Amount must be a number').nullable(),
        }),
      )
      .when('type', {
        is: (type) => !type,
        then: (schema) => schema.required('Transactions are required'),
        otherwise: (schema) => schema.nullable(),
      })
      .when('id', {
        is: (id) => id !== null && id !== undefined,
        then: (schema) =>
          schema.test(
            'max-transactions',
            'Group updating is not supported yet, please select only one transaction',
            (value) => !value || value.length <= 1,
          ),
      }),
    description: Yup.string()
      .nullable()
      .when(['type'], {
        is: (type) => !!type,
        then: (schema) => schema.required('Description required for not transaction items'),
        otherwise: (schema) => schema.nullable(),
      }),
    name: Yup.string().nullable(),
    seats: Yup.number().typeError('Seats must be a number').nullable(),
    metadata: Yup.string()
      .nullable()
      .test('valid-json', 'Metadata must be valid JSON', (value) => {
        if (!value) return true;
        try {
          JSON.parse(value);
          return true;
        } catch (e) {
          return false;
        }
      }),
  };

  const createSchema = Yup.array().of(Yup.object(baseSchema));

  const updateSchema = Yup.array().of(
    Yup.object({
      ...baseSchema,
      id: Yup.string()
        .nullable()
        .required('Invoice Item ID is required')
        .test('existing-invoice-item', 'Invalid invoice item ID', function (value) {
          const invoice = existingInvoices?.find((inv) => inv?.id === this?.parent?.invoice_id);
          return invoice?.invoice_items?.some((item) => item?.id === value);
        }),
    }),
  );

  return { createSchema, updateSchema };
};
