import { useContext, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Checkbox } from 'antd';
import { isEmpty } from 'lodash';

import { AppContext } from 'AppContext';
import { Centerer, FlexerColumn, FlexerRow, Row, Spacer } from 'components/Core';
import { NUMBER_FORMATS } from 'consts/global';
import { useClickOutside } from 'utils/hooks';
import { numberFormatter } from 'utils/formatters';
import { useDebouncedSearchBar } from 'components/Blocks';
import { usePortal } from 'components/Portal';
import { TooltipContainer, TruncateStringWithTooltip } from 'components/Tooltip';
import { transactionDisplayName, transactionDisplayTitle } from 'models/transaction';
import { useTransactionsAPI } from 'api/transactions';
import { REQUIRED_BILLING_PARAMS } from 'api/transactions/hooks';
import { CircleLoader } from 'components/Loaders';
import { ModalContainer, Modal } from 'components/Modal';

import {
  TableHeader,
  Footer,
  TransactionsList,
  Header,
  Body,
  EmptyState,
  ConfirmButton,
  TransactionItemWrapper,
} from '../InvoicingScheduleModal/InvoicingScheduleSelectTransactionsPanel/styles';
import { getTransactionAmountByRecognition, groupTransactionsByExternalId } from '../InvoicingScheduleModal/utils';
import { SelectTransactionsTable } from '../InvoicingScheduleModal/InvoicingScheduleSelectTransactionsPanel/SelectTransactionsTable';
import {
  Wrapper,
  TransactionName,
  TransactionAmount,
  TransactionTitleRow,
  StyledExternalLink,
  TransactionDates,
  AllocationRow,
  AllocationInput,
  AllocationSubtitle,
  AllocationAmount,
  AllocationInputWrapper,
  RedPlaceholder,
  SelectedItem,
  Counter,
  CustomSelector,
} from './styles';

const renderTransaction = ({
  transaction,
  setFieldValue,
  selectedTransactions,
  index,
  amount = 0,
  calculateAllocatedAmounts,
}) => {
  let displayDate = dayjs.utc(transaction.start_date).format('MMM DD, YYYY');
  if (transaction.end_date && dayjs(transaction.end_date).isValid()) {
    displayDate += ' - ' + dayjs.utc(transaction.end_date).format('MMM DD, YYYY');
  }

  if (transaction.replaced_by) {
    displayDate += ' (transaction is replaced)';
  }

  const selectedTransaction = selectedTransactions?.find((t) => t?.transaction_id === transaction?.id);

  const recalculateAllocatedAmounts = (percent) => {
    const newSelectedTransactions = selectedTransactions?.map((t) => {
      if (t?.transaction_id === selectedTransaction?.transaction_id) {
        return {
          ...t,
          amount: (percent / 100) * amount,
          percent,
        };
      }
      return t;
    });

    setFieldValue(newSelectedTransactions);
  };

  return (
    <TransactionItemWrapper
      data-cy={`select-transactions-table__selected-transaction`}
      key={transaction.id}
      disabled={!transaction.product_id}
      hasBorder={index > 0}
    >
      <Wrapper>
        <TransactionTitleRow>
          <Row horizontal="flex-start" style={{ width: '250px' }}>
            <Checkbox
              disabled={!transaction.product_id}
              checked={!!selectedTransaction}
              onClick={() => {
                const newSelectedTransactions = selectedTransaction
                  ? selectedTransactions?.filter((t) => t?.transaction_id !== transaction?.id)
                  : [
                      ...(selectedTransactions ?? []),
                      {
                        transaction_id: transaction?.id,
                        amount: 0,
                        customer_id: transaction?.customer_id,
                        transaction_name: transactionDisplayName({ transaction }),
                      },
                    ];

                const allocatedTransactions = calculateAllocatedAmounts({ newSelectedTransactions });

                setFieldValue(allocatedTransactions);
              }}
              data-cy={`select-transaction-modal__checkbox--${transaction.name}`}
              style={{
                marginTop: '5px',
                marginRight: '8px',
              }}
            />
            <TransactionName>
              <TruncateStringWithTooltip length={25} tooltipWidth={200}>
                {transactionDisplayTitle(transaction)}
              </TruncateStringWithTooltip>
            </TransactionName>
            <StyledExternalLink
              data-cy={`select-transaction-modal__transaction-${transaction?.name}--external-link`}
              onClick={() => window.open(`/transactions/${transaction.id}`, '_blank')}
              style={{
                marginLeft: '5px',
                alignSelf: 'center',
              }}
            />
          </Row>

          <TransactionDates>{displayDate}</TransactionDates>
          <TransactionAmount>
            {numberFormatter({
              type: NUMBER_FORMATS.CURRENCY,
              rawValue: getTransactionAmountByRecognition({ transaction }),
            })}
          </TransactionAmount>

          <div />
        </TransactionTitleRow>

        {!!selectedTransaction ? (
          <AllocationRow>
            <FlexerRow alignItems="center">
              <AllocationInputWrapper>
                <AllocationInput
                  data-cy={`csv-upload__transactions-selector-allocation-${index}`}
                  type="number"
                  value={selectedTransaction?.percent}
                  onChange={(e) => {
                    recalculateAllocatedAmounts(e.target.value);
                  }}
                />
              </AllocationInputWrapper>

              <AllocationSubtitle>
                of the imported invoice item ({' '}
                {numberFormatter({
                  type: NUMBER_FORMATS.CURRENCY,
                  rawValue: amount,
                })}
                ) for this trx
              </AllocationSubtitle>
            </FlexerRow>
            <AllocationAmount>
              Recognizes:
              <span>
                {selectedTransaction?.amount
                  ? numberFormatter({
                      type: NUMBER_FORMATS.CURRENCY,
                      rawValue: selectedTransaction?.amount,
                    })
                  : '0'}
              </span>
            </AllocationAmount>
          </AllocationRow>
        ) : null}
      </Wrapper>
    </TransactionItemWrapper>
  );
};

export const CsvUploadTransactionsSelector = ({
  amount,
  selectedTransactions = [],
  customerId,
  setFieldValue,
  disabled,
  notTransactionItemRow,
}) => {
  const { orgId } = useContext(AppContext);

  const { triggerRef, togglePortal, isPortalVisible, hidePortal, Portal } = usePortal();

  const [searchQuery, setSearchQuery] = useState();

  const { data, isLoading } = useTransactionsAPI({
    orgId,
    filters: {
      limit: 1000,
      params: {
        confirmed: true,
        archived: false,
        includeChildCustomerTransactions: true,
        ...REQUIRED_BILLING_PARAMS,
      },
      body: {
        orderBy: [{ column: 'start_date', order: 'asc' }],
        customerIds: [customerId],
      },
    },
  });

  const groupedTransactions = useMemo(
    () =>
      data
        ? groupTransactionsByExternalId({
            transactions: data,
            searchQuery,
          })
        : {},
    [searchQuery, data],
  );

  useEffect(() => {
    if (data && selectedTransactions?.length) {
      setFieldValue(calculateAllocatedAmounts({ newSelectedTransactions: selectedTransactions }));
    }
    // eslint-disable-next-line
  }, [data, amount]);

  const modalRef = useClickOutside(() => {
    hidePortal();
  });

  const { DebouncedSearchBar } = useDebouncedSearchBar({
    onSearchQueryChange: setSearchQuery,
    initialValue: searchQuery,
    placeholder: 'Search by Name or External ID...',
    placeholderColor: 'var(--primaryBlack30)',
    backgroundColor: 'transparent',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    width: '180px',
  });

  const calculateAllocatedAmounts = ({ newSelectedTransactions }) => {
    const transactions = data?.filter((trx) => newSelectedTransactions?.some((t) => t?.transaction_id === trx?.id));

    const totalSelectedAmount = transactions.reduce((sum, trx) => {
      return sum + getTransactionAmountByRecognition({ transaction: trx });
    }, 0);

    const allocatedTransactions = transactions.reduce((acc, trx) => {
      const trxAmount = getTransactionAmountByRecognition({ transaction: trx });
      const allocatedAmount = (trxAmount / totalSelectedAmount) * amount;
      const finalAmount =
        newSelectedTransactions?.find((t) => t?.transaction_id === trx?.id)?.initial_amount ?? allocatedAmount;

      acc[trx.id] = {
        amount: finalAmount,
        percent: ((finalAmount / amount) * 100).toFixed(2),
        customer_id: trx?.customer_id,
        transaction_name: transactionDisplayName({ transaction: trx }),
      };
      return acc;
    }, {});

    return newSelectedTransactions.map((t) => ({
      ...t,
      ...allocatedTransactions?.[t?.transaction_id],
    }));
  };

  return (
    <>
      <TooltipContainer
        width={150}
        toolTipContent={
          notTransactionItemRow
            ? 'Taxes, discounts, and shipping items not required for transaction'
            : 'Select invoice first'
        }
        isVisible={!!disabled}
      >
        <CustomSelector
          data-cy={`csv-upload__transactions-selector-${amount}`}
          disabled={disabled}
          ref={triggerRef}
          onClick={() => {
            togglePortal();
          }}
        >
          {selectedTransactions?.length ? (
            <>
              <SelectedItem>
                <Counter>{selectedTransactions?.length}</Counter>
                <TruncateStringWithTooltip length={25} tooltipWidth={200}>
                  {selectedTransactions?.map((t) => t?.transaction_name ?? t?.transaction_id)?.join(', ')}
                </TruncateStringWithTooltip>
              </SelectedItem>
            </>
          ) : (
            <RedPlaceholder notTransactionItemRow={notTransactionItemRow}>Select transaction(s)...</RedPlaceholder>
          )}
        </CustomSelector>
      </TooltipContainer>
      <>
        {isPortalVisible ? (
          <Portal>
            <ModalContainer>
              <Modal
                padding="0"
                width="860px"
                height="675px"
                ref={modalRef}
                data-cy="invoicing-schedule__select-transaction-modal"
                overflow="visible"
                compact
              >
                <FlexerColumn>
                  <Header>
                    <DebouncedSearchBar />
                  </Header>

                  <TableHeader>
                    <div>transaction</div>
                    <div>dates</div>
                    <div>amount</div>
                  </TableHeader>

                  <>
                    <Body>
                      <TransactionsList>
                        {isLoading ? (
                          <Centerer>
                            <Spacer height="200px" />
                            <CircleLoader width={24} height={24} />
                          </Centerer>
                        ) : isEmpty(groupedTransactions) ? (
                          <EmptyState>Subscript didn’t find any transactions not invoiced by this customer.</EmptyState>
                        ) : (
                          <SelectTransactionsTable
                            setFieldValue={setFieldValue}
                            customRender={(transaction, index) => {
                              return renderTransaction({
                                transaction,
                                index,
                                selectedTransactions,
                                calculateAllocatedAmounts,
                                amount,
                                setFieldValue,
                              });
                            }}
                            groupedTransactions={groupedTransactions}
                          />
                        )}
                      </TransactionsList>
                    </Body>

                    <Footer>
                      <ConfirmButton
                        filled
                        onClick={() => {
                          hidePortal();
                        }}
                        data-cy="select-transaction-modal__confirm-button"
                      >
                        <span>Save transaction selection</span>
                      </ConfirmButton>
                    </Footer>
                  </>
                </FlexerColumn>
              </Modal>
            </ModalContainer>
          </Portal>
        ) : null}
      </>
    </>
  );
};
