import { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { AppContext } from 'AppContext';
import { ArrowNarrowLeftIcon } from 'components/Icons';
import { useReceiptsAPI } from 'api/billingReceipts';
import { BillingEntitySelector } from 'views/Billing/Common/BillingEntitySelector';
import { Centerer, Column, FlexEndContainer, Row, Spacer } from 'components/Core';
import { useConfirmModal } from 'shared/ConfirmModal';
import { SwitchContainer, SwitchWithLabel } from 'components/Controls';
import { Modal, ModalButton, ModalCloseIcon, ModalContainer, ModalFooter } from 'components/Modal';
import { TooltipContainer } from 'components/Tooltip';
import { InlineButton, SubmitButton } from 'components/Buttons';
import { CircleLoader } from 'components/Loaders';
import { stringToBoolean } from 'utils/stringUtils';
import { hasInvoiceFailedToSend } from 'views/Billing/utils';
import { BillingContext } from 'views/Billing/BillingContext';

import { DEFAULT_INVOICE_LANGUAGE, INVOICE_MAGIC_METADATA } from '../../../consts';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { InvoicingScheduleLanguageSelector } from '../../InvoicingScheduleLanguageSelector';
import { getSortedInvoices, groupTransactionsByExternalId } from '../../utils';
import { IntegrationSelector } from '../../IntegrationSelector';
import { useInvoiceCurrencySwitch } from '../../InvoicingScheduleCreatePanel/useInvoiceCurrencySwitch';
import { SelectTransactionsTable } from '../../InvoicingScheduleSelectTransactionsPanel/SelectTransactionsTable';
import { useExternalInvoiceTemplateSelector } from '../../useExternalInvoiceTemplateSelector';
import { AutoChargeSettingsSection } from './AutoChargeSettingsSection';
import { useSaveSettingsModal } from './useSaveSettingsModal';
import {
  Dot,
  StyledHelpCircleIcon,
  SettingsColumn,
  ColumnTitle,
  StyledArrowIcon,
  IncludedItemsColumn,
  IncludedItemsHeader,
  IncludedItemsCounter,
  IncludedItemsTitle,
} from './styles';
import { useSelectTransactionModal } from '../../InvoicingScheduleSelectTransactionsPanel';
import { useInvoicingScheduleFrequencyDropdown } from '../useInvoicingScheduleFrequencyDropdown';
import { useSelectImportInvoicesModal } from '../../InvoicingScheduleSelectImportInvoicesPanel';
import {
  IMPORT_INVOICES_TABLE_LIST_MODE,
  ImportInvoiceLine,
} from '../../InvoicingScheduleSelectImportInvoicesPanel/ImportInvoiceLine';
import { SELECT_TRANSACTIONS_TABLE_LIST_MODE } from '../../InvoicingScheduleSelectTransactionsPanel/TransactionLineItem';
import { findFirstInvoiceAfterRemoval } from '../InvoiceActions/utils';

export const SettingsModal = ({ onClose, onSave, onEditCustomer, hideCancelButton }) => {
  const {
    orgConfigs,
    orgId,
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);

  const { removeInvoice } = useContext(BillingContext);

  const {
    glIntegration,
    isScheduleDraft,
    includedTransactions,
    currentInvoicingSchedule,
    invoicingScheduleFormValues,
    invoiceUpdateLoading,
    scheduleFormRef,
    invoiceFormRef,
    invoiceFormValues,
    invoicesCreatedInGl,
    handleImportInvoicesSave,
    fillScheduleWithDraftInvoices,
    setSelectedInvoiceId,
    setFetchedSelectedInvoice,
    customerDetails,
  } = useContext(InvoicingScheduleContext);

  const [isLoading, setIsLoading] = useState(false);

  const { billingReceiptDefaults, billingReminderDefaults } = orgConfigs;

  const initialEntityIdRef = useRef(invoicingScheduleFormValues?.entity_id);

  const setFieldValue = scheduleFormRef?.current?.setFieldValue;
  const singleInvoiceSetFieldValue = invoiceFormRef?.current?.setFieldValue;

  const invoices = useMemo(() => getSortedInvoices({ invoices: invoicingScheduleFormValues?.invoices }), [
    invoicingScheduleFormValues?.invoices,
  ]);

  const isCreateMode = !currentInvoicingSchedule?.id;

  const { data: receipts } = useReceiptsAPI({
    orgId,
    filters: {
      invoiceIds: invoices?.map((invoice) => invoice.id)?.filter((id) => !!id) ?? null,
    },
    autoFetch: !!invoices?.find((invoice) => !!invoice.id),
  });

  const { Modal: SelectTransactionModal, openModal: openSelectTransactionModal } = useSelectTransactionModal();
  const { openModal: openImportInvoicesModal, Modal: SelectImportInvoicesModal } = useSelectImportInvoicesModal({
    onImportInvoicesSave: handleImportInvoicesSave,
    selectedInvoices: invoicesCreatedInGl ?? [],
  });

  const { InvoicingScheduleFrequencyDropdown, ignoreImportInvoiceDates } = useInvoicingScheduleFrequencyDropdown({
    isRegularSelector: true,
    setFieldValue,
  });

  const autoSendReceiptValue = useMemo(() => {
    const unsentReceipts = receipts?.filter((receipt) => !receipt.sent_at);

    return (
      invoicingScheduleFormValues?.auto_send_receipts ??
      (unsentReceipts && unsentReceipts.length > 0
        ? unsentReceipts.every((receipt) => receipt.auto_send)
        : billingReceiptDefaults.auto_send)
    );
  }, [billingReceiptDefaults.auto_send, invoicingScheduleFormValues?.auto_send_receipts, receipts]);

  const unsentReminders = invoicingScheduleFormValues?.invoices
    ?.flatMap((invoice) => invoice?.reminders || [])
    ?.filter((reminder) => !reminder?.sent_at);

  const {
    ExternalInvoiceTemplateSelector,
    externalInvoiceTemplateOptions,
    hasInvoiceTemplates,
  } = useExternalInvoiceTemplateSelector({
    name: 'external_invoice_template_id',
    integrationId: glIntegration?.id,
  });

  const {
    openConfirmModal: openConfirmTurnOffAutoSendModal,
    ConfirmModal: ConfirmTurnOffAutoSendModal,
  } = useConfirmModal({
    title: 'Turn off auto-send?',
    width: '500px',
    content: (
      <Column>
        Turning off this setting means you will have to manually save and send all invoices. Subscript won't
        automatically retry invoices that previously failed to send.
      </Column>
    ),
    denyButtonText: 'No, cancel',
    confirmButtonText: 'Yes, turn off auto-send',
    onConfirm: () => {
      setFieldValue('auto_send', !invoicingScheduleFormValues?.auto_send);
      singleInvoiceSetFieldValue('metadata', {
        ...invoiceFormValues?.metadata,
        [`${INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_FAILED_AT}`]: null,
        [`${INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_ERROR}`]: null,
        [`${INVOICE_MAGIC_METADATA.SEND_EMAIL_ERROR_COUNT}`]: null,
      });
      setFieldValue(
        'invoices',
        invoices?.map((invoice) => ({
          ...invoice,
          metadata: {
            ...invoice.metadata,
            [`${INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_FAILED_AT}`]: null,
            [`${INVOICE_MAGIC_METADATA.LAST_SEND_EMAIL_ERROR}`]: null,
            [`${INVOICE_MAGIC_METADATA.SEND_EMAIL_ERROR_COUNT}`]: null,
          },
        })),
      );
    },
    onDeny: () => {},
  });

  const handleAutoSendClick = useCallback(
    (selectedValue) => {
      if (!isScheduleDraft) {
        if (
          stringToBoolean(selectedValue) === false &&
          invoices?.some((invoice) => hasInvoiceFailedToSend({ invoice }))
        ) {
          openConfirmTurnOffAutoSendModal();
          return;
        }
      }

      setFieldValue('auto_send', !invoicingScheduleFormValues?.auto_send);
    },
    [isScheduleDraft, setFieldValue, invoicingScheduleFormValues?.auto_send, invoices, openConfirmTurnOffAutoSendModal],
  );

  const autoSendReminders = invoicingScheduleFormValues?.auto_send_reminders ?? billingReminderDefaults?.auto_send;

  const handleAutoSendRemindersClick = useCallback(() => setFieldValue('auto_send_reminders', !autoSendReminders), [
    setFieldValue,
    autoSendReminders,
  ]);

  const handleAutoSendReceiptsClick = useCallback(() => setFieldValue('auto_send_receipts', !autoSendReceiptValue), [
    autoSendReceiptValue,
    setFieldValue,
  ]);

  const AutoSendComponent = () => (
    <SwitchContainer>
      <SwitchWithLabel
        name="schedule-auto-send"
        onChange={handleAutoSendClick}
        checked={invoicingScheduleFormValues?.auto_send}
        label={
          <>
            Send all <b>invoices</b> automatically
          </>
        }
        disabled={invoicingScheduleFormValues?.auto_charge === true}
        labelSize="12px"
      />
    </SwitchContainer>
  );

  const { InvoiceCurrencySwitch, hasOriginalCurrencyToBillIn } = useInvoiceCurrencySwitch({
    setFieldValue,
    values: invoicingScheduleFormValues,
  });

  const { openSaveSettingsModal, SaveSettingsModal } = useSaveSettingsModal({
    showCurrency: hasOriginalCurrencyToBillIn,
    hasInvoiceTemplates,
    autoSendReceiptValue,
    autoSendRemindersValue: autoSendReminders,
    autoSendInvoicesValue: invoicingScheduleFormValues?.auto_send,
    autoChargeValue: invoicingScheduleFormValues?.auto_charge,
    currencyValue: invoicingScheduleFormValues?.currency ?? defaultOrgCurrency,
    externalInvoiceTemplateValue: invoicingScheduleFormValues?.external_invoice_template_id,
    languageValue: invoicingScheduleFormValues?.language ?? DEFAULT_INVOICE_LANGUAGE,
    receipts,
    unsentReminders,
    afterConfirmFn: () => onClose(),
    externalInvoiceTemplateOptions,
    initialEntityId: initialEntityIdRef.current,
  });

  const handleOnClose = () => {
    scheduleFormRef?.current && scheduleFormRef.current.resetForm();
    onClose();
  };

  const [transactionIdsAndOptions, setTransactionIdsAndOptions] = useState(
    (includedTransactions ?? []).reduce((acc, trx) => Object.assign(acc, { [trx?.id]: {} }), {}),
  );

  const handleInvoiceOnRemove = async ({ invoice }) => {
    setIsLoading(true);
    if (invoice.id) {
      await removeInvoice({ id: invoice.id, params: { keepInExternalSystem: true } });
      findFirstInvoiceAfterRemoval({
        removedInvoicesIds: [invoiceFormValues?.id],
        invoicingScheduleFormValues,
        setSelectedInvoiceId,
        setFetchedSelectedInvoice,
      });
    } else {
      await fillScheduleWithDraftInvoices({
        invoices: invoicingScheduleFormValues.invoices.filter((i) => i.external_id !== invoice.external_id),
        frequency: invoicingScheduleFormValues?.invoicing_frequency,
      });
    }
    setIsLoading(false);
  };

  const handleOnSave = async () => {
    if (onSave) {
      setIsLoading(true);
      try {
        await onSave({ ignoreImportInvoiceDates });
      } finally {
        setIsLoading(false);
      }
    } else {
      openSaveSettingsModal();
    }
  };

  return (
    <>
      <ModalContainer>
        <Modal width="1400px" minHeight="500px" padding="0px" height="auto" overflow="visible">
          <ModalCloseIcon data-cy="billing__invoice-schedule-modal__settings__close-button" onClick={handleOnClose} />

          {invoiceUpdateLoading ? (
            <Centerer style={{ marginTop: 40, marginBottom: 'auto' }}>
              <CircleLoader />
            </Centerer>
          ) : (
            <Row horizontal="space-between" vertical="flex-start" style={{ flexGrow: 1 }}>
              <SettingsColumn>
                <ColumnTitle>Main settings</ColumnTitle>

                <BillingEntitySelector name="entity_id" value={invoicingScheduleFormValues?.entity_id} />
                <Spacer height="16px" />

                <InvoicingScheduleLanguageSelector />
                <Spacer height="16px" />

                <IntegrationSelector />
                <Spacer height="8px" />
                <ExternalInvoiceTemplateSelector />
                <Spacer height="16px" />

                {isCreateMode && (
                  <>
                    <InvoicingScheduleFrequencyDropdown />
                    <Spacer height="16px" />
                  </>
                )}

                {!!hasOriginalCurrencyToBillIn && (
                  <>
                    <InvoiceCurrencySwitch />
                    <Spacer height="16px" />
                  </>
                )}

                {isCreateMode && (
                  <>
                    <SwitchWithLabel
                      label="Prorate invoices"
                      name="prorate_invoices"
                      checked={invoicingScheduleFormValues?.prorate_invoices}
                      onChange={() => setFieldValue('prorate_invoices', !invoicingScheduleFormValues?.prorate_invoices)}
                    />
                    <Spacer height="16px" />
                  </>
                )}

                {!!customerDetails?.parent_customer_id && isCreateMode && (
                  <>
                    <SwitchWithLabel
                      label="Bill to parent customer"
                      name="useParentCustomer"
                      checked={invoicingScheduleFormValues?.useParentCustomer}
                      onChange={() =>
                        setFieldValue('useParentCustomer', !invoicingScheduleFormValues?.useParentCustomer)
                      }
                    />
                    <Spacer height="16px" />
                  </>
                )}

                <ColumnTitle>Sending settings</ColumnTitle>

                <SwitchWithLabel
                  name="schedule-auto-send-receipts"
                  onChange={handleAutoSendReceiptsClick}
                  checked={autoSendReceiptValue}
                  label={
                    <span>
                      Send all <b>receipts</b> to customers after payments{' '}
                      <TooltipContainer
                        fontSize="12px"
                        toolTipContent={
                          <ul
                            style={{
                              textAlign: 'left',
                            }}
                          >
                            <li>
                              <Dot /> Subscript will auto-create and auto-send a receipt upon auto-charging a customer.
                            </li>
                            <li>
                              <Dot /> To auto-send a receipt when invoices are marked as paid in your GL, please
                              generate the receipt manually first.
                            </li>
                            <li>
                              <Dot /> Receipts will not be auto-sent when an invoice is manually marked as paid in
                              Subscript.
                            </li>
                          </ul>
                        }
                      >
                        <StyledHelpCircleIcon />
                      </TooltipContainer>
                    </span>
                  }
                  labelSize="12px"
                />

                <Spacer height="16px" />

                {invoicingScheduleFormValues?.auto_charge === true ? (
                  <TooltipContainer toolTipContent="When auto-charge is enabled, the invoices will be charged on invoice date, and you will not need to send them">
                    <AutoSendComponent />
                  </TooltipContainer>
                ) : (
                  <AutoSendComponent />
                )}

                <Spacer height="16px" />

                <SwitchContainer>
                  <SwitchWithLabel
                    onChange={handleAutoSendRemindersClick}
                    checked={autoSendReminders}
                    name="schedule-auto-send-reminders"
                    label={
                      <>
                        Send all <b>reminders</b> automatically
                      </>
                    }
                    labelSize="12px"
                  />
                </SwitchContainer>
                <AutoChargeSettingsSection />
              </SettingsColumn>

              <IncludedItemsColumn>
                <IncludedItemsHeader>
                  <IncludedItemsCounter data-cy="invoicing-schedule__settings-modal__transactions-counter">
                    {includedTransactions?.length ?? 0}
                  </IncludedItemsCounter>
                  <IncludedItemsTitle>Transactions</IncludedItemsTitle>
                  <InlineButton
                    data-cy="billing__invoice-schedule-modal__open-transactions-list-button"
                    style={{ padding: '4px 8px' }}
                    isSecondary
                    withBackground
                    onClick={(event) => {
                      event.preventDefault();
                      openSelectTransactionModal();
                    }}
                  >
                    Add transactions
                  </InlineButton>
                </IncludedItemsHeader>

                <Column horizontal="flex-start" vertical="flex-start" style={{ height: '300px', overflow: 'auto' }}>
                  <SelectTransactionsTable
                    showOptions={isCreateMode}
                    currentInvoicingSchedule={currentInvoicingSchedule}
                    transactionIdsAndOptions={transactionIdsAndOptions}
                    setTransactionIdsAndOptions={setTransactionIdsAndOptions}
                    groupedTransactions={groupTransactionsByExternalId({
                      transactions: includedTransactions,
                      initiallySelectedTransactions: includedTransactions,
                    })}
                    listMode={SELECT_TRANSACTIONS_TABLE_LIST_MODE.DELETABLE}
                  />
                </Column>

                <Spacer height="30px" />

                <IncludedItemsHeader>
                  <IncludedItemsCounter data-cy="invoicing-schedule__settings-modal__imported-invoices-counter">
                    {invoicesCreatedInGl?.length ?? 0}
                  </IncludedItemsCounter>
                  <IncludedItemsTitle>Imported Invoices</IncludedItemsTitle>
                  <InlineButton
                    data-cy="billing__invoice-schedule-modal__open-imported-invoices-list-button"
                    style={{ padding: '4px 8px' }}
                    isSecondary
                    withBackground
                    onClick={(event) => {
                      event.preventDefault();
                      openImportInvoicesModal({ customerId: invoicingScheduleFormValues?.customer_id });
                    }}
                  >
                    Import external
                  </InlineButton>
                </IncludedItemsHeader>

                <Column horizontal="flex-start" vertical="flex-start" style={{ maxHeight: '300px', overflow: 'auto' }}>
                  {invoicesCreatedInGl?.map((invoice) => (
                    <ImportInvoiceLine
                      key={invoice.external_id}
                      externalInvoice={invoice}
                      showCheckbox
                      onInvoiceRemove={async () => await handleInvoiceOnRemove({ invoice })}
                      isSelected={invoicesCreatedInGl.some((i) => i.external_id === invoice.external_id)}
                      listMode={IMPORT_INVOICES_TABLE_LIST_MODE.DELETABLE}
                    />
                  ))}
                </Column>
              </IncludedItemsColumn>
            </Row>
          )}

          <ModalFooter noFixedHeight padding="12px 36px">
            <Row horizontal="space-between">
              <div></div>
              <FlexEndContainer>
                {!hideCancelButton && (
                  <ModalButton
                    data-cy="invoicing-schedule__settings-modal__cancel"
                    style={{ marginRight: 8 }}
                    onClick={handleOnClose}
                  >
                    <b>Cancel</b>
                  </ModalButton>
                )}
                {!!onEditCustomer && (
                  <ModalButton
                    data-cy="invoicing-schedule__settings-modal__edit-customer"
                    style={{ marginRight: 8 }}
                    onClick={onEditCustomer}
                    fontSize="12px"
                  >
                    <ArrowNarrowLeftIcon size="16px" />
                    <Spacer width="6px" />
                    <b>Edit Customer</b>
                  </ModalButton>
                )}
                <SubmitButton
                  data-cy="invoicing-schedule__settings-modal__confirm"
                  style={{ fontSize: 12 }}
                  onClick={handleOnSave}
                  disabled={isLoading}
                >
                  <b>Save Settings</b>
                  <Spacer width="8px" />
                  {isLoading ? <CircleLoader width="16px" height="16px" /> : <StyledArrowIcon />}
                </SubmitButton>
              </FlexEndContainer>
            </Row>
          </ModalFooter>
        </Modal>
      </ModalContainer>

      <SaveSettingsModal />
      <ConfirmTurnOffAutoSendModal />
      <SelectTransactionModal />
      <SelectImportInvoicesModal />
    </>
  );
};

export const useSettingsModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = useCallback(() => setShowModal(true), []);

  const Modal = useCallback(
    () => (showModal ? <SettingsModal onClose={() => setShowModal(false)} {...props} /> : null),
    [props, showModal],
  );

  return {
    openSettingsModal: openModal,
    SettingsModal: Modal,
    isSettingsModalVisible: showModal,
  };
};
