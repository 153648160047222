import { components, createFilter } from 'react-select';

import { FormikCustomInput, FormikCustomSelector } from 'components/Controls';
import { ColumnMapperSelector } from 'shared/CsvUpload/CsvTable/CsvTable';
import { SelectDropdownButton } from 'components/Buttons';
import { QuestionIcon } from 'components/Icons';

import {
  HeaderSelector,
  SelectorTitle,
  CustomOption,
  CustomOptionKey,
  CustomOptionValue,
} from 'shared/CsvUpload/styles';
import { CSV_MODES } from 'shared/CsvUpload/CsvUpload';

import { renderProductIdCell } from './fuzzyCells';
import { CsvUploadTransactionsSelector } from './CsvUploadTransactionsSelector';
import { INVOICE_ITEM_TYPES } from '../consts';
import { TooltipContainer } from 'components/Tooltip';

const { Option } = components;

export const CustomSingleOption = (props) => {
  // optimization for large lists
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;

  return (
    <Option {...props}>
      <CustomOption>
        <CustomOptionKey>{props.data.value}</CustomOptionKey>
        <CustomOptionValue>{props.data.label}</CustomOptionValue>
      </CustomOption>
    </Option>
  );
};

export const SelectDropdownWithTooltip = ({ name, selected, options, onSelect }) => (
  <SelectDropdownButton
    name={name}
    noMargin
    selected={selected}
    options={options}
    fontSize="12px"
    onSelect={onSelect}
    toolTipContent="Click to change type"
    toolTipWidth="125px"
  />
);

export const getColumns = ({
  organization,
  formRef,
  csvColumns,
  csvColumnsMapper,
  setCsvColumnsMapper,

  createOrUpdateMode,

  invoicesSelectOptions,

  invoices,

  fuzzyRowsMapper,
  setFuzzyRowsMapper,
}) => {
  return [
    {
      width: 50,
      accessor: 'status',
      Cell: () => <></>,
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Invoice</SelectorTitle>
          <ColumnMapperSelector
            field="invoice_id"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      width: 180,
      accessor: 'invoice_id',
      Cell: ({ row, cell: { value } }) => (
        <FormikCustomSelector
          isClearable={true}
          height={32}
          components={{ Option: CustomSingleOption }}
          placeholder="Select id..."
          minWidth={160}
          menuWidth={200}
          filterOption={createFilter({ ignoreAccents: false })}
          errorWithoutTooltip
          value={value ? invoicesSelectOptions?.find((option) => option.value === value) : null}
          options={invoicesSelectOptions}
          noSort
          handleChange={(option) => {
            if (option) {
              formRef?.current?.setFieldValue(`[${row.original.rowIndex}].invoice_id`, option.value);
            } else {
              formRef?.current?.setFieldValue(`[${row.original.rowIndex}].invoice_id`, null);
            }
          }}
          name={`[${row.original.rowIndex}].invoice_id`}
        />
      ),
    },
    ...(createOrUpdateMode === CSV_MODES.UPDATE
      ? [
          {
            Header: () => (
              <HeaderSelector>
                <SelectorTitle>Invoice Item ID</SelectorTitle>
                <ColumnMapperSelector
                  field="id"
                  csvColumnsMapper={csvColumnsMapper}
                  setCsvColumnsMapper={setCsvColumnsMapper}
                  csvColumns={csvColumns}
                />
              </HeaderSelector>
            ),
            width: 180,
            accessor: 'id',
            Cell: ({ row, cell: { value } }) => {
              const invoice = invoices?.data?.find((invoice) => invoice?.id === row?.original?.invoice_id);
              const invoieItemsOptions = invoice?.invoice_items?.map((item) => ({
                value: item?.id,
                label: item?.description ?? item?.name,
              }));

              return (
                <TooltipContainer isVisible={!row?.original?.invoice_id} toolTipContent={'Select invoice first'}>
                  <FormikCustomSelector
                    isDisabled={!row?.original?.invoice_id}
                    isClearable={true}
                    height={32}
                    components={{ Option: CustomSingleOption }}
                    placeholder="Select id..."
                    minWidth={160}
                    menuWidth={200}
                    filterOption={createFilter({ ignoreAccents: false })}
                    errorWithoutTooltip
                    value={value ? { value: value, label: value } : null}
                    options={invoieItemsOptions}
                    noSort
                    handleChange={(option) => {
                      if (option) {
                        formRef?.current?.setFieldValue(`[${row.original.rowIndex}].id`, option.value);
                      } else {
                        formRef?.current?.setFieldValue(`[${row.original.rowIndex}].id`, null);
                      }
                    }}
                    name={`[${row.original.rowIndex}].id`}
                  />
                </TooltipContainer>
              );
            },
          },
        ]
      : []),
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Transactions</SelectorTitle>

          <ColumnMapperSelector
            field="transactions"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      width: 220,
      accessor: 'transactions',
      Cell: ({ row, cell: { value } }) => {
        const invoice = invoices?.data?.find((invoice) => invoice?.id === row?.original?.invoice_id);

        return (
          <CsvUploadTransactionsSelector
            notTransactionItemRow={!!row?.original?.type}
            disabled={!row?.original?.invoice_id || !!row?.original?.type}
            setFieldValue={(fieldValue) =>
              formRef?.current?.setFieldValue(`[${row.original.rowIndex}].transactions`, fieldValue)
            }
            selectedTransactions={value}
            amount={row?.original?.amount}
            customerId={invoice?.customer_id}
          />
        );
      },
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Product</SelectorTitle>

          <ColumnMapperSelector
            field="product_id"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'product_id',
      width: 180,
      Cell: (cellProps) =>
        renderProductIdCell({
          ...cellProps,
          organization,
          formCurrent: formRef?.current,
          fuzzyRowsMapper,
          setFuzzyRowsMapper,
        }),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Amount</SelectorTitle>
          <ColumnMapperSelector
            field="amount"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'amount',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter amount..."
          width="100%"
          name={`[${row.original.rowIndex}].amount`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Seats</SelectorTitle>
          <ColumnMapperSelector
            field="seats"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'seats',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter seats..."
          width="100%"
          name={`[${row.original.rowIndex}].seats`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>
            <span>Description</span>
            <TooltipContainer
              tooltipWrapperStyles={{ display: 'flex', marginLeft: '4px' }}
              toolTipContent={'Description is required for not transaction items'}
            >
              <QuestionIcon stroke="var(--primaryBlack20)" />
            </TooltipContainer>
          </SelectorTitle>
          <ColumnMapperSelector
            field="description"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'description',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter description..."
          width="100%"
          name={`[${row.original.rowIndex}].description`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>
            <span>Type</span>
            <TooltipContainer
              tooltipWrapperStyles={{ display: 'flex', marginLeft: '4px' }}
              toolTipContent={"Don't need to specify type for transaction items"}
            >
              <QuestionIcon stroke="var(--primaryBlack20)" />
            </TooltipContainer>
          </SelectorTitle>
          <ColumnMapperSelector
            field="type"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'type',
      width: 180,
      Cell: ({ row, cell: { value } }) => (
        <FormikCustomSelector
          isClearable={true}
          height={32}
          components={{ Option: CustomSingleOption }}
          placeholder="Transaction item"
          minWidth={170}
          menuWidth={200}
          filterOption={createFilter({ ignoreAccents: false })}
          errorWithoutTooltip
          value={value ? { value: value, label: value } : null}
          options={Object.values(INVOICE_ITEM_TYPES)?.map((type) => ({
            value: type,
            label: type,
          }))}
          noSort
          handleChange={(option) => {
            if (option) {
              formRef?.current?.setFieldValue(`[${row.original.rowIndex}].type`, option.value);
            } else {
              formRef?.current?.setFieldValue(`[${row.original.rowIndex}].type`, null);
            }
          }}
          name={`[${row.original.rowIndex}].type`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>
            <span>Name</span>
            <TooltipContainer
              tooltipWrapperStyles={{ display: 'flex', marginLeft: '4px' }}
              toolTipContent={'Name is required for grouped transactions'}
            >
              <QuestionIcon stroke="var(--primaryBlack20)" />
            </TooltipContainer>
          </SelectorTitle>
          <ColumnMapperSelector
            field="name"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'name',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter name..."
          width="100%"
          name={`[${row.original.rowIndex}].name`}
        />
      ),
    },
    {
      Header: () => (
        <HeaderSelector>
          <SelectorTitle>Metadata</SelectorTitle>
          <ColumnMapperSelector
            field="metadata"
            csvColumnsMapper={csvColumnsMapper}
            setCsvColumnsMapper={setCsvColumnsMapper}
            csvColumns={csvColumns}
          />
        </HeaderSelector>
      ),
      accessor: 'metadata',
      width: 180,
      Cell: ({ row }) => (
        <FormikCustomInput
          style={{ height: 32 }}
          errorWithoutTooltip
          placeholder="Enter metadata..."
          width="100%"
          name={`[${row.original.rowIndex}].metadata`}
        />
      ),
    },
  ];
};
