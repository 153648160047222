import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ORG_CONFIGS } from 'consts/global';
import { safeParseJson } from 'utils/jsonUtils';

dayjs.extend(utc);

const LOCAL_STORAGE_KEYS = {
  customerMetadataSegments: 'customerMetadataSegments',
  transactionMetadataSegments: 'transactionMetadataSegments',
};

const CONFIGS_FOR_SESSION_STORAGE = [ORG_CONFIGS.BILLING_REMINDER_DEFAULTS, ORG_CONFIGS.BILLING_INVOICE_DEFAULTS];

export const getLocalAppSettings = () => safeParseJson(localStorage.getItem('appSettings'));
const setLocalAppSettings = (value) => localStorage.setItem('appSettings', JSON.stringify(value));
export const clearAppSettings = () => localStorage.removeItem('appSettings');

//we save configs in sessionStorage to be able to get them using a function in not a react components
export const setSessionOrgConfigs = ({ configs }) => {
  const filteredConfigs = Object.fromEntries(
    Object.entries(configs ?? {})?.filter(([key]) => CONFIGS_FOR_SESSION_STORAGE.includes(key)),
  );
  sessionStorage.setItem('orgConfigs', JSON.stringify(filteredConfigs ?? {}));
};

export const setSessionCustomerMetadataSegments = ({ customerMetadataSegments }) =>
  sessionStorage.setItem(LOCAL_STORAGE_KEYS.customerMetadataSegments, JSON.stringify(customerMetadataSegments));

export const setSessionTransactionMetadataSegments = ({ transactionMetadataSegments }) =>
  sessionStorage.setItem(LOCAL_STORAGE_KEYS.transactionMetadataSegments, JSON.stringify(transactionMetadataSegments));

export const transformConfigKeysToCamelCase = ({ config, customerMetadataSegments, transactionMetadataSegments }) => {
  const {
    [ORG_CONFIGS.TRANSACTION_RECOGNITION]: defaultTransactionsRecognition,
    [ORG_CONFIGS.CUSTOMER_METADATA]: customerMetadata,
    [ORG_CONFIGS.TRANSACTION_METADATA]: transactionMetadata,
    [ORG_CONFIGS.TRANSACTIONS_LOCK_BEFORE_DATE]: transactionsLockBeforeDate,
    [ORG_CONFIGS.ACCOUNTING_SPREADS_LOCK_BEFORE_DATE]: accountingSpreadsLockBeforeDate,
    [ORG_CONFIGS.SEGMENTS]: segments,
    [ORG_CONFIGS.LINE_ITEM_MAPPINGS]: lineItemMappings,
    [ORG_CONFIGS.TRANSACTION_MAPPINGS]: transactionMappings,
    [ORG_CONFIGS.CLOSED_WON_STAGES]: closedWonStages,
    [ORG_CONFIGS.UPLOAD_CSV_CUSTOMERS_ID_SOURCE]: uploadCsvCustomersIdSource,
    [ORG_CONFIGS.AUTOMATIC_CONFIRM_RULESET]: transactionsAutoConfirmRuleset,
    [ORG_CONFIGS.QUARTERS]: quarters,
    [ORG_CONFIGS.QUARTERS_YEAR_OFFSET]: quartersYearOffset,
    [ORG_CONFIGS.CUSTOMER_CUSTOM_FIELDS]: customerCustomFields,
    [ORG_CONFIGS.TRANSACTION_CUSTOM_FIELDS]: transactionCustomFields,
    [ORG_CONFIGS.BILLING_REMINDER_DEFAULTS]: billingReminderDefaults,
    [ORG_CONFIGS.BILLING_INVOICE_DEFAULTS]: billingInvoiceDefaults,
    [ORG_CONFIGS.BILLING_SENDER_DEFAULTS]: billingSenderDefaults,
    [ORG_CONFIGS.BILLING_TILL_CANCELLED_INVOICE_MONTHS_IN_ADVANCE]: billingTillCancelledInvoiceMonthsInAdvance,
    [ORG_CONFIGS.CONTRACT_READER_ASSISTANT]: contractReaderAssistant,
    [ORG_CONFIGS.ACCOUNTING_SPREAD_POLICY]: accountingSpreadPolicy,
    [ORG_CONFIGS.INCOME_ACCOUNT_REF_ID]: incomeAccountRefId,
    [ORG_CONFIGS.INCOME_ACCOUNT_OPTIONS]: incomeAccountOptions,
    [ORG_CONFIGS.BILLING_UPDATES_EMAILS_TO_SEND]: billingUpdatesEmailsToSend,
    [ORG_CONFIGS.BILLING_CUSTOM_FIELDS]: billingCustomFields,
    [ORG_CONFIGS.BILLING_RECEIPT_DEFAULTS]: billingReceiptDefaults,
    [ORG_CONFIGS.BILLING_CREDIT_NOTE_DEFAULTS]: billingCreditNoteDefaults,
    [ORG_CONFIGS.AUTO_CHARGE_INVOICE_SENDING_DEFAULTS]: autoChargeInvoiceSendingDefaults,
    [ORG_CONFIGS.DEFAULT_ACCOUNTING_RATE_TYPE]: defaultAccountingRateType,
    [ORG_CONFIGS.ACCOUNTING_CURRENCY]: accountingCurrency,
    [ORG_CONFIGS.ACCOUNTING_CURRENCIES]: accountingCurrencies,
    // The ones we don't need to convert to camel case
    ...rest
  } = { ...config, ...config?.custom_settings };

  return {
    defaultTransactionsRecognition,
    customerMetadata,
    transactionMetadata,
    segments,
    lineItemMappings,
    transactionMappings,
    transactionsLockBeforeDate,
    accountingSpreadsLockBeforeDate,
    closedWonStages,
    uploadCsvCustomersIdSource,
    transactionsAutoConfirmRuleset,
    quarters: quarters?.value,
    quartersYearOffset: quartersYearOffset?.value,
    customCustomerMetadataSegments: customerMetadataSegments,
    customTransactionMetadataSegments: transactionMetadataSegments,
    customerCustomFields,
    transactionCustomFields,
    billingReminderDefaults,
    billingInvoiceDefaults,
    billingSenderDefaults,
    billingTillCancelledInvoiceMonthsInAdvance,
    contractReaderAssistant,
    accountingSpreadPolicy,
    incomeAccountRefId,
    incomeAccountOptions,
    billingUpdatesEmailsToSend,
    billingCustomFields,
    billingReceiptDefaults,
    autoChargeInvoiceSendingDefaults,
    defaultAccountingRateType,
    accountingCurrency,
    accountingCurrencies,
    billingCreditNoteDefaults,
    ...rest,
  };
};

export const getOrgConfigs = (organizationConfigs) => {
  const config = organizationConfigs ?? safeParseJson(sessionStorage.getItem('orgConfigs'));

  const customerMetadataSegments = safeParseJson(
    sessionStorage.getItem(LOCAL_STORAGE_KEYS.customerMetadataSegments),
    [],
  );
  const transactionMetadataSegments = safeParseJson(
    sessionStorage.getItem(LOCAL_STORAGE_KEYS.transactionMetadataSegments),
    [],
  );
  return transformConfigKeysToCamelCase({ config, customerMetadataSegments, transactionMetadataSegments });
};

const isLeaf = (obj) => typeof obj === 'object' && obj.value !== undefined && obj.lastUpdatedAt !== undefined;
const mergeLeaves = (base, custom) => (dayjs(base.lastUpdatedAt).isAfter(custom.lastUpdatedAt) ? base : custom);
const getLeaf = (appSettings, key) => key.split('.').reduce((acc, curr) => acc?.[curr], appSettings);

const mergeAppSettings = (base, custom = {}) =>
  Object.entries(base).reduce((acc, [key, value]) => {
    if (!custom || !custom[key]) {
      acc[key] = value;
    } else if (isLeaf(value)) {
      acc[key] = isLeaf(custom[key]) ? mergeLeaves(value, custom[key]) : value;
    } else {
      acc[key] = mergeAppSettings(value, custom[key]);
    }
    return acc;
  }, {});

export const syncAppSettings = (backendAppSettings) => {
  const localAppSettings = getLocalAppSettings();
  const merged = mergeAppSettings(backendAppSettings, localAppSettings);
  setLocalAppSettings(merged);
};

export const getAppSetting = (key, appSettings) => getLeaf(appSettings ?? getLocalAppSettings(), key)?.value;

export const setAppSetting = (key, value, timestamp) => {
  const localAppSettings = getLocalAppSettings();
  const leaf = getLeaf(localAppSettings, key);
  if (leaf) {
    leaf.value = value;
    leaf.lastUpdatedAt = timestamp || dayjs.utc().format();
  }
  setLocalAppSettings(localAppSettings);
};
