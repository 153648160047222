import styled from 'styled-components';
import { FlexerColumn } from 'components/Core';

export const HeaderSelector = styled(FlexerColumn)`
  font-weight: 400;
  text-transform: ${({ textTransform }) => textTransform ?? 'capitalize'};
  color: var(--primaryBlack);
`;

export const SelectorTitle = styled.div`
  font-size: 12px;
  line-height: 21px;
  align-items: center;
  display: flex;
  opacity: 0.4;
  margin-bottom: 8px;
`;

export const CustomOption = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: flex-start;
  border-bottom: 1px solid var(--primaryBlack3);

  &:hover {
    cursor: pointer;
  }
`;

export const CustomOptionKey = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const CustomOptionValue = styled.div`
  opacity: 0.3;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
`;

export const IdSourceSelectorWrapper = styled.div`
  min-height: 20px;
  text-transform: capitalize;
  color: var(--primaryBlack);

  * {
    width: fit-content;
    font-weight: 400;
  }
`;
