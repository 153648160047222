import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { isEmpty, omit } from 'lodash';
import dayjs from 'dayjs';

import {
  INVOICE_FAILED_STATUS,
  INVOICE_ITEM_TYPE_TO_KEY,
  INVOICE_STATUSES,
  INVOICE_JOBS_OPERATIONS,
} from 'views/Billing/consts';
import { useReceiptAPI } from 'api/billingReceipts';
import { AppContext } from 'AppContext';
import {
  getInvoiceData,
  getInvoiceInitialValues,
  getModalInvoiceStatus,
  getModalInvoiceTitle,
  invoiceGenerateChanges,
  invoiceWasEdited,
} from 'views/Billing/InvoiceModal/utils';
import { BillingContext } from 'views/Billing/BillingContext';
import { SplitButton, SplitButtonPart, SplitButtonSeparator } from 'components/Buttons';
import { AlertFilledIcon } from 'components/Icons';
import { Popover, PopoverActions, PopoverButton, PopoverPrompt, PopoverWrapper, usePortal } from 'components/Portal';
import { useClickOutside } from 'utils/hooks';
import { ReactComponent as SaveIcon } from 'images/device-floppy.svg';
import { ReactComponent as FileCheckIcon } from 'images/contracts-file-check-icon.svg';
import { useToasts } from 'components/Toasts';
import { Centerer, FlexerColumn, FlexerRow, Row } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { ReactComponent as ArrowRightCircleWhiteIcon } from 'images/arrow-right-circle-white.svg';
import { ReactComponent as FileInvoiceIcon } from 'images/file-invoice.svg';
import { ReactComponent as ReceiptIcon } from 'images/receipt.svg';
import { COLORS } from 'consts/colors';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { usePDFPreviewModal } from 'views/Billing/InvoicePreviewModalV2';
import { TooltipContainer } from 'components/Tooltip';
import { useReceiptModal } from 'views/Billing/ReceiptModal';
import { GL_INTEGRATION_SERVICES } from 'consts/integrations';

import { ApplyToAllPopover, ApplyToAllPopoverText } from '../../InvoiceScheduleWarnings/styles';
import { SaveToExternalPopover } from './SaveToExternalPopover';
import { ExternalAllocationConfirmModal } from './ExternalAllocationConfirmModal';
import { InvoicingScheduleContext } from '../../InvoicingScheduleContext';
import { InvoiceActions } from '../../InvoicingScheduleTabsPanel/InvoiceActions/InvoiceActions';
import {
  buildInvoicesFromChangedDraftInvoice,
  getClearedInvoiceChanges,
  getInvoicesForBulkEdit,
  getSortedInvoices,
} from '../../utils';
import {
  ExternalLinkText,
  PanelHeaderButton,
  PanelHeaderTitle,
  PanelHeaderTitleText,
  PanelHeaderInfo,
  PanelHeaderWrapper,
} from '../../panel.styles';
import { EditNoteButton, InternalNoteTitle, InternalNoteWrapper } from '../../styles';
import { useInternalNoteModal } from '../../InvoicingScheduleTabsPanel/useInternalNoteModal';
import { isImportInvoiceTotalMatchWithSubscriptTotal } from './InvoiceItems/utils';

dayjs.extend(require('dayjs/plugin/utc'));

const StyledFileInvoiceIcon = styled(FileInvoiceIcon)`
  margin-left: 8px;

  path {
    fill: var(--darkGreen);
  }
`;

const StyledReceiptIcon = styled(ReceiptIcon)`
  width: 20px;
  height: 20px;
  margin-left: 8px;

  path {
    fill: var(--darkGreen);
  }
`;

const InvoiceStatus = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 900;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
  line-height: 20px;
`;

const InvoicePdfOption = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  border-bottom: 1px solid var(--neutralGray);
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;

    g {
      opacity: 1;
    }

    path {
      fill: var(--primaryBlack50);
    }
  }

  &:hover {
    svg {
      path {
        fill: var(--primaryBlack);
      }
    }
  }
`;

const ProceedToSendEmailPopover = ({ children, onClose, onProceed }) => {
  const popoverRef = useClickOutside(onClose);

  return (
    <Popover darkMode width="200px" XOffset={100} YOffset={35} ref={popoverRef}>
      <PopoverPrompt>{children}</PopoverPrompt>
      <PopoverActions>
        <PopoverButton onClick={onClose}>No</PopoverButton>
        <PopoverButton onClick={onProceed} primary>
          Yes
        </PopoverButton>
      </PopoverActions>
    </Popover>
  );
};

export const InvoiceHeader = () => {
  const {
    orgId,
    appSettings: { currencyISOCode: defaultOrgCurrency },
  } = useContext(AppContext);
  const {
    invoicingScheduleFormValues,
    validateInvoiceFormAndExecuteAction,
    fetchedSelectedInvoice,
    invoiceFormValues,
    allowResend,
    isScheduleDraft,
    openInvoicePreviewModal,
    fillScheduleWithDraftInvoices,
    setAllowResend,
    setSelectedInvoiceId,
    scheduleFormRef,
    selectedInvoiceId,
    invoicingService,
    isSelectedInvoiceImported,
    invoicingServiceDisplayName,
    currentInvoicingSchedule,
    customerDetails: customer,
  } = useContext(InvoicingScheduleContext);

  const creditNotes = invoiceFormValues?.credit_notes ?? [];
  const internalNotes = invoiceFormValues?.internal_notes;

  const { Modal: InternalNoteModal, openModal: openInternalNoteModal } = useInternalNoteModal({
    values: invoiceFormValues,
  });

  const { createInvoice, updateInvoice, bulkEditInvoices, saveInvoiceWithPreview } = useContext(BillingContext);

  const { triggerRef, togglePortal, isPortalVisible, Portal } = usePortal({ XOffset: 40, YOffset: 50 });

  const {
    operations: { createReceipt },
  } = useReceiptAPI({ autoFetch: false, orgId });

  const { openModal: openPreviewPdfModal, Modal: PreviewPDFModal, isModalOpen } = usePDFPreviewModal();
  const { openModal: openReceiptModal, Modal: ReceiptModal } = useReceiptModal({
    receiptId: invoiceFormValues?.receipt?.id,
  });

  const [invoiceChanges, setInvoiceChanges] = useState(null);
  const [showSaveToExternalPopover, setShowSaveToExternalPopover] = useState(false);
  const [showProceedToSendPopover, setShowProceedToSendPopover] = useState(false);
  const [showApplyToAllPopover, setShowApplyToAllPopover] = useState(false);
  const [externalAllocationSubmitParams, setExternalAllocationSubmitParams] = useState(null);
  const popoverRef = useClickOutside(() => setShowApplyToAllPopover(false));

  const toggleShowApplyToAllPopover = () => setShowApplyToAllPopover((prevValue) => !prevValue);

  const { pushToast } = useToasts();

  const invoices = useMemo(() => getSortedInvoices({ invoices: invoicingScheduleFormValues?.invoices }), [
    invoicingScheduleFormValues?.invoices,
  ]);

  const modalInvoiceStatus = invoiceFormValues ? getModalInvoiceStatus({ invoice: invoiceFormValues }) : '';
  const currentJobOperation = fetchedSelectedInvoice ? fetchedSelectedInvoice.jobOperation || null : null;

  const modalInvoiceTitle = invoiceFormValues
    ? getModalInvoiceTitle({
        invoice: invoiceFormValues,
        isScheduleAutoCharge: invoicingScheduleFormValues?.auto_charge,
        hasActivePayment: customer?.has_active_payment_method,
      })
    : '';

  const isNotSendOrRemindInvoice = ![INVOICE_STATUSES.SENT, INVOICE_STATUSES.REMIND].includes(modalInvoiceStatus);

  const isProcessingInvoice = [INVOICE_STATUSES.QUEUED_UP, INVOICE_STATUSES.PROCESSING].includes(modalInvoiceStatus);
  const isProcessingTaxes = [INVOICE_STATUSES.TAXES_PROCESSING].includes(modalInvoiceStatus);

  const invoiceIndex = invoices.findIndex(
    (item) => (item.id ?? item?.unsavedId) === (fetchedSelectedInvoice.id ?? fetchedSelectedInvoice?.unsavedId),
  );

  const handleSubmit = async ({
    applyToAll,
    isPreviewAndSave,
    invoiceChanges: propInvoiceChanges,
    createExternalInvoice,
    saveOnlyInSubscript,
    isResend,
  }) => {
    const changes = getClearedInvoiceChanges({ invoiceChanges: propInvoiceChanges ?? invoiceChanges });

    const applyChangesToAllInvoices = async ({ ignoreCurrent = false }) =>
      await bulkEditInvoices({
        data: getInvoicesForBulkEdit({
          ignoreCurrent,
          changes,
          currentInvoice: invoiceFormValues,
          invoices: invoicingScheduleFormValues?.invoices,
        }),
      });

    if (invoiceFormValues?.unsavedId) {
      if (isScheduleDraft) {
        validateInvoiceFormAndExecuteAction({
          action: async () => {
            await fillScheduleWithDraftInvoices({
              invoices: buildInvoicesFromChangedDraftInvoice({
                applyToAll,
                invoiceChanges: changes,
                invoices: invoicingScheduleFormValues?.invoices,
                savedInvoice: invoiceFormValues,
              }),
            });

            pushToast('Successfully updated invoice!', 'success');
          },
        });
      } else {
        validateInvoiceFormAndExecuteAction({
          action: async () => {
            await scheduleFormRef?.current?.setFieldValue(
              'invoices',
              invoicingScheduleFormValues?.invoices?.filter(
                (invoice) => invoice?.unsavedId !== invoiceFormValues?.unsavedId,
              ),
            );

            const createdInvoice = await createInvoice({
              data: { ...getInvoiceData(invoiceFormValues), invoicing_schedule_id: invoicingScheduleFormValues?.id },
            });

            setSelectedInvoiceId(createdInvoice?.id);

            if (applyToAll) {
              await applyChangesToAllInvoices({ ignoreCurrent: true });
            }
          },
        });
      }
    } else if (isPreviewAndSave) {
      validateInvoiceFormAndExecuteAction({
        action: async () => {
          const source = fetchedSelectedInvoice ? getInvoiceInitialValues(fetchedSelectedInvoice) : {};
          const target = invoiceFormValues ? getInvoiceInitialValues(invoiceFormValues) : {};

          const isEdited = invoiceWasEdited({
            source,
            target,
            fields: Object.keys(source).concat(Object.values(INVOICE_ITEM_TYPE_TO_KEY)),
          });

          if (isEdited) {
            await saveInvoiceWithPreview({
              invoice: invoiceFormValues,
            });

            if (applyToAll) {
              await applyChangesToAllInvoices({ ignoreCurrent: true });
            }
          }

          openInvoicePreviewModal({ isResend });
        },
      });
    } else {
      validateInvoiceFormAndExecuteAction({
        action: async () => {
          if (applyToAll) {
            await applyChangesToAllInvoices({ ignoreCurrent: false, saveOnlyInSubscript });
          } else {
            await updateInvoice({
              // paid status can be changed by the "Marked as Paid" invoice action. The Save button is not supposed to
              //  ever change the paid status.
              invoice: omit(invoiceFormValues, ['paid_at', 'marked_paid_by', 'polling_taxes_job_id']),
              createExternalInvoice,
              saveOnlyInSubscript,
              integrationId: currentInvoicingSchedule?.integration_id,
            });
          }
        },
      });
    }
  };

  const isCreateMode = invoiceFormValues?.unsavedId && !isScheduleDraft;
  // If the invoicing service is not a GL integration service it is a manual GL integration, so we will never have credit notes allocated externally
  const areCreditNotesAllocatedExternally =
    !GL_INTEGRATION_SERVICES.includes(invoicingService) ||
    (creditNotes ?? []).every(({ allocated_externally }) => allocated_externally);

  const handleSaveClick = () => {
    if (isSelectedInvoiceImported && creditNotes?.length > 0 && !areCreditNotesAllocatedExternally) {
      setExternalAllocationSubmitParams({ applyToAll: false });
    } else if (GL_INTEGRATION_SERVICES.includes(invoicingService) && !invoiceFormValues?.unsavedId) {
      setShowSaveToExternalPopover(true);
    } else if (isCreateMode) {
      handleSubmit({ applyToAll: false });
    } else if (!isEmpty(invoiceChanges) && invoices?.length > 1) {
      setShowApplyToAllPopover(true);
    } else {
      handleSubmit({ applyToAll: false, invoiceChanges });
    }
  };

  const showSendTooltip = !!invoicingService && !showProceedToSendPopover;

  const isResend = allowResend;

  useEffect(() => {
    setInvoiceChanges(null);

    const invoiceChanges = invoiceGenerateChanges({
      source: fetchedSelectedInvoice ? getInvoiceInitialValues(fetchedSelectedInvoice) : {},
      target: invoiceFormValues ? getInvoiceInitialValues(invoiceFormValues) : {},
      fields: Object.keys(fetchedSelectedInvoice ? getInvoiceInitialValues(fetchedSelectedInvoice) : {}).concat(
        Object.values(INVOICE_ITEM_TYPE_TO_KEY),
      ),
    });

    if (!isEmpty(invoiceChanges) && invoices?.length > 0) {
      setInvoiceChanges(invoiceChanges);
    }
  }, [fetchedSelectedInvoice, invoiceFormValues, invoices?.length]);

  const isInvoiceImportedAndTotalsNotMatch =
    invoiceFormValues?.is_imported &&
    !isImportInvoiceTotalMatchWithSubscriptTotal({
      originalAmount: invoiceFormValues?.importedOriginalTotal,
      newAmount: invoiceFormValues?.amount,
    });

  const isDraftImportedInvoice = invoiceFormValues?.is_imported && !!invoiceFormValues?.unsavedId;

  const MemoizedPreviewPDFModal = useMemo(() => (isModalOpen ? <PreviewPDFModal /> : null), [isModalOpen]);

  return (
    <>
      <PanelHeaderWrapper>
        <PanelHeaderTitle>
          <Centerer>
            <FlexerColumn data-cy="billing__invoice-modal__counter">
              <PanelHeaderTitleText>
                {invoiceFormValues?.unsavedId
                  ? 'Draft - '
                  : !isEmpty(invoiceFormValues?.changedFieldsWithOldValues ?? {})
                  ? 'Unsaved - '
                  : ''}
                {invoiceIndex + 1} of {invoices?.length}:{' '}
                {GL_INTEGRATION_SERVICES.includes(invoicingService) && (
                  <div>
                    {!isSelectedInvoiceImported ? (
                      `No invoice in ${invoicingServiceDisplayName}`
                    ) : invoiceFormValues?.invoice_external_url ? (
                      <>
                        See invoice in{' '}
                        <ExternalLinkText href={invoiceFormValues?.invoice_external_url} target="_blank">
                          {invoicingServiceDisplayName}
                        </ExternalLinkText>
                      </>
                    ) : (
                      `Invoice created in ${invoicingServiceDisplayName}`
                    )}
                  </div>
                )}
              </PanelHeaderTitleText>
              <InvoiceStatus>
                <span>{modalInvoiceTitle}</span>

                {INVOICE_FAILED_STATUS.includes(modalInvoiceStatus) && <AlertFilledIcon />}
                {isProcessingInvoice || isProcessingTaxes ? (
                  <>
                    <PanelHeaderInfo data-cy="billing__invoice-modal__processing_info">
                      {INVOICE_JOBS_OPERATIONS[currentJobOperation] === 'charge'
                        ? 'charging invoice'
                        : INVOICE_JOBS_OPERATIONS[currentJobOperation] === 'send'
                        ? 'sending invoice'
                        : 'saving invoice'}
                    </PanelHeaderInfo>
                    <CircleLoader width={20} height={20} />
                  </>
                ) : (
                  ''
                )}
              </InvoiceStatus>
            </FlexerColumn>
          </Centerer>

          <Row>
            <InvoiceActions headerView />

            {!invoiceFormValues?.unsavedId && selectedInvoiceId && (
              <>
                <PanelHeaderButton
                  ref={triggerRef}
                  onClick={() => {
                    !!invoiceFormValues?.paid_at
                      ? togglePortal()
                      : openPreviewPdfModal({
                          invoiceId: selectedInvoiceId,
                        });
                  }}
                  disabled={isProcessingInvoice}
                  data-cy="pdf-preview__button"
                >
                  PDF <StyledFileInvoiceIcon />
                </PanelHeaderButton>

                {isPortalVisible && (
                  <Portal>
                    <Popover width="180px">
                      <InvoicePdfOption
                        onClick={() => {
                          openPreviewPdfModal({ invoiceId: selectedInvoiceId, showOriginalPdf: false });
                          togglePortal();
                        }}
                      >
                        <FileCheckIcon />
                        Paid invoice PDF
                      </InvoicePdfOption>
                      <InvoicePdfOption
                        onClick={() => {
                          openPreviewPdfModal({ invoiceId: selectedInvoiceId, showOriginalPdf: true });
                          togglePortal();
                        }}
                      >
                        <FileInvoiceIcon />
                        Original invoice PDF
                      </InvoicePdfOption>
                    </Popover>
                  </Portal>
                )}
              </>
            )}

            {!invoiceFormValues?.unsavedId && (
              <PanelHeaderButton
                onClick={async () => {
                  if (invoiceFormValues?.receipt) {
                    openReceiptModal();
                  } else {
                    selectedInvoiceId &&
                      (await createReceipt({
                        invoice_id: selectedInvoiceId,
                      }));
                  }
                }}
                data-cy="billing__invoice-modal__receipt-button"
                disabled={isProcessingInvoice}
              >
                {invoiceFormValues?.receipt ? 'Receipt' : 'Generate Receipt'} <StyledReceiptIcon />
              </PanelHeaderButton>
            )}

            <PopoverWrapper>
              {([INVOICE_STATUSES.UNSENT, INVOICE_STATUSES.SAVE_FAILED, INVOICE_STATUSES.SENT_FAILED].includes(
                modalInvoiceStatus,
              ) ||
                isResend ||
                isProcessingInvoice ||
                isProcessingTaxes) &&
              !invoiceFormValues?.unsavedId ? (
                <SplitButton>
                  <SplitButtonPart
                    onClick={() => {
                      if (isProcessingInvoice) return;
                      handleSaveClick();
                      isResend && setAllowResend(false);
                    }}
                    data-cy="billing__invoice-modal__confirm-button"
                    disabled={isProcessingInvoice}
                    hasTooltip={isProcessingInvoice || isEmpty(invoiceChanges)}
                  >
                    <TooltipContainer
                      tooltipWrapperStyles={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 14px',
                        whiteSpace: 'nowrap',
                      }}
                      fontSize="12px"
                      width={160}
                      toolTipContent={
                        isProcessingInvoice
                          ? 'Invoice is processing'
                          : isEmpty(invoiceChanges)
                          ? 'No changes to save'
                          : ''
                      }
                      hideArrow
                      isVisible={isProcessingInvoice || isEmpty(invoiceChanges)}
                    >
                      {!areCreditNotesAllocatedExternally
                        ? 'Save and allocate all credit notes'
                        : isDraftImportedInvoice
                        ? 'Save only in Subscript'
                        : 'Save'}{' '}
                      <SplitButtonSeparator />
                    </TooltipContainer>
                  </SplitButtonPart>

                  <SplitButtonPart
                    onClick={() => {
                      if (fetchedSelectedInvoice.auto_charge && customer?.has_active_payment_method) {
                        setShowProceedToSendPopover(true);
                      } else {
                        handleSubmit({ isPreviewAndSave: true, applyToAll: false, isResend });
                      }
                    }}
                    data-cy={isResend ? 'billing__invoice-modal__resend-button' : 'billing__invoice-modal__send-button'}
                    disabled={isProcessingInvoice || !areCreditNotesAllocatedExternally}
                    hasTooltip={!isResend && showSendTooltip}
                  >
                    <TooltipContainer
                      tooltipWrapperStyles={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 14px',
                        whiteSpace: 'nowrap',
                      }}
                      fontSize="12px"
                      width={160}
                      toolTipContent={
                        areCreditNotesAllocatedExternally
                          ? `We'll create the invoice in ${invoicingServiceDisplayName} to apply taxes`
                          : `Please allocate all the credit notes on ${invoicingServiceDisplayName} first`
                      }
                      hideArrow
                      isVisible={!isResend && showSendTooltip}
                    >
                      & {isResend ? 'Resend' : 'Send'}{' '}
                      <ArrowRightCircleWhiteIcon width={16} height={16} style={{ marginLeft: 8 }} />
                    </TooltipContainer>
                  </SplitButtonPart>
                </SplitButton>
              ) : (
                <TooltipContainer
                  fontSize="12px"
                  width={160}
                  toolTipContent={
                    isScheduleDraft
                      ? `← First save the schedule`
                      : `Match the imported invoice data with transactions in Subscript based on the total amount of ${numberFormatter(
                          {
                            type: NUMBER_FORMATS.CURRENCY,
                            rawValue: invoiceFormValues?.importedOriginalTotal,
                            decimalPlaces: 2,
                            currency: invoiceFormValues?.currency ?? defaultOrgCurrency,
                          },
                        )}`
                  }
                  hideArrow
                  isVisible={!!isScheduleDraft || !!isInvoiceImportedAndTotalsNotMatch}
                >
                  <PanelHeaderButton
                    data-cy="billing__invoice-modal__confirm-button"
                    filled={true}
                    color={COLORS.GREEN}
                    active={!isScheduleDraft}
                    disabled={isScheduleDraft || isInvoiceImportedAndTotalsNotMatch}
                    onClick={handleSaveClick}
                  >
                    <>
                      <span>
                        {isDraftImportedInvoice
                          ? 'Save only in Subscript'
                          : isScheduleDraft
                          ? 'Save'
                          : invoiceFormValues?.unsavedId
                          ? 'Create & Save'
                          : [
                              INVOICE_STATUSES.PAID,
                              INVOICE_STATUSES.VOID,
                              INVOICE_STATUSES.SENT,
                              INVOICE_STATUSES.REMIND,
                            ].includes(modalInvoiceStatus)
                          ? 'Save'
                          : 'Preview'}
                      </span>

                      {([INVOICE_STATUSES.PAID, INVOICE_STATUSES.VOID].includes(modalInvoiceStatus) ||
                        fetchedSelectedInvoice.auto_charge ||
                        isNotSendOrRemindInvoice) &&
                        !invoiceFormValues?.unsavedId && (
                          <ArrowRightCircleWhiteIcon width={16} height={16} style={{ marginLeft: 8 }} />
                        )}
                    </>
                  </PanelHeaderButton>
                </TooltipContainer>
              )}

              {showSaveToExternalPopover && (
                <SaveToExternalPopover
                  onClose={() => setShowSaveToExternalPopover(false)}
                  hasChanges={!isEmpty(invoiceChanges)}
                  onSelect={({ applyChangesToAllInvoices, createExternalInvoice, saveOnlyInSubscript }) => {
                    const handler =
                      createExternalInvoice && !areCreditNotesAllocatedExternally
                        ? setExternalAllocationSubmitParams
                        : handleSubmit;
                    handler({
                      applyToAll: applyChangesToAllInvoices,
                      createExternalInvoice,
                      saveOnlyInSubscript,
                    });
                  }}
                />
              )}

              {showApplyToAllPopover && (
                <ApplyToAllPopover ref={popoverRef}>
                  <SaveIcon />

                  <ApplyToAllPopoverText>
                    Do you want to apply these changes to <b>all invoices in this series?</b>
                  </ApplyToAllPopoverText>
                  <Row>
                    <PanelHeaderButton
                      style={{ width: 74, marginLeft: 0 }}
                      data-cy="billing__invoice-modal__confirm--no-apply"
                      onClick={() => {
                        toggleShowApplyToAllPopover();
                        handleSubmit({ applyToAll: false });
                      }}
                    >
                      No
                    </PanelHeaderButton>
                    <PanelHeaderButton
                      style={{ width: 74 }}
                      data-cy="billing__invoice-modal__confirm--apply-to-all"
                      onClick={() => {
                        toggleShowApplyToAllPopover();
                        handleSubmit({ applyToAll: true });
                      }}
                      filled={true}
                      color={COLORS.GREEN}
                      active
                    >
                      Yes
                    </PanelHeaderButton>
                  </Row>
                </ApplyToAllPopover>
              )}

              {showProceedToSendPopover && (
                <ProceedToSendEmailPopover
                  onClose={() => setShowProceedToSendPopover(false)}
                  onProceed={() => {
                    handleSubmit({ isPreviewAndSave: true, applyToAll: false, isResend });
                    setShowProceedToSendPopover(false);
                  }}
                >
                  The payment will be automatically processed on the invoice date. Do you still want to send?
                </ProceedToSendEmailPopover>
              )}
            </PopoverWrapper>

            {modalInvoiceStatus === INVOICE_STATUSES.REMIND && !isResend && (
              <PanelHeaderButton
                data-cy="billing__invoice-modal__remind-button"
                filled={true}
                color={COLORS.GREEN}
                active
                onClick={() => openInvoicePreviewModal()}
              >
                Remind
                <ArrowRightCircleWhiteIcon width={16} height={16} style={{ marginLeft: 8 }} />
              </PanelHeaderButton>
            )}
          </Row>
        </PanelHeaderTitle>
      </PanelHeaderWrapper>
      {internalNotes ? (
        <InternalNoteWrapper data-cy="invoice-header__internal-note">
          <FlexerColumn>
            <InternalNoteTitle>Note</InternalNoteTitle>
            <FlexerRow>
              {internalNotes}

              <EditNoteButton data-cy="invoice-header__edit-internal-note" onClick={openInternalNoteModal}>
                Edit note
              </EditNoteButton>
            </FlexerRow>
          </FlexerColumn>
        </InternalNoteWrapper>
      ) : null}

      <InternalNoteModal />
      {MemoizedPreviewPDFModal}
      <ReceiptModal />
      {externalAllocationSubmitParams && (
        <ExternalAllocationConfirmModal
          onClose={() => setExternalAllocationSubmitParams(null)}
          onConfirm={() => handleSubmit(externalAllocationSubmitParams)}
          invoicingServiceDisplayName={invoicingServiceDisplayName}
          creditNoteWaitingForTaxes={(creditNotes ?? []).find((creditNote) => !!creditNote.polling_taxes_job_id)}
        />
      )}
    </>
  );
};
