import axios from 'axios';
import { API_URL } from 'api/consts';

export const getAccountingRevenueForDateRange = async ({ orgId, params }) => {
  const url = `${API_URL}/organizations/${orgId}/accounting/range`;
  const { data } = await axios.get(url, { params });
  return data;
};

export const getAccountingRevenueForMonth = async ({ orgId, params }) => {
  const url = `${API_URL}/organizations/${orgId}/accounting/month`;
  const { data } = await axios.get(url, { params });
  return data;
};

export const getAccountingRevenueForMonthCustomer = async ({ orgId, params }) => {
  const url = `${API_URL}/organizations/${orgId}/accounting/customer`;
  const { data } = await axios.get(url, { params });
  return data;
};

export const getAccountingRevenueForCustomer = async ({ orgId, customerId, params }) => {
  const url = `${API_URL}/organizations/${orgId}/accounting/customer/${customerId}`;
  const { data } = await axios.get(url, { params });
  return data;
};

export const getAccountingRevenueForContract = async ({ orgId, contractId, params }) => {
  const url = `${API_URL}/organizations/${orgId}/accounting/contract/${contractId}`;
  const { data } = await axios.get(url, { params });
  return data;
};

export const getAccountingSpreadsForContract = async ({ orgId, contractId, params }) => {
  const url = `${API_URL}/organizations/${orgId}/accounting/contract/${contractId}/accounting-spreads`;
  const { data } = await axios.get(url, { params });
  return data;
};

export const saveManualAccountingSpreadsForContract = async ({ orgId, contractId, body }) => {
  const url = `${API_URL}/organizations/${orgId}/contracts/${contractId}/save-accounting-spreads`;
  const { data } = await axios.put(url, body);
  return data;
};

export const getDefaultAccountingSpreadsForContract = async ({ orgId, contractId }) => {
  const url = `${API_URL}/organizations/${orgId}/accounting/contract/${contractId}/default-accounting-spreads`;
  const { data } = await axios.get(url);
  return data;
};
