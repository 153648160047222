import styled, { css } from 'styled-components';
import { components } from 'react-select';
import { useFormikContext } from 'formik';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';
import { usePortal } from 'components/Portal';
import { useClickOutside } from 'utils/hooks';
import { FormikCustomSelector } from './FormikCustomSelector';

export const GroupWrapper = styled.div`
  &:first-child {
    border-bottom: 1px solid var(--primaryBlack5);
    margin-bottom: 16px;
    padding-bottom: 8px;
  }
`;

export const GroupTitle = styled.div`
  font-weight: 900;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--primaryBlack);
  opacity: 0.3;
  margin-bottom: 16px;
`;

export const SelectorWrapper = styled.div`
  position: relative;
`;

export const StyledSelectorMenuBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px;
  width: ${({ menuWidth }) => menuWidth ?? '398px'};
  background: #ffffff;
  box-shadow: 16px 16px 60px var(--primaryBlack10);
  border-radius: 8px;
`;

export const Column = styled(FlexerColumn)`
  max-width: 300px;
`;

export const Label = styled.div`
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
  margin-bottom: 4px;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack);
  opacity: 0.5;
`;

export const IconWrapper = styled(Centerer)`
  width: 36px;
  height: 36px;
  background: var(--primaryBlack2);
  border-radius: 8px;
  margin-right: 12px;
`;

export const Row = styled(Flexer)`
  padding: 8px;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    background: var(--primaryBlack2);
    border-radius: 8px;

    ${IconWrapper} {
      background: var(--primaryBlack2);
      border: 1px solid var(--accentGraySecond);
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      ${IconWrapper} {
        background: #fff;
        border: 1px solid var(--accentGraySecond);
      }

      ${Label} {
        opacity: 0.3;
      }

      ${Description} {
        opacity: 0.3;
      }
    `}
`;

export const FormikSelectorWithDescription = ({
  name,
  options,
  values: overriddenValues,
  menuWidth = '320px',
  iconRenderer,
  onSelect,
  ...rest
}) => {
  const { values: formikValues, setFieldValue } = useFormikContext();
  const { triggerRef, togglePortal, hidePortal, isPortalVisible } = usePortal({ xOffset: 20, YOffset: 25 });

  const handleSelectOption = (value) => {
    onSelect ? onSelect(value) : setFieldValue(name, value);
    hidePortal();
  };

  const formikSelectorRef = useClickOutside(() => hidePortal());
  const values = overriddenValues ?? formikValues;

  const isGroup = !Array.isArray(options);

  const renderRow = ({ label, value, description }) => (
    <Row
      key={label}
      selected={value === values?.[name]}
      onClick={() => handleSelectOption(value)}
      data-cy={`${name}-selector__option-${value}`}
    >
      <Column>
        {iconRenderer ? <IconWrapper>{iconRenderer({ label, value, description })}</IconWrapper> : null}
        <Label>{label}</Label>
        <Description>{description}</Description>
      </Column>
    </Row>
  );

  const MenuContent = () => (
    <>
      {isGroup
        ? Object.entries(options).map(([groupName, group]) => (
            <GroupWrapper key={groupName}>
              <GroupTitle>{group.title}:</GroupTitle>
              <>{group.values.map(renderRow)}</>
            </GroupWrapper>
          ))
        : options.map(renderRow)}
    </>
  );

  return (
    <SelectorWrapper ref={triggerRef}>
      <div ref={formikSelectorRef}>
        <FormikCustomSelector
          boldValue
          options={options.map(({ label, value }) => ({ label, value }))}
          dataCy={`${name}-selector`}
          menuWidth={menuWidth}
          menuIsOpen={isPortalVisible}
          onMenuOpen={togglePortal}
          components={{
            Menu: (props) => (
              <components.Menu {...props}>
                <StyledSelectorMenuBase data-cy={`${name}-menu`} menuWidth={menuWidth}>
                  <MenuContent />
                </StyledSelectorMenuBase>
              </components.Menu>
            ),
          }}
          name={name}
          {...rest}
        />
      </div>
    </SelectorWrapper>
  );
};
