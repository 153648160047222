import { omit } from 'lodash';
import { guessDateFormat } from 'utils/dateUtils';

export const DEFAULT_INVOICES_MAPPER = {
  id: 'Invoice ID',
  date: 'Invoice Date',
  invoicing_schedule_id: 'Invoicing Schedule ID',
  customer_id: 'Customer ID',
  service_start: 'Service Start',
  service_end: 'Service End',
  currency: 'Currency',
  metadata: 'Metadata',
  invoice_items: 'Invoice items',
  memo: 'Memo',
  secondary_memo: 'Secondary Memo',
  email_addresses: 'Email Addresses',
  email_cc: 'Email CC',
  invoice_number: 'Invoice Number',
};

export const DEFAULT_INVOICES_CREATE_MAPPER = omit(DEFAULT_INVOICES_MAPPER, 'id');

export const DEFAULT_INVOICES_TRANSFORMATIONS = {
  date: guessDateFormat,
  service_start: guessDateFormat,
  service_end: guessDateFormat,
};

export const SOURCE_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

export const INTERNAL_CUSTOMER_ID_SOURCE = 'Subscript Customer ID';
export const NAME_MATCHING_SOURCE = 'nameMatching';
