import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { CirclePlusBlueIcon } from 'components/Icons';
import { GreyBox, GreyBoxActionButtonLabel, GreyBoxContent, GreyBoxHeader, GreyBoxTitle } from 'components/Metadata';
import { Centerer, Flexer, FlexerColumn } from 'components/Core';
import { CircleLoader } from 'components/Loaders';
import { MetadataItemFormikInput, useCombinedMetadata, useMetadataActionsModal } from 'shared/Common/MetadataSection';
import { METADATA_TYPES } from 'shared/Common/MetadataSection';
import { METADATA_FILTER_TYPES } from 'shared/Filters/MetadataFilter';

export const ProductMetadata = ({ values = {}, setFieldValue }) => {
  const { orgId } = useContext(AppContext);

  const { combinedMetadataOptions, isLoading } = useCombinedMetadata({
    orgId,
    metadataFilterType: METADATA_FILTER_TYPES.PRODUCTS,
    currentValues: values?.metadata ?? {},
  });

  const { openCreateMetadataModal, MetadataActionsModal } = useMetadataActionsModal({
    organizationId: orgId,
    onClose: (metadataItem) => {
      if (metadataItem) {
        setFieldValue(`metadata`, {
          ...values?.metadata,
          [metadataItem.key]: metadataItem.value,
        });
      }
    },
  });

  const handleOpenMetadataActionsModal = () => openCreateMetadataModal({ metadataType: METADATA_TYPES.PRODUCTS });

  return (
    <GreyBox>
      {isLoading ? (
        <Centerer>
          <CircleLoader />
        </Centerer>
      ) : (
        <FlexerColumn gap="16px">
          <GreyBoxHeader>
            <GreyBoxTitle>Metadata ({combinedMetadataOptions?.length ?? 0})</GreyBoxTitle>

            <Centerer gap="8px">
              <GreyBoxActionButtonLabel>
                <span>Manage</span> metadata keys
              </GreyBoxActionButtonLabel>
              <CirclePlusBlueIcon data-cy="product-metadata-add-button" onClick={handleOpenMetadataActionsModal} />
            </Centerer>
          </GreyBoxHeader>

          <GreyBoxContent>
            <Flexer flexDirection="column" gap="8px" wrapRow>
              {combinedMetadataOptions.map((item) => (
                <Flexer width="calc(33% - 3px)" key={item.key}>
                  <MetadataItemFormikInput name={`metadata.${item.key}`} metadataDataType={item.dataType} item={item} />
                </Flexer>
              ))}
            </Flexer>
          </GreyBoxContent>
        </FlexerColumn>
      )}

      <MetadataActionsModal />
    </GreyBox>
  );
};
