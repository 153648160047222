import styled from 'styled-components';
import { CentererVertical } from 'components/Core';

export const StyledLink = styled.a`
  color: var(--primaryBlue);
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const FadedText = styled.span`
  color: var(--primaryBlack30);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const CustomerField = styled(CentererVertical)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NewSubscriptionButton = styled.div`
  position: relative;
  width: 100%;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: var(--primaryBlue);
  text-transform: uppercase;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};

  svg path {
    fill: var(--primaryBlue);
  }

  &:hover {
    cursor: pointer;
    color: var(--secondaryBlue);

    svg path {
      fill: var(--secondaryBlue);
    }
  }
`;

export const TrialUnitsText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
