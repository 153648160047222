import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { omit } from 'lodash';
import styled, { css } from 'styled-components';
import { updateDateFromDatePicker } from 'utils/dateUtils';
import { Popover, PopoverButton, PopoverActions, PopoverPrompt } from 'components/Portal';
import { FlexerRow } from 'components/Core';
import { ReactComponent as ArrowRight } from 'images/arrow-right-white.svg';
import { FlexEndContainer, Flexer } from 'components/Core';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleText,
} from 'components/Modal';
import { CircleCheckbox } from 'views/Wizard/styles';
import { InvoicingScheduleContext } from './InvoicingScheduleContext';
import { BillingContext } from '../BillingContext';
import { EyeIcon } from 'components/Icons';
import { useInvoicesAPI } from 'api/billing';
import { AppContext } from 'AppContext';
import { useBulkChangesModal } from './useBulkChangesModal';
import { getModalInvoiceStatus } from '../InvoiceModal/utils';
import { INVOICE_STATUSES } from '../consts';
import { updateDraftInvoiceAutoChargeIfNeeded } from './utils';

const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledArrowRight = styled(ArrowRight)`
  margin-left: 8px;
`;

const FooterInfoText = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryBlack50);
  margin-left: 8px;
`;

const CardTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 12px;
`;

const OptionCard = styled.div`
  padding: 20px;
  border-radius: 16px;
  background: white;
  border: 1px solid var(--accentGraySecond);
  box-shadow: 2px 2px 12px 0px var(--primaryBlack2);
`;

const OptionWrapper = styled(FlexerRow)``;

const Checkbox = styled(CircleCheckbox)`
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  flex-grow: 100;
  ${({ checked }) =>
    checked
      ? css`
          border: 5px solid var(--primaryBlue);
          background: white;
        `
      : css`
          border: 1px solid var(--accentGrayThird);
          background: var(--dark10);
        `};
`;

const OptionLabel = styled.div``;

const MOVE_DATE_OPTIONS = {
  NONE: 'none',
  LAST_DAY: 'lastDay',
  FIXED_DAY: 'fixedDay',
};

const CHANGE_AUTO_SEND_OPTIONS = {
  NO: 'no',
  THIS_INVOICE: 'thisInvoice',
  ALL: 'all',
};

const SERVICE_DATES_OPTIONS = {
  NO: 'no',
  THIS_INVOICE: 'thisInvoice',
  ALL: 'all',
};

const QuestionnaireModal = ({
  onClose,
  changeInMonths,
  invoicesToChange,
  monthDay,
  newDate,
  invoice,
  showMoveToLastDayOption,
}) => {
  const { orgId } = useContext(AppContext);
  const { invoicingScheduleFormValues, validateInvoiceFormAndExecuteAction, invoiceFormValues } = useContext(
    InvoicingScheduleContext,
  );
  const { updateInvoice } = useContext(BillingContext);

  const { openModal: openBulkChangesModal, Modal: BulkChangesModal } = useBulkChangesModal();

  const moveDatesOptions = [
    { label: 'No', value: MOVE_DATE_OPTIONS.NONE },
    {
      label: `${changeInMonths !== 0 ? `mo. by ${changeInMonths} and` : ''} date to ${monthDay}`,
      value: MOVE_DATE_OPTIONS.FIXED_DAY,
    },
  ];

  const {
    operations: { generateChangesToOtherInvoices },
  } = useInvoicesAPI({ orgId, autoFetch: false });

  const [updateOtherInvoiceDates, setUpdateOtherInvoiceDates] = useState(MOVE_DATE_OPTIONS.NONE);
  const [changeAutoSendDates, setChangeAutoSendDates] = useState(CHANGE_AUTO_SEND_OPTIONS.THIS_INVOICE);
  const [changeServiceDates, setChangeServiceDates] = useState(SERVICE_DATES_OPTIONS.NO);

  const doSomeInvoicesHaveAutoSend = invoicesToChange.some((i) => i.auto_send);

  if (showMoveToLastDayOption)
    moveDatesOptions.push({ label: 'Last day of the month', value: MOVE_DATE_OPTIONS.LAST_DAY });

  const setAutoSendOptions = [{ label: 'No', value: CHANGE_AUTO_SEND_OPTIONS.NO }];
  if (doSomeInvoicesHaveAutoSend) {
    setAutoSendOptions.push({ label: 'Yes, only for this invoice', value: CHANGE_AUTO_SEND_OPTIONS.THIS_INVOICE });

    if (updateOtherInvoiceDates !== MOVE_DATE_OPTIONS.NONE) {
      setAutoSendOptions.push({ label: 'Yes, for all subsequent invoices', value: CHANGE_AUTO_SEND_OPTIONS.ALL });
    }
  }

  const serviceDatesOptions = [
    { label: 'No', value: SERVICE_DATES_OPTIONS.NO },
    {
      label: 'Yes, only for this invoice',
      value: SERVICE_DATES_OPTIONS.THIS_INVOICE,
    },
  ];

  if (updateOtherInvoiceDates !== MOVE_DATE_OPTIONS.NONE) {
    serviceDatesOptions.push({ label: 'Yes, for all subsequent invoices', value: SERVICE_DATES_OPTIONS.ALL });
  }

  useEffect(() => {
    if (updateOtherInvoiceDates !== MOVE_DATE_OPTIONS.NONE) {
      // probably should change auto send dates too
      setChangeAutoSendDates(CHANGE_AUTO_SEND_OPTIONS.ALL);
      setChangeServiceDates(SERVICE_DATES_OPTIONS.ALL);
    } else if (updateOtherInvoiceDates === MOVE_DATE_OPTIONS.NONE) {
      setChangeAutoSendDates(CHANGE_AUTO_SEND_OPTIONS.THIS_INVOICE);
      setChangeServiceDates(SERVICE_DATES_OPTIONS.THIS_INVOICE);
    }
  }, [updateOtherInvoiceDates]);

  const handleSubmit = async () => {
    try {
      const updatedInvoicesResponse = await generateChangesToOtherInvoices({
        body: {
          invoices: invoicesToChange,
          changedInvoiceId: invoice?.id ?? invoice?.unsavedId,
          invoicingSchedule: invoicingScheduleFormValues,
          updatedFields: { date: newDate },
          datesSurvey: {
            updateOtherInvoiceDates,
            changeAutoSendDates,
            changeServiceDates,
          },
        },
      });

      const saveUnsavedChangesOfInvoice = async () => {
        validateInvoiceFormAndExecuteAction({
          action: async () => {
            await updateInvoice({
              invoice: omit(invoiceFormValues, [
                'date',
                'service_end',
                'service_start',
                'paid_at',
                'marked_paid_by',
                'polling_taxes_job_id',
              ]),
            });
          },
        });
      };

      openBulkChangesModal({
        updatedInvoices: updatedInvoicesResponse,
        oldInvoices: invoicesToChange,
        doSomeInvoicesHaveAutoSend,
        changedFields: { date: newDate },
        onClose,
        beforeSubmit: saveUnsavedChangesOfInvoice,
      });
    } catch (error) {
      console.error(error);
      onClose();
    }
  };

  return (
    <>
      <ModalContainer style={{ cursor: 'default' }}>
        <Modal data-cy="date-change-popovers__questionnaire" width="1140px" height="444px">
          <ModalHeader>
            <ModalCloseIcon onClose={onClose} />
            <ModalTitle margin="0 36px" compact>
              <Flexer gap="8px" alignItems="center">
                <ModalTitleText compact>
                  <b>Pick the settings so that your changes can be applied to unsent invoices in the series</b>
                </ModalTitleText>
              </Flexer>
            </ModalTitle>
          </ModalHeader>

          <ModalBody>
            <OptionCard>
              <CardTitle>Move the invoice dates of subsequent invoices?</CardTitle>

              <OptionsWrapper>
                {moveDatesOptions.map(({ label, value }) => (
                  <OptionWrapper key={value}>
                    <Checkbox
                      checked={value === updateOtherInvoiceDates}
                      onClick={() => {
                        setUpdateOtherInvoiceDates(value);
                      }}
                      data-cy={`invoicing-schedule__change-future-dates-portal__option-${value}-checkbox`}
                    />
                    <OptionLabel>{value === MOVE_DATE_OPTIONS.NONE ? label : `Yes, ${label}`}</OptionLabel>
                  </OptionWrapper>
                ))}
              </OptionsWrapper>
            </OptionCard>

            {setAutoSendOptions.length > 1 && (
              <OptionCard>
                <CardTitle>Do you also want to change the auto-send date?</CardTitle>

                <OptionsWrapper>
                  {setAutoSendOptions.map(({ label, value }) => (
                    <OptionWrapper key={value}>
                      <Checkbox
                        checked={value === changeAutoSendDates}
                        onClick={() => {
                          setChangeAutoSendDates(value);
                        }}
                        data-cy={`invoicing-schedule__change-future-dates-portal__auto-send-option-${value}-checkbox`}
                      />
                      <OptionLabel>{label}</OptionLabel>
                    </OptionWrapper>
                  ))}
                </OptionsWrapper>
              </OptionCard>
            )}

            <OptionCard>
              <CardTitle>
                Change the service start date(s) according to the new invoice date(s) and shift the service end date(s)
                accordingly?
              </CardTitle>

              <OptionsWrapper>
                {serviceDatesOptions.map(({ label, value }) => (
                  <OptionWrapper key={value}>
                    <Checkbox
                      checked={value === changeServiceDates}
                      onClick={() => {
                        setChangeServiceDates(value);
                      }}
                      data-cy={`invoicing-schedule__change-future-dates-portal__service-dates-option-${value}-checkbox`}
                    />
                    <OptionLabel>{label}</OptionLabel>
                  </OptionWrapper>
                ))}
              </OptionsWrapper>
            </OptionCard>
          </ModalBody>

          <ModalFooter
            noFixedHeight
            padding="12px 36px"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <FlexerRow alignItems="center">
              <EyeIcon fill="var(--primaryBlack50)" size="16px" />
              <FooterInfoText>Subscript will show a preview of the changes in the next step</FooterInfoText>
            </FlexerRow>
            <FlexEndContainer>
              <ModalButton data-cy="date-change-popovers__back" onClick={onClose}>
                <b>Cancel</b>
              </ModalButton>

              <ModalButton data-cy="date-change-popovers__save" hideIcon primary onClick={handleSubmit}>
                <b>Save Settings</b>
                <StyledArrowRight />
              </ModalButton>
            </FlexEndContainer>
          </ModalFooter>
        </Modal>
      </ModalContainer>

      <BulkChangesModal />
    </>
  );
};

export const useDateChangePopovers = ({
  invoice,
  invoices,
  scheduleFormValues,
  invoiceFormValues,
  index,
  setFieldValue,
  scheduleFormRef,
  moveDatesPopoverXOffset,
  moveDatesPopoverYOffset,
  shouldSaveOnNo = true,
}) => {
  const { invoiceFormRef } = useContext(InvoicingScheduleContext);

  const { editInvoice } = useContext(BillingContext);

  const [showPopover, setShowPopover] = useState(false);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [showMoveToLastDayOption, setShowMoveToLastDayOption] = useState(false);

  const openPopover = () => setShowPopover(true);
  const closePopover = () => setShowPopover(false);

  const [invoicesToChange, setInvoicesToChange] = useState([]);
  const [monthDay, setMonthDay] = useState();
  const [newDate, setNewDate] = useState();
  const [changeInMonths, setChangeInMonths] = useState();
  const [oldIndex, setOldIndex] = useState(null);

  const checkIfShouldMoveToLastDay = ({ currentInvoice, invoices }) => {
    if (invoices?.length > 1) {
      // do not need to handle if selected date is 31st
      const selectedDateIsLastDayOfMonth =
        dayjs.utc(currentInvoice.date).date() !== 31 &&
        dayjs.utc(currentInvoice.date).isSame(dayjs.utc(invoices[0].date).endOf('month'), 'day');

      const generatedInvoicesAreNotLastDayOfMonth = invoices.some((invoice) => {
        return !dayjs.utc(invoice.date).isSame(dayjs.utc(invoice.date).endOf('month'), 'day');
      });

      return selectedDateIsLastDayOfMonth && generatedInvoicesAreNotLastDayOfMonth;
    }
  };

  const onDateChange = async (name, date) => {
    if (!date) return null;

    setShowMoveToLastDayOption(
      checkIfShouldMoveToLastDay({
        currentInvoice: invoice,
        invoices,
      }),
    );

    updateDraftInvoiceAutoChargeIfNeeded({
      setFieldValue,
      invoiceFormRef,
      scheduleFormRef,
      date,
      invoice,
      scheduleFormValues,
    });

    if (index !== invoices.length - 1) {
      // the difference between the old date value and the new one
      const toChange = [];
      for (const [invoiceIndex, invoice] of Object.entries(invoices)) {
        if (parseInt(invoiceIndex) >= index && getModalInvoiceStatus({ invoice }) === INVOICE_STATUSES.UNSENT)
          toChange.push(invoice);
      }

      if (toChange?.length > 0) {
        const dayjsNewDate = dayjs(date);
        const dayjsOldDate = dayjs.utc(invoice.date);
        const monthDifference = Math.round(
          dayjsNewDate.startOf('month').diff(dayjsOldDate.startOf('month'), 'month', true),
        ); // positive if moving forward in months and negative if moving backwards

        const monthDay = dayjsNewDate.get('date');
        const newDate = dayjsNewDate.utc(true).toDate();
        const shouldshowMoveToLastDayOption =
          index === 0 &&
          checkIfShouldMoveToLastDay({
            invoices: invoices.map((invoice, i) =>
              i === 0
                ? {
                    ...invoice,
                    date: newDate,
                  }
                : invoice,
            ),
            currentInvoice: {
              ...invoice,
              date: newDate,
            },
          });

        setInvoicesToChange(toChange);
        setChangeInMonths(monthDifference);
        setMonthDay(monthDay);
        setNewDate(newDate);
        setOldIndex(index); // because this will change if date is changed, and popover will get confused
        setShowMoveToLastDayOption(shouldshowMoveToLastDayOption);
        openPopover();
      }
    }
    const newDateForField = updateDateFromDatePicker(date);
    setFieldValue(name, newDateForField);
    // Sync the date in the invoice form
    invoiceFormRef?.current?.setFieldValue('date', newDateForField);
  };

  const moveDatesPopover = showQuestionnaire ? (
    <QuestionnaireModal
      onClose={() => setShowQuestionnaire(false)}
      changeInMonths={changeInMonths}
      monthDay={monthDay}
      showMoveToLastDayOption={showMoveToLastDayOption}
      invoice={invoice}
      newDate={newDate}
      invoicesToChange={invoicesToChange}
    />
  ) : showPopover ? (
    <Popover
      darkMode
      width="178px"
      data-cy="invoicing-schedule__change-future-dates-portal"
      XOffset={moveDatesPopoverXOffset ?? 80}
      YOffset={moveDatesPopoverYOffset ?? 30}
    >
      <PopoverPrompt opacity={1}>Move the invoice dates of subsequent invoices?</PopoverPrompt>

      <PopoverActions>
        <PopoverButton
          onClick={async () => {
            if (shouldSaveOnNo && invoice?.id) {
              const date = scheduleFormValues
                ? scheduleFormValues?.invoices?.[oldIndex ?? index]?.date
                : invoiceFormValues?.date;

              await editInvoice({
                id: invoice?.id,
                data: {
                  date,
                  send_date: date,
                },
              });
              setOldIndex(null);
            } else {
              closePopover();
            }
          }}
          data-cy={'invoicing-schedule__dates-popover__dismiss'}
        >
          No
        </PopoverButton>
        <PopoverButton
          onClick={() => {
            setShowQuestionnaire(true);
            setShowPopover(false);
          }}
          data-cy={'invoicing-schedule__dates-popover__save'}
          primary
        >
          Yes
        </PopoverButton>
      </PopoverActions>
    </Popover>
  ) : null;

  return { moveDatesPopover, onDateChange };
};
