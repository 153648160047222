import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useTable, usePagination, useRowSelect, useSortBy, useExpanded, useGlobalFilter } from 'react-table';
import { matchSorter } from 'match-sorter';
import { debounce, isEmpty, isUndefined } from 'lodash';

import { wasManuallyEdited } from 'models/transaction';
import { useStateWithStorage } from 'utils/hooks';
import { formatValueForDisplay } from 'utils/formatters';
import {
  ReactTable,
  ReactTableBody,
  ReactTableHeader,
  ReactTableHeaderColumn,
  ReactTableHeaderRow,
  RowsCount,
  TableHeaderActions,
  SortIconWrapper,
  HeaderCellWrapper,
} from 'components/Table';
import { Row, Spacer, Flexer } from 'components/Core';
import { PaginationButton } from 'components/Buttons';
import { MultiselectRibbon, SearchBar } from 'components/Blocks';
import { InfoIcon } from 'components/Icons';
import { TooltipContainer } from 'components/Tooltip';
import { SwitchWithLabel } from 'components/Controls';
import { TableColumnSettings } from 'shared/Common/TableColumnSettings';
import { ReactComponent as GreyDot } from 'images/grey-dot.svg';
import { ReactComponent as FilterIcon } from 'images/Filter-icon.svg';
import { ReactComponent as TotalIcon } from 'images/sort-descending.svg';

import {
  ExternalUpdatesRow,
  ExternalUpdatesCell,
  MetadataSection,
  MetadataLabelText,
  MetadataWrapper,
  SearchContainer,
  ExternalUpdatesTable,
  InfoIconWrapper,
  TableFilters,
  DropdownTitle,
} from './styles';
import { EXTERNAL_UPDATES_TABLE_COLUMNS, EXTERNAL_UPDATE_TYPES, HIDEABLE_COLUMNS } from './consts';
import { ComparatorDisplay } from './Components/ComparatorDisplay';
import { FilterButton } from './Components/FilterButton';
import { FilteredColumnsExplanation } from './Components/FilteredColumnsExplanation';
import { FilterDropdown } from './Components/FilterDropdown';

export const customGlobalFilter = (rows, columnIds, filterData) => {
  const { search, filteredColumns, filteredType, showManuallyEditedTransactions } = filterData;

  let returned =
    search && search.length > 0
      ? matchSorter(rows, search, {
          keys: [
            'original.targetObject.name',
            'original.externalUpdate.update_data.name',
            'original.targetObject.customer_name',
            'original.externalUpdate.update_data.customer_name',
          ],
          threshold: matchSorter.rankings.CONTAINS,
        })
      : rows;

  if (filteredType) returned = returned.filter((row) => row.original.externalUpdate.type === filteredType);

  if (filteredType === EXTERNAL_UPDATE_TYPES.edit) {
    returned = returned.filter((row) => {
      for (const key in filteredColumns) {
        const fieldChanged = row.original.changedFields[key] ?? false;
        if (filteredColumns[key] === true && !fieldChanged) return false;
        if (filteredColumns[key] === false && fieldChanged) return false;
      }
      return true;
    });
  }

  if (!showManuallyEditedTransactions)
    returned = returned.filter((row) => !wasManuallyEdited(row?.original?.targetObject));
  return returned;
};

export const cycleState = (value) => {
  if (value === true) return false;
  if (value === false) return undefined;
  return true;
};

const Table = ({
  data,
  columns,
  invoicingSchedulesPage,
  onSelectedRowsChange,
  onSelectedBulkAction,
  filtersToShow = null,
}) => {
  const [localStoragePageSize, setLocalStoragePageSize] = useStateWithStorage('external-updates-page-size', 50);
  const [localStorageHiddenTransactionColumns, setLocalStorageHiddenTransactionColumns] = useStateWithStorage(
    'external-updates-hidden-columns',
    [],
  );
  const [localStorageFilterState, setLocalStorageFilterState] = useStateWithStorage('external-updates-filters', {
    search: '',
    filteredColumns: {},
    filteredType: EXTERNAL_UPDATE_TYPES.edit,
    showManuallyEditedTransactions: true,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    visibleColumns,
    rows,
    // Global Filter
    setGlobalFilter,
    // Pagination
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    // SelectRow
    toggleAllRowsSelected,

    state: { pageIndex, pageSize, selectedRowIds, hiddenColumns, globalFilter },
  } = useTable(
    {
      columns,
      data,
      paginateExpandedRows: false,
      globalFilter: customGlobalFilter,
      initialState: {
        pageSize: localStoragePageSize,
        hiddenColumns: localStorageHiddenTransactionColumns,
        globalFilter: !invoicingSchedulesPage && localStorageFilterState,
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    setLocalStoragePageSize(pageSize);
  }, [pageSize, setLocalStoragePageSize]);

  useEffect(() => {
    setLocalStorageHiddenTransactionColumns(hiddenColumns);
  }, [hiddenColumns, setLocalStorageHiddenTransactionColumns]);

  useEffect(() => {
    !invoicingSchedulesPage && setLocalStorageFilterState(globalFilter);
  }, [globalFilter, invoicingSchedulesPage, setLocalStorageFilterState]);

  // Sometimes, if the user has a filter on and then refreshes the page and nothing matches it, it can be confusing
  useEffect(() => {
    const filteredColumnsWithOnlyOnesToShow = Object.entries(globalFilter?.filteredColumns ?? {}).reduce(
      (acc, [filter, value]) => (filtersToShow?.has(filter) ? Object.assign(acc, { [filter]: value }) : acc),
      {},
    );
    setGlobalFilter({ ...globalFilter, filteredColumns: filteredColumnsWithOnlyOnesToShow });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // this can really only be a problem on page load

  useEffect(() => {
    onSelectedRowsChange(selectedRowIds);
  }, [selectedRowIds, onSelectedRowsChange]);

  const [searchText, setSearchText] = useState(localStorageFilterState.search);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- debounce triggers this warning, but we can ignore it
  const updateSearchState = useCallback(
    debounce((text) => {
      setGlobalFilter({
        ...globalFilter,
        filteredColumns: { ...globalFilter?.filteredColumns },
        search: text,
      });
    }, 200),
    [globalFilter?.filteredColumns, globalFilter?.showManuallyEditedTransactions],
  );

  const updateSearch = (text) => {
    setSearchText(text);
    updateSearchState(text);
  };

  const updateFilteredColumns = (col) => {
    setGlobalFilter({
      ...globalFilter,
      filteredColumns: {
        ...globalFilter?.filteredColumns,
        [col]: cycleState(globalFilter?.filteredColumns[col]),
      },
    });
  };

  const handleFilterDropdownAction = (type) => {
    setGlobalFilter({
      ...globalFilter,
      filteredColumns: {},
      filteredType: type,
    });
  };

  const countByChangeType = data.reduce((map, change) => {
    for (const [key, value] of Object.entries(change.changedFields)) if (value) map[key] = (map[key] || 0) + 1;
    return map;
  }, {});

  return (
    <ExternalUpdatesTable data-cy="external-updates-table">
      <TableFilters>
        <Row horizontal="start">
          <TooltipContainer
            tooltipWrapperStyles={{ height: 20 }}
            toolTipContent="Filter by changed or unchanged fields"
            width={125}
          >
            <FilterIcon />
          </TooltipContainer>
          {invoicingSchedulesPage ? (
            <DropdownTitle>Invoicing Schedules updates</DropdownTitle>
          ) : (
            <>
              <FilterDropdown onAction={handleFilterDropdownAction} activeType={globalFilter?.filteredType} />
              <GreyDot style={{ marginLeft: 4, marginRight: 12, minWidth: 4, minHeight: 4 }} />
              <SwitchWithLabel
                name={'showManuallyUpdatedTransactions'}
                onChange={(val) => {
                  setGlobalFilter({
                    ...globalFilter,
                    showManuallyEditedTransactions: val,
                  });
                }}
                checked={globalFilter?.showManuallyEditedTransactions}
                label={`Include transactions you've manually updated and confirmed on Subscript`}
                labelSize={'12px'}
              />
            </>
          )}
        </Row>

        {globalFilter?.filteredType === EXTERNAL_UPDATE_TYPES.edit ? (
          <Row horizontal="start" style={{ marginTop: 20 }}>
            <FilterButton
              name="all"
              isAllUpdatesButton
              state={
                isEmpty(globalFilter?.filteredColumns) ||
                Object.values(globalFilter?.filteredColumns ?? {}).every(isUndefined)
              }
              onClick={() => {
                setGlobalFilter({
                  ...globalFilter,
                  filteredColumns: {},
                });
              }}
            >
              <b>All Update Types</b>
            </FilterButton>
            <Spacer width="12px" />
            {visibleColumns.map((column) => {
              const value = EXTERNAL_UPDATES_TABLE_COLUMNS[column.id];
              if (!value?.isFilterable || (filtersToShow && !filtersToShow.has(value.key))) return null;

              return (
                <Fragment key={column.id}>
                  <FilterButton
                    state={globalFilter?.filteredColumns[value.key]}
                    onClick={() => {
                      updateFilteredColumns(value.key);
                    }}
                    name={value.name}
                  >
                    <b>
                      {' '}
                      {value.name}{' '}
                      <span data-cy={`external-updates__filter-button__change-count--${value.name}`}>
                        ({countByChangeType[column.id] || '0'})
                      </span>
                    </b>
                  </FilterButton>
                  <Spacer width="12px" />
                </Fragment>
              );
            })}
            {!invoicingSchedulesPage && (
              <FilterButton
                state={globalFilter?.filteredColumns['metadata']}
                onClick={() => {
                  updateFilteredColumns('metadata');
                }}
              >
                <b>Metadata ({countByChangeType['metadata'] || '0'})</b>
              </FilterButton>
            )}
          </Row>
        ) : null}
      </TableFilters>
      <FilteredColumnsExplanation
        filteredColumns={globalFilter?.filteredColumns ?? {}}
        filteredType={globalFilter?.filteredType}
      />

      <TableHeaderActions horizontal="space-between">
        <SearchContainer>
          <SearchBar onChange={({ target }) => updateSearch(target.value)} value={searchText} />
        </SearchContainer>
        <Row>
          <RowsCount>
            {rows.length} rows found ({data.length} total)
          </RowsCount>
          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
          <PaginationButton
            pageIndex={pageIndex + 1}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            nextPage={nextPage}
            previousPage={previousPage}
          />
          <GreyDot style={{ marginLeft: 20, marginRight: 20, minWidth: 4, minHeight: 4 }} />
          <TableColumnSettings
            numberValue={pageSize}
            handleShowResultsChange={(number) => {
              setPageSize(number?.value ?? 10);
            }}
            allColumns={allColumns}
            columnsTitles={HIDEABLE_COLUMNS}
            resultsLabel={'# of updates on page'}
            options={[
              { label: '10', value: 10 },
              { label: '20', value: 20 },
              { label: '50', value: 50 },
              { label: '100', value: 100 },
            ]}
          />
        </Row>
      </TableHeaderActions>

      <ReactTable {...getTableProps()}>
        <ReactTableHeader>
          {headerGroups.map((headerGroup) => {
            return (
              <ReactTableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <ReactTableHeaderColumn
                    alignRight={column?.alignRight}
                    customWidth={column.width}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <HeaderCellWrapper isSortable={column.canSort && !column.disableSortBy} isSorted={column.isSorted}>
                      {column.render('Header')}
                      {column.isSorted && (
                        <SortIconWrapper isSortedDesc={column.isSortedDesc}>
                          <TotalIcon />
                        </SortIconWrapper>
                      )}
                    </HeaderCellWrapper>
                  </ReactTableHeaderColumn>
                ))}
              </ReactTableHeaderRow>
            );
          })}
        </ReactTableHeader>
        <ReactTableBody {...getTableBodyProps()}>
          {Object.keys(selectedRowIds).length > 0 && (
            <MultiselectRibbon
              label={`${Object.keys(selectedRowIds).length} selected rows:`}
              actions={
                invoicingSchedulesPage
                  ? [
                      {
                        role: 'destructive',
                        label: 'Skip',
                        onClick: () => {
                          onSelectedBulkAction('dismiss');
                        },
                      },
                    ]
                  : [
                      {
                        role: 'primary',
                        label: 'Confirm',
                        onClick: () => {
                          onSelectedBulkAction('overwrite');
                        },
                      },
                      {
                        role: 'destructive',
                        label: 'Skip',
                        onClick: () => {
                          onSelectedBulkAction('dismiss');
                        },
                      },
                    ]
              }
              onResetSelection={() => toggleAllRowsSelected(false)}
            />
          )}
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row?.id}>
                <ExternalUpdatesRow data-cy="external-updates__row" type={row.original.externalUpdate.type}>
                  {row.cells.map((cell) => (
                    <ExternalUpdatesCell
                      noPaddingRight={cell.column.noPaddingRight}
                      customWidth={cell.column.width}
                      {...cell.getCellProps()}
                    >
                      {cell?.column?.id === 'name' && wasManuallyEdited(row?.original?.targetObject) && (
                        <TooltipContainer
                          width={240}
                          toolTipContent="This transaction was manually updated and confirmed"
                        >
                          <InfoIconWrapper>
                            <InfoIcon size={'16px'} />
                          </InfoIconWrapper>
                        </TooltipContainer>
                      )}
                      {cell.render('Cell')}
                    </ExternalUpdatesCell>
                  ))}
                </ExternalUpdatesRow>

                {row.isExpanded ? (
                  <MetadataSection data-cy="external-updates__metadata">
                    <MetadataLabelText> Metadata: </MetadataLabelText>
                    <Spacer width="10px" />
                    <Flexer wrapRow>
                      {row.original.metadata.keys.map((key) => {
                        return (
                          <ComparatorDisplay
                            key={key}
                            original={row.original.metadata.original[key]}
                            updated={row.original.metadata.updated[key]}
                            isChanged={row.original.metadata.original[key] !== row.original.metadata.updated[key]}
                            displayOriginal={
                              <MetadataWrapper>
                                {`${key}: `} <b> {formatValueForDisplay(row.original.metadata.original[key])} </b>
                              </MetadataWrapper>
                            }
                            displayUpdated={
                              <MetadataWrapper highlight>
                                {`${key}: `} <b> {formatValueForDisplay(row.original.metadata.updated[key])} </b>
                              </MetadataWrapper>
                            }
                          />
                        );
                      })}
                    </Flexer>
                  </MetadataSection>
                ) : null}
              </Fragment>
            );
          })}
        </ReactTableBody>
      </ReactTable>
    </ExternalUpdatesTable>
  );
};

export { Table };
