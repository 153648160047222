import { searchCustomers } from 'api';
import { getImports } from 'api/imports/requests';
import { SOURCE_TYPES } from 'views/Billing/InvoicesCsvUploadV2/consts';

export const searchCustomersWithExternal = async ({
  searchQuery,
  orgId,
  additional,
  // Used for the CSV upload
  customerIdSourceAndType,
  withoutProspects = false,
  paginated = true,
}) => {
  const currentPage = additional?.currentPage ?? 0;
  let searchResult;

  const isExternal = customerIdSourceAndType && customerIdSourceAndType?.type === SOURCE_TYPES.EXTERNAL;

  if (isExternal) {
    const imports = await getImports({
      orgId,
      params: {
        providerName: customerIdSourceAndType.source,
        chifferObjectName: 'customer',
        includeCustomerName: true,
        customerNameSearchQuery: searchQuery,
      },
    });

    searchResult = {
      data: imports,
    };
  } else {
    searchResult = await searchCustomers({
      orgId,
      searchQuery,
      withoutProspects,
      page: currentPage + 1,
    });
  }

  const customers = searchResult.data.map((customer) => ({
    label: isExternal
      ? `${customer?.customer_name || ''} / ${customer?.provider_object_id || ''}`
      : `${customer.name || ''} / ${customer.id || ''}`,
    id: isExternal ? customer?.provider_object_id : customer.id,
    value: isExternal ? customer?.provider_object_id : customer.id,
    name: customer.name,
    parentName: customer.parent_customer_name,
    metadata: customer.metadata,
  }));
  return paginated
    ? {
        options: customers,
        hasMore: !!searchResult.metadata?.hasMore,
        additional: searchResult.metadata,
      }
    : customers;
};
