import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { CentererVertical } from 'components/Core';
import { ToggleDropdownButton } from 'components/Buttons/ToggleDropdownButton';
import { SwitchLabel } from 'components/Controls';
import { SwitchLabelContainer } from 'components/Controls/Switch';
import { INVOICE_STATUSES } from 'views/Billing/consts';
import { ReactComponent as GreyDot } from 'images/medium-grey-dot.svg';
import { SelectDropdownButton } from 'components/Buttons';
import { INVOICES_FILTER_GROUP_BY } from 'views/Billing/BillingProvider';
import { BillingContext } from 'views/Billing/BillingContext';
import { INVOICE_SYNC_STATUSES } from 'views/Billing/InvoicingTables/consts';

const InvoicesTogglesWrapper = styled.div`
  ${SwitchLabelContainer} {
    align-items: center;
  }

  ${SwitchLabel} {
    font-weight: 700;
    font-size: 12px;
  }
`;

export const INVOICE_SYNC_STATUS_TOGGLES = [
  {
    label: 'Unsynced',
    value: INVOICE_SYNC_STATUSES.UNSYNCED,
  },
  {
    label: 'Synced',
    value: INVOICE_SYNC_STATUSES.SYNCED,
  },
  {
    label: 'No Sync',
    value: INVOICE_SYNC_STATUSES.NO_SYNC,
  },
];

export const INVOICE_STATUS_TOGGLES = [
  {
    label: 'Failed Invoices',
    // Includes 'save-failed', 'send-failed', 'auto-charge-failed' statuses
    value: INVOICE_STATUSES.ALL_FAILED,
  },
  {
    label: 'New to send',
    value: INVOICE_STATUSES.UNSENT,
  },
  {
    label: 'Queued up',
    value: INVOICE_STATUSES.QUEUED_UP,
  },
  {
    label: 'Processing',
    // Includes 'processing' and 'taxes-processing' statuses
    value: INVOICE_STATUSES.PROCESSING,
  },
  {
    label: 'Sent & Awaiting payment',
    value: INVOICE_STATUSES.SENT,
  },
  {
    label: 'Will be auto-charged',
    value: INVOICE_STATUSES.AUTO_CHARGE,
  },
  {
    label: 'Paid Invoices',
    value: INVOICE_STATUSES.PAID,
  },
  {
    label: 'Voided Invoices',
    value: INVOICE_STATUSES.VOID,
  },
];

export const InvoicesFilter = () => {
  const {
    invoiceStatusFilter,
    setInvoiceStatusFilter,
    invoicesGroupBy,
    setInvoicesGroupBy,
    invoiceSyncStatusFilter,
    setInvoiceSyncStatusFilter,
  } = useContext(BillingContext);

  const isAllInvoicesPage = invoiceStatusFilter.length === Object.keys(INVOICE_STATUS_TOGGLES).length;

  useEffect(() => {
    !isAllInvoicesPage && setInvoicesGroupBy(INVOICES_FILTER_GROUP_BY.MONTH);
  }, [isAllInvoicesPage, setInvoicesGroupBy]);

  return (
    <InvoicesTogglesWrapper>
      <CentererVertical>
        <GreyDot width={4} height={4} style={{ marginLeft: 12, marginRight: 12 }} />

        <span>Type: </span>
        <ToggleDropdownButton
          name="filters__invoices-type"
          allSelectedLabel={'All Invoices'}
          toggles={INVOICE_STATUS_TOGGLES}
          selected={invoiceStatusFilter ?? []}
          onSelect={setInvoiceStatusFilter}
        />

        <GreyDot width={4} height={4} style={{ marginLeft: 12, marginRight: 12 }} />

        <span>Sync Status: </span>
        <ToggleDropdownButton
          name="filters__invoices-sync-status"
          allSelectedLabel={'All'}
          toggles={INVOICE_SYNC_STATUS_TOGGLES}
          selected={invoiceSyncStatusFilter ?? []}
          onSelect={setInvoiceSyncStatusFilter}
        />

        {isAllInvoicesPage && (
          <>
            <GreyDot width={4} height={4} style={{ marginLeft: 12, marginRight: 12 }} />

            <span>Group By: </span>
            <SelectDropdownButton
              showSelectedDirectly
              name="invoices-page__group-by"
              options={INVOICES_FILTER_GROUP_BY}
              selected={invoicesGroupBy}
              onSelect={(option) => {
                setInvoicesGroupBy(INVOICES_FILTER_GROUP_BY[option]);
              }}
            />
          </>
        )}
      </CentererVertical>
    </InvoicesTogglesWrapper>
  );
};
