import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { AlertFilledIcon } from 'components/Icons';
import { formatDateForDatepicker } from 'utils/dateUtils';
import { CustomDatePicker } from 'components/Controls';
import { Centerer, CentererVertical, FlexBetweenContainer, Flexer, FlexerColumn } from 'components/Core';
import { PopoverActions, PopoverButton, PopoverWrapper } from 'components/Portal';
import { MAGIC_METADATA } from 'consts/global';
import { TooltipContainer } from 'components/Tooltip';
import { ReactComponent as NoteIcon } from 'images/note-info-circle.svg';
import { getModalInvoiceStatus, getModalInvoiceTitle } from 'views/Billing/InvoiceModal/utils';
import { INVOICE_FAILED_STATUS, INVOICE_STATUSES } from 'views/Billing/consts';
import { FilterHeadTime } from 'components/Controls/CustomDatePicker/styles';

import { InvoicingScheduleContext } from '../InvoicingScheduleContext';
import { ActionsButton, InvoiceActions } from './InvoiceActions/InvoiceActions';
import { useSafelySwitchInvoice } from '../useSafelySwitchInvoice';
import { useDateChangePopovers } from '../useDateChangePopovers';
import { useInternalNoteModal } from './useInternalNoteModal';

const Header = styled(FlexBetweenContainer)`
  align-items: center;
`;

const StyledNoteIcon = styled(NoteIcon)`
  &:hover {
    path {
      fill: var(--secondaryBlue);
    }
  }
`;

const HeaderMain = styled(CentererVertical)`
  flex: 1;
  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
`;

const InvoiceLineItemCheckbox = styled.div`
  position: absolute;
  left: 29px;
  top: 13px;

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(0.8);
  }
`;

const InvoiceStatus = styled(CentererVertical)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  gap: 7px;
`;

export const InvoiceCounter = styled(Centerer)`
  width: 16px;
  height: 16px;
  border-radius: 4px;

  font-size: 9px;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;

  color: var(--primaryBlue);
  background: var(--primaryBlue10);
`;

const InvoiceStatusSubtext = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 12px;

  color: var(--primaryBlack50);
`;

export const Wrapper = styled(FlexerColumn)`
  padding: 8px 32px;
  padding-left: 51px;
  overflow: visible;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  background-color: ${({ selected, bulkSelected }) =>
    selected ? 'var(--primaryBlue5)' : bulkSelected ? 'var(--primaryBlue3)' : 'transparent'};
  border-bottom: 1px solid var(--primaryBlack3);

  ${InvoiceStatus} {
    font-weight: ${({ selected }) => selected && 700};
  }

  ${InvoiceStatusSubtext} {
    color: ${({ selected }) => selected && 'var(--primaryBlack)'};
  }

  ${InvoiceCounter} {
    color: ${({ selected }) => selected && 'white'};
    background: ${({ selected }) => (selected ? 'var(--primaryBlue)' : 'var(--primaryBlue10)')};
  }

  ${FilterHeadTime} {
    background: ${({ selected }) => (selected ? 'var(--primaryBlue10)' : 'white')};
    color: ${({ selected }) => (selected ? 'var(--primaryBlue)' : 'var(--primaryBlack)')};
  }

  &:hover {
    background-color: ${({ selected }) => (selected ? 'var(--primaryBlue5)' : 'var(--primaryBlue3)')};

    ${InvoiceLineItemCheckbox} {
      visibility: visible;
    }

    ${FilterHeadTime} {
      color: var(--primaryBlue);
      background: var(--primaryBlue10);
    }

    ${ActionsButton} {
      opacity: 1;
    }

    ${InvoiceCounter} {
      color: ${({ selected }) => (selected ? 'white' : 'var(--secondaryBlue)')};
    }
  }
`;

export const InvoiceImportedTag = styled.div`
  padding: 2px 4px;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid var(--neutralGray);
  background: white;
  color: var(--primaryBlack50);
  font-size: 9px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
  text-transform: uppercase;
`;

export const InvoiceLineItem = ({ invoice, invoices, index, customer }) => {
  const {
    selectedInvoiceId,
    invoiceFormValues,
    invoicingScheduleFormValues,
    invoicingService,
    scheduleFormRef,
    invoicingServiceDisplayName,
    bulkChangeInvoices,
    setBulkChangeInvoices,
  } = useContext(InvoicingScheduleContext);

  const { setFieldValue, values } = useFormikContext();

  const { Modal: InternalNoteModal, openModal: openInternalNoteModal } = useInternalNoteModal({
    values: invoiceFormValues,
  });

  const selectedInvoiceRef = useRef(null);

  const { moveDatesPopover, onDateChange } = useDateChangePopovers({
    invoice,
    invoices,
    scheduleFormValues: values,
    scheduleFormRef,
    index,
    setFieldValue,
  });

  // Sync the date from the invoice form
  useEffect(() => {
    if (
      invoice &&
      invoiceFormValues &&
      invoice.id === invoiceFormValues?.id &&
      invoice.unsavedId === invoiceFormValues?.unsavedId &&
      invoice.date &&
      invoiceFormValues?.date &&
      dayjs(invoice.date).format('YYYY-MM-DD') !== dayjs(invoiceFormValues?.date).format('YYYY-MM-DD')
    ) {
      setFieldValue(`invoices.${index}.date`, dayjs(invoiceFormValues?.date).utc().toDate());
    }
  }, [invoiceFormValues, invoice, selectedInvoiceId, setFieldValue, index]);

  useEffect(() => {
    if (selectedInvoiceRef && selectedInvoiceRef.current) {
      const rect = selectedInvoiceRef.current.getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (!isInView) {
        selectedInvoiceRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [selectedInvoiceRef]);

  const { checkAndSwitchInvoice } = useSafelySwitchInvoice();

  const modalInvoiceStatus = getModalInvoiceStatus({ invoice, omitReminder: true });
  const modalInvoiceTitle = getModalInvoiceTitle({
    invoice,
    hasActivePayment: customer?.has_active_payment_method,
    isScheduleAutoCharge: invoicingScheduleFormValues?.auto_charge,
    omitReminder: true,
  });

  return (
    <>
      <PopoverWrapper>
        <Wrapper
          id={`invoice-${selectedInvoiceId}`}
          //uses for autoscroll to selected invoice on open schedule
          ref={invoice?.id && selectedInvoiceId && invoice?.id === selectedInvoiceId ? selectedInvoiceRef : null}
          selected={
            selectedInvoiceId && (invoice?.id === selectedInvoiceId || invoice?.unsavedId === selectedInvoiceId)
          }
          data-selected={
            selectedInvoiceId && (invoice?.id === selectedInvoiceId || invoice?.unsavedId === selectedInvoiceId)
          }
          bulkSelected={bulkChangeInvoices.some(
            (bulkInvoice) => (bulkInvoice?.id ?? bulkInvoice?.unsavedId) === (invoice?.id ?? invoice?.unsavedId),
          )}
          data-bulk-selected={bulkChangeInvoices.some(
            (bulkInvoice) => (bulkInvoice?.id ?? bulkInvoice?.unsavedId) === (invoice?.id ?? invoice?.unsavedId),
          )}
          onClick={() => checkAndSwitchInvoice({ clickedInvoice: invoice })}
          data-cy={`billing__invoice_schedule-modal__invoice-list__item-${index}`}
        >
          <InvoiceLineItemCheckbox
            data-cy={`billing__invoice_schedule-modal__invoice-list__item__bulk-checkbox-${index}`}
            isVisible={
              bulkChangeInvoices.some(
                (bulkInvoice) => (bulkInvoice?.id ?? bulkInvoice?.unsavedId) === (invoice?.id ?? invoice?.unsavedId),
              ) ||
              (selectedInvoiceId && (invoice?.id === selectedInvoiceId || invoice?.unsavedId === selectedInvoiceId))
            }
          >
            <Checkbox
              checked={bulkChangeInvoices.some(
                (bulkInvoice) => (bulkInvoice?.id ?? bulkInvoice?.unsavedId) === (invoice?.id ?? invoice?.unsavedId),
              )}
              onClick={(e) => e.stopPropagation()}
              onChange={() => {
                if (
                  !bulkChangeInvoices.some(
                    (bulkInvoice) =>
                      (bulkInvoice?.id ?? bulkInvoice?.unsavedId) === (invoice?.id ?? invoice?.unsavedId),
                  )
                ) {
                  setBulkChangeInvoices((invoices) => [...invoices, invoice]);
                } else {
                  setBulkChangeInvoices((invoices) => invoices.filter((i) => i?.id !== invoice?.id));
                }
              }}
            />
          </InvoiceLineItemCheckbox>

          <Header>
            <HeaderMain>
              <PopoverWrapper>
                <CustomDatePicker
                  formik
                  selected={invoice?.date ? formatDateForDatepicker(invoice?.date) : null}
                  onChange={onDateChange}
                  name={`invoices.${index}.date`}
                  filtersViewDateFormat="MMM D, YYYY"
                  filtersView
                  white
                  tooltipInputDisplay="Invoice date"
                  filtersViewPrefix={<InvoiceCounter>{index + 1}</InvoiceCounter>}
                  toolTipWidth={100}
                  toolTipHideArrow
                />

                {moveDatesPopover}
              </PopoverWrapper>

              <Flexer gap="4px" alignItems="center">
                <InvoiceStatus data-cy={`billing__invoice_schedule-modal__invoice-list__item-${index}-status`}>
                  {invoice?.unsavedId && invoicingScheduleFormValues?.id
                    ? 'Draft - '
                    : !isEmpty(invoice?.changedFieldsWithOldValues ?? {})
                    ? 'Unsaved - '
                    : ''}
                  {modalInvoiceTitle}
                </InvoiceStatus>

                {invoicingService && invoice?.invoice_external_id && (
                  <InvoiceImportedTag>{invoicingServiceDisplayName}</InvoiceImportedTag>
                )}

                {!!invoice?.metadata?.[MAGIC_METADATA.IS_HISTORICAL] && <InvoiceImportedTag>CSV</InvoiceImportedTag>}

                {invoice?.internal_notes ? (
                  <TooltipContainer
                    width={220}
                    tooltipWrapperStyles={{ display: 'flex' }}
                    fontSize="12px"
                    toolTipContent={
                      <div data-cy={`billing__invoice_schedule-modal__invoice-list__item-${index}-internal-notes`}>
                        {invoice?.internal_notes}
                        <PopoverActions>
                          <PopoverButton
                            onClick={openInternalNoteModal}
                            data-cy={`billing__invoice_schedule-modal__invoice-list__item-${index}-internal-notes-edit-button`}
                            primary
                          >
                            <b style={{ fontWeight: 900, fontSize: '9px' }}>EDIT INVOICE NOTE</b>
                          </PopoverButton>
                        </PopoverActions>
                      </div>
                    }
                  >
                    <StyledNoteIcon
                      data-cy={`billing__invoice_schedule-modal__invoice-list__item-${index}-internal-notes-icon`}
                    />
                  </TooltipContainer>
                ) : null}

                {INVOICE_FAILED_STATUS.includes(modalInvoiceStatus) && <AlertFilledIcon width="16" height="16" />}
              </Flexer>
            </HeaderMain>
            <InvoiceActions isSelected={invoice?.id === selectedInvoiceId} />
          </Header>

          {[INVOICE_STATUSES.QUEUED_UP, INVOICE_STATUSES.PROCESSING].includes(modalInvoiceStatus) ? (
            <InvoiceStatusSubtext>Will be finishing processing soon</InvoiceStatusSubtext>
          ) : [INVOICE_STATUSES.TAXES_PROCESSING].includes(modalInvoiceStatus) ? (
            <InvoiceStatusSubtext>Syncing taxes from upstream integrations</InvoiceStatusSubtext>
          ) : (
            ![INVOICE_STATUSES.VOID, INVOICE_STATUSES.SENT, INVOICE_STATUSES.PAID].includes(modalInvoiceStatus) &&
            invoice.auto_send && (
              <InvoiceStatusSubtext>
                Auto send: {dayjs.utc(invoice.send_date).format('MMM D, YYYY')}
              </InvoiceStatusSubtext>
            )
          )}
        </Wrapper>
      </PopoverWrapper>

      <InternalNoteModal />
    </>
  );
};
