import { useContext, useState } from 'react';
import styled from 'styled-components';
import { useClickOutside } from 'utils/hooks';
import { Portal } from 'components/Portal';
import { TransactionSidebar } from '../TransactionSidebar';
import { TransactionContext } from '../TransactionContext';
import { TRANSACTION_VIEW_MODE } from '../consts';

const Toggle = styled.div`
  position: absolute;
  right: 32px;
  bottom: -8px;
  padding: 0 8px;
  background: var(--primaryGray);

  @media (min-width: 1600px) {
    display: none;
  }

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primaryBlack30);
  cursor: pointer;

  span {
    color: var(--primaryBlue);
  }
`;

const PortalContent = styled.div`
  position: fixed;
  width: 336px;
  height: ${({ pageView }) => (pageView ? '100%' : '95vh')};
  bottom: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 20px 100px rgba(0, 21, 46, 0.2);
`;

export const TransactionSidebarToggle = () => {
  const { view } = useContext(TransactionContext);
  const pageView = view === TRANSACTION_VIEW_MODE.PAGE;
  const [visible, setVisible] = useState(false);
  const togglePortal = () => setVisible(!visible);

  const portalContentRef = useClickOutside(togglePortal);

  return (
    <>
      <Toggle pageView={pageView} onClick={togglePortal} pa>
        Show <span>Transactions</span>
      </Toggle>
      {visible && (
        <Portal>
          <PortalContent ref={portalContentRef} pageView={pageView}>
            <TransactionSidebar floating />
          </PortalContent>
        </Portal>
      )}
    </>
  );
};
