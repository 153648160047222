import React, { Fragment } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { NUMBER_FORMATS } from 'consts/global';
import { numberFormatter } from 'utils/formatters';
import { CentererVertical, FlexBetweenContainer, FlexerColumn } from 'components/Core';
import { ExternalLinkFullIcon } from 'components/Icons';
import { useContractFilesModal } from '../ContractFilesModal/useContractFilesModal';

const ContractDetailsWrapper = styled(FlexerColumn)`
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--neutralGray);
`;

const ContractDetailsWrapperUpper = styled(FlexerColumn)`
  border-bottom: 1px solid var(--neutralGray);
  padding-bottom: 20px;
`;

const CustomerName = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

const ContractDetailsWrapperLower = styled(FlexBetweenContainer)`
  align-items: center;
  padding-top: 20px;
`;

const AttachmentsText = styled.span`
  cursor: pointer;
  background-color: var(--primaryBlue10);
  color: var(--primaryBlue);
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
`;

const ContractDetailsLabel = styled.span`
  color: var(--primaryBlack50);
  font-size: 12px;
  font-weight: 400;
`;

const ContractDetailsValue = styled.span`
  color: var(--primaryBlack);
  font-size: 14px;
  font-weight: 400;
`;

export const ContractDetails = ({ contractData, transactionsData }) => {
  const { ContractFilesModal, openContractFilesModal } = useContractFilesModal({
    transactionIds: transactionsData ? transactionsData.map((t) => t.id) : [],
    customerId: transactionsData?.[0]?.customer_id,
  });

  let transactionsAmountTotal = 0;
  let transactionRecurringAmountTotal = 0;
  let transactionsSeatsTotal = 0;
  let earliestBookingDate;
  let earliestStartDate;
  let latestEndDate;
  let contractCurrency;
  let renewalDeadline;
  for (const {
    amount,
    recurring_amount,
    seats,
    currency,
    date,
    start_date,
    end_date,
    renewal_deadline,
  } of transactionsData) {
    transactionsAmountTotal += amount ?? 0;
    transactionRecurringAmountTotal += recurring_amount ?? 0;
    transactionsSeatsTotal += seats ?? 0;

    contractCurrency = contractCurrency ?? currency;
    renewalDeadline = renewalDeadline ?? renewal_deadline;

    if (!earliestBookingDate || date < earliestBookingDate) {
      earliestBookingDate = date;
    }

    if (!earliestStartDate || start_date < earliestStartDate) {
      earliestStartDate = start_date;
    }

    if (!latestEndDate || end_date > latestEndDate) {
      latestEndDate = end_date;
    }
  }

  const attachmentsText = contractData?.attachments?.length
    ? `See ${contractData.attachments.length} attachment(s)`
    : 'Upload attachments';

  return (
    <Fragment>
      <ContractDetailsWrapper>
        <ContractDetailsWrapperUpper>
          <CentererVertical gap="8px">
            <CustomerName>{contractData?.customer_name ?? '[No customer name]'}</CustomerName>
            <ExternalLinkFullIcon
              data-cy="contract-details-open-customer-detail"
              fill="var(--primaryBlue)"
              onClick={() => window.open(`/customers/${contractData.customer_id}`, '_blank')}
            />
          </CentererVertical>
        </ContractDetailsWrapperUpper>

        <ContractDetailsWrapperLower>
          <FlexerColumn gap="4px">
            <ContractDetailsLabel>Contract</ContractDetailsLabel>
            <AttachmentsText data-cy="contract-details-open-attachments" onClick={openContractFilesModal}>
              {attachmentsText}
            </AttachmentsText>
          </FlexerColumn>

          <FlexerColumn gap="4px">
            <ContractDetailsLabel>Total Amount</ContractDetailsLabel>
            <ContractDetailsValue data-cy="contract-details-total-amount">
              {numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: transactionsAmountTotal,
                currency: contractCurrency,
              })}
            </ContractDetailsValue>
          </FlexerColumn>

          <FlexerColumn gap="4px">
            <ContractDetailsLabel>ARR</ContractDetailsLabel>
            <ContractDetailsValue>
              {numberFormatter({
                type: NUMBER_FORMATS.CURRENCY,
                rawValue: transactionRecurringAmountTotal * 12,
                currency: contractCurrency,
              })}
            </ContractDetailsValue>
          </FlexerColumn>

          <FlexerColumn gap="4px">
            <ContractDetailsLabel>Seats</ContractDetailsLabel>
            <ContractDetailsValue>
              {numberFormatter({
                type: NUMBER_FORMATS.NUMBER,
                rawValue: transactionsSeatsTotal,
              })}
            </ContractDetailsValue>
          </FlexerColumn>

          <FlexerColumn gap="4px">
            <ContractDetailsLabel>Contract Date</ContractDetailsLabel>
            <ContractDetailsValue>{dayjs.utc(earliestBookingDate).format('MMM D, YYYY')}</ContractDetailsValue>
          </FlexerColumn>

          <FlexerColumn gap="4px">
            <ContractDetailsLabel>Start Date</ContractDetailsLabel>
            <ContractDetailsValue>{dayjs.utc(earliestStartDate).format('MMM D, YYYY')}</ContractDetailsValue>
          </FlexerColumn>

          <FlexerColumn gap="4px">
            <ContractDetailsLabel>End Date</ContractDetailsLabel>
            <ContractDetailsValue>{dayjs.utc(latestEndDate).format('MMM D, YYYY')}</ContractDetailsValue>
          </FlexerColumn>

          <FlexerColumn gap="4px">
            <ContractDetailsLabel>Renewal Deadline</ContractDetailsLabel>
            <ContractDetailsValue>
              {renewalDeadline ? dayjs.utc(renewalDeadline).format('MMM D, YYYY') : 'none'}
            </ContractDetailsValue>
          </FlexerColumn>
        </ContractDetailsWrapperLower>
      </ContractDetailsWrapper>
      <ContractFilesModal />
    </Fragment>
  );
};
