import { columnGenerator } from './columnGenerator';
import { displayInterpretedValue } from './utils';

const column = columnGenerator({ entityName: 'subscriptions' });

export const generateColumns = ({ onEditClick, formatDateWithLocale }) => {
  const columns = [
    column.header(),
    column.customer({ onCustomerClick: null }),
    {
      Header: 'Pricing Plan',
      accessor: 'pricing_plan_name',
      width: 130,
      Cell: ({ row }) =>
        displayInterpretedValue({
          entityName: 'pricing plan',
          interpretedValue: row.original.interpreter_pricing_plan_name,
          title: row.original.pricing_plan_name,
          confidence: row.original.pricing_plan_confidence,
        }),
    },
    column.startDate({ formatter: formatDateWithLocale }),
    column.endDate({ formatter: formatDateWithLocale }),
    {
      Header: 'Trial Units',
      accessor: 'trial_units',
      width: 80,
      Cell: ({ row }) => <>{row.original.trial_units}</>,
    },
    column.actions({ onEditClick: onEditClick }),
  ];

  return columns;
};
