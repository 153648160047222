// TEMPORARY DISABLE. TODO: Optimistic updates
/*eslint no-unused-vars: "off"*/
import * as Sentry from '@sentry/react';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { AppContext } from 'AppContext';
import { usePrefetchingPages } from 'utils/hooks';
import { useToasts } from 'components/Toasts';
import { refreshTransactionFields as _refreshTransactionFields } from 'api/integrations';
import { CACHE_KEY as MONTHLY_BREAKDOWN_CACHE_KEY } from 'api/monthlyBreakdown';
import { CACHE_KEY as COHORTS_CACHE_KEY } from 'api/cohorts';
import { CACHE_KEY as REVENUE_DETAILS_CACHE_KEY } from 'api/revenueDetails';
import { CACHE_KEY as REVENUE_SPREADS_CACHE_KEY } from 'api/revenueSpreads';
import { CACHE_KEY as CUSTOMERS_CACHE_KEY } from 'api/customers';
import { CACHE_KEY as EXTERNAL_UPDATES_CACHE_KEY } from 'api/externalUpdates/hooks';
import { CACHE_KEY as BILLING_CACHE_KEY } from 'api/billing';
import { ORGANIZATION_HELPERS_CACHE_KEY } from 'api/organizations/hooks';
import { FEATURES, isFeatureActive } from 'utils/featureUtils';
import { INVOICE_STATUSES } from 'views/Billing/consts';
import { useDelegatedMutationPolling } from 'api/jobHooks.helper';
import {
  getTransactions,
  getTransaction,
  createTransaction,
  updateTransaction,
  deleteTransaction,
  bulkUploadTransactions,
  duplicateTransaction as _duplicateTransaction,
  bulkReviewTransactions,
  restoreTransaction as _restoreTransaction,
  getTillCancelMonthlyIncrementPercentages,
} from './requests';

export const CACHE_KEY = 'transactions';

export const REQUIRED_BILLING_PARAMS = { includeAccountingSpreadSum: true, includeProductMetadata: true };

export const useTransactionsAPI = ({
  orgId,
  filters,
  metadataFilter,
  autoFetch = true,
  enableToasts = true,
  onBulkUploadSettled = () => {},
}) => {
  const { orgConfigs } = useContext(AppContext);
  // Key to identify data by - has to include orgId and filters
  const dataKey = [CACHE_KEY, orgId, filters, metadataFilter];

  const { pushToast: wrappedPushToast, pushError: wrappedPushError } = useToasts();
  const pushToast = (...args) => {
    if (enableToasts) wrappedPushToast(...args);
  };
  const pushError = (...args) => {
    if (enableToasts) wrappedPushError(...args);
  };

  const queryClient = useQueryClient();

  const history = useHistory();

  const { data, error, isLoading, isFetching, refetch, dataUpdatedAt } = useQuery(
    dataKey,
    () => getTransactions({ orgId, filters, metadataFilter }),
    {
      enabled: autoFetch,
    },
  );

  usePrefetchingPages({
    orgId,
    queryClient,
    fetchedData: data,
    fetchParams: { filters, metadataFilter },
    cacheKey: CACHE_KEY,
    fetcher: (newFilters) =>
      getTransactions({
        orgId,
        filters: {
          ...newFilters?.filters,
          page: newFilters?.page,
        },
        metadataFilter: newFilters?.metadataFilter,
      }),
  });

  const invalidateCache = () => {
    queryClient.invalidateQueries(CACHE_KEY);
  };

  const invalidateAll = () => {
    queryClient.invalidateQueries(CACHE_KEY);
    queryClient.invalidateQueries(MONTHLY_BREAKDOWN_CACHE_KEY);
    queryClient.invalidateQueries(REVENUE_DETAILS_CACHE_KEY);
    queryClient.invalidateQueries(REVENUE_SPREADS_CACHE_KEY);
    queryClient.invalidateQueries(CUSTOMERS_CACHE_KEY);
    queryClient.invalidateQueries(EXTERNAL_UPDATES_CACHE_KEY);
    queryClient.invalidateQueries(ORGANIZATION_HELPERS_CACHE_KEY);
    queryClient.invalidateQueries(BILLING_CACHE_KEY);
    queryClient.invalidateQueries(COHORTS_CACHE_KEY);
  };

  const addTransaction = useMutation(({ data }) => createTransaction({ orgId, body: data }), {
    onMutate: async ({ data: newTransaction }) => {
      /*
      TODO: Optimistic updates
      await queryClient.cancelQueries(dataKey);

      const previousTransactions = queryClient.getQueryData(dataKey);

      queryClient.setQueryData(dataKey, (old) => [...old, newTransaction]);

      return { previousTransactions };
      */
    },

    onError: (err, _newTransaction, context) => {
      /*
      TODO: Optimistic updates
      queryClient.setQueryData(dataKey, context.previousTransactions);
      */

      pushError(err, 'Failed to create transaction.');
    },

    onSuccess: () => {
      pushToast('Successfully created transaction!', 'success');
    },

    onSettled: invalidateAll,
  });

  const editTransaction = useMutation(
    ({ id, data, params }) => updateTransaction({ orgId, transactionId: id, body: data, params }),
    {
      onMutate: async ({ id, data: newTransaction }) => {
        /*
      TODO: Optimistic updates
      await queryClient.cancelQueries(dataKey);

      const previousTransactions = queryClient.getQueryData(dataKey);

      queryClient.setQueryData(dataKey, (old) => {
        old.forEach((transaction) => {
          if (transaction.id === id.toString()) {
            transaction.name = newTransaction.name;
          }
        });
        return old;
      });

      return { previousTransactions };
      */
      },

      onError: (err, _newTransaction, context) => {
        /*
      TODO: Optimistic updates
      queryClient.setQueryData(dataKey, context.previousTransactions);
      */

        pushError(err, 'Failed to update transaction.');
      },

      onSuccess: () => {
        pushToast('Successfully updated transaction!', 'success');
      },

      onSettled: invalidateAll,
    },
  );

  const unconfirmTransaction = useMutation(
    ({ id }) => updateTransaction({ orgId, transactionId: id, body: { confirmed: false } }),
    {
      onMutate: async () => {},

      onError: (err, _newTransaction, context) => {
        /*
      TODO: Optimistic updates
      queryClient.setQueryData(dataKey, context.previousTransactions);
      */

        pushError(err, 'Failed to unconfirm transaction.');
      },

      onSuccess: () => {
        pushToast('Successfully unconfirmed transaction!', 'success');
      },

      onSettled: invalidateAll,
    },
  );

  // TODO: deprecated in favor of going useCSVImports
  const bulkUpload = useDelegatedMutationPolling({
    mutationFn: async ({ data, params }) => bulkUploadTransactions({ orgId, transactions: data, params }),
    mutationOptions: {
      onProgress: () =>
        pushToast(
          `Bulk uploading transactions is in progress, but may take a while. Please check back in a few minutes.`,
          'success',
        ),
      onFailed: () => {
        throw new Error(`We did not finish uploading transactions. Please try again later.`);
      },
      onMaxAttempts: () => {
        Sentry.captureMessage(`Uploading transactions is taking long to complete for org ${orgId}`, 'warning');
      },
      onError: (err, request, context) => {
        /*
        TODO: Optimistic updates
        queryClient.setQueryData(dataKey, context.previousTransactions);
        */
        pushError(err, 'Failed to upload transactions.', -1);
      },

      onSuccess: (response, request) => {
        pushToast(
          `${
            response.updatedTransactions.length
              ? 'Successfully updated ' + response.updatedTransactions.length + ' transactions'
              : ''
          } ${
            response.createdTransactions?.length
              ? 'Successfully created ' + response.createdTransactions.length + ' transactions'
              : ''
          }`,
          'success',
          -1,
        );

        setTimeout(() => {
          const hasBillingEnabled = isFeatureActive({ feature: FEATURES.BILLING, orgConfigs });
          if (hasBillingEnabled && request.params.autoCreateInvoicingSchedules) {
            history.push({
              pathname: '/billing/invoices',
              state: { status: INVOICE_STATUSES.UNSENT },
            });
          } else {
            history.push('/transactions');
          }
        }, 1000);
      },
      onSettled: async (response) => {
        invalidateAll();
        await onBulkUploadSettled?.(response);
      },
    },
  });

  const removeTransaction = useMutation(({ id, params }) => deleteTransaction({ orgId, transactionId: id, params }), {
    onMutate: async ({ id }) => {
      /*
      TODO: Optimistic updates
      await queryClient.cancelQueries(dataKey);

      const previousTransactions = queryClient.getQueryData(dataKey);

      queryClient.setQueryData(dataKey, (old) => {
        return old.filter((transaction) => transaction.id !== id.toString());
      });

      return { previousTransactions };
      */
    },

    onError: (err, _newTransaction, context) => {
      /*
      TODO: Optimistic updates
      queryClient.setQueryData(dataKey, context.previousTransactions);
      */

      pushError(err, 'Failed to remove transaction.');
    },

    onSuccess: () => {
      pushToast('Successfully removed transaction!', 'success');
    },

    onSettled: invalidateAll,
  });

  const restoreTransaction = useMutation(({ id }) => _restoreTransaction({ orgId, transactionId: id }), {
    onMutate: async ({ id }) => {
      /*
      TODO: Optimistic updates
      await queryClient.cancelQueries(dataKey);

      const previousTransactions = queryClient.getQueryData(dataKey);

      queryClient.setQueryData(dataKey, (old) => {
        return old.filter((transaction) => transaction.id !== id.toString());
      });

      return { previousTransactions };
      */
    },

    onError: (err, _newTransaction, context) => {
      /*
      TODO: Optimistic updates
      queryClient.setQueryData(dataKey, context.previousTransactions);
      */

      pushError(err, 'Failed to restore transaction.');
    },

    onSuccess: () => {
      pushToast('Successfully restored transaction!', 'success');
    },

    onSettled: invalidateAll,
  });

  const duplicateTransaction = useMutation(({ id }) => _duplicateTransaction({ orgId, transactionId: id }), {
    onMutate: async ({ id: idNew }) => {
      /*
      TODO: Optimistic updates
      await queryClient.cancelQueries(dataKey);

      const previousTransactions = queryClient.getQueryData(dataKey);

      queryClient.setQueryData(dataKey, (old) => [...old, old.filter((transaction) => transaction.id === idNew)[0]]);

      return { previousTransactions };
      */
    },

    onError: (err, _newTransaction, context) => {
      /*
      TODO: Optimistic updates
      queryClient.setQueryData(dataKey, context.previousTransactions);
      */

      pushError(err, 'Failed to duplicate transaction.');
    },

    onSuccess: () => {
      pushToast('Successfully duplicated transaction!', 'success');
    },

    onSettled: invalidateAll,
  });

  const bulkReview = useMutation(({ ids }) => bulkReviewTransactions({ orgId, transactionIds: ids }), {
    onSettled: invalidateCache,
  });

  const refreshTransactionFields = useMutation(
    ({ transactionId, fields }) => _refreshTransactionFields({ orgId, transactionId, fields }),
    {
      onSuccess: () => pushToast('Successfully refreshed transaction', 'success'),
      onError: (err) => pushError(err, 'Could not refresh transaction'),
    },
  );

  return {
    data: data?.data,
    metadata: data?.metadata,
    error,
    isLoading,
    isFetching,
    dataUpdatedAt,
    operations: {
      addTransaction,
      editTransaction,
      removeTransaction,
      duplicateTransaction,
      unconfirmTransaction,
      restoreTransaction,
      bulkUpload,
      bulkReview,
      refetch,
      invalidateCache,
      invalidateAll,
      refreshTransactionFields,
    },
  };
};

export const useTransactionAPI = ({ orgId, transactionId, params, autoFetch = true, staleTime = null }) => {
  const dataKey = [transactionId, orgId, params];
  const options = { enabled: autoFetch };
  if (staleTime) options.staleTime = staleTime;
  const { data, error, isLoading, isFetching, refetch, dataUpdatedAt } = useQuery(
    dataKey,
    () => getTransaction({ orgId, transactionId, params }),
    options,
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    dataUpdatedAt,
    operations: {
      refetch,
    },
  };
};

export const useTillCancelMonthlyIncrementPercentages = ({
  orgId,
  transactionId,
  autoFetch = true,
  usesAutoIncrease,
  increments,
  monthlyAmount,
  endDate,
  autoIncreaseMonths,
}) => {
  const params = {
    usesAutoIncrease,
    increments,
    monthlyAmount,
    endDate,
    autoIncreaseMonths,
  };
  const dataKey = ['tillCancelMonthlyIncrementPercentages', orgId, transactionId, params];
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    dataKey,
    () => getTillCancelMonthlyIncrementPercentages({ orgId, params }),
    { enabled: autoFetch },
  );

  return {
    data,
    error,
    isLoading,
    isFetching,
    operations: {
      refetch,
    },
  };
};
