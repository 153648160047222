import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { AppContext } from 'AppContext';
import { METADATA_FILTER_TYPES } from 'shared/Filters/MetadataFilter';
import { ReactComponent as HeaderIcon } from 'images/metadata_modal_icon.svg';
import { Loader } from 'components/Loaders';
import { FormikCustomSelector } from 'components/Controls';
import { ModalContainer } from 'components/Modal';
import { READ_ONLY_METADATA } from 'models/transaction';
import { useMetadataOptionsAPI } from 'api/metadata';
import { METADATA_TYPES } from '../consts';

const MetadataModal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 30px 120px rgba(0, 21, 46, 0.4);
  border-radius: 20px;
  padding: 24px;
  width: 280px;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const CancelButton = styled.div`
  padding: 11px 14px;
  font-size: 14px;
  line-height: 18px;
  background: var(--primaryBlack5);
  border: 1px solid var(--primaryBlack5);
  border-radius: 100px;
  margin-right: 10px;
  cursor: pointer;
`;

const SubmitButton = styled.div`
  padding: 11px 14px;
  font-size: 14px;
  color: #ffffff;
  line-height: 18px;
  background: ${(props) => (props.isRemove ? 'var(--primaryRed)' : 'var(--primaryGreen)')};
  box-shadow: 0px 4px 20px rgba(82, 181, 120, 0.2);
  border-radius: 100px;
  border: none;
  cursor: pointer;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: center;
`;

const StyledHeaderIcon = styled.div`
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  top: -20px;
`;

const FieldsWrapper = styled.div`
  > *:first-child {
    margin-bottom: 20px;
  }
`;

const METADATA_MAPPER = {
  [METADATA_TYPES.TRANSACTIONS]: METADATA_FILTER_TYPES.TRANSACTIONS,
  [METADATA_TYPES.CUSTOMERS]: METADATA_FILTER_TYPES.CUSTOMERS,
  [METADATA_TYPES.PRODUCTS]: METADATA_FILTER_TYPES.PRODUCTS,
};

const MetadataSectionActionsModal = ({ metadataType, actionMetadataItem, closeModal, modalAction }) => {
  const { orgId } = useContext(AppContext);
  const [metadataOptions, setMetadataOptions] = useState(null);

  const initialValue = actionMetadataItem
    ? actionMetadataItem
    : {
        key: '',
        value: '',
      };

  const { metadataOptions: fetchedMetadataOptions } = useMetadataOptionsAPI({
    orgId,
    metadataFilterType: METADATA_MAPPER[metadataType],
  });

  useEffect(() => setMetadataOptions(fetchedMetadataOptions), [fetchedMetadataOptions]);

  return (
    <ModalContainer>
      <MetadataModal>
        <StyledHeaderIcon>
          <HeaderIcon />
        </StyledHeaderIcon>
        <ModalTitle>{modalAction === 'create' ? 'Metadata Creating' : 'Metadata Updating'}</ModalTitle>
        {!metadataOptions ? (
          <div className="w-100 flexer">
            <Loader containerStyles={{ width: 40 }} />
          </div>
        ) : (
          <Formik
            initialValues={initialValue}
            validationSchema={Yup.object({
              key: Yup.string()
                .nullable()
                .required('Please, select type')
                .test(
                  'Cannot use a reserved field name',
                  'Cannot use a reserved field name',
                  (value) => !new RegExp(READ_ONLY_METADATA.join('|')).test(value),
                ),
              //we allow null value so we dont use 'required()'
              value: Yup.string().nullable().notOneOf([undefined, ''], 'Please, select value'),
            })}
            enableReinitialize={true}
            onSubmit={(values) => {
              closeModal(values);
            }}
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <FieldsWrapper>
                <FormikCustomSelector
                  creatable
                  isClearable={true}
                  label="Type"
                  placeholder="Select type"
                  name="key"
                  handleChange={(option) => {
                    if (option) {
                      setFieldValue('key', option.value);
                      setFieldValue('value', null);
                    } else {
                      setFieldValue('key', '');
                      setFieldValue('value', null);
                    }
                  }}
                  options={Object.keys(metadataOptions).map((key) => {
                    return { value: key, label: key };
                  })}
                  onCreateOption={(newType) => {
                    setMetadataOptions({ ...metadataOptions, [newType]: [] });
                    setFieldValue('key', newType);
                    setFieldValue('value', null);
                  }}
                />

                <FormikCustomSelector
                  key={`${values.key}${values.value}`}
                  creatable
                  isDisabled={!values.key}
                  isClearable={true}
                  label="Value"
                  placeholder="Select value"
                  name="value"
                  handleChange={(option) => {
                    if (option) {
                      setFieldValue('value', option.value);
                    } else {
                      setFieldValue('value', null);
                    }
                  }}
                  options={
                    metadataOptions[values.key]
                      ? metadataOptions[values.key].map((value) => {
                          return { value: value, label: String(value) };
                        })
                      : []
                  }
                  onCreateOption={(newValue) => {
                    setMetadataOptions({
                      ...metadataOptions,
                      [values.key]: [...metadataOptions[values.key], newValue],
                    });
                    setFieldValue('value', newValue);
                  }}
                />

                <ButtonsRow>
                  <CancelButton onClick={() => closeModal(null)}>No, cancel</CancelButton>
                  <SubmitButton data-cy="metadata-submit-button" onClick={() => handleSubmit()}>
                    {modalAction === 'edit' ? 'Yes, update' : 'Yes, create'}
                  </SubmitButton>
                </ButtonsRow>
              </FieldsWrapper>
            )}
          </Formik>
        )}
      </MetadataModal>
    </ModalContainer>
  );
};

export { MetadataSectionActionsModal };
